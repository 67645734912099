<template>
<div v-if="showPopup" class="popup-container">
  <div class="popup-inner-container">
    <div class="popup-inner">
      <div class="header-container">
        <img src="../../assets/FinalImages/NewUI-NJLSLogo.png" alt="">
        <h3>is now</h3>
        <img src="../../assets/FinalImages/NewUI-LREXLogo.png" alt="">
      </div>
      <div class="image-container">
        <div class="image-container-inner">
            <img src="../../assets/FinalImages/NewUI-NJLSsite.png" alt="">
            <img v-if="showLREXUI" class="lrex-img" src="../../assets/FinalImages/NewUI-LREXsite.png" alt="">
        </div>
      </div>
      <p>The same functionality you expect, with a refreshed look.</p>
      <p class="bottom-text">Once you sign in, you will be redirected to LREX.</p>
      <div class="button-container">
        <button class="main-button-blue-white" @click="CloseNewUIPopup()">Okay</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            showPopup: false,
            showLREXUI: false,
            njlsSiteImg: '',
            lrexSiteImg: ''
        }
    },
    methods:{
        CloseNewUIPopup(){
            localStorage.setItem("newUIAlertDisplayed", "true");
            this.showPopup = false;
            this.showLREXUI = false;
        }
    },
    mounted(){
        //Toggle New UI Alert
        setTimeout(() => {
          if ((!localStorage.getItem("newUIAlertDisplayed")) && (location.host == "www.njls.com")) {
            this.showPopup = true;
            setTimeout(() => {
                this.showLREXUI = true;
            }, "2000")
          }
        }, 1200);
    }
}
</script>

<style scoped>
@import "../../styles/button-styles.css";

.popup-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 90%, rgba(236, 236, 236, 0.05) 100%);
}

.popup-inner-container{
  margin-top: 1%;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: auto;
  padding: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  animation: animate-popup 1s ease;
}

.popup-inner{
  display: flex;
  flex-direction: column;
}

.image-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.image-container img{
  width: auto;
  height: 120px;
  margin: 5px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.lrex-img{
  animation: animate-image 2s ease;
}

@keyframes animate-image {
  from{margin-left: -100px; opacity: 0;}
  to{margin-left: 5px; opacity: 1;}
}

@keyframes animate-popup{
  from{margin-top: -10%;}
  to{margin-top: 1%;}
}

.header-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.header-container img{
  height: 20px;
  width: auto;
  margin: 10px;
}

.button-container{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.bottom-text{
  margin-top: 0;
}

@media only screen and (max-width: 1000px) {
  .image-container{
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 600px){
    .image-container{
        flex-direction: column;
    }
    .popup-inner-container{
        width: 90%;
    }

    .lrex-img{
        animation: animate-image-mobile 2s ease;
    }

    @keyframes animate-image-mobile {
        from{margin-top: -50px; opacity: 0;}
        to{margin-top: 5px; opacity: 1;}
    }
}

</style>
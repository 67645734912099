<template>
  <div v-if="routeCodeData" class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <svg class="close-shipment-info" @click="closeAddressInfo()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">Route Details</h1>
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.75 148.87">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M154.27,12.53c.87-.17,1.78,0,2.54,.46,1.29,.53,2.2,1.79,2.2,3.28l.74,103.46c.07,1.27-.56,2.54-1.73,3.22l-42.25,25.21c-.6,.46-1.34,.72-2.15,.72-.2,0-.39-.01-.57-.05L3.39,136.62c-1.89-.08-3.39-1.64-3.39-3.55V27.85H0c-.04-1.4,.75-2.77,2.13-3.37L57.14,.31h0c.57-.26,1.22-.36,1.9-.27l95.23,12.49h0Zm-37.27,30.93v96.48l36-21.47-.7-97.26-35.3,22.25h0Zm-7,97.91V45l-46.8-5.97-1.54,44.72-16.43-11.19-16.43,9.29,3.4-46.78-25.19-3.21v98.07l103,11.44h0ZM102.56,12.88l-34.52,19.59,44.9,5.72,31.47-19.83-41.86-5.49h0ZM36.56,28.46L70.02,8.61l-11.03-1.44L16.4,25.89l20.15,2.56h0Z"/>
                                            </g>
                                        </svg>
                                        <h2>Route</h2>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <p name="geoCodeID">Route Code: {{routeCodeData.routeCode}}</p>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <select name="routeCodeSelect" v-model="routeCodeData.serviceNetwork" id="routeCodeSelect">
                                            <option disabled selected value="">Route Code</option>
                                            <option v-for="(route, index) in serviceNetworkData.items" :key="index" :value="route.serviceNetwork">{{route.serviceNetwork}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <loading-charts v-if="loadingData"/>
                    <button class="print-button" @click="updateRouteInfo()">Update Route</button>
                    
            </div>
    </div>
  </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import { updateRoute } from '../../../graphql/mutations';

export default {
    props:['geoCodeID', 'jwtToken'],
    components:{
        AlertUser,
        LoadingCharts
    },
    data(){
        return{
            routeCodeData: null,
            isActive: undefined,
            toggleAlertBox: false, 
            alertMessage: null,
            loadingData: false,
            toggleServiceData: "",
            serviceNetworkData: {}
        }
    },
    methods:{
        async listRouteCodes(){
            try {
                let listServiceNetworkData = await API.graphql({
                    query: `query listServiceNetworks{
                        listServiceNetworks{
                            items{
                                serviceNetwork
                            }
                        }
                    }`,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.serviceNetworkData = listServiceNetworkData.data.listServiceNetworks;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get service network information"
            }
        },
        toggleServiceDataOption(selectedService){
            this.toggleServiceData = selectedService
        },
        async getRouteForTable(){
            this.loadingTable = true;
            let graphqlQuery = `query getRoute{
                getRoute(routeCode: "${this.geoCodeID}"){
                    routeCode
                    products {
                        items {
                            description
                            additionalService
                            price
                            productName
                            owner
                            serviceNetwork
                        }
                    }
                    serviceNetwork
                }
            }`
            
            try{
                let getDropBox = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.routeCodeData = getDropBox.data.getRoute;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting route"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async updateRouteInfo(){
            this.loadingTable = true;
            try{
                let updateRouteData = await API.graphql({
                query:  updateRoute,
                variables:{
                    input: {
                        routeCode: this.routeCodeData.routeCode,
                        serviceNetwork: this.routeCodeData.serviceNetwork
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "Route updated"
                console.log(updateRouteData)
                await this.getRouteForTable();
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error updating route"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeAddressInfo(){
            this.$emit('closeAddressInfo', false)
        }
    },
    mounted(){
        this.getRouteForTable();
        this.listRouteCodes();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

    input, select{
        padding: 10px;
        font-size: 15px;
        border: 1px solid rgba(0,0,0,.336);
        border-radius: 15px;
        outline: none;
        margin-top: 5px;
    }


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    overflow-x: auto;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.service-option{
    padding: 5px 10px;
    border-radius: 999px;
    background-color: #e4e4e4;
    cursor: pointer;
    transition-duration: .5s;
}

.service-option:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.file-input{
    width: 80%;
}

.weight-container{
    background-color: #fff;
    margin: 0px 5% 2% 5%;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    overflow-y: auto;
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.update-location{
    flex-basis: 100%;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    padding: 0 !important;
    margin-top: 5px;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.geoCode-services{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.auto-complete-container{
    margin-top: 5px;
}

.service-container{
    display: flex;
    margin: 5px;
    flex-direction: column;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.add-price{
    margin-left: 0;
    margin-top: 5px;
    background-color: #33f18a;
}


.add-price:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.create-button{
    background-color: #33f18a;
    margin-left: 20px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 530px){
    .service-data{
        flex-direction: column;
    }
}
</style>
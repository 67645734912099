<template>
  <div v-if="shipmentData" class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <!-- <button class="close-shipment-info" @click="closeShipmentInfo()">Close</button> -->
                    <svg class="close-shipment-info" @click="closeShipmentInfo()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">Package Details {{shipmentData.shipmentID}}</h1>
                    <img src="../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                    <div class="label-container" id="label-container" v-if="labelData != null">
                        <shipment-label :baseLabelData="labelData" />
                    </div>
               
                    <div class="confirmContainerInner">
                        <div class="confirm-container-address">
                            <div v-if="!updateServiceAddressToggleValue" class="confirmContainerInner1">
                                <div class="confirm-svg">
                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                                        <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                            <g>
                                            <g>
                                                <polyline class="cls-2-alt" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                                                <line class="cls-2-alt" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                                                <polyline class="cls-2-alt" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                                                <line class="cls-2-alt" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                                                <line class="cls-2-alt" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                                            </g>
                                            <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                            <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                            <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                            <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                            <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                            <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <h2>Delivery Address</h2>
                                </div>
                                
                                <div  class="confirmLabelContainer">
                                    <p name="companyName">{{shipmentData.serviceAddress.address.companyName}}</p>
                                </div>
                                
                                <div  class="confirmLabelContainer">
                                    <p name="address1">{{shipmentData.serviceAddress.address.address1}}</p>
                                </div>
                                
                                <div v-if="(shipmentData.serviceAddress.address.address2)" class="confirmLabelContainer">
                                    <p name="address2">{{shipmentData.serviceAddress.address.address2}}</p>
                                </div>
                                
                                <div  class="confirmLabelContainer">
                                    <p name="city">{{shipmentData.serviceAddress.address.city}}, {{shipmentData.serviceAddress.address.state}} {{shipmentData.serviceAddress.address.zipCode}}</p>
                                </div>

                                <div  class="confirmLabelContainer">
                                    <p name="phone">{{shipmentData.serviceAddress.address.phone}}</p>
                                </div>

                                <div v-if="(shipmentData.serviceAddress.address.attention)" class="confirmLabelContainer">
                                    <label for="contactName">Contact Name:</label>
                                    <p style="white-space: nowrap;" name="contactName">{{shipmentData.serviceAddress.address.attention}}</p>
                                </div>
                            </div>

                            <div v-if="updateServiceAddressToggleValue" class="confirmContainerInner1">
                                <div class="auto-complete-container">
                                    <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                                </div>
                                <div class="confirm-svg">
                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                                        <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                            <g>
                                            <g>
                                                <polyline class="cls-2-alt" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                                                <line class="cls-2-alt" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                                                <polyline class="cls-2-alt" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                                                <line class="cls-2-alt" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                                                <line class="cls-2-alt" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                                            </g>
                                            <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                            <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                            <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                            <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                            <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                            <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <h2>Delivery Address</h2>
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" v-model="shipmentData.serviceAddress.address.companyName">
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" id="address1" disabled v-model="shipmentData.serviceAddress.address.address1">
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input placeholder="Suite/Building" type="text" v-model="shipmentData.serviceAddress.address.address2">
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" id="city" disabled v-model="shipmentData.serviceAddress.address.city" placeholder="City">
                                </div>

                                <div class="confirmLabelContainer">
                                    <select disabled required name="state" id="state" v-model="shipmentData.serviceAddress.address.state">
                                        <option value="" disabled selected>State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" id="zipcode" disabled v-model="shipmentData.serviceAddress.address.zipCode" placeholder="Zip Code">
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" id="phone" v-model="shipmentData.serviceAddress.address.phone" placeholder="Phone">
                                </div>

                                <div class="confirmLabelContainer">
                                    <label for="contactName">Contact Name:</label>
                                    <input type="text" v-model="shipmentData.serviceAddress.address.attention">
                                </div>
                            </div>

                            <div v-if="!updatePickupAddressToggleValue" class="confirmContainerInner1">
                                <div class="confirm-svg">
                                        <svg version="1.1" id="Layer_2_00000011015521083262897090000013693107950429558190_"
                                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 320.6 219.7"
                                                style="enable-background:new 0 0 320.6 219.7;" xml:space="preserve">
                                            <g id="_Ñëîé_1">
                                                <g>
                                                    <g>
                                                        <polyline class="st1" points="59.6,21 93.4,54.7 21,127.6 5,111.7 			"/>
                                                        <path class="st1" d="M101.7,187l1.4,1.2l4.7,4.7c4.6,4.6,5.4,12,1.3,17c-4.7,5.8-13.2,6.1-18.4,1l-22.1-21.7
                                                            c-5-5-9.4-10.6-13-16.6c-5-8.4-8.4-17.7-10.2-27.3L43.1,132l-13.3-13.2"/>
                                                        <path class="st1" d="M106.1,191.2l20,19.9c4.9,4.8,12.7,4.8,17.5-0.1c4.8-4.9,4.8-12.7-0.1-17.5l-1-1l-4.4-4.3l-2.5-2.5"/>
                                                        <path class="st1" d="M144.7,194.8l14,13.9l-0.7-0.7l1.9,1.9c4.9,4.8,12.7,4.8,17.6-0.1c2.7-2.7,3.8-6.3,3.5-9.8
                                                            c-0.2-2.8-1.4-5.6-3.6-7.8l-9.1-8.7"/>
                                                        <path class="st1" d="M85.1,63.1l15.1,14.9l11.4-3.4c12.2-3.7,25.5-1.7,36.1,5.3l51,33.9c2.1,1.4,3,3.7,2.8,6c-0.1,1-0.4,2-1,2.9
                                                            c-5.9,9-18.2,11.3-26.9,5L159,117.4c-1.2-0.8-2.5-1.3-3.8-1.4c-2.4-0.2-4.8,0.7-6.5,2.5c-2.8,3.1-2.7,7.8,0.3,10.8l62,61.6
                                                            c5.2,5.2,4.9,13.8-1,18.6c-5,3.9-12.2,3.2-16.8-1.3l-14.5-14.3"/>
                                                    </g>
                                                    <g>
                                                        <polyline class="st1" points="237.1,206.3 315.6,206.3 315.6,5 115.9,5 115.9,49.6 			"/>
                                                        <g>
                                                            <line class="st1" x1="258.2" y1="177.4" x2="253.3" y2="177.4"/>
                                                            <line class="st1" x1="285.9" y1="177.4" x2="281" y2="177.4"/>
                                                        </g>
                                                    </g>
                                                    <polyline class="st1" points="242.2,5 242.2,55.2 213.6,38.6 185,55.2 185,5 		"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2>Pickup Address</h2>
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <p name="companyName">{{shipmentData.deliveryPickup.address.companyName}}</p>
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <p name="address1">{{shipmentData.deliveryPickup.address.address1}}</p>
                                </div>
                                
                                <div v-if="shipmentData.deliveryPickup.address.address2" class="confirmLabelContainer">
                                    <p name="address2">{{shipmentData.deliveryPickup.address.address2}}</p>
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <p name="city">{{shipmentData.deliveryPickup.address.city}}, {{shipmentData.deliveryPickup.address.state}}, {{shipmentData.deliveryPickup.address.zipCode}}</p>
                                </div>

                                <div class="confirmLabelContainer">
                                    <p name="phone">{{shipmentData.deliveryPickup.address.phone}}</p>
                                </div>

                                <div v-if="shipmentData.deliveryPickup.address.attention" class="confirmLabelContainer">
                                    <label for="contactName">Contact Name:</label>
                                    <p style="white-space: nowrap;" name="contactName">{{shipmentData.deliveryPickup.address.attention}}</p>
                                </div>
                            </div>

                            <div v-if="updatePickupAddressToggleValue" class="confirmContainerInner1">
                                <div class="auto-complete-container">
                                    <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                                </div>
                                <div class="confirm-svg">
                                        <svg version="1.1" id="Layer_2_00000011015521083262897090000013693107950429558190_"
                                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 320.6 219.7"
                                                style="enable-background:new 0 0 320.6 219.7;" xml:space="preserve">
                                            <g id="_Ñëîé_1">
                                                <g>
                                                    <g>
                                                        <polyline class="st1" points="59.6,21 93.4,54.7 21,127.6 5,111.7 			"/>
                                                        <path class="st1" d="M101.7,187l1.4,1.2l4.7,4.7c4.6,4.6,5.4,12,1.3,17c-4.7,5.8-13.2,6.1-18.4,1l-22.1-21.7
                                                            c-5-5-9.4-10.6-13-16.6c-5-8.4-8.4-17.7-10.2-27.3L43.1,132l-13.3-13.2"/>
                                                        <path class="st1" d="M106.1,191.2l20,19.9c4.9,4.8,12.7,4.8,17.5-0.1c4.8-4.9,4.8-12.7-0.1-17.5l-1-1l-4.4-4.3l-2.5-2.5"/>
                                                        <path class="st1" d="M144.7,194.8l14,13.9l-0.7-0.7l1.9,1.9c4.9,4.8,12.7,4.8,17.6-0.1c2.7-2.7,3.8-6.3,3.5-9.8
                                                            c-0.2-2.8-1.4-5.6-3.6-7.8l-9.1-8.7"/>
                                                        <path class="st1" d="M85.1,63.1l15.1,14.9l11.4-3.4c12.2-3.7,25.5-1.7,36.1,5.3l51,33.9c2.1,1.4,3,3.7,2.8,6c-0.1,1-0.4,2-1,2.9
                                                            c-5.9,9-18.2,11.3-26.9,5L159,117.4c-1.2-0.8-2.5-1.3-3.8-1.4c-2.4-0.2-4.8,0.7-6.5,2.5c-2.8,3.1-2.7,7.8,0.3,10.8l62,61.6
                                                            c5.2,5.2,4.9,13.8-1,18.6c-5,3.9-12.2,3.2-16.8-1.3l-14.5-14.3"/>
                                                    </g>
                                                    <g>
                                                        <polyline class="st1" points="237.1,206.3 315.6,206.3 315.6,5 115.9,5 115.9,49.6 			"/>
                                                        <g>
                                                            <line class="st1" x1="258.2" y1="177.4" x2="253.3" y2="177.4"/>
                                                            <line class="st1" x1="285.9" y1="177.4" x2="281" y2="177.4"/>
                                                        </g>
                                                    </g>
                                                    <polyline class="st1" points="242.2,5 242.2,55.2 213.6,38.6 185,55.2 185,5 		"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2>Pickup Address</h2>
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" placeholder="Company Name" v-model="shipmentData.deliveryPickup.address.companyName">
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" placeholder="Address" disabled id="address1" v-model="shipmentData.deliveryPickup.address.address1">
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" placeholder="Suite/Building" v-model="shipmentData.deliveryPickup.address.address2">
                                </div>

                                <div class="confirmLabelContainer">
                                    <input placeholder="City" type="text" disabled id="city" v-model="shipmentData.deliveryPickup.address.city">
                                </div>

                                <div class="confirmLabelContainer">
                                    <select disabled required name="state" id="state" v-model="shipmentData.deliveryPickup.address.state">
                                        <option value="" disabled selected>State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" placeholder="Phone" id="phone" v-model="shipmentData.deliveryPickup.address.phone">
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" disabled id="zipcode" placeholder="ZipCode" v-model="shipmentData.deliveryPickup.address.zipCode">
                                </div>

                                <div class="confirmLabelContainer">
                                    <label for="contactName">Contact Name:</label>
                                    <input type="text" v-model="shipmentData.deliveryPickup.address.attention">
                                </div>
                            </div>
                        </div>

                        <div class="confirmContainerInner2">
                            <div class="confirm-svg notifications">
                                <svg data-name="Livello 1" viewBox="10 12.5 40 40" xmlns="http://www.w3.org/2000/svg"><path d="M1 33A32 32 0 1 0 33 1 32 32 0 0 0 1 33Z" fill="none" class="fill-000000"></path><rect height="24.18" rx="1.48" ry="1.48" width="32" x="17" y="21" stroke-miterlimit="10" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></rect><path d="M48.44 21.83 37.53 33.54a6.27 6.27 0 0 1-9.07 0l-10.9-11.7M39.19 31.75l8.96 12.76M26.98 31.88 17.7 44.51" stroke-linejoin="round" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></path></svg>
                                <h2>Notifications</h2>
                            </div>
                            
                            <div v-if="shipmentData.notify" class="confirmLabelContainer2">
                                <label for="deliveryemail">Delivery Email:</label>
                                <div class="notificationArray" name="deliveryemail" v-for="(email, index) in shipmentData.notify[0].delivery[0].email" :key="index">
                                        <p>{{shipmentData.notify[0].delivery[0].email[index]}}</p>
                                </div>
                            </div>

                            <div v-if="shipmentData.notify" class="confirmLabelContainer2">
                                <label for="deliveryphone">Delivery Phone:</label>
                                <div class="notificationArray" name="deliveryphone" v-for="(email, index) in shipmentData.notify[0].delivery[0].phone" :key="index">
                                        <p>{{shipmentData.notify[0].delivery[0].phone[index]}}</p>
                                </div>
                            </div>

                            <div v-if="shipmentData.notify" class="confirmLabelContainer2">
                                <label for="nonDeliveryemail">Non Delivery Email:</label>
                                <div class="notificationArray" name="nonDeliveryemail" v-for="(email, index) in shipmentData.notify[0].nonDelivery[0].email" :key="index">
                                        <p>{{shipmentData.notify[0].nonDelivery[0].email[index]}}</p>
                                </div>
                            </div>

                            <div v-if="shipmentData.notify" class="confirmLabelContainer2">
                                <label for="nonDeliveryphone">Non Delivery Phone:</label>
                                <div class="notificationArray" name="nonDeliveryphone" v-for="(email, index) in shipmentData.notify[0].nonDelivery[0].phone" :key="index">
                                        <p>{{shipmentData.notify[0].nonDelivery[0].phone[index]}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="weight-container">
                            <div v-if="shipmentData.deliveryInstructions" class="confirmLabelContainer">
                                <label style="font-weight: bold; white-space: nowrap;" for="DeliveryInstructions">Delivery Instructions:</label>
                                <p style="text-align: left;" name="DeliveryInstructions">{{shipmentData.deliveryInstructions}}</p>
                            </div>

                            <div class="confirmLabelContainer">
                                <label style="font-weight: bold;" for="service">Service:</label>
                                <p name="service">{{shipmentServiceType}}</p>
                            </div>

                            <div v-if="shipmentData.additionalServices" class="confirmLabelContainer">
                                <label v-if="shipmentData.additionalServices.length" style="font-weight: bold; white-space: nowrap;" for="service">Additional Services:</label>
                                <div v-for="(additionalService, index) in shipmentData.additionalServices" :key="index">
                                    <p style="margin-right: 5px;">{{additionalService}}</p>
                                </div>
                            </div>
                            <div class="confirmLabelContainer">
                                <label style="font-weight: bold; white-space: nowrap;" for="weight">Package Weight:</label>
                                <p name="weight" v-if="shipmentData.weight == 1">Less than 16(lbs)</p>
                                <p v-else name="weight">{{shipmentData.weight}} (lbs)</p>
                            </div>
                            <div v-if="(AdminUser || InternalAdmin) && (shipmentData.inTransitKey != null)" class="add-comment-container">
                                <textarea type="text" cols="50" rows="5" maxlength="50" placeholder="Add Comment" v-model="internalCommentInput"></textarea>
                                <button class="internal-comment-button" @click="addInternalComment()">Add</button>
                            </div>
                        </div>
                            <div>
                                <div class="shipment-history-container">
                                    <div class="history-container" v-for="(items, index) in shipmentData.activity" v-bind:key="items">
                                        <div class="check-mark-container" v-if="shouldShowItem(items)">
                                            <div class="check-mark" :class="{active:index==0, activeLast:index==shipmentData.activity.length - 1}">
                                            <div class="check-mark-item"><img src="../../assets/FinalImages/checkMark.png" alt=""></div>
                                            </div>
                                        </div>
                                        <div class="inner-history-container" v-if="shouldShowItem(items)">
                                            <div class="history-description-container">
                                                <p class="history-description">{{items.description}}</p>
                                                <p class="history-process-date">{{items.activityTime}}</p>
                                            </div>
                                            <div v-if="items.internalComments != 'Scanned out to partner network'">
                                            <p>{{items.internalComments}}</p>
                                            </div>
                                            <div v-if="items.imageKeyID > 0">
                                            <div class="proof-delivery-container">
                                                <button v-if="(items.imageKeyID > 0)" @click="GetDeliveryImages(items.imageKeyID, index)" class="main-button-blue-white">Show Image</button>
                                                <img class="proof-delivery-image" loading="lazy" v-if="index == indexImageURL" :src="linkImageURL" alt="">
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <loading-charts v-if="loadingData"/>
                    <button v-if="(updatePickupAddressToggleValue || updateServiceAddressToggleValue) && !validateCustomAddress" class="print-button create-button" @click="updateShipmentData()">Update Address</button>
                    <button v-if="validateCustomAddress" class="print-button create-button" @click="checkAddressCorrection()">Check Address</button>
                    <button v-if="(updatePickupAddressToggleValue || updateServiceAddressToggleValue)" class="print-button cancel-button" @click="cancelAddressUpdate()">Cancel Update</button>
                    <button v-if="((shipmentData.inTransitKey != null) && !updateServiceAddressToggleValue) && (AdminUser || InternalAdmin)" class="print-button" @click="updatePickupAddressToggle()">Update Pickup Address</button>
                    <button v-if="((shipmentData.inTransitKey != null) && !updatePickupAddressToggleValue) && (AdminUser || InternalAdmin)" class="print-button" @click="updateServiceAddressToggle()">Update Delivery Address</button>
                    <button v-if="(shipmentData.currentKey != null) || ((shipmentData.inTransitKey != null) && (AdminUser || InternalAdmin))" class="print-button" @click="PrintLabel()">Print Label</button>
                    <button v-if="((shipmentData.currentKey != null) || ((shipmentData.inTransitKey != null) && (AdminUser || InternalAdmin))) && isPickupPage" class="print-button" @click="PrintPickupLabel()">Print Pickup Label</button>
                    <!-- <button v-if="shipmentData.historyKey != null" class="print-button" @click="updateActivity()">Request Signature</button> -->
                    <button class="print-button" @click="printContent()">Print</button>
            </div>
    </div>
  </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
<AWSAddressCorrection v-if="updatePickupAddressToggleValue && validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="shipmentData.deliveryPickup.address"/>
<AWSAddressCorrection v-if="updateServiceAddressToggleValue && validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="shipmentData.serviceAddress.address"/>

</template>

<script>
import {API} from 'aws-amplify';
import axios from 'axios'
import AlertUser from '../../components/Popups/AlertUser.vue';
import ShipmentLabel from '../ShipmentDashboard/ShipmentLabel.vue';
import LoadingCharts from '../ShipmentDashboard/LoadingCharts.vue';
import {updateShipmentActivity, createShipmentLabelLambda, updateShipmentData} from '../../graphql/mutations'
import AWSAutoComplete from '../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../AWSLocation/AddressCorrection.vue'

export default {
    props:['trackShipmentID', 'userData', 'jwtToken', 'cognitoUser'],
    components:{
        AlertUser,
        ShipmentLabel,
        LoadingCharts,
        AWSAutoComplete,
        AWSAddressCorrection
    },
    data(){
        return{
            shipmentData: null,
            toggleAlertBox: false, 
            alertMessage: null,
            labelData: null,
            loadingData: false,
            updateServiceAddressToggleValue: false,
            updatePickupAddressToggleValue: false,
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            internalCommentInput: "",
            linkImageURL: '',
            indexImageURL: 0
        }
    },
    computed:{
        isPickupPage(){
            return this.$route.name == "PickupDashboard"
        },
        shipmentServiceType(){
            if(this.shipmentData.service == "SSLRLOC"){
                return "Standard"
            }else if(this.shipmentData.service == "PSLRLOC"){
                return "Priority"
            }else if(this.shipmentData.service == "SSLRPICK"){
                return "Pickup"
            }else{
                return this.shipmentData.service
            }
        }
    },
    methods:{
        shouldShowItem(items) {
            // Show the item if status ID is 49 and if InternalAdmin is true
            if(items.statusID != 49){
                return true
            }else if(items.statusID == 49 && (this.InternalAdmin == true)){
                return true
            }else{
                return false
            }
        },
        async GetDeliveryImages(imageKeyId, indexImageURL){
            let dataFound = false;
            const endpoints = [
                'https://s2ai.njls.com/s2ai/s2ai/read/4988C3F4-5619-4E41-A82B-2138586ECEA9/NJLS/DeliveryImages/0/Images/'+ imageKeyId + '.jpg',
                'https://s2ai.njls.com/s2ai/s2ai/read/4988C3F4-5619-4E41-A82B-2138586ECEA9/NJLS/DeliveryImages/0/Images/'+ imageKeyId + '.png'
            ]
            this.indexImageURL = indexImageURL;
            for(const endpoint of endpoints){
                try {
                    const response = await axios.get(endpoint);
                    if(response.data) {
                        this.linkImageURL = response.data;
                        dataFound = true;
                        break;
                    }
                }catch(error){
                    console.log(error)
                }
            }

            if(!dataFound){
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to access delivery image."
            }
        },
        async GetShipmentData(){
            try{
                let getShipment = await API.graphql({
                query:  `query getShipment{
                        getShipment(shipmentID: "${this.trackShipmentID}"){
                            owner
                            shipmentID
                            weight
                            currentKey
                            historyKey
                            inTransitKey
                            labelImage
                            accountNo
                            username
                            service
                            additionalServices
                            deliveryInstructions
                            serviceAddress{
                            address{
                                companyName
                                address1
                                address2
                                city
                                state
                                zipCode
                                attention
                                phone
                                latitude
                                longitude
                                relevance
                            }
                            }
                            deliveryPickup{
                            address{
                                companyName
                                address1
                                address2
                                city
                                state
                                zipCode
                                attention
                                phone
                                latitude
                                longitude
                                relevance
                            }
                            }
                            notify{
                            delivery{
                                email
                                phone
                            }
                            nonDelivery{
                                email
                                phone
                            }
                            }
                            activity{
                                internalComments
                                description
                                activityTime
                                imageKeyID
                                statusID
                            }
                        }
                        }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                if(getShipment.data.getShipment == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting shipment"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }else{
                    this.shipmentData = getShipment.data.getShipment;
                }
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting shipment"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }
            }
        },
        async addInternalComment(){
            let date = new Date();
            this.loadingData = true;
            document.querySelector(".internal-comment-button").disabled = true;
            //Status ID 49 = Internal Comment
            try {
                let shipmentActivity = await API.graphql({
                    query: updateShipmentActivity,
                    variables: {
                        input: {
                            shipmentID: this.shipmentData.shipmentID,
                            Code: "49",
                            Description: "Internal Comment",
                            statusID: "49",
                            ShipmentStage: "InternalComment",
                            ShipmentPoint: "InternalComment",
                            ShippingEvent: "InternalComment",
                            ActivityTime: date.toJSON(),
                            RecordedTime: date.toJSON(),
                            Comments: this.internalCommentInput,
                            InternalComments: this.internalCommentInput,
                            Notes: this.internalCommentInput,
                            ShipmentId: parseInt(this.shipmentData.shipmentID),
                            TripSheetId: 127,
                            ShipmentStatusId: 49,
                            ProcessedDate: date.toJSON(),
                            Latitude: "",
                            Longitude: "",
                            Altitude: ""
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "Comment Added"
                console.log(shipmentActivity.data.updateShipmentActivity)
                this.internalCommentInput = "";
                this.GetShipmentData();
                this.loadingData = false;
                document.querySelector(".internal-comment-button").disabled = false;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to add comment"
                this.loadingData = false;
                document.querySelector(".internal-comment-button").disabled = false;
            }
        },
        async updateActivity(){
            // this.loadingUser = true;
            try {
                let shipmentActivity = await API.graphql({
                    query: updateShipmentActivity,
                    variables: {
                        input: {
                            shipmentID: this.shipmentData.shipmentID,
                            Code: "12",
                            Description: "Description",
                            statusID: "Status ID",
                            ShipmentStage: "Stage",
                            ShipmentPoint: "Point",
                            ShippingEvent: "Event",
                            ActivityTime: "Time",
                            RecordedTime: "Recorded Time",
                            Comments: "Comments",
                            InternalComments: "Internal Comments",
                            Notes: "Notes"
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "Signature Requested"
                console.log(shipmentActivity.data.updateShipmentActivity)
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to prompt signature"
            }finally{
                // this.loadingUser = false;
            }
        },
        async updateShipmentData(){
            let phoneValidated;

            if((this.updatePickupAddressToggleValue == true) && (this.shipmentData.deliveryPickup.address.phone.length !== 0)){
                phoneValidated = this.cleanAndValidatePhoneNumber(this.shipmentData.deliveryPickup.address.phone);
            }else if((this.updateServiceAddressToggleValue == true) && (this.shipmentData.serviceAddress.address.phone !== 0)){
                phoneValidated = this.cleanAndValidatePhoneNumber(this.shipmentData.serviceAddress.address.phone);
            }else if((this.updatePickupAddressToggleValue == true) && (this.shipmentData.deliveryPickup.address.phone.length == 0)){
                phoneValidated = true;
            }else if((this.updateServiceAddressToggleValue == true) && (this.shipmentData.serviceAddress.address.phone.length == 0)){
                phoneValidated = true;
            }

            this.loadingData = true;

            let input = {
                    shipmentId: this.shipmentData.shipmentID,
                    deliveryCompanyName: this.shipmentData.serviceAddress.address.companyName,
                    deliveryAttention: this.shipmentData.serviceAddress.address.attention,
                    deliveryAddress1: this.shipmentData.serviceAddress.address.address1,
                    deliveryAddress2: this.shipmentData.serviceAddress.address.address2,
                    deliveryCity: this.shipmentData.serviceAddress.address.city,
                    deliveryState: this.shipmentData.serviceAddress.address.state,
                    deliveryZipCode: this.shipmentData.serviceAddress.address.zipCode,
                    deliveryPhone: this.shipmentData.serviceAddress.address.phone,
                    deliveryLatitude: this.shipmentData.serviceAddress.address.latitude,
                    deliveryLongitude: this.shipmentData.serviceAddress.address.longitude,
                    deliveryAddressRelevance: this.shipmentData.serviceAddress.address.relevance,
                    pickupCompanyName: this.shipmentData.deliveryPickup.address.companyName,
                    pickupAttention: this.shipmentData.deliveryPickup.address.attention,
                    pickupAddress1: this.shipmentData.deliveryPickup.address.address1,
                    pickupAddress2: this.shipmentData.deliveryPickup.address.address2,
                    pickupCity: this.shipmentData.deliveryPickup.address.city,
                    pickupState: this.shipmentData.deliveryPickup.address.state,
                    pickupZipCode: this.shipmentData.deliveryPickup.address.zipCode,
                    pickupPhone: this.shipmentData.deliveryPickup.address.phone,
                    pickupLatitude: this.shipmentData.deliveryPickup.address.latitude,
                    pickupLongitude: this.shipmentData.deliveryPickup.address.longitude,
                    pickupAddressRelevance: this.shipmentData.deliveryPickup.address.relevance
                }
            
            Object.keys(input).forEach(key => {
                if (input[key] === null) {
                    input[key] = ""
                }
            });

            if(phoneValidated == true){
            try{
                    await API.graphql({
                    query: updateShipmentData,
                    variables: {
                        input
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    }).then((response)=>{
                        console.log(response)
                        this.GetShipmentData();
                        this.updateServiceAddressToggleValue = false
                        this.updatePickupAddressToggleValue = false
                        this.toggleAlertBox = true;
                        this.alertMessage = "Shipment has been updated."
                        this.loadingData = false;
                    })
                }catch(e){
                    if(e.errors[0].message){
                        this.alertMessage = e.errors[0].message;
                    }else{
                        this.alertMessage = "Error updating shipment.";
                    }
                    this.toggleAlertBox = true;
                    this.loadingData = false;
                }
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Add a valid phone number."
                this.loadingData = false;
            }
        },
        async PrintLabel(){
            this.loadingData = true;
            const renderRequest = {
                    data: {
                        user: this.userData.userName,
                        jwtToken: this.jwtToken,
                        idArray: [this.shipmentData.shipmentID]
                    },
                    template: {
                        name: "LREXTemplateGraphQLv2",
                        engine: "handlebars",
                        recipe: "chrome-pdf"
                    }
                }
            try{
                let shipmentLabels = await API.graphql({
                query:  createShipmentLabelLambda,
                variables:{ input:{renderRequest} },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.labelData = "data:application/pdf;base64, " + shipmentLabels.data.createShipmentLabelLambda.labelImage.slice(1, -1);
                const element = document.getElementById('inner-overflow-container');
                element.scrollTop = element.scrollHeight;
            }catch(e){
                console.log(e)
                if(e){
                    this.loadingData = false;
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error printing label"
                }
            }finally{
                this.loadingData = false;
            }
        },
        async PrintPickupLabel(){
            this.loadingData = true;
            const renderRequest = {
                    data: {
                        user: this.userData.userName,
                        jwtToken: this.jwtToken,
                        idArray: [this.shipmentData.shipmentID]
                    },
                    template: {
                        name: "LREXLabelGraphQLPickupTemplate",
                        engine: "handlebars",
                        recipe: "chrome-pdf"
                    }
                }
            try{
                let shipmentLabels = await API.graphql({
                query:  createShipmentLabelLambda,
                variables:{ input:{renderRequest} },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.labelData = "data:application/pdf;base64, " + shipmentLabels.data.createShipmentLabelLambda.labelImage.slice(1, -1);
                const element = document.getElementById('inner-overflow-container');
                element.scrollTop = element.scrollHeight;
            }catch(e){
                console.log(e)
                if(e){
                    this.loadingData = false;
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error printing label"
                }
            }finally{
                this.loadingData = false;
            }
        },
        printContent() {
            const printableContent = document.getElementById('printable-content');
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`
                <html>
                <head>
                    <title>Package Details</title>
                    <style>
                            @import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

                            .st0{
                                fill: none;
                                stroke: #000000;
                                stroke-width: 8;
                                stroke-miterlimit: 10;
                            }

                            .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}

                            .cls-1-alt{
                                fill: none;
                                stroke: #000;
                                stroke-miterlimit: 10;
                                stroke-width: 8px;
                            }

                            .cls-1 {
                                fill: none;
                                stroke: #000;
                                stroke-miterlimit: 10;
                                stroke-width: 8px;
                            }

                            .cls-2 {
                                fill: none;
                                stroke: #000;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 12px;
                            }

                            .cls-2-alt{
                                stroke: #000;
                                fill: none;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 11px;
                            }

                        .main-container-shipmentinfo{
                            font-family: 'Work Sans', sans-serif;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            margin: 0;
                            padding: 0;
                            z-index: 10;
                        }

                        @keyframes shipment-details-animate {
                            from{
                                margin-top: -2%;
                            }
                            to{
                                margin-top: 2.5%;
                            }
                        }

                        .inner-container{
                            font-family: 'Work Sans', sans-serif;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: left;
                            flex-direction: column;
                        }

                        .confirm-container-main{
                            font-family: 'Work Sans', sans-serif;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: fit-content;
                            overflow-x: auto;
                            animation: shipment-details-animate 1s ease;
                            margin-top: 2.5%;
                            background-color: #fff;
                            border-radius: 25px;
                        }

                        .confirm-header-container{
                            font-family: 'Work Sans', sans-serif;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 15px 15px 0 15px;
                            justify-content: space-between;
                        }

                        .confirm-header-container img{
                            width: 40px;
                            margin-right: 15px;
                        }

                        .legend-header-container{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .map-legend-container{
                            display: flex;
                            flex-direction: column;
                            width: fit-content;
                            padding: 5px;
                            border-radius: 25px;
                            background-color: #f5f5f5;
                            margin-bottom: 5px;
                        }

                        .map-legend{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }

                        .map-legend p{
                            margin-left: 5px;
                        }

                        .weight-container{
                            background-color: #fff;
                            margin: 0px 5% 2% 5%;
                            padding: 10px;
                            border-radius: 25px;
                            box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
                        }

                        .weight-container label{
                            margin-right: 5px;
                        }

                        .confirmContainerInner{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;
                            text-align: left;
                            padding: 10px;
                        }

                        .confirmContainerInner1{
                            background-color: #f5f5f5;
                            margin: 5px;
                            padding: 10px;
                            border-radius: 25px;
                            flex-basis: 25%;
                            box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
                        }

                        .confirmContainerInner1 h2{
                            margin: 0;
                            text-align: left;
                            white-space: nowrap;
                        }

                        .notifications{
                            background-color: #f5f5f5;
                        }

                        .confirm-svg{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            background-color: #e4e4e4;
                            border-radius: 999px;
                            padding: 5px;
                            width: fit-content;
                        }

                        .confirm-svg svg{
                            width: 35px;
                            height: 35px;
                            padding-right: 5px;
                        }

                        .confirm-svg h2{
                            margin: 0;
                        }

                        .confirm-container-address{
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            flex-wrap: wrap;
                        }

                        .package-details-header{
                            background-color: #32ccfe;
                            color: #fff;
                            padding: 10px;
                            border-radius: 50px;
                            width: fit-content;
                            margin: 0;
                        }

                        .confirmContainerInner2{
                            background-color: #fff;
                            margin: 5px;
                            padding: 10px;
                            border-radius: 25px;
                            flex-basis: 25%;
                            box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
                        }

                        .confirmContainer{
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                        }

                        .confirmContainerInner label{
                            background-color: transparent;
                            border: none;
                            padding: 0px;
                            margin-right: 10px;
                            font-weight: bold;
                            white-space: nowrap;
                        }

                        .confirmLabelContainer{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 100%;
                            height: auto;
                        }

                        .confirmLabelContainer p{
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }

                        .confirmLabelContainer2{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            width: 100%;
                            padding-bottom: 5px;
                        }

                        .confirmContainerInner2 h2{
                            text-align: left;
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        .notificationArray p{
                            display: flex;
                            flex-direction: column;
                            margin: 2px;
                        }

                        .close-shipment-info{
                            background-color: #32ccfe;
                            color: #fff;
                            border: none;
                            border-radius: 5px;
                            padding: 5px;
                            width: fit-content;
                            margin-right: 5%;
                            margin-top: 5px;
                            transition-duration: .5s;
                            cursor: pointer;
                        }

                        .close-shipment-info:hover{
                            transition-duration: .5s;
                            background-color: #2aa7d1;
                        }

                        .close-shipment-info-container{
                            display: none;
                        }

                        .print-button{
                            display: none;
                        }
                    
                    @media print {
                            .st0{
                                fill: none;
                                stroke: #000000;
                                stroke-width: 8;
                                stroke-miterlimit: 10;
                            }

                            .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}

                            .cls-1-alt{
                                fill: none;
                                stroke: #000;
                                stroke-miterlimit: 10;
                                stroke-width: 8px;
                            }

                            .cls-1 {
                                fill: none;
                                stroke: #000;
                                stroke-miterlimit: 10;
                                stroke-width: 8px;
                            }

                            .cls-2 {
                                fill: none;
                                stroke: #000;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 12px;
                            }

                            .cls-2-alt{
                                stroke: #000;
                                fill: none;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 11px;
                            }

                        .main-container-shipmentinfo{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            margin: 0;
                            padding: 0;
                            z-index: 10;
                        }

                        .inner-container{
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: left;
                            flex-direction: column;
                        }

                        .confirm-container-main{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: fit-content;
                            overflow-x: auto;
                            margin-top: 2.5%;
                        }

                        .confirm-header-container{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 15px 15px 0 15px;
                            justify-content: space-between;
                        }

                        .confirm-header-container img{
                            width: 40px;
                            margin-right: 15px;
                        }

                        .legend-header-container{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .map-legend-container{
                            display: flex;
                            flex-direction: column;
                            width: fit-content;
                            padding: 5px;
                            border-radius: 25px;
                            background-color: #f5f5f5;
                            margin-bottom: 5px;
                        }

                        .map-legend{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }

                        .map-legend p{
                            margin-left: 5px;
                        }

                        .weight-container{
                            background-color: #fff;
                            margin: 0px 5% 2% 5%;
                            padding: 10px;
                            border-radius: 25px;
                            box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
                        }

                        .weight-container label{
                            margin-right: 5px;
                        }

                        .confirmContainerInner{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;
                            text-align: left;
                            padding: 10px;
                        }

                        .confirmContainerInner1{
                            background-color: #f5f5f5;
                            margin: 5px;
                            padding: 10px;
                            border-radius: 25px;
                            flex-basis: 25%;
                            box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
                        }

                        .confirmContainerInner1 h2{
                            margin: 0;
                            text-align: left;
                            white-space: nowrap;
                        }

                        .notifications{
                            background-color: #f5f5f5;
                        }

                        .confirm-svg{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            background-color: #e4e4e4;
                            border-radius: 999px;
                            padding: 5px;
                            width: fit-content;
                        }

                        .confirm-svg svg{
                            width: 35px;
                            height: 35px;
                            padding-right: 5px;
                        }

                        .confirm-svg h2{
                            margin: 0;
                        }

                        .confirm-container-address{
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            flex-wrap: wrap;
                        }

                        .package-details-header{
                            background-color: #32ccfe;
                            color: #000;
                            padding: 10px;
                            border-radius: 50px;
                            width: fit-content;
                            margin: 0;
                        }

                        .confirmContainerInner2{
                            background-color: #fff;
                            margin: 5px;
                            padding: 10px;
                            border-radius: 25px;
                            flex-basis: 25%;
                            box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
                        }

                        .confirmContainer{
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                        }

                        .confirmContainerInner label{
                            background-color: transparent;
                            border: none;
                            padding: 0px;
                            margin-right: 10px;
                            font-weight: bold;
                            white-space: nowrap;
                        }

                        .confirmLabelContainer{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 100%;
                            height: auto;
                        }

                        .confirmLabelContainer p{
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }

                        .confirmLabelContainer2{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            width: 100%;
                            padding-bottom: 5px;
                        }

                        .confirmContainerInner2 h2{
                            text-align: left;
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        .notificationArray p{
                            display: flex;
                            flex-direction: column;
                            margin: 2px;
                        }

                        .close-shipment-info{
                            background-color: #32ccfe;
                            color: #fff;
                            border: none;
                            border-radius: 5px;
                            padding: 5px;
                            width: fit-content;
                            margin-right: 5%;
                            margin-top: 5px;
                            transition-duration: .5s;
                            cursor: pointer;
                        }

                        .close-shipment-info:hover{
                            transition-duration: .5s;
                            background-color: #2aa7d1;
                        }

                        .close-shipment-info-container{
                            display: none;
                        }

                        .print-button{
                            display: none;
                        }

                        .inner-history-container p{
                            margin: 0;
                        }

                        .inner-history-container{
                            border: 1px solid #b3b3b3;
                            border-radius: 15px;
                            padding: 15px;
                            margin: 15px;
                            margin-left: 12.5%;
                            width: 50%;
                        }

                        .history-container{
                            position: relative;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                    }
                    </style>
                </head>
                <body>
                    ${printableContent.innerHTML}
                </body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
        },
        cleanAndValidatePhoneNumber(inputPhoneNumber) {
            // Remove all non-digit characters from the input phone number
            const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, '');

            // Check if the cleaned phone number is exactly 10 digits
            if(cleanedPhoneNumber.length == 0){
                return true;
            }else if(cleanedPhoneNumber.length !== 10) {
                return false; // Invalid phone number
            }else{
                if(this.updatePickupAddressToggleValue == true){
                    this.shipmentData.deliveryPickup.address.phone = cleanedPhoneNumber;
                    return true;
                }

                if(this.updateServiceAddressToggleValue == true){
                    this.shipmentData.serviceAddress.address.phone = cleanedPhoneNumber;
                    return true;
                }
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeShipmentInfo(){
            this.$emit('closeShipmentInfo', false)
        },
        updateServiceAddressToggle(){
            this.updateServiceAddressToggleValue = true;
            this.updatePickupAddressToggleValue = false;
        },
        updatePickupAddressToggle(){
            this.updatePickupAddressToggleValue = true;
            this.updateServiceAddressToggleValue = false;
        },
        GetSelectedAddressData(selectedAddress){
            if(this.updatePickupAddressToggleValue){
                this.shipmentData.deliveryPickup.address.latitude = selectedAddress.latitude.toString();
                this.shipmentData.deliveryPickup.address.longitude = selectedAddress.longitude.toString();
                this.shipmentData.deliveryPickup.address.address1 = selectedAddress.address1;
                this.shipmentData.deliveryPickup.address.address2 = selectedAddress.address2;
                this.shipmentData.deliveryPickup.address.zipCode = selectedAddress.zipCode;
                this.shipmentData.deliveryPickup.address.city = selectedAddress.city;
                this.shipmentData.deliveryPickup.address.state = selectedAddress.state;
                this.shipmentData.deliveryPickup.address.relevance = selectedAddress.relevance.toString();
            }

            if(this.updateServiceAddressToggleValue){
                this.shipmentData.serviceAddress.address.latitude = selectedAddress.latitude.toString();
                this.shipmentData.serviceAddress.address.longitude = selectedAddress.longitude.toString();
                this.shipmentData.serviceAddress.address.address1 = selectedAddress.address1;
                this.shipmentData.serviceAddress.address.address2 = selectedAddress.address2;
                this.shipmentData.serviceAddress.address.zipCode = selectedAddress.zipCode;
                this.shipmentData.serviceAddress.address.city = selectedAddress.city;
                this.shipmentData.serviceAddress.address.state = selectedAddress.state;
                this.shipmentData.serviceAddress.address.relevance = selectedAddress.relevance.toString();
            }
        },
        customAddressToggle(toggleCustom){
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";

            document.getElementById('address1').disabled = false;
            document.getElementById('city').disabled = false;
            document.getElementById('state').disabled = false;
            document.getElementById('zipcode').disabled = false;
            this.validateCustomAddress = true;
        },
        useEnteredAddress(event){
            if(event.callInsertAddressBook == true){
                this.updateShipmentData();
            }
        },
        setCustomAddress(event){
            if(this.updatePickupAddressToggleValue){
                this.shipmentData.deliveryPickup.address.latitude = event.shipmentAddressData.latitude;
                this.shipmentData.deliveryPickup.address.longitude = event.shipmentAddressData.longitude;
                this.shipmentData.deliveryPickup.address.address1 = event.shipmentAddressData.Address1;
                this.shipmentData.deliveryPickup.address.address2 = event.shipmentAddressData.Address2;
                this.shipmentData.deliveryPickup.address.zipCode = event.shipmentAddressData.ZipCode;
                this.shipmentData.deliveryPickup.address.city = event.shipmentAddressData.City;
                this.shipmentData.deliveryPickup.address.state = event.shipmentAddressData.State;
                this.shipmentData.deliveryPickup.address.relevance = event.shipmentAddressData.relevance;
            }

            if(this.updateServiceAddressToggleValue){
                this.shipmentData.serviceAddress.address.latitude = event.shipmentAddressData.latitude;
                this.shipmentData.serviceAddress.address.longitude = event.shipmentAddressData.longitude;
                this.shipmentData.serviceAddress.address.address1 = event.shipmentAddressData.Address1;
                this.shipmentData.serviceAddress.address.address2 = event.shipmentAddressData.Address2;
                this.shipmentData.serviceAddress.address.zipCode = event.shipmentAddressData.ZipCode;
                this.shipmentData.serviceAddress.address.city = event.shipmentAddressData.City;
                this.shipmentData.serviceAddress.address.state = event.shipmentAddressData.State;
                this.shipmentData.serviceAddress.address.relevance = event.shipmentAddressData.relevance;
            }

            if(event.callInsertAddressBook == true){
                this.updateShipmentData();
                this.validateCustomAddress = false;
            }
        },
        useCorrectedAddress(event){
            if(this.updatePickupAddressToggleValue){
                this.shipmentData.deliveryPickup.address.latitude = event.shipmentDataValue.latitude;
                this.shipmentData.deliveryPickup.address.longitude = event.shipmentDataValue.longitude;
                this.shipmentData.deliveryPickup.address.address1 = event.shipmentDataValue.Address1;
                this.shipmentData.deliveryPickup.address.address2 = event.shipmentDataValue.Address2;
                this.shipmentData.deliveryPickup.address.zipCode = event.shipmentDataValue.ZipCode;
                this.shipmentData.deliveryPickup.address.city = event.shipmentDataValue.City;
                this.shipmentData.deliveryPickup.address.state = event.shipmentDataValue.State;
                this.shipmentData.deliveryPickup.address.relevance = event.shipmentDataValue.relevance;
            }

            if(this.updateServiceAddressToggleValue){
                this.shipmentData.serviceAddress.address.latitude = event.shipmentDataValue.latitude;
                this.shipmentData.serviceAddress.address.longitude = event.shipmentDataValue.longitude;
                this.shipmentData.serviceAddress.address.address1 = event.shipmentDataValue.Address1;
                this.shipmentData.serviceAddress.address.address2 = event.shipmentDataValue.Address2;
                this.shipmentData.serviceAddress.address.zipCode = event.shipmentDataValue.ZipCode;
                this.shipmentData.serviceAddress.address.city = event.shipmentDataValue.City;
                this.shipmentData.serviceAddress.address.state = event.shipmentDataValue.State;
                this.shipmentData.serviceAddress.address.relevance = event.shipmentDataValue.relevance;
            }
            
            if(event.callInsertAddressBook == true){
                this.updateShipmentData();
                this.validateCustomAddress = false;
            }
        },
        checkAddressCorrection(){
            if(this.updatePickupAddressToggleValue){
                this.userCustomAddress = this.shipmentData.deliveryPickup.address.address1 + " " + this.shipmentData.deliveryPickup.address.city + " " + this.shipmentData.deliveryPickup.address.state + " " + this.shipmentData.deliveryPickup.address.zipCode;
            }

            if(this.updateServiceAddressToggleValue){
                this.userCustomAddress = this.shipmentData.serviceAddress.address.address1 + " " + this.shipmentData.serviceAddress.address.city + " " + this.shipmentData.serviceAddress.address.state + " " + this.shipmentData.serviceAddress.address.zipCode;
            }
            //AddressCorrection Child Component is triggered when customAddress changes values
        },
        cancelAddressUpdate(){
            this.GetShipmentData();
            this.updateServiceAddressToggleValue = false
            this.updatePickupAddressToggleValue = false
            this.validateCustomAddress = false
        }
    },
    mounted(){
        this.GetShipmentData();
        if(this.cognitoUser.signInUserSession.idToken.payload['cognito:groups']){
            this.cognitoUser.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    overflow-x: auto;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.legend-header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-legend-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 5px;
    border-radius: 25px;
    background-color: #f5f5f5;
    margin-bottom: 5px;
}

.map-legend{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-legend p{
    margin-left: 5px;
}

.weight-container{
    background-color: #fff;
    margin: 0px 5% 2% 5%;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    overflow-y: auto;
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
    /* border-radius: 25px; */
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.auto-complete-container{
    margin-bottom: 5px !important;
    width: 90%;
    display: block;
    margin: auto;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

.notifications{
    background-color: #f5f5f5;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainerInner2{
    background-color: #fff;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.confirmContainerInner2 h2{
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.add-comment-container{
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: row;
}

.add-comment-container textarea{
    border: 1px solid #e2e2e2;
    border-right: none;
    padding: 5px;
    border-radius: 10px 0px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    outline: none;
    margin-top: 0;
}

.add-comment-container button{
    cursor: pointer;
    border: 1px solid #e2e2e2;
    background-color: #32ccfe;
    color: #fff;
    padding: 5px;
    border-radius: 0px 10px 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    transition-duration: .5s;
}

.add-comment-container button:hover{
    background-color: #2baeda;
    transition-duration: .5s;
}


.notificationArray p{
    display: flex;
    flex-direction: column;
    margin: 2px;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.create-button{
    background-color: #33f18a;
    margin-left: 25px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.cancel-button{
    background-color: #fe804d;
    margin-left: 25px;
}

.cancel-button:hover{
    transition-duration: .5s;
    background-color: #e27144;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

/* History Check Mark */
.inner-history-container p{
    margin: 0;
}

.inner-history-container{
  border: 1px solid #b3b3b3;
  border-radius: 15px;
  padding: 15px;
  margin: 15px;
  margin-left: 12.5%;
  width: 50%;
}

.history-container{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.check-mark.active{
  background: linear-gradient(
    to bottom, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.check-mark.activeLast{
  background: linear-gradient(
    to top, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.main-button-blue-white{
    padding-top: 5px;
    padding-bottom: 5px;
}

.proof-delivery-image{
  width: 100px;
  border-radius: 5px;
}

.proof-delivery-container{
  display: flex;
  flex-direction: column;
}

.proof-delivery-container button{
  width: 140px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.check-mark{
  background-color:#33f18a;
  color: #fff;
  width: 2.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}

.check-mark-item{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #33f18a;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.check-mark-item img{
  width: 20px;
}

.check-mark-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-description{
  font-weight: bold;
}

.history-process-date{
  background: #f0f0f0;
  color: #858585;
  border-radius: 2.5px;
  padding: 2px;
}

.history-description-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.label-container{
    margin-top: 5px;
    width: 100%;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1, .confirmContainerInner2{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }

    .inner-history-container{
        margin: 15px;
        margin-left: 12.5%;
        width: 70%;
    }
}
</style>
<template>
  <div class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <svg class="close-shipment-info" @click="closeCreateAddress()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">Create Address</h1>
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                    <div class="auto-complete-container">
                        <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                    </div>
                    <div class="confirmContainerInner">
                        <div class="confirm-container-address">
                            <div class="confirmContainerInner1">
                                <div class="confirm-svg">
                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                                        <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                            <g>
                                            <g>
                                                <polyline class="cls-2-alt" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                                                <line class="cls-2-alt" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                                                <polyline class="cls-2-alt" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                                                <line class="cls-2-alt" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                                                <line class="cls-2-alt" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                                            </g>
                                            <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                            <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                            <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                            <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                            <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                            <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <h2>Delivery Address</h2>
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" v-model="addressData.companyName" placeholder="Company Name">
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" v-model="addressData.attention" placeholder="Attention">
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" id="address1" disabled v-model="addressData.address1" placeholder="Address">
                                </div>
                                
                                <div class="confirmLabelContainer">
                                    <input type="text" v-model="addressData.address2" placeholder="Suite/Apt.">
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" id="city" disabled v-model="addressData.city" placeholder="City">
                                </div>

                                <div class="confirmLabelContainer">
                                    <select disabled required name="state" id="state" v-model="addressData.state">
                                        <option value="" disabled selected>State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>

                                <div class="confirmLabelContainer">
                                    <input type="text" id="zipcode" disabled v-model="addressData.zipCode" placeholder="Zip Code">
                                </div>
                            </div>
                        </div>

                        <div class="confirmContainerInner2">
                            <div class="confirm-svg notifications">
                                <svg data-name="Livello 1" viewBox="10 12.5 40 40" xmlns="http://www.w3.org/2000/svg"><path d="M1 33A32 32 0 1 0 33 1 32 32 0 0 0 1 33Z" fill="none" class="fill-000000"></path><rect height="24.18" rx="1.48" ry="1.48" width="32" x="17" y="21" stroke-miterlimit="10" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></rect><path d="M48.44 21.83 37.53 33.54a6.27 6.27 0 0 1-9.07 0l-10.9-11.7M39.19 31.75l8.96 12.76M26.98 31.88 17.7 44.51" stroke-linejoin="round" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></path></svg>
                                <h2>Notifications</h2>
                            </div>
                            
                            <div  class="confirmLabelContainer2">
                                <div class="notificationArray" name="deliveryemail">
                                        <input type="text" v-model="addressData.email" placeholder="Email">
                                </div>
                            </div>

                            <div class="confirmLabelContainer2">
                                <div class="notificationArray" name="deliveryphone">
                                        <input type="text" v-model="addressData.phone" placeholder="Phone">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="weight-container">
                        <div class="confirmLabelContainer">
                            <input type="text" v-model="addressData.deliveryInstructions" style="margin-top: 0;" placeholder="Delivery Instructions">
                        </div>
                    </div>
                    </div>
                    <loading-charts v-if="loadingData"/>
                    <button v-if="validateCustomAddress" class="print-button create-button" @click="checkAddressCorrection()">Check Address</button>
                    <button v-if="!validateCustomAddress" class="print-button create-button" @click="createAddressBookItem()">Create Address</button>
            </div>
    </div>
  </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="addressData"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import {createAddressBook} from '../../../graphql/mutations'
import AWSAutoComplete from '../../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';

export default {
    props:['id', 'userData', 'jwtToken'],
    components:{
        AlertUser,
        LoadingCharts,
        AWSAutoComplete,
        AWSAddressCorrection
    },
    data(){
        return{
            addressData: {
                userName: this.userData.userName,
                accountNo: this.userData.accountNo,
                companyName: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                attention: "",
                email: "",
                phone: "",
                deliveryInstructions: "",
                latitude: "",
                longitude: "",
                relevance: "",
                phoneExt: ""
            },
            toggleAlertBox: false, 
            alertMessage: null,
            labelData: null,
            loadingData: false,
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: ""
        }
    },
    methods:{
        async createAddressBookItem(){
            let phoneInput = this.addressData.phone;
            if(!this.cleanAndValidatePhoneNumber(phoneInput)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter a valid phone number."
                this.loadingTable = false;
                return;
            }
            if((this.addressData.address1 != "") && (this.addressData.city != "") && (this.addressData.state != "") && (this.addressData.zipCode != "") ){
                this.loadingTable = true;
                try{
                    let getAddressBook = await API.graphql({
                    query:  createAddressBook,
                    variables: {input:{
                        userName: this.addressData.userName,
                        accountNo: this.addressData.accountNo,
                        address1: this.addressData.address1,
                        address2: this.addressData.address2,
                        city: this.addressData.city,
                        state: this.addressData.state,
                        zipCode: this.addressData.zipCode,
                        companyName: this.addressData.companyName,
                        attention: this.addressData.attention,
                        email: this.addressData.email,
                        phone: this.addressData.phone,
                        deliveryInstructions: this.addressData.deliveryInstructions,
                        relevance: this.addressData.relevance,
                        longitude: this.addressData.longitude,
                        latitude: this.addressData.latitude,
                    }},
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    
                    console.log(getAddressBook)
                    this.toggleAlertBox = true;
                    this.alertMessage = "Address Created"
                    
                    setTimeout(() => {
                        this.closeCreateAddress();
                    }, 1000);
                }catch(e){
                    console.log(e)
                    if(e){
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error getting addresses"
                    }
                }finally{
                    this.loadingTable = false;
                    this.isFiltered = false;
                }
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Please add an address"
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeCreateAddress(){
            this.$emit('closeCreateAddress', false)
        },
        GetSelectedAddressData(selectedAddress){
            this.addressData.latitude = selectedAddress.latitude;
            this.addressData.longitude = selectedAddress.longitude;
            this.addressData.address1 = selectedAddress.address1;
            this.addressData.zipCode = selectedAddress.zipCode;
            this.addressData.city = selectedAddress.city;
            this.addressData.state = selectedAddress.state;
            this.addressData.relevance = selectedAddress.relevance;
        },
        customAddressToggle(toggleCustom){
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";

            document.getElementById('address1').disabled = false;
            document.getElementById('city').disabled = false;
            document.getElementById('state').disabled = false;
            document.getElementById('zipcode').disabled = false;
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            let customAddressString = this.addressData.address1 + " " + this.addressData.city + " " + this.addressData.state + " " + this.addressData.zipCode;
            //AddressCorrection Child Component is triggered when customAddress changes values
            this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            this.addressData.relevance = event.shipmentAddressData.relevance;
            this.addressData.latitude = event.shipmentAddressData.latitude;
            this.addressData.longitude = event.shipmentAddressData.longitude;
            this.addressData.address1 = event.shipmentAddressData.address1;
            this.addressData.zipCode = event.shipmentAddressData.zipCode;
            this.addressData.city = event.shipmentAddressData.city;
            this.addressData.state = event.shipmentAddressData.state;

            if(event.callInsertAddressBook == true){
                this.createAddressBookItem();
            }
        },
        useCorrectedAddress(event){
            this.addressData.relevance = event.shipmentDataValue.relevance;
            this.addressData.latitude = event.shipmentDataValue.latitude;
            this.addressData.longitude = event.shipmentDataValue.longitude;
            this.addressData.address1 = event.shipmentDataValue.address1;
            this.addressData.zipCode = event.shipmentDataValue.zipCode;
            this.addressData.city = event.shipmentDataValue.city;
            this.addressData.state = event.shipmentDataValue.state;
            
            if(event.callInsertAddressBook == true){
                this.createAddressBookItem();
            }
        },
        useEnteredAddress(event){
            if(event.callInsertAddressBook == true){
                this.createAddressBookItem();
            }
        },
        cleanAndValidatePhoneNumber(inputPhoneNumber) {
            // Remove all non-digit characters from the input phone number
            const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, '');

            // Check if the cleaned phone number is exactly 10 digits
            if(cleanedPhoneNumber.length == 0){
                return true;
            }else if(cleanedPhoneNumber.length !== 10) {
                return false; // Invalid phone number
            }else{
                this.addressData.phone = cleanedPhoneNumber;
                return true;
            }
        },
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    overflow-x: auto;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.legend-header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-legend-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 5px;
    border-radius: 25px;
    background-color: #f5f5f5;
    margin-bottom: 5px;
}

.map-legend{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-legend p{
    margin-left: 5px;
}

.weight-container{
    background-color: #fff;
    margin: 0px 5% 2% 5%;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    overflow-y: auto;
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
    /* border-radius: 25px; */
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    display: block;
    margin: auto;
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.notifications{
    background-color: #f5f5f5;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainerInner2{
    background-color: #fff;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.confirmContainerInner2 h2{
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.notificationArray p{
    display: flex;
    flex-direction: column;
    margin: 2px;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.create-button{
    background-color: #33f18a;
    margin-left: 20px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

/* History Check Mark */
.inner-history-container p{
    margin: 0;
}

.inner-history-container{
  border: 1px solid #b3b3b3;
  border-radius: 15px;
  padding: 15px;
  margin: 15px;
  margin-left: 12.5%;
  width: 50%;
}

.history-container{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.check-mark.active{
  background: linear-gradient(
    to bottom, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.check-mark.activeLast{
  background: linear-gradient(
    to top, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.proof-delivery-image{
  width: 100px;
  border-radius: 5px;
}

.proof-delivery-container{
  display: flex;
  flex-direction: column;
}

.proof-delivery-container button{
  width: 140px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.check-mark{
  background-color:#33f18a;
  color: #fff;
  width: 2.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}

.check-mark-item{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #33f18a;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.check-mark-item img{
  width: 20px;
}

.check-mark-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-description{
  font-weight: bold;
}

.history-process-date{
  background: #f0f0f0;
  color: #858585;
  border-radius: 2.5px;
  padding: 2px;
}

.history-description-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.label-container{
    margin-top: 5px;
    width: 100%;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1, .confirmContainerInner2{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }
}
</style>
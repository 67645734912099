<template>
  <div class="product-options-container">
    <div v-if="specialPricing == true">
        <!-- Update account products in popup -->
        <h2 v-if="specialPricing">Special Pricing</h2>
        <div class="product-options-inner-container selected-special-pricing">
            <div v-for="productCodeItem in productCodes" :key="productCodeItem" class="product-code-container">
            <h2>{{productCodeItem}} <i class="fa fa-caret-right"></i></h2>
                <div class="product-container-row">
                    <div v-for="product in specialPricingProducts" :key="product" class="product-container-outer">
                        <div v-if="product.productCode == productCodeItem" class="product-container">
                            <div>
                                <p class="product-name">{{product.productName}}</p>
                            </div>

                            <div>
                                <p>{{product.productCode}}</p>
                            </div>

                            <div v-if="product.serviceGroup">
                                <p><span style="font-weight: bold;">Service Group: </span>{{product.serviceGroup}}</p>
                            </div>

                            <div>
                                <p>{{product.description}}</p>
                            </div>

                            <div>
                                <p><span style="font-weight: bold;">Price: </span>{{product.price}}</p>
                            </div>

                            <div v-if="product.productSequence">
                                <p><span style="font-weight: bold;">Product Sequence: </span>{{product.productSequence}}</p>
                            </div>

                            <div v-if="specialPricing">
                                <input type="number" v-model="product.price">
                            </div>

                            <div v-if="product.additionalService == true">
                                <p><span style="font-weight: bold;">Additional Service</span></p>
                            </div>
                        
                            <div class="button-container">
                                <input type="checkbox" v-model="specialPricingProducts" :value="product" name="" id="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h2>Product Options</h2>
        <!-- Create Account Products with Special Pricing -->
        <div v-if="!updateAccountProducts" class="product-options-inner-container">
            <div v-for="productCodeItem in productCodes" :key="productCodeItem" class="product-code-container">
            <h2 @click="displayProductOptions(productCodeItem)">{{productCodeItem}} <i class="fa fa-caret-right"></i></h2>
                <div class="product-container-row">
                    <div v-for="product in productData" :key="product" class="product-container-outer">
                        <div v-for="userClicked in userClickedProducts" :key="userClicked">
                            <div v-if="(product.productCode == productCodeItem) && (userClicked == productCodeItem)" class="product-container">
                                <div>
                                    <p class="product-name">{{product.productName}}</p>
                                </div>

                                <div>
                                    <p>{{product.productCode}}</p>
                                </div>

                                <div v-if="product.serviceGroup">
                                    <p><span style="font-weight: bold;">Service Group: </span>{{product.serviceGroup}}</p>
                                </div>

                                <div>
                                    <p>{{product.description}}</p>
                                </div>

                                <div>
                                    <p><span style="font-weight: bold;">Price: </span>{{product.price}}</p>
                                </div>

                                <div v-if="product.productSequence">
                                    <p><span style="font-weight: bold;">Product Sequence: </span>{{product.productSequence}}</p>
                                </div>

                                <div v-if="product.additionalService == true">
                                    <p><span style="font-weight: bold;">Additional Service</span></p>
                                </div>
                            
                                <div v-if="!isInSpecialPricing(product)" class="button-container">
                                    <input type="checkbox" v-model="specialPricingProducts" :value="product" name="" id="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Update Account Products with Special Pricing/General Product Selection -->
        <div v-if="updateAccountProducts" class="product-options-inner-container">
            <div v-for="productCodeItem in productCodes" :key="productCodeItem" class="product-code-container">
            <h2 @click="displayProductOptions(productCodeItem)">{{productCodeItem}} <i class="fa fa-caret-right"></i></h2>
                <div class="product-container-row">
                    <div v-for="product in productData" :key="product" class="product-container-outer">
                        <div v-for="userClicked in userClickedProducts" :key="userClicked">
                            <div v-if="(product.productCode == productCodeItem) && (userClicked == productCodeItem)" class="product-container">
                                <div>
                                    <p class="product-name">{{product.productName}}</p>
                                </div>

                                <div>
                                    <p>{{product.productCode}}</p>
                                </div>

                                <div v-if="product.serviceGroup">
                                    <p><span style="font-weight: bold;">Service Group: </span>{{product.serviceGroup}}</p>
                                </div>

                                <div>
                                    <p>{{product.description}}</p>
                                </div>

                                <div>
                                    <p><span style="font-weight: bold;">Price: </span>{{product.price}}</p>
                                </div>

                                <div v-if="product.productSequence">
                                    <p><span style="font-weight: bold;">Product Sequence: </span>{{product.productSequence}}</p>
                                </div>

                                <div v-if="product.additionalService == true">
                                    <p><span style="font-weight: bold;">Additional Service</span></p>
                                </div>

                                <div v-if="!isInSpecialPricing(product)" class="button-container">
                                    <input type="checkbox" v-model="specialPricingProducts" :value="product" name="" id="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="specialPricing == false">
        <!-- Create Account Products -->
        <h2>Product Options</h2>
        <div v-if="!updateAccountProducts" class="product-options-inner-container">
            <div v-for="productCodeItem in productCodes" :key="productCodeItem" class="product-code-container">
            <h2 @click="displayProductOptions(productCodeItem)">{{productCodeItem}} <i class="fa fa-caret-right"></i></h2>
            <div class="product-container-row">
                <div v-for="product in productData" :key="product" class="product-container-outer">
                    <div v-for="userClicked in userClickedProducts" :key="userClicked">
                    
                    <div v-if="(product.productCode == productCodeItem) && (userClicked == productCodeItem)" class="product-container">
                        <div>
                            <p class="product-name">{{product.productName}}</p>
                        </div>

                        <div>
                            <p>{{product.productCode}}</p>
                        </div>

                        <div v-if="product.serviceGroup">
                            <p><span style="font-weight: bold;">Service Group: </span>{{product.serviceGroup}}</p>
                        </div>

                        <div>
                            <p>{{product.description}}</p>
                        </div>

                        <div>
                            <p><span style="font-weight: bold;">Price: </span>{{product.price}}</p>
                        </div>

                        <div v-if="product.productSequence">
                            <p><span style="font-weight: bold;">Product Sequence: </span>{{product.productSequence}}</p>
                        </div>

                        <div v-if="product.additionalService == true">
                            <p><span style="font-weight: bold;">Additional Service</span></p>
                        </div>
                    
                        <div class="button-container">
                            <input type="checkbox" v-model="selectedProducts" :value="product" name="" id="">
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- Update Account Products -->
        <div v-if="updateAccountProducts" class="product-options-inner-container">
            <div v-for="productCodeItem in productCodes" :key="productCodeItem" class="product-code-container">
            <h2 @click="displayProductOptions(productCodeItem)">{{productCodeItem}} <i class="fa fa-caret-right"></i></h2>
            <div class="product-container-row">
                <div v-for="product in productData" :key="product" class="product-container-outer">
                    <div v-for="userClicked in userClickedProducts" :key="userClicked">
                        <div v-if="(product.productCode == productCodeItem) && (userClicked == productCodeItem)" class="product-container">
                            <div>
                                <p class="product-name">{{product.productName}}</p>
                            </div>

                            <div>
                                <p>{{product.productCode}}</p>
                            </div>

                            <div v-if="product.serviceGroup">
                                <p><span style="font-weight: bold;">Service Group: </span>{{product.serviceGroup}}</p>
                            </div>

                            <div>
                                <p>{{product.description}}</p>
                            </div>

                            <div>
                                <p><span style="font-weight: bold;">Price: </span>{{product.price}}</p>
                            </div>

                            <div v-if="product.productSequence">
                                <p><span style="font-weight: bold;">Product Sequence: </span>{{product.productSequence}}</p>
                            </div>

                            <div v-if="product.additionalService == true">
                                <p><span style="font-weight: bold;">Additional Service</span></p>
                            </div>
                        
                            <div class="button-container">
                                <input type="checkbox" v-model="selectedProducts" :value="product" name="" id="">
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import {API} from 'aws-amplify'

export default {
props:['updateAccountProducts', 'currentAccountProducts', 'specialPricing', 'selectedAccount'],
data(){
    return{
        productData: [],
        selectedProducts: [],
        specialPricingProducts: [],
        userClickedProducts: []
    }
},
computed:{
    productCodes(){
        const productCodeSet = new Set();
        this.productData.forEach(group => productCodeSet.add(group.productCode));
        return Array.from(productCodeSet); 
    },
    isInSpecialPricing() {
      return (product) => {
        // Check if the product is in specialPricingProducts
        return this.specialPricingProducts.some(
          (specialProduct) => specialProduct.productCode === product.productCode && specialProduct.serviceCode === product.serviceCode
        );
      };
    }
},
watch:{
    'selectedProducts'(){
        if(this.specialPricing == false){
            this.$emit('getSelectedProducts', this.selectedProducts)
        }
    },
    'specialPricingProducts'(){
        if(this.specialPricing == true){
            this.$emit('getSelectedProducts', this.specialPricingProducts)
        }
    },
    'productData'(){
        if(this.updateAccountProducts == true){
            this.setOverlapProducts();
        }
    },
    'specialPricing'(){
        if(this.specialPricing == true){
            this.getAccountProductsSpecialPricing();
        }
    }
},
mounted(){
    this.listProductOptions();

    if(this.specialPricing == true){
        this.getAccountProductsSpecialPricing();
    }
},
methods:{
    displayProductOptions(selectedProduct){
        if (this.userClickedProducts.includes(selectedProduct)) {
            const index = this.userClickedProducts.indexOf(selectedProduct);
            this.userClickedProducts.splice(index, 1); // Remove the item
        } else {
            this.userClickedProducts.push(selectedProduct); // Add the item
        }
    },
    async listProductOptions(){
        this.loadingUser = true;
        try {
            let lisProductData = await API.graphql({
                query: `query listProducts{
                    listProducts{
                        items{
                            productCode
                            serviceCode
                            description
                            productSequence
                            serviceNetwork
                            additionalService
                            price
                            productName
                            vendor
                            restServiceCode
                            descriptionUI
                            deliveryWindowEnd
                            deliveryWindowStart
                            deliveryWindowLabel
                            productFamily
                            serviceGroup
                            serviceGroupSequence
                        }
                    }
                }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            })
            this.productData = lisProductData.data.listProducts.items;
        } catch (error) {
            this.toggleAlertBox = true;
            this.alertMessage = "Unable to get product information"
        }finally{
            this.loadingUser = false;
        }
    },
    async getAccountProductsSpecialPricing(){
        this.loadingUser = true;
        try {
            let productSpecialPricing = await API.graphql({
                query: `query getAccount{
                    getAccount(accountNo:"${this.selectedAccount}"){
                        product {
                            productCode
                            serviceCode
                            description
                            productSequence
                            serviceNetwork
                            additionalService
                            price
                            productName
                            vendor
                            restServiceCode
                            descriptionUI
                            deliveryWindowEnd
                            deliveryWindowStart
                            deliveryWindowLabel
                            productFamily
                            serviceGroup
                            serviceGroupSequence
                        }
                    }
                }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            })
            this.specialPricingProducts = productSpecialPricing.data.getAccount.product;
            // this.selectedProducts = this.specialPricingProducts;
        } catch (error) {
            this.toggleAlertBox = true;
            this.alertMessage = "Unable to get product information"
        }finally{
            this.loadingUser = false;
        }
    },
    async setOverlapProducts(){
        for (let i = 0; i < this.productData.length; i++) {
            const objA = this.productData[i];
            for (let j = 0; j < this.currentAccountProducts.length; j++) {
                const objB = this.currentAccountProducts[j];
                this.areObjectsEqual(objA, objB);
            }
        }
    },
    areObjectsEqual(objA, objB) {
        if (objA.serviceCode == objB.serviceCode && objA.productCode == objB.productCode) {
            this.selectedProducts.push(objB)
            return true;
        }else{
            return false;
        }
    }
}
}
</script>

<style scoped>
p{
    margin: 0;
}

h2{
    background-color: #e4e4e4;
    color: #000;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin: 0;
}

.product-options-main-container{
    width: 100%;
    height: fit-content;
    display: flex;
}

.product-options-inner-container{
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    flex-wrap: wrap; */
}

.product-code-container h2{
    margin-top: 5px;
    cursor: pointer;
    transition-duration: .25s;
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
    border: 1px solid #cccccc;
}

.product-code-container h2:hover{
    margin-top: 5px;
    cursor: pointer;
    transition-duration: .25s;
    background-color: #e4e4e4;
}

.product-code-container i{
    transition: all 0.4s ease;
}

.product-code-container h2:hover i{
  transform: rotateZ(90deg);
}

.product-code-container{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.product-container-outer{
    display: flex;
    /* flex-basis: 50%; */
}

.product-container-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.selected-special-pricing{
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 2px solid #000;
}

.product-container{
    /* flex-basis: 50%; */
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
    border-radius: 25px;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #dbdbdb;
}

.button-container{
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product-name{
    background-color: #e4e4e4;
    color: #000;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.add-button{
    font-size: 10px;
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.add-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.remove-button{
    font-size: 10px;
    background-color: #fe804d;
    color: #fff;
    border: none;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.remove-button:hover{
    transition-duration: .5s;
    background-color: #e27145;
}

input[type="number"]{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    margin-top: 5px;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

@media only screen and (max-width: 750px) {
    .product-container{
        flex-basis: 80%;
    }
}
</style>
<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <loading-charts v-if="loadingUser" class="loading-charts"/>
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                    <div @click="selectPartner('P2PGlobal')" class="partner-option">
                        <img src="../../../assets/FinalImages/P2PGlobal.png" alt="">
                    </div>
                    <div v-if="(AdminUser || InternalAdmin)">
                        <div v-if="selectedPartner == 'P2PGlobal'" class="confirmLabelContainer">
                            <form class="submit-form" @submit.prevent="submitForm">
                                <input v-model="userInput" placeholder="Shipment ID" type="text" id="userInput" name="userInput" @keyup.enter="submitForm">
                                <button type="submit">Get Shipment</button>
                            </form>
                            <div v-if="shipmentDataREST.shipmentID" class="shipment-data">
                                <div class="header-track-inner">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.75 148.87">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M154.27,12.53c.87-.17,1.78,0,2.54,.46,1.29,.53,2.2,1.79,2.2,3.28l.74,103.46c.07,1.27-.56,2.54-1.73,3.22l-42.25,25.21c-.6,.46-1.34,.72-2.15,.72-.2,0-.39-.01-.57-.05L3.39,136.62c-1.89-.08-3.39-1.64-3.39-3.55V27.85H0c-.04-1.4,.75-2.77,2.13-3.37L57.14,.31h0c.57-.26,1.22-.36,1.9-.27l95.23,12.49h0Zm-37.27,30.93v96.48l36-21.47-.7-97.26-35.3,22.25h0Zm-7,97.91V45l-46.8-5.97-1.54,44.72-16.43-11.19-16.43,9.29,3.4-46.78-25.19-3.21v98.07l103,11.44h0ZM102.56,12.88l-34.52,19.59,44.9,5.72,31.47-19.83-41.86-5.49h0ZM36.56,28.46L70.02,8.61l-11.03-1.44L16.4,25.89l20.15,2.56h0Z"/>
                                            </g>
                                        </svg>
                                    <p style="font-weight: bold">{{shipmentDataREST.shipmentID}}</p>
                                </div>
                                <div class="address-container">
                                    <p><span style="font-weight: bold">Delivery Address: </span>{{shipmentDataREST.deliveryAddress1}}, {{shipmentDataREST.deliveryCity}} {{shipmentDataREST.deliveryState}}, {{shipmentDataREST.deliveryZipCode}}</p>
                                    <p><span style="font-weight: bold">Pickup Address: </span>{{shipmentDataREST.pickupAddress1}}, {{shipmentDataREST.pickupCity}} {{shipmentDataREST.pickupState}}, {{shipmentDataREST.pickupZipCode}}</p>
                                </div>

                                <div class="service-type">
                                    <p>Service Type:</p>
                                </div>

                                <div class="signatureInputLabel" v-for="p2pServiceCodeOption in p2pServiceCodeOptions" :key="p2pServiceCodeOption">
                                    <label for="p2pServiceCode">UPS Ground</label>
                                    <input type="radio" :value="p2pServiceCodeOption" v-model="p2pServiceCode" id="p2pServiceCode">
                                </div>
                                
                                <button  @click="createP2PParcel()" class="create-button">Create P2P Parcel</button>
                                <button @click="createP2PLabel()" class="create-label-button">Re-Create Label</button>
                            </div>
                            <div class="label-container" id="label-container" v-if="labelData != null">
                                <shipment-label :baseLabelData="labelData" />
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {Auth, API} from 'aws-amplify'
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue';
import {getShipmentDataREST, createParcelP2P, createLabelP2P} from '../../../graphql/mutations'
import ShipmentLabel from '../../ShipmentDashboard/ShipmentLabel.vue';

export default {
    data(){
        return{
            user: {},
            p2pServiceCodeOptions: ["UPS_GND"],
            p2pServiceCode: "",
            userInput: "",
            labelData: null, 
            selectedPartner: "",
            selectedShipment: 'Current',
            jwtToken: null,
            loadingUser: false,
            userData: {},
            userDataCognito: {},
            refreshKey: 0,
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            createCognitoUserInput: {
                email: "",
                username: "",
                accountNo: "",
                locationID: ""
            },
            selectedUserData:{
                locationID: ""
            },
            shipmentDataREST: {},
            p2pReturnData: {}
        }
    },
    components:{
        LoadingCharts,
        AlertUser,
        SignedInUserInfo,
        ShipmentLabel
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to pickup dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async createP2PLabel(){
            this.loadingUser = true;
            try {
                let p2pLabelReturnData = await API.graphql({
                    query: createLabelP2P,
                    variables: {
                        input:{
                            UniqueParcelID: this.shipmentDataREST.shipmentID
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.p2pLabelReturnData = p2pLabelReturnData.data.createLabelP2P;
                if(this.p2pLabelReturnData.status == 'success'){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Created P2P Label"
                    this.labelData = "data:application/pdf;base64, " + this.p2pLabelReturnData.Label;
                }else{
                    if(this.p2pLabelReturnData.errors != null){
                        this.toggleAlertBox = true;
                        this.alertMessage = this.p2pLabelReturnData.errors
                    }else{
                        this.toggleAlertBox = true;
                        this.alertMessage = "Unable to print label. Make sure parcel has been created in P2P."
                    }
                }
                this.loadingUser = false;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create P2P Parcel"
                this.loadingUser = false;
            }finally{
                this.loadingUser = false;
            }
        },
        async createP2PParcel(){
            if(this.p2pServiceCode == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Select a service type."
                return;
            }
            this.loadingUser = true;
            try {
                let p2pReturnData = await API.graphql({
                    query: createParcelP2P,
                    variables: {
                        input:{
                            UniqueParcelID: this.shipmentDataREST.shipmentID,
                            ConsigneeName: this.shipmentDataREST.deliveryAttention || "Not Provided",
                            JobName: "LREX",
                            ConsigneeBusinessName: this.shipmentDataREST.deliveryCompanyName,
                            ConsigneeStreetAddress1: this.shipmentDataREST.deliveryAddress1,
                            ConsigneeStreetAddress2: this.shipmentDataREST.deliveryAddress2 ?? "",
                            ConsigneeCity: this.shipmentDataREST.deliveryCity,
                            ConsigneeStateOrProvince: this.shipmentDataREST.deliveryState,
                            ConsigneePostalCode: this.shipmentDataREST.deliveryZipCode,
                            ConsigneeCountry: "US",
                            ConsigneeTelephone: this.shipmentDataREST.deliveryPhone ?? "",
                            ConsigneeEmail: this.shipmentDataREST.deliveryNotifyemail ?? "",
                            DeliveryInstructions: this.shipmentDataREST.DeliveryInstructions ?? "",
                            WeightCode: "",
                            CurrencyCode: "",
                            ServiceRequired: this.p2pServiceCode,
                            Items: [{
                                ItemDescription: this.shipmentDataREST.description ?? "LREX Item",
                                LineItemQuantity: "1",
                                CountryOfOrigin: "US",
                                CustomsValue: "1",
                                Weight: this.shipmentDataREST.shipmentWeight
                            }]
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.p2pReturnData = p2pReturnData.data.createParcelP2P;
                if(this.p2pReturnData.status == 'success'){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Created P2P Parcel. Label is being generated."
                    await this.createP2PLabel();
                }else{
                    this.toggleAlertBox = true;
                    this.alertMessage = this.p2pReturnData.errors
                }
                this.loadingUser = false;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create P2P Parcel"
                this.loadingUser = false;
            }finally{
                this.loadingUser = false;
            }
        },
        async getShipmentDataREST(){
            this.labelData = null;
            this.loadingUser = true;
            try {
                let shipmentReturnData = await API.graphql({
                    query: getShipmentDataREST,
                    variables: {
                        input:{
                            shipmentID: this.userInput
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.shipmentDataREST = shipmentReturnData.data.getShipmentDataREST;
                this.loadingUser = false;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get shipment"
                this.loadingUser = false;
            }finally{
                this.loadingUser = false;
            }
        },
        selectPartner(partner){
            this.selectedPartner = "";
            this.selectedPartner = partner;
        },
        submitForm() {
            this.getShipmentDataREST()
            // Add additional logic or send data to a server as needed
        },
        async checkBoxSelectID(event){
            this.createCognitoUserInput.locationID = event;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($UserName: String!){
                        getUser(userName: $UserName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            UserName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

html{
    background-color: #f3f3f3;
}

.submit-form {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
    width: fit-content;
}

.submit-form label,
.submit-form input,
.submit-form button {
    margin: 0; /* Remove default margins */
}

.submit-form button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    transition-duration: .5s;
    cursor: pointer;
}

.submit-form button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.address-container{
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
    padding: 12.5px;
    border-radius: 25px;
    margin-top: 5px;
}

.partner-option{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    transition-duration: .5s;
    cursor: pointer;
}

.partner-option:hover{
    transition-duration: .5s;
    background-color: #f2f2f2;
}

.partner-option img{
    width: 80%;
}

.label-container{
    margin-top: 5px;
    width: 100%;
}

.shipment-data{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    transition-duration: .5s;
}

.shipmentid-data{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
}

.create-button{
    background-color: #33f18a;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    transition-duration: .5s;
    cursor: pointer;
    margin-top: 5px;
    align-self: flex-start;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #2cd679;
}

.create-label-button{
    background-color: #32ccfe;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    transition-duration: .5s;
    cursor: pointer;
    margin-top: 5px;
    align-self: flex-start;
}

.create-label-button:hover{
    transition-duration: .5s;
    background-color: #30bdec;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.inner-overflow-container{
    width: 100%;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.create-user{
    flex-basis: 40%;
}

.update-user{
    flex-basis: 60%;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirmLabelContainer{
    margin-bottom: 5px;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.service-type{
    width: 100%;
    text-align: left;
    font-weight: bold;
    margin-top: 5px;
}

.signatureInputLabel{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.signatureInputLabel label{
    padding-bottom: 5px;
    padding-right: 5px;
    padding-top: 5px;
    text-align: left;
    /* width: 80%; */
}

.signatureInputLabel input{
    margin-left: auto !important;
    /* width: 20%; */
}

    input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #c4c4c4;
    border-radius: 999px;
    padding: 0;

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="radio"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #33f18a;
    border-radius: 999px;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }

    .partner-option{
        width: 60%;
    }

    .shipment-data{
        width: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
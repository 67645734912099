<template>
    <div class="carousel">
        <slot></slot>
    </div>
</template>

<script>

export default {
  data(){
      return{

      }
  },
  methods:{
      next(){
          this.$emit('next');
      },
      prev(){
          this.$emit('prev');
      }
  }
}
</script>

<style>
.carousel{
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 75vw;
    height: 15vw;
    overflow: hidden;
}

@media only screen and (max-width: 1000px){
    .carousel{
        height: 25vw;
    }
}

</style>
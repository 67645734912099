<template>
    <div class="image-carousel">
        <slot></slot>
    </div>
</template>

<script>

export default {
  data(){
      return{

      }
  },
  methods:{
      Imagenext(){
          this.$emit('Imagenext');
      },
      Imageprev(){
          this.$emit('Imageprev');
      }
  }
}
</script>

<style>
.image-carousel{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 3vw;
    margin-left: 3vw;
    position: relative;
    width: 80vw;
    height: 20vw;
    overflow-x: hidden;
}

@media only screen and (max-width: 400px){
    .image-carousel{
        height: 25vw;
    }
}
</style>
<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.75 148.87">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M154.27,12.53c.87-.17,1.78,0,2.54,.46,1.29,.53,2.2,1.79,2.2,3.28l.74,103.46c.07,1.27-.56,2.54-1.73,3.22l-42.25,25.21c-.6,.46-1.34,.72-2.15,.72-.2,0-.39-.01-.57-.05L3.39,136.62c-1.89-.08-3.39-1.64-3.39-3.55V27.85H0c-.04-1.4,.75-2.77,2.13-3.37L57.14,.31h0c.57-.26,1.22-.36,1.9-.27l95.23,12.49h0Zm-37.27,30.93v96.48l36-21.47-.7-97.26-35.3,22.25h0Zm-7,97.91V45l-46.8-5.97-1.54,44.72-16.43-11.19-16.43,9.29,3.4-46.78-25.19-3.21v98.07l103,11.44h0ZM102.56,12.88l-34.52,19.59,44.9,5.72,31.47-19.83-41.86-5.49h0ZM36.56,28.46L70.02,8.61l-11.03-1.44L16.4,25.89l20.15,2.56h0Z"/>
                                            </g>
                                        </svg>
                                        <h2>Create Dropbox</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="auto-complete-container">
                            <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                        </div>
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                        <g id="image">
                                            <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                        </g>
                                    </svg>
                                        <h2>Address</h2>
                                    </div>
                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="dropBoxInput.dropBoxCode" placeholder="Dropbox Code">
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <input type="text" id="address1" disabled v-model="dropBoxInput.serviceAddress.address.address1" placeholder="Address">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" id="city" disabled v-model="dropBoxInput.serviceAddress.address.city" placeholder="City">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <select disabled required name="state" id="state" v-model="dropBoxInput.serviceAddress.address.state">
                                            <option value="" disabled selected>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" id="zipcode" disabled v-model="dropBoxInput.serviceAddress.address.zipCode" placeholder="Zip Code">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                <button class="update-user-button" v-if="validateCustomAddress == false" @click="CreateDropBoxItem()">Create Dropbox</button>
                <button class="update-user-button" style="background-color: #33f18a;" @click="checkAddressCorrection()" v-if="validateCustomAddress == true">Check Address</button>
            </div>
        </div>
        <div v-if="(AdminUser || InternalAdmin)" class="track-shipment update-user">
            <dropbox-table :key="refreshKey" :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>

<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="dropBoxInput.serviceAddress.address"/>
</template>

<script>
import {createDropBox} from '../../../graphql/mutations'
import {Auth, API} from 'aws-amplify'
import AWSAutoComplete from '../../AWSLocation/AddressAutoComplete.vue';
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import DropboxTable from '../DropboxDashboard/DropboxTable.vue'

export default {
    data(){
        return{
            user: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            dropBoxInput: {
                dropBoxCode: "",
                isActive: true,
                serviceAddress: {
                    address:{
                        companyName: "",
                        attention: "",
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zipCode: "",
                        phone: "",
                        phoneExt: "",
                        relevance: "",
                        latitude: "",
                        longitude: ""
                    }
                }
            },
            refreshKey: 0,
            uploadFile: null,
            uploadFileNoSpaces: "",
            getFileStorageURL: null,
            logoUploadSuccess: false,
            logoLocationID: ""
        }
    },
    components:{
        AWSAutoComplete,
        AWSAddressCorrection,
        AlertUser,
        DropboxTable,
        SignedInUserInfo
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to dropbox dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async CreateDropBoxItem(){
            this.loadingUser = true;
            await this.createDropBox();
            this.validateCustomAddress = false;
            this.loadingUser = false;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createDropBox(){
            this.loadingUser = true;

            if((this.dropBoxInput.serviceAddress.address.address1.length == 0) || (this.dropBoxInput.serviceAddress.address.city.length == 0) || (this.dropBoxInput.serviceAddress.address.state.length == 0) || (this.dropBoxInput.serviceAddress.address.zipCode.length == 0)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter valid address information."
                this.loadingUser = false;
                throw new Error("Invalid Address");
            }

            try {
                let locationData = await API.graphql({
                    query: createDropBox,
                    variables: {
                        input:{
                            dropBoxCode: this.dropBoxInput.dropBoxCode,
                            serviceAddress: this.dropBoxInput.serviceAddress,
                            isActive: this.dropBoxInput.isActive
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.refreshKey++
                let obj = this.dropBoxInput.serviceAddress.address;
                console.log(locationData)
                for (const key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        obj[key] = '';
                    }
                }
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create dropbox."
                this.loadingUser = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        GetSelectedAddressData(selectedAddress){
            this.dropBoxInput.serviceAddress.address.latitude = selectedAddress.latitude;
            this.dropBoxInput.serviceAddress.address.longitude = selectedAddress.longitude;
            this.dropBoxInput.serviceAddress.address.address1 = selectedAddress.address1;
            this.dropBoxInput.serviceAddress.address.address2 = selectedAddress.address2;
            this.dropBoxInput.serviceAddress.address.zipCode = selectedAddress.zipCode;
            this.dropBoxInput.serviceAddress.address.city = selectedAddress.city;
            this.dropBoxInput.serviceAddress.address.state = selectedAddress.state;
            this.dropBoxInput.serviceAddress.address.relevance = selectedAddress.relevance;
        },
        customAddressToggle(toggleCustom){
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";

            document.getElementById('address1').disabled = false;
            document.getElementById('city').disabled = false;
            document.getElementById('state').disabled = false;
            document.getElementById('zipcode').disabled = false;
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            let customAddressString = this.dropBoxInput.serviceAddress.address.address1 + " " + this.dropBoxInput.serviceAddress.address.city + " " + this.dropBoxInput.serviceAddress.address.state + " " + this.dropBoxInput.serviceAddress.address.zipCode;
            //AddressCorrection Child Component is triggered when customAddress changes values
            this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            this.dropBoxInput.serviceAddress.address.relevance = event.shipmentAddressData.relevance;
            this.dropBoxInput.serviceAddress.address.latitude = event.shipmentAddressData.latitude;
            this.dropBoxInput.serviceAddress.address.longitude = event.shipmentAddressData.longitude;
            this.dropBoxInput.serviceAddress.address.address1 = event.shipmentAddressData.Address1;
            this.dropBoxInput.serviceAddress.address.address2 = event.shipmentAddressData.Address2;
            this.dropBoxInput.serviceAddress.address.zipCode = event.shipmentAddressData.ZipCode;
            this.dropBoxInput.serviceAddress.address.city = event.shipmentAddressData.City;
            this.dropBoxInput.serviceAddress.address.state = event.shipmentAddressData.State;

            if(event.callInsertAddressBook == true){
                this.CreateDropBoxItem();
            }
        },
        useCorrectedAddress(event){
            this.dropBoxInput.serviceAddress.address.relevance = event.shipmentDataValue.relevance;
            this.dropBoxInput.serviceAddress.address.latitude = event.shipmentDataValue.latitude;
            this.dropBoxInput.serviceAddress.address.longitude = event.shipmentDataValue.longitude;
            this.dropBoxInput.serviceAddress.address.address1 = event.shipmentDataValue.Address1;
            this.dropBoxInput.serviceAddress.address.address2 = event.shipmentDataValue.Address2;
            this.dropBoxInput.serviceAddress.address.zipCode = event.shipmentDataValue.ZipCode;
            this.dropBoxInput.serviceAddress.address.city = event.shipmentDataValue.City;
            this.dropBoxInput.serviceAddress.address.state = event.shipmentDataValue.State;
            
            if(event.callInsertAddressBook == true){
                this.CreateDropBoxItem();
            }
        },
        useEnteredAddress(event){
            if(event.callInsertAddressBook == true){
                this.CreateDropBoxItem();
            }
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.file-input{
    width: 80%;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.update-user{
    flex-basis: 60%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
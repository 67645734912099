<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <loading-skeleton :loadingHeight="'300px'" v-if="loadingUser"/>
            <div v-if="userData && userData.locationAddress" class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                                            <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                                <g>
                                                <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                                <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                                <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                                <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                                <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                                <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2>Profile</h2>
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="userData.fullname" placeholder="Full Name">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="userData.userEmail" placeholder="Email">
                                    </div>
                                </div>
                            </div>
                            <div class="confirmContainerInner">
                                <div class="confirm-container-address">
                                    <div class="confirmContainerInner1">
                                        <div class="confirm-svg">
                                            <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                            <g id="image">
                                                <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                            </g>
                                        </svg>
                                            <h2>Address</h2>
                                        </div>
                                        
                                        <div class="confirmLabelContainer">
                                            <input type="text" disabled v-model="userData.locationAddress.serviceAddress.address.companyName" placeholder="Company Name">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" disabled v-model="userData.locationAddress.serviceAddress.address.attention" placeholder="Attention">
                                        </div>
                                        
                                        <div class="confirmLabelContainer">
                                            <input type="text" id="address1" disabled v-model="userData.locationAddress.serviceAddress.address.address1" placeholder="Address">
                                        </div>
                                        
                                        <div class="confirmLabelContainer">
                                            <input type="text" disabled v-model="userData.locationAddress.serviceAddress.address.address2" placeholder="Suite/Apt.">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" id="city" disabled v-model="userData.locationAddress.serviceAddress.address.city" placeholder="City">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <select disabled required name="state" id="state" v-model="userData.locationAddress.serviceAddress.address.state">
                                                <option value="" disabled selected>State</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                            </select>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" id="zipcode" disabled v-model="userData.locationAddress.serviceAddress.address.zipCode" placeholder="Zip Code">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <account-locations @checkBoxSelectID="checkBoxSelectID($event)" :jwtToken="jwtToken" :userData="userData" :selectedUserData="selectedUserData"/>
                    </div>
                <button class="update-user-button" @click="UpdateUserGraphQL()">Update User</button>

            </div>
            <div v-if="(!userData && !userData.locationAddress) && !loadingUser" class="user-info">
                <p style="font-weight: bold;">No User Data</p>
            </div>
        </div>
        <div v-if="(AdminUser || InternalAdmin)" class="track-shipment update-user">
            <account-users :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>

</template>

<script>
import {updateUser} from '../../../graphql/mutations'
import {Auth, API} from 'aws-amplify'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'
import AlertUser from '../../../components/Popups/AlertUser.vue';
import AccountUsers from '../UserDashboard/AccountUsers.vue'
import AccountLocations from '../LocationDashboard/AccountLocations.vue'
import LoadingSkeleton from '../LoadingSkeleton.vue'

export default {
    data(){
        return{
            user: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            selectedUserData: null,
            selectedLocationID: ""
        }
    },
    components:{
        AlertUser,
        AccountUsers,
        SignedInUserInfo,
        AccountLocations,
        LoadingSkeleton
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin && !this.Customer && !this.CustomerAdmin) {
                    alert("Access denied to user dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
                this.selectedUserData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async UpdateUserGraphQL(){
            if((this.selectedLocationID == null) || (this.selectedLocationID == "")){
                this.toggleAlertBox = true;
                this.alertMessage = "Select the users location"
                return;
            }
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: updateUser,
                    variables: {
                        input:{
                            userName: this.userData.userName,
                            fullname: this.userData.fullname,
                            userEmail: this.userData.userEmail,
                            locationID: this.selectedLocationID
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.updateUser;
                this.GetUserGraphQL();
                this.toggleAlertBox = true;
                this.alertMessage = "Updated user information"
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to update user information"
                this.GetUserGraphQL();
            }finally{
                this.loadingUser = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async checkBoxSelectID(event){
            this.selectedLocationID = event;
            // await this.updateUserLocationID();
        },
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.update-user{
    flex-basis: 60%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
<template>
  <div class="table-container-main">
    <div class="confirm-svg">
        <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
            <g id="image">
                <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
            </g>
        </svg>
        <h2>GeoCodes</h2>
    </div>
    <table-filter @filterValues="filterValues($event)"/>
    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">GeoCode</div>
            <div class="col-3">Route Code</div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div class="table-row-container">
            <div class="table-row" v-for="(routeCode, index) in geoCodeTableData" :key="index">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text" @click="getGeoCodeClick(routeCode.geoCode, routeCode.routeCode)">{{routeCode.geoCode}}</p></div>
                <div class="col-3"><p class="service-text">{{routeCode.routeCode}}</p></div>
            </div>
            <div v-if="geoCodeTableData.length == 0" class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
            </div>
        </div>
    </div>
  </div>
  <div v-if="this.$route.name != 'UserDashboard'" class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
<geo-code-info v-if="toggleAddressBookInfo" @closeAddressInfo="closeAddressInfo($event)" :geoCodeID="geoCodeID" :geoCodeRouteCode="geoCodeRouteCode"/>
<alert-user  v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import GeoCodeInfo from '../GeoCodeDashboard/GeoCodeInfo.vue'
import TableFilter from '../TableFilter.vue'
import jsonexport from 'jsonexport'

export default {
    components:{
        AlertUser,
        LoadingCharts,
        GeoCodeInfo,
        TableFilter
    },
    props: ['userData', 'jwtToken', 'selectedUserData'],
    watch:{
        'userData':function(){
            if(this.userData)
            {
                this.getGeoCodeData();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user data to generate table."
            }
        }
    },
    mounted(){
        if(this.userData){
            this.getGeoCodeData(); 
        }
    },
    data(){
        return{
            toggleAddressBookInfo: false,
            id: null,
            geoCodeID: null,
            geoCodeRouteCode: null,
            geoCodeTableData: [],
            loadingTable: false,
            toggleAlertBox: '',
            alertMessage: '',
            selectedIndex: null,
            filterField: null,
            filterValue: null
        }
    },
    methods:{
        // isChecked(userLocationID){
        //     if(this.selectedUserData.locationID == userLocationID){
        //         return true;
        //     }else{
        //         return false;
        //     }
        // },
        // checkBoxSelectID(locationID, index){
        //     this.$emit('checkBoxSelectID', locationID)
        //     let checkbox = document.querySelectorAll("#selectIDCheckBox");
        //     this.selectedIndex = index;
        //     checkbox.forEach(element => {
        //         element.checked = false;
        //     });

        //     checkbox[index].checked = true;
        // },
        filterValues(event){
            this.isFiltered = true;
            this.filterField = event.filterField;
            this.filterValue = event.filterValue;

            switch (event.filterField) {
                case "zipCode":
                    this.GetGeoCodeByGeoCode(event.filterValue)
                    break;
                default:
                    break;
            }
        },
        async GetGeoCodeByGeoCode(filterValue){
            this.loadingTable = true;
            let listGeoCodes = `query getShipment {
                listGeoCodes(geoCode: "${filterValue}") {
                    items{
                    routeCode
                    geoCode
                    }
                }
            }`

            try{
                let getGeoCodes = await API.graphql({
                query:  listGeoCodes,
                variables:{
                    geoCode: filterValue
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.geoCodeTableData = getGeoCodes.data.listGeoCodes.items
                this.loadingTable = false;
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting GeoCode data"
                    this.loadingTable = false;
                }
            }
        },
        async getGeoCodeClick(id, routeCode){
            this.geoCodeID = id;
            this.geoCodeRouteCode = routeCode;
            this.toggleAddressBookInfo = true;
        },
        closeAddressInfo(event){
            this.toggleAddressBookInfo = event;
            this.getGeoCodeData();
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async getGeoCodeData(){
            this.loadingTable = true;
            let graphqlQuery = `query listGeoCodes{
                listGeoCodes(limit: 100){
                items{
                    routeCode
                    geoCode
                    }
                }
            }`
            
            try{
                let getGeoCodeDataReturn = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.geoCodeTableData = getGeoCodeDataReturn.data.listGeoCodes.items;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting route codes"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;
            csvArray = this.geoCodeTableData.map(obj =>{
                return {
                    geoCode: obj.geoCode,
                    RouteCode: obj.routeCode
                }
            })
            jsonexport(csvArray, (error, csv) => {
                if (error) {
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error downloading geocode data"
                }
                const blob = new Blob([csv], { type: 'text/csv' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = 'GeoCodes.csv';
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
                anchor.remove();
                this.loadingTable = false;
            })
        }
    }
}
</script>

<style scoped>
h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

.responsive-table{
    width: 100%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin-bottom: 5px;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    /* white-space: nowrap; */
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
    max-height: 40px;
    overflow-y: auto;
    border: 1px solid #a0a0a0;
    border-radius: 5px 0px 0px 5px;
}

.col-data p{
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text, .col-data{
        font-size: 8px;
    }

    .shipmentid-text{
        font-size: 10px;
    }
}
</style>
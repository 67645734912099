<template>
<div v-if="loadingInitiate" class="loading-container">
    <loading-data :headerMessage="'Initiating Pickup'"/>
</div>
  <div v-if="((selectedShipmentArray.length > 0) && (selectedShipment == 'Current'))" class="button-container">
    <div class="print-selected">
        <p class="print-selected-text" @click="updateActivity()">Initiate Pickup</p>
    </div>
    <div v-if="labelData != null" class="print-selected close-label">
        <p class="print-selected-text" @click="closeLabel(); labelData = null; selectedShipmentArray = []">Close Label</p>
    </div>
  </div>
  <div class="label-container" v-if="labelData != null">
      <shipment-label :baseLabelData="labelData" />
  </div>
  <loading-charts v-if="loadingData" class="loading-charts"/>
  <div class="table-container-main">
    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">Shipment ID</div>
            <div class="col-2">Location</div>
            <div class="col-3">Route</div>
            <div class="col-4"><input v-if="selectedShipment == 'Current'" style="border: 0.15em solid #000; margin-left: 5px;" type="checkbox" name="" id="select-all-checkbox" @change="selectAllShipmentID()"></div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div v-if="selectedShipment == 'Current'" class="table-row-container">
            <div class="table-row" v-for="(shipment, index) in currentShipments" :key="index">
                <div @click="trackShipment(shipment.shipmentID)" class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{shipment.shipmentID}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{shipment.deliveryPickup.address.companyName}}</span> {{shipment.deliveryPickup.address.address1}}, {{shipment.deliveryPickup.address.city}}, {{shipment.deliveryPickup.address.state}}, {{shipment.deliveryPickup.address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{shipment.deliveryPickup.address.routeCode}}</p></div>
                <div class="col-4"><input type="checkbox" name="" id="" :value="shipment.shipmentID" v-model="selectedShipmentArray"></div>
            </div>
        </div>
        <div v-if="selectedShipment == 'In Transit'" class="table-row-container">
            <div class="table-row" v-for="(shipment, index) in inTransitShipments" :key="index">
                <div @click="trackShipment(shipment.shipmentID)" class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{shipment.shipmentID}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{shipment.deliveryPickup.address.companyName}}</span> {{shipment.deliveryPickup.address.address1}}, {{shipment.deliveryPickup.address.city}}, {{shipment.deliveryPickup.address.state}}, {{shipment.deliveryPickup.address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{shipment.deliveryPickup.address.routeCode}}</p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="(inTransitShipments.length == 0) && (selectedShipment == 'In Transit')" class="table-row-container">
            <div class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Shipments</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="(currentShipments.length == 0) && (selectedShipment == 'Current')" class="table-row-container">
            <div class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Shipments</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
    </div>
  </div>
  <div class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
  
<shipment-info v-if="toggleTrackShipment" @closeShipmentInfo="closeShipmentInfo($event)" :trackShipmentID="trackShipmentID" :cognitoUser="cognitoUser" :jwtToken="jwtToken" :userData="userData"/>
<alert-user  v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import ShipmentInfo from './../ShipmentInfo.vue'
import {createShipmentLabelLambda, updateShipmentActivity} from '../../../graphql/mutations'
import ShipmentLabel from '../../ShipmentDashboard/ShipmentLabel.vue'
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingData from '../../Popups/LoadingData.vue'
import jsonexport from 'jsonexport'

export default {
    components:{
        ShipmentInfo,
        ShipmentLabel,
        LoadingCharts,
        AlertUser,
        LoadingData
    },
    props: ['selectedShipment', 'userData', 'jwtToken', 'cognitoUser'],
    mounted(){
        document.getElementById('header').style.backgroundColor = "#33f18a"
    },
    watch:{
        'userData':function(){
            if(this.userData)
            {
                this.getCurrentShipmentsGraphQL();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user data to generate table."
            }
        },
        'selectedShipment'(newValue, oldValue){
            if(newValue != oldValue){
            if(this.selectedShipment == 'Current'){
                document.getElementById('header').style.backgroundColor = "#33f18a"
                this.selectedShipmentArray = [];
                this.currentShipments = []
                this.labelData = null;
                this.getCurrentShipmentsGraphQL();
            }else if(this.selectedShipment == 'In Transit'){
                document.getElementById('header').style.backgroundColor = "#32ccfe"
                this.selectedShipmentArray = [];
                this.inTransitShipments = [];
                this.labelData = null;
                this.getInTransitShipmentsGraphQL();
            }
            }
        }
    },
    data(){
        return{
            currentShipments: [],
            inTransitShipments: [],
            trackShipmentID: null,
            toggleTrackShipment: false,
            user: null,
            selectedShipmentArray: [],
            labelData: null,
            loadingData: false,
            loadingInitiate: false,
            loadingTable: false,
            toggleAlertBox: '',
            alertMessage: ''
        }
    },
    methods:{
        selectAllShipmentID(){
            if(this.selectedShipmentArray.length == 0){
                this.selectedShipmentArray = [];
                this.selectedShipmentArray = this.currentShipments.map(item => item.shipmentID)
            }else{
                this.selectedShipmentArray = [];
            }
        },
        async getCurrentShipmentsGraphQL(){
            this.loadingTable = true;
            let graphqlQuery;
            this.currentShipments = []
            let currentArray = []

            graphqlQuery = `query getCurrentPickups{
                getCurrentShipmentsPickupService{
                    shipmentID
                    currentKey
                    service
                    serviceAddress{
                        address{
                            routeCode
                            companyName
                            address1
                            city
                            state
                            zipCode
                        }
                    }
                    deliveryPickup{
                        address{
                            routeCode
                            companyName
                            address1
                            city
                            state
                            zipCode
                        }
                    }
                }
            }`
            
            try{
                let shipmentByCurrentKey = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                currentArray = shipmentByCurrentKey.data.getCurrentShipmentsPickupService;

                this.currentShipments = currentArray;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting current shipments"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async getInTransitShipmentsGraphQL(){
            this.loadingTable = true;
            let graphqlQuery;
            let inTransitArray = []

            graphqlQuery = `query getPickupInitiated{
                getShipmentsPickupInitiated{
                    shipmentID
                    inTransitKey
                    service
                    serviceAddress{
                        address{
                            routeCode
                            companyName
                            address1
                            city
                            state
                            zipCode
                        }
                    }
                    deliveryPickup{
                        address{
                            routeCode
                            companyName
                            address1
                            city
                            state
                            zipCode
                        }
                    }
                }
            }`

            try{
                this.inTransitShipments = []
                let shipmentByPickupInitiated = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                inTransitArray = shipmentByPickupInitiated.data.getShipmentsPickupInitiated;

                this.inTransitShipments = inTransitArray;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting in pickup initiated shipments"
                }
            }finally{
                this.loadingTable = false;
            }
        },
        async PrintLabel(){
            this.loadingData = true;
            this.loadingInitiate = true;
            const renderRequest = {
                    data: {
                        user: this.userData.userName,
                        jwtToken: this.jwtToken,
                        idArray: this.selectedShipmentArray
                    },
                    template: {
                        name: "LREXLabelGraphQLPickupTemplate",
                        engine: "handlebars",
                        recipe: "chrome-pdf"
                    }
                }
            try{
                let shipmentLabels = await API.graphql({
                query:  createShipmentLabelLambda,
                variables:{ input:{renderRequest} },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.labelData = "data:application/pdf;base64, " + shipmentLabels.data.createShipmentLabelLambda.labelImage.slice(1, -1);
                this.loadingInitiate = false;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error printing shipment label"
                    this.loadingInitiate = false;
                }
            }finally{
                this.loadingData = false;
                this.loadingInitiate = false;
            }
        },
        async updateActivity(){
            this.loadingInitiate = true;
            let date = new Date();
            let counter = 0;
            try {
                for (let i = 0; i < this.selectedShipmentArray.length; i++) {
                    counter++
                    await API.graphql({
                        query: updateShipmentActivity,
                        variables: {
                            input: {
                                shipmentID: this.selectedShipmentArray[i],
                                ShipmentId: parseInt(this.selectedShipmentArray[i]),
                                Code: "9",
                                Description: "Pickup Initiated",
                                statusID: "9",
                                ShipmentStatusId: 9,
                                ShipmentStage: "Pickup Initiated",
                                ShipmentPoint: "Pickup Initiated",
                                ShippingEvent: "Pickup Initiated",
                                ActivityTime: date.toJSON(),
                                RecordedTime: date.toJSON(),
                                Comments: "Pickup Initiated",
                                InternalComments: "Pickup Initiated",
                                Notes: "Pickup Initiated"
                            }
                        },
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                }
                this.toggleAlertBox = true;
                this.alertMessage = "Pickup Initiated";
                if(counter == this.selectedShipmentArray.length){
                    this.PrintLabel();
                    this.getCurrentShipmentsGraphQL();
                    this.loadingInitiate = false;
                }
            } catch (error) {
                console.log(error)
                this.loadingInitiate = false;
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to initiate pickup"
            }finally{
                this.loadingInitiate = false;
            }
        },
        closeLabel(){
            const checkbox = document.getElementById('select-all-checkbox');
            if (checkbox) {
                checkbox.checked = false;
            }
        },
        trackShipment(shipmentID){
            this.toggleTrackShipment = true;
            this.trackShipmentID = shipmentID;
        },
        closeShipmentInfo(event){
            if(this.selectedShipment == 'In Transit'){
                this.toggleTrackShipment = event;
                this.getInTransitShipmentsGraphQL();
                this.$emit('refreshAfterVoid', true);
            }
            this.toggleTrackShipment = event;
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;

            if (this.selectedShipment == 'Current') {
                csvArray = this.currentShipments.map(obj =>{
                    return {
                        shipmentID: obj.shipmentID,
                        Service: obj.service,
                        AccountNo: obj.accountNo,
                        DeliveryCompanyName: obj.serviceAddress.address.companyName,
                        DeliveryAddress1: obj.serviceAddress.address.address1,
                        DeliveryAddress2: obj.serviceAddress.address.address2,
                        DeliveryCity: obj.serviceAddress.address.city,
                        DeliveryState: obj.serviceAddress.address.state,
                        DeliveryZipCode: obj.serviceAddress.address.zipCode,
                        PickupCompanyName: obj.deliveryPickup.address.companyName,
                        PickupAddress1: obj.deliveryPickup.address.address1,
                        PickupAddress2: obj.deliveryPickup.address.address2,
                        PickupCity: obj.deliveryPickup.address.city,
                        PickupState: obj.deliveryPickup.address.state,
                        PickupZipCode: obj.deliveryPickup.address.zipCode,
                    }
                })
                jsonexport(csvArray, (error, csv) => {
                    if (error) {
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error downloading current shipments"
                    }
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.download = 'CurrentShipments.csv';
                    anchor.click();
                    window.URL.revokeObjectURL(anchor.href);
                    anchor.remove();
                    this.loadingTable = false;
                })
            }else if(this.selectedShipment == 'In Transit'){
                csvArray = this.inTransitShipments.map(obj =>{
                    return {
                        shipmentID: obj.shipmentID,
                        Service: obj.service,
                        AccountNo: obj.accountNo,
                        DeliveryCompanyName: obj.serviceAddress.address.companyName,
                        DeliveryAddress1: obj.serviceAddress.address.address1,
                        DeliveryAddress2: obj.serviceAddress.address.address2,
                        DeliveryCity: obj.serviceAddress.address.city,
                        DeliveryState: obj.serviceAddress.address.state,
                        DeliveryZipCode: obj.serviceAddress.address.zipCode,
                        PickupCompanyName: obj.deliveryPickup.address.companyName,
                        PickupAddress1: obj.deliveryPickup.address.address1,
                        PickupAddress2: obj.deliveryPickup.address.address2,
                        PickupCity: obj.deliveryPickup.address.city,
                        PickupState: obj.deliveryPickup.address.state,
                        PickupZipCode: obj.deliveryPickup.address.zipCode,
                    }
                })
                jsonexport(csvArray, (error, csv) => {
                    if (error) {
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error downloading in transit shipments"
                    }
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.download = 'InTransitShipments.csv';
                    anchor.click();
                    window.URL.revokeObjectURL(anchor.href);
                    anchor.remove();
                    this.loadingTable = false;
                })
            }
        }
    }
}
</script>

<style scoped>
.loading-container{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center !important;
}

h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.responsive-table{
    width: 100%;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    white-space: nowrap;
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text{
        font-size: 8px;
    }

    .col-data, .col-1{
        font-size: 10px;
    }
}

@media only screen and (max-width: 1135px) {
   .col-1{
        min-width: 30%;
    }
}
</style>
<template>
  <div v-show="loading == false" class="popup">
      <div class="popup-inner">
        <div class="closeSignIn-container">
            <h1>Sign in</h1>
            <div class="close-popup-container"><i class="fa fa-times-circle" @click="SignInTogglePopup()"></i></div>
            <!-- <div class="closeSignIn" @click="SignInTogglePopup()"></div>  -->
        </div>
        
            <div class="signIn-container">
                <!-- <iframe src="https://stage.njls.com/clients/MarketingHeader.aspx" frameborder="0" class="login-iframe"></iframe> -->
                <div class="iframe-container">
                    <!-- <iframe src="https://www.lrex.com/clients/MarketingHeader.aspx" frameborder="0" scrolling="no" class="login-iframe"></iframe> -->
                    <!-- <iframe src="../clients/MarketingHeader.aspx" @load="SignInLoaded()" frameborder="0" scrolling="no" class="login-iframe"></iframe> -->
                    <iframe v-bind:src="iFrameSrc" @load="SignInLoaded()" frameborder="0" scrolling="no" class="login-iframe"></iframe>
                </div> 
            </div>
            <!-- <div class="redirect-container">
                <p>To Sign in at NJLS <a href="https://www.njls.com/Home" target="_blank">Click Here</a></p>
            </div> -->
      </div>
  </div>

    <!-- <div class="" v-if="loading"> -->
        <LoadingData v-if="loading == true" :headerMessage="headerMessage"/>
    <!-- </div> -->
</template>

<script>
import LoadingData from '../../components/Popups/LoadingData.vue';

export default {
    components:{
        LoadingData
    },
    props: ['SignInTogglePopup'],
    data(){
        return{
            shipmentID: this.$route.params.shipId,
            shipmentZip: this.$route.params.shipZip,
            locationHost: location.host,
            loading: true,
            headerMessage: "Loading",
            iFrameSrc: ''
        }
    },
    methods:{
        SignInLoaded(){
            console.log("Frame Loaded");
            this.loading = false;
        }
    },
    beforeMount(){
        if(this.$route.params.shipZip){
            this.iFrameSrc = '../../clients/MarketingHeader.aspx'
        }else{
            this.iFrameSrc = '../clients/MarketingHeader.aspx'
        }
    }

}
</script>

<style scoped>

.iframe-container{
    width: 100%;
}

.login-iframe{
    width: 1px;
    min-width: 100%;
    *width: 100%;
    height: 125%;
    padding-bottom: 10px;
}

.popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: drop .5s ease forwards;
    

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    font-family: 'Work Sans', sans-serif;
    
}

.popup-inner{
    width: 295px;
    height: 220px;
    border-radius: 15px;
    background-color: white;

    overflow-y: hidden;

    display: flex;
    justify-content: center;
    flex-direction: column;

    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.redirect-container{
    background-color: #fe804d;
    border-radius: 15px;
    margin: 5px;
    padding: 2px;
}

.popup-inner p{
    color: #fff;
    margin: 2px;
}

.popup-close{
    position: absolute;
    top: 5px;
    justify-content: center;
    margin-left: 2.5vw;
}

.signIn-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 1%;
    margin-right: 1%;
    padding-bottom: 10px;
}


@keyframes drop {
  0%{}
  70%{transform: translateY(20px);}
  100%{transform: translateY(15px);}
}

.close-popup-container{
    margin-left: auto;
    margin-right: 5px;
}

.fa-times-circle{
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
    transition-duration: .5s;
}

.fa-times-circle:hover{
    color: rgb(218, 218, 218);
    cursor: pointer;
    transition-duration: .5s;
}

.closeSignIn-container{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2cb6e4;
    border-radius: 15px 15px 0 0;
}

.closeSignIn-container h1{
    color: #fff;
    text-align: center;
    font-size: 1.8rem;
    flex: 1;
}

</style>
<template>
  <div class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <svg class="close-shipment-info" @click="closeImportAddress()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <div class="header-container">
                        <svg class="address-book-svg" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <g>
                                <path d="m21,5H4c-.6,0-1-.4-1-1s.4-1,1-1h17c.6,0,1,.4,1,1s-.4,1-1,1Z" style="fill: #fff;"/>
                                <path d="m21,6H4c-1.1,0-2-.9-2-2s.9-2,2-2h17c.6,0,1-.4,1-1s-.4-1-1-1H4C1.8,0,0,1.8,0,4v20c0,2.2,1.8,4,4,4h17c.6,0,1-.4,1-1V7c0-.6-.4-1-1-1Z" style="fill: #fff;"/>
                                </g>
                                <path d="m6.85,14.05c0-2.29,1.86-4.15,4.15-4.15s4.15,1.86,4.15,4.15" style="fill: none; stroke: #32ccfe; stroke-miterlimit: 10; stroke-width: .8px;"/>
                                <path d="m15.15,14.05c0,3.35-4.15,7.75-4.15,7.75,0,0-4.15-4.4-4.15-7.75" style="fill: none; stroke: #32ccfe; stroke-miterlimit: 10; stroke-width: .8px;"/>
                                <circle cx="10.95" cy="14" r="1.9" style="fill: none; stroke: #32ccfe; stroke-miterlimit: 10; stroke-width: .8px;"/>
                            </g>
                        </svg>
                        <h1 class="package-details-header">Import Address</h1>
                    </div>
                
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>
                    <div class="import-address-text-container"> 
                        <div class="import-address-text-container-inner">
                            <p>To upload a .csv file, save an Excel document as .csv. Be sure your file contains the following fields:
                                <br>
                                <strong>companyName, contactName, address1, address2, city, state, zipCode, plusFour, phone, phoneExt, email, fax, deliveryInstructions</strong>
                                <br>
                                To retrieve a template of such a layout, export the template from the button below. Be sure not to delete the first row with the headers listed above.
                            </p>
                            <div>
                                <button style="margin: 0 !important;" class="main-button-green-white" @click="DownloadCSVTemplate()">Download Template</button>
                            </div>
                            <div>
                                <input type="file" id="csvFile" accept=".csv" @change="ImportCSV()" />
                            </div>
                            <p v-if="ImportCSVData.length > 0">Number of addresses added: <strong>{{ImportCSVData[0].length}}</strong></p>
                    <div class="validation-errors" v-if="ValidateImportErrors.length > 0">
                        <h3>Validation Errors</h3>
                        <p v-for="(items, index) in this.ValidateImportErrors" v-bind:key="items">{{ValidateImportErrors[index]}}</p>
                    </div>
                    <div class="address-book-import-error" v-if="errorGeoCodeAddressImportArray.length > 0">
                        <h3>Address Book Import Errors</h3>
                        <button style="margin: 0 !important;" class="main-button-green-white" @click="DownloadErrorGeoCodeArrayCSV()">Download Address Errors</button>
                    </div>
                    <loading-charts v-show="loadingValidate"/>
                    </div>
                    </div>
                        <div v-if="ImportCSVData.length > 0" class="inner-overflow-container">
                            <div v-if="(addressBookImportArray.length > 0) && geoCoded" class="responsive-table">
                                <p class="table-header" style="margin-bottom: 1px;" v-if="(addressBookImportArray.length > 0) && geoCoded">GeoCoded Address Data</p>
                                <div class="table-header" id="header">
                                    <div class="col-1">Company Name</div>
                                    <div class="col-2">Location</div>
                                    <div class="col-3">Attention</div>
                                    <div class="col-4">Delivery Instructions</div>
                                    <div class="col-5">Delivery Email</div>
                                    <div class="col-6">Delivery Phone</div>
                                </div>
                                <div class="table-row-container">
                                    <div class="table-row" v-for="(address, index) in addressBookImportArray" :key="index">
                                        <div v-if="address.relevance < .9" class="col-1"><p style="background-color: red; color: #fff;" class="shipmentid-text" id="shipmentid-text">{{address.companyName}}</p></div>
                                        <div v-else class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{address.companyName}}</p></div>
                                        <div class="col-2 col-data"><span style="font-weight: bold;">{{address.companyName}}</span> {{address.address1}}, {{address.city}}, {{address.state}}, {{address.zipCode}}</div>
                                        <div class="col-3 col-data"><p class="service-text">{{address.attention}}</p></div>
                                        <div class="col-4"><p>{{address.deliveryInstructions}}</p></div>
                                        <div class="col-5"><p>{{address.email}}</p></div>
                                        <div class="col-6"><p>{{address.phone}}</p></div>
                                    </div>
                                    <div v-if="addressBookImportArray.length == 0" class="table-row">
                                        <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                                        <div class="col-2 col-data">No Addresses</div>
                                        <div class="col-3 col-data"><p class="service-text"></p></div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="ImportCSVData.length > 0" class="responsive-table">
                                <p class="table-header" style="margin-bottom: 1px;" v-if="ImportCSVData.length > 0">Imported Address Data</p>
                                <div class="table-header" id="header">
                                    <div class="col-1">Company Name</div>
                                    <div class="col-2">Location</div>
                                    <div class="col-3">Attention</div>
                                    <div class="col-4">Delivery Instructions</div>
                                    <div class="col-5">Delivery Email</div>
                                    <div class="col-6">Delivery Phone</div>
                                </div>
                                <div class="table-row-container">
                                    <div class="table-row" v-for="(address, index) in ImportCSVData[0]" :key="index">
                                        <div class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{address.companyName}}</p></div>
                                        <div class="col-2 col-data"><span style="font-weight: bold;">{{address.companyName}}</span> {{address.address1}}, {{address.city}}, {{address.state}}, {{address.zipCode}}</div>
                                        <div class="col-3 col-data"><p class="service-text">{{address.contactName}}</p></div>
                                        <div class="col-4"><p>{{address.deliveryInstructions}}</p></div>
                                        <div class="col-5"><p>{{address.email}}</p></div>
                                        <div class="col-6"><p>{{address.phone}}</p></div>
                                    </div>
                                    <div v-if="ImportCSVData.length == 0" class="table-row">
                                        <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                                        <div class="col-2 col-data">No Addresses</div>
                                        <div class="col-3 col-data"><p class="service-text"></p></div>
                                    </div>
                                </div>
                            </div>

                            </div>
                            <loading-charts v-if="loadingData"/>
                            <button class="print-button create-button" v-if="showValidateButton && (ImportCSVData[0].length > 0)" @click="ValidateImport()">Validate Address</button>
                            <button class="print-button create-button" v-if="showImportButton && !geoCoded" @click="GeoCodeAddressImport()">GeoCode Address</button>
                            <button class="print-button create-button" v-if="showImportButton && geoCoded" @click="createAddressBookItem()">Import Address</button>
                            <button class="print-button print-button" v-if="showImportButton && geoCoded" @click="CancelImport()">Cancel Import</button>
                        </div>
                    </div>
            </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="addressData"/>
</template>

<script>
import {API, Auth} from 'aws-amplify';
import Location from "aws-sdk/clients/location";
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import {createAddressBook} from '../../../graphql/mutations'
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';
import jsonexport from 'jsonexport'

export default {
    props:['id', 'userData', 'jwtToken'],
    components:{
        AlertUser,
        LoadingCharts,
        AWSAddressCorrection
    },
    data(){
        return{
            addressData: {
                userName: this.userData.userName,
                accountNo: this.userData.accountNo,
                companyName: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                attention: "",
                email: "",
                phone: "",
                deliveryInstructions: "",
                latitude: "",
                longitude: "",
                relevance: "",
                phoneExt: ""
            },
            toggleAlertBox: false, 
            alertMessage: null,
            labelData: null,
            loadingData: false,
            loadingValidate: false,
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            ImportCSVData: [],
            ValidateImportErrors: [],
            addressBookImportArray: [],
            showValidateButton: false,
            showImportButton: false,
            geoCoded: false,
            importArray: null,
            errorGeoCodeAddressImportArray: []
        }
    },
    methods:{
        async createAddressBookItem(){
            let counter = 0;
            this.loadingData = true;
            for (let i = 0; i < this.importArray[0].length; i++) {
                    this.loadingTable = true;
                    try{
                        let getAddressBook = await API.graphql({
                        query:  createAddressBook,
                        variables: {input:{
                            userName: this.userData.userName,
                            accountNo: this.userData.accountNo,
                            address1: this.importArray[0][i].address1,
                            address2: this.importArray[0][i].address2,
                            city: this.importArray[0][i].city,
                            state: this.importArray[0][i].state,
                            zipCode: this.importArray[0][i].zipCode,
                            companyName: this.importArray[0][i].companyName,
                            attention: this.importArray[0][i].attention,
                            email: this.importArray[0][i].email,
                            phone: this.importArray[0][i].phone,
                            deliveryInstructions: this.importArray[0][i].deliveryInstructions,
                            relevance: this.importArray[0][i].relevance,
                            longitude: this.importArray[0][i].longitude,
                            latitude: this.importArray[0][i].latitude,
                        }},
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        })
                        counter += 1;
                        console.log(getAddressBook)
                    }catch(e){
                        console.log(e)
                        if(e){
                            this.toggleAlertBox = true;
                            this.alertMessage = "Error importing addresses"
                        }
                    }finally{
                        this.loadingTable = false;
                    }
            }

            if(counter == this.importArray[0].length){
                this.toggleAlertBox = true;
                this.alertMessage = "Addresses Imported"
                this.loadingData = false;
                setTimeout(() => {
                    this.closeImportAddress();
                }, 1000);
            }
        },
        /**
         * Creates download (CSV) for user to use as template for import
         */
        DownloadCSVTemplate(){
                let data = [];
                let addressData = {
                    companyName: null,
                    contactName: null,
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    zipCode: null,
                    plusFour: null,
                    phone: null,
                    phoneExt: null,
                    fax: null,
                    email: null,
                    deliveryInstructions: null
                }
                data.push(addressData);
                let Testcsv = data.map(row => Object.values(row));
                Testcsv.unshift(Object.keys(data[0]));
                Testcsv.join('\n');
                let returnCSV = `"${Testcsv.join('"\n"').replace(/,/g, '","')}"`;

                this.DownloadCSVData(returnCSV);
        },
        DownloadCSVData(dataCSV){
            var fileCSV = new Blob([dataCSV], {
                type: "text/csv;charset=utf-8"
            });

            let downloadLink = document.createElement('a');

            downloadLink.download = "AddressBook.csv";
            let url = window.URL.createObjectURL(fileCSV);
            downloadLink.href = url;

            downloadLink.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        DownloadErrorGeoCodeArrayCSV(){
            let csvArray;
            csvArray = this.errorGeoCodeAddressImportArray.map(obj =>{
                return {
                    Address1: obj.address1,
                    Address2: obj.address2,
                    Attention: obj.attention,
                    City: obj.city,
                    CompanyName: obj.companyName,
                    Email: obj.email,
                    Latitude: obj.latitude,
                    Longitude: obj.longitude,
                    Phone: obj.phone,
                    State: obj.state,
                    ZipCode: obj.zipCode
                }
            })
            jsonexport(csvArray, (error, csv) => {
                if (error) {
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error downloading address data"
                }
                const blob = new Blob([csv], { type: 'text/csv' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = 'AddressBookError.csv';
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
                anchor.remove();
                this.loadingTable = false;
            })
        },
        /**
         * ImportCSV() is called when file input changes
         * Using FileReader to consume the CSV data and send text to csvToArray()
         */
        ImportCSV(){
            this.ImportCSVData = [];
            this.ValidateImportErrors = [];
            this.InsertAddressErrorArray = [];
            this.ValidateImportErrors = [];
            this.addressBookImportArray = [];
            this.showValidateButton = true;
            this.showImportButton = false;
            this.showOkayButton = false;
            this.showCancelButton = true;

            const csvFile = document.getElementById("csvFile");
            const fileInput = csvFile.files[0];
            const reader = new FileReader();

            reader.onload =  (e)=> {
                const text = e.target.result;
                this.csvToArray(text)
                //console.log(text)
            }
            reader.readAsText(fileInput);
        },
        /**
         * csvToArray() takes csvString from user input and converts it to an array 
         * The array is checked for empty rows and then returned
         */
        csvToArray(csvString, delimiter = ','){
            var headers = csvString.slice(0, csvString.indexOf("\n")).split(delimiter);
            const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");

            headers = headers.map(function(el){
                el.trim();
                return el.replace(/\s/g, '');
            });

            const arrayCSV =  rows.map(function(row){
                // eslint-disable-next-line
                const values = row.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g);
                const element = headers.reduce(function(object, header, index){
                    object[header] = values[index];
                    return object;
                }, {});
                return element;
            });
            let jsonString = JSON.stringify(arrayCSV);
            let str = jsonString.replace(/\\"/g, '');
            const obj = JSON.parse(str);

            //Check for empty rows/rows with no values
            this.ImportCSVData.push(obj);            

            for(let i = this.ImportCSVData[0].length - 1; i >= 0; i--){
                //console.log(Object.keys(this.ImportCSVData[0][i]).length)
                //console.log(this.ImportCSVData[0])

                if(Object.keys(this.ImportCSVData[0][i]).length < 13){
                    this.ImportCSVData[0].splice(i, 1);
                }
                else if((this.ImportCSVData[0][i].companyName == null || this.ImportCSVData[0][i].companyName == "\r") 
                    && (this.ImportCSVData[0][i].contactName == null || this.ImportCSVData[0][i].contactName == "\r")
                    && (this.ImportCSVData[0][i].address1 == null || this.ImportCSVData[0][i].address1 == "\r")
                    && (this.ImportCSVData[0][i].address2 == null || this.ImportCSVData[0][i].address2 == "\r")
                    && (this.ImportCSVData[0][i].city == null || this.ImportCSVData[0][i].city == "\r")
                    && (this.ImportCSVData[0][i].state == null || this.ImportCSVData[0][i].state == "\r")
                    && (this.ImportCSVData[0][i].zipCode == null || this.ImportCSVData[0][i].zipCode == "\r")
                    && (this.ImportCSVData[0][i].plusFour == null || this.ImportCSVData[0][i].plusFour == "\r")
                    && (this.ImportCSVData[0][i].phone == null || this.ImportCSVData[0][i].phone == "\r")
                    && (this.ImportCSVData[0][i].phoneExt == null || this.ImportCSVData[0][i].phoneExt == "\r")
                    && (this.ImportCSVData[0][i].fax == null || this.ImportCSVData[0][i].fax == "\r")
                    && (this.ImportCSVData[0][i].email == null || this.ImportCSVData[0][i].email == "\r")
                    && (this.ImportCSVData[0][i].deliveryInstructions == null || this.ImportCSVData[0][i].deliveryInstructions == "\r")){
                        this.ImportCSVData[0].splice(i, 1);
                    }
                }

            return arrayCSV;
        },
        /**
         * Validate user import before allowing the import button to toggle
         */
        ValidateImport(){
            this.ValidateImportErrors = [];
            let counter = 0;
            this.loadingData = true;
            for(let i = 0; i < this.ImportCSVData[0].length; i++){
                counter++
                if(this.ImportCSVData[0][i].companyName == ""){
                    this.ValidateImportErrors.push("Enter a Company Name for Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].address1 == ""){
                    this.ValidateImportErrors.push("Enter an Address1 for Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].city == ""){
                    this.ValidateImportErrors.push("Enter a City for Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].zipCode.length != 5){
                    this.ValidateImportErrors.push("Invalid Zip Code in Address " + (i+1));
                }
                if((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.ImportCSVData[0][i].phone) == true) || (this.ImportCSVData[0][i].phone == "")){
                    if(this.ImportCSVData[0][i].phone != ""){
                        let trimPhone = this.ImportCSVData[0][i].phone.replace(/[^0-9]/g, '');
                        //let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                        this.ImportCSVData[0][i].phone = trimPhone;
                    }
                }else{
                    this.ValidateImportErrors.push("Invalid Phone Number in Address " + (i+1));
                }
                if((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.ImportCSVData[0][i].fax) == true) || (this.ImportCSVData[0][i].fax == "")){
                    if(this.ImportCSVData[0][i].fax != ""){
                        let trimFax = this.ImportCSVData[0][i].fax.replace(/[^0-9]/g, '');
                        let trimFaxDashes = trimFax.slice(0,3)+"-"+trimFax.slice(3,6)+"-"+trimFax.slice(6);
                        this.ImportCSVData[0][i].Fax = trimFaxDashes;
                    }
                }else{
                    this.ValidateImportErrors.push("Invalid Fax Number in Address " + (i+1));
                }
                if((/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.ImportCSVData[0][i].email) == true) || (this.ImportCSVData[0][i].email == "")){
                    //break;
                }else{
                    this.ValidateImportErrors.push("Invalid Email in Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].state.length != 2){
                    this.ValidateImportErrors.push("Invalid State Value in Address " + (i+1));
                }else{
                    let stateUpper = this.ImportCSVData[0][i].state;
                    let resultState = stateUpper.toUpperCase();
                    this.ImportCSVData[0][i].state = resultState;
                }
                if((this.ImportCSVData[0][i].plusFour.length == 4) || (this.ImportCSVData[0][i].plusFour == "")){
                    //break;
                }else{
                    this.ValidateImportErrors.push("Invalid Plus Four in Address " + (i+1));
                }
            }
            //If no errors found toggle import button and create import array
            if((this.ValidateImportErrors.length == 0) && (counter == this.ImportCSVData[0].length)){
                this.showImportButton = true;
                this.showValidateButton = false;
                this.CreateAddressBookImportArray();
                // this.toggleAlertBox = true;
                // this.alertMessage = "Address input structure validated."
                this.loadingData = false;
            }
            //If validation errors remove file from input
            if(this.ValidateImportErrors.length > 0){
                document.getElementById("csvFile").value = "";
                this.toggleAlertBox = true;
                this.alertMessage = "Error validating input file structure."
                this.loadingData = false;
            }
        },
        /**
         * CreateAddressBookImportArray() is called when there are no validation errors
         * For each imported address set the addressBookImport value and push to addressBookImportArray
         * Set addressBookImport relevance, latitude, and longitude to sort in GeoCodeAddressImport()
         */
        async CreateAddressBookImportArray(){
            document.querySelector(".print-button").disabled = true;
            this.loadingValidate = true;
            let counter = 0;
            for(let i = 0; i < this.ImportCSVData[0].length; i++){
                counter++
                let addressInput = this.ImportCSVData[0][i].address1 + ", " + this.ImportCSVData[0][i].city + ", " + this.ImportCSVData[0][i].state + ", " + this.ImportCSVData[0][i].zipCode;
                let geoCodeData = await this.getClientImport(addressInput);
                // console.log(geoCodeData)
                let addressBookImport = {
                    companyName: "",
                    attention: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    parc: "",
                    plusFour: "",
                    phone: "",
                    phoneExt: "",
                    fax: "",
                    email: "",
                    warning: "",
                    addressBookID: 0,
                    deliveryInstructions: "",
                    latitude: "",
                    longitude: "",
                    routeCode: "",
                    addressId: 0,
                    relevance: ""
                };

                //Passing the GeoCode Data and Relevance Objects for geocode function
                addressBookImport.relevance = geoCodeData;
                addressBookImport.latitude = geoCodeData;
                addressBookImport.longitude = geoCodeData;
                addressBookImport.companyName = this.ImportCSVData[0][i].companyName;
                addressBookImport.attention = this.ImportCSVData[0][i].contactName;
                addressBookImport.address1 = geoCodeData;
                addressBookImport.address2 = this.ImportCSVData[0][i].address2;
                addressBookImport.city = geoCodeData;
                addressBookImport.state = geoCodeData;
                addressBookImport.zipCode = geoCodeData;
                addressBookImport.plusFour = geoCodeData;
                addressBookImport.phone = this.ImportCSVData[0][i].phone;
                addressBookImport.phoneExt = this.ImportCSVData[0][i].phoneExt;
                addressBookImport.fax = this.ImportCSVData[0][i].fax;
                addressBookImport.email = this.ImportCSVData[0][i].email;
                addressBookImport.deliveryInstructions = this.ImportCSVData[0][i].deliveryInstructions;

                this.addressBookImportArray.push(addressBookImport);
            }

            if(counter == this.ImportCSVData[0].length){
                this.loadingValidate = false;
                this.toggleAlertBox = true;
                document.querySelector(".print-button").disabled = false;
                this.alertMessage = "Address input structure validated."
            }
        },
        /**
         * GeoCodeAddressImport() called when user clicks Import button
         * For each item in addressBookImportArray set the lat, long, and relevance returned from getClientImport()
         */
        async GeoCodeAddressImport(){
            let counter = 0;
            for(let i = 0; i < this.addressBookImportArray.length; i++){
                counter += 1;
                //If the relevance is less than .9 do not geocode, secondary geocode process will handle this
                if(this.addressBookImportArray[i].relevance.locationRelevance >= .9){
                    this.addressBookImportArray[i].latitude = JSON.stringify(this.addressBookImportArray[i].latitude.geoCodeDataReturn[0][1]);
                    this.addressBookImportArray[i].longitude = JSON.stringify(this.addressBookImportArray[i].longitude.geoCodeDataReturn[0][0]);
                }else{
                    this.errorGeoCodeAddressImportArray.push(this.addressBookImportArray[i])
                    this.addressBookImportArray[i].latitude = "";
                    this.addressBookImportArray[i].longitude = "";
                    this.toggleAlertBox = true;
                    this.alertMessage = "There may be an issue with this address: " + "<br/>" + this.ImportCSVData[0][i].Address1 + ", " + this.ImportCSVData[0][i].City + ", " + this.ImportCSVData[0][i].State + ", " + this.ImportCSVData[0][i].ZipCode;
                }
                
                this.addressBookImportArray[i].relevance = JSON.stringify(this.addressBookImportArray[i].relevance.locationRelevance);

                if((this.addressBookImportArray[i].address1.placeData.AddressNumber === undefined)){
                    this.addressBookImportArray[i].address1 = this.ImportCSVData[0][i].address1;
                }else{
                    this.addressBookImportArray[i].address1 = this.addressBookImportArray[i].address1.placeData.AddressNumber + " " + this.addressBookImportArray[i].address1.placeData.Street;
                }

                if((this.addressBookImportArray[i].city.placeData.Municipality === undefined)){
                    this.addressBookImportArray[i].city = this.ImportCSVData[0][i].city;
                }else{
                    this.addressBookImportArray[i].city = this.addressBookImportArray[i].city.placeData.Municipality;
                }

                if(this.addressBookImportArray[i].state.placeData.Region === undefined){
                    this.addressBookImportArray[i].state = this.ImportCSVData[0][i].state;
                }else{
                    switch(this.addressBookImportArray[i].state.placeData.Region){
                        case "Alabama":
                           this.addressBookImportArray[i].state = "AL";
                            break;
                        case "Alaska":
                           this.addressBookImportArray[i].state = "AK";
                            break;
                        case "Arizona":
                           this.addressBookImportArray[i].state = "AZ";
                            break;
                        case "Arkansas":
                           this.addressBookImportArray[i].state = "AR";
                            break;
                        case "California":
                           this.addressBookImportArray[i].state = "CA";
                            break;
                        case "Colorado":
                           this.addressBookImportArray[i].state = "CO";
                            break;
                        case "Florida":
                           this.addressBookImportArray[i].state = "FL";
                            break;
                        case "Georgia":
                           this.addressBookImportArray[i].state = "GA";
                            break;
                        case "Hawaii":
                           this.addressBookImportArray[i].state = "HI";
                            break;
                        case "Idaho":
                           this.addressBookImportArray[i].state = "ID";
                            break;
                        case "Illinois":
                           this.addressBookImportArray[i].state = "IL";
                            break;
                        case "Indiana":
                           this.addressBookImportArray[i].state = "IN";
                            break;
                        case "Iowa":
                           this.addressBookImportArray[i].state = "IA";
                            break;
                        case "Kansas":
                           this.addressBookImportArray[i].state = "KS";
                            break;
                        case "Kentucky":
                           this.addressBookImportArray[i].state = "KY";
                            break;
                        case "Louisiana":
                           this.addressBookImportArray[i].state = "LA";
                            break;
                        case "Michigan":
                           this.addressBookImportArray[i].state = "MI";
                            break;
                        case "Minnesota":
                           this.addressBookImportArray[i].state = "MN";
                            break;
                        case "Mississippi":
                           this.addressBookImportArray[i].state = "MS";
                            break;
                        case "Missouri":
                           this.addressBookImportArray[i].state = "MO";
                            break;
                        case "Montana":
                           this.addressBookImportArray[i].state = "MT";
                            break;
                        case "Nebraska":
                           this.addressBookImportArray[i].state = "NE";
                            break;
                        case "Nevada":
                           this.addressBookImportArray[i].state = "NV";
                            break;
                        case "New Mexico":
                           this.addressBookImportArray[i].state = "NM";
                            break;
                        case "North Carolina":
                           this.addressBookImportArray[i].state = "NC";
                            break;
                        case "North Dakota":
                           this.addressBookImportArray[i].state = "ND";
                            break;
                        case "Ohio":
                           this.addressBookImportArray[i].state = "OH";
                            break;
                        case "Oklahoma":
                           this.addressBookImportArray[i].state = "OK";
                            break;
                        case "Oregon":
                           this.addressBookImportArray[i].state = "OR";
                            break;
                        case "South Carolina":
                           this.addressBookImportArray[i].state = "SC";
                            break;
                        case "South Dakota":
                           this.addressBookImportArray[i].state = "SD";
                            break;
                        case "Tennessee":
                           this.addressBookImportArray[i].state = "TN";
                            break;
                        case "Texas":
                           this.addressBookImportArray[i].state = "TX";
                            break;
                        case "Utah":
                           this.addressBookImportArray[i].state = "UT";
                            break;
                        case "Vermont":
                           this.addressBookImportArray[i].state = "VT";
                            break;
                        case "Washington":
                           this.addressBookImportArray[i].state = "WA";
                            break;
                        case "West Virginia":
                           this.addressBookImportArray[i].state = "WV";
                            break;
                        case "Wisconsin":
                           this.addressBookImportArray[i].state = "WI";
                            break;
                        case "Wyoming":
                           this.addressBookImportArray[i].state = "WY";
                            break;
                        case "District of Columbia":
                           this.addressBookImportArray[i].state = "DC";
                            break;
                        case "Delaware":
                           this.addressBookImportArray[i].state = "DE";
                            break;
                        case "Massachusetts":
                           this.addressBookImportArray[i].state = "MA";
                            break;
                        case "Maryland":
                           this.addressBookImportArray[i].state = "MD";
                            break;
                        case "Maine":
                           this.addressBookImportArray[i].state = "ME";
                            break;
                        case "New Hampshire":
                           this.addressBookImportArray[i].state = "NH";
                            break;
                        case "New Jersey":
                           this.addressBookImportArray[i].state = "NJ";
                            break;
                        case "New York":
                           this.addressBookImportArray[i].state = "NY";
                            break;
                        case "Pennsylvania":
                           this.addressBookImportArray[i].state = "PA";
                            break;
                        case "Rhode Island":
                           this.addressBookImportArray[i].state = "RI";
                            break;
                        case "Virginia":
                           this.addressBookImportArray[i].state = "VA";
                            break;
                        default:
                            alert("Error with State Input")
                    }
                }

                if(this.addressBookImportArray[i].zipCode.placeData.PostalCode === undefined){
                    this.addressBookImportArray[i].zipCode = this.ImportCSVData[0][i].zipCode;
                    this.addressBookImportArray[i].plusFour = this.ImportCSVData[0][i].plusFour;
                }else{
                    this.addressBookImportArray[i].zipCode = this.addressBookImportArray[i].zipCode.placeData.PostalCode.substring(0, 5);
                    this.addressBookImportArray[i].plusFour = this.addressBookImportArray[i].plusFour.placeData.PostalCode.substring(6, 10);
                }
            }
            let returnArray = [];
            returnArray.push(this.addressBookImportArray);
            this.importArray = returnArray;
            if(counter == this.addressBookImportArray.length){
                this.geoCoded = true;
            }
        },
        CancelImport(){
            this.showImportAddress = false;
            this.showImportButton = false;
            this.showValidateButton = false;
            this.geoCoded = false;
            this.ImportCSVData = [];
            this.ValidateImportErrors = [];
            this.InsertAddressErrorArray = [];
            this.addressBookImportArray = [];
            this.importArray = [];
            document.getElementById("csvFile").value = "";
        },
        /**
         * Import Address and GeoCode with AWS Location
         * For each imported address call getClientImport() to set the relevance and geocode data
         */
        async getClientImport(addressInput){
            const credentials = await Auth.currentCredentials();

            let AWSLocationData = {
                geoCodeDataReturn: [],
                locationRelevance: "",
                placeData: {}
            }

            const locationClient = new Location({
                credentials,
                region: 'us-east-1'
            });

            const params = {
                IndexName: "lrex-place",
                Text: addressInput,
                FilterCountries: ["USA"],
                BiasPosition: [-74.1724, 40.7357],
                MaxResults: 5
            };

            locationClient.searchPlaceIndexForText(params,(err,data)=>{
                if(err){
                    alert(err)
                    }
                if(data){
                    let returnDataGeoCode = data.Results[0].Place.Geometry.Point;
                    let returnDataRelevance = data.Results[0].Relevance;
                    let returnPlaceData = data.Results[0].Place;
                    AWSLocationData.geoCodeDataReturn.push(returnDataGeoCode);
                    AWSLocationData.locationRelevance = returnDataRelevance;
                    AWSLocationData.placeData = returnPlaceData;
                }
            });

            return AWSLocationData;
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeImportAddress(){
            this.$emit('closeImportAddress', false)
        }
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.validation-errors h3{
    margin: 0;
}

.validation-errors p{
    margin: 0;
}

.validation-errors{
    max-height: 50px;
    overflow-y: auto;
    /* margin: 0; */
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 5px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
}

.address-book-import-error h3{
    margin: 0;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    /* max-height: 95vh; */
    max-width: 90%;
    overflow-x: hidden;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    /* box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px; */
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}


.inner-overflow-container{
    max-height: 90%;
    /* overflow-y: auto; */
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.header-container{
    background-color: #32ccfe;
    color: #fff;
    padding: 0px 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-container h3{
    padding: 0;
    margin: 0;
}

.header-container svg{
    height: 25px;
    width: auto;
    margin-right: 5px;
}

/* .package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
} */

.import-address-text-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    margin-top: 5px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.import-address-text-container-inner{
    width: 90%;
    height: fit-content;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    width: calc(100% - 20px);
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    width: calc(100% - 20px);
}

.table-header .col-1{
    margin-right: 5px;
}

.responsive-table{
    width: 100%;
    font-size: 10px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    /* white-space: nowrap; */
}

.col-2{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 10%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 13.3%;
    text-align: left;
    margin-left: 5px;
}

.col-5{
    flex-basis: 13.3%;
    text-align: left;
    margin-left: 5px;
}

.col-6{
    flex-basis: 13.3%;
    text-align: left;
    margin-left: 5px;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    /* font-size: 12px; */
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 20px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.create-button{
    background-color: #33f18a;
    margin-left: 20px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        max-width: 99%;
    }

    .package-details-header{
        font-size: 1.6em;
    }

    .inner-overflow-container{
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 360px){
    .responsive-table{
        font-size: 8px;
    }
}
</style>
<template>
  <div class="table-container-main">
    <div v-if="addressIDArray.length > 0" class="button-container">
        <div class="void-selected">
            <p class="void-selected-text" @click="deleteAddressBookFunc()">Delete Address</p>
        </div>
    </div>
    <table-filter 
    :filterByService="true" 
    :filterByReference="true" 
    :filterByCompanyName="true" 
    :filterByAddress="true" 
    :filterByCity="true"
    :filterByState="true"
    :filterByZipCode="true"
    @filterValues="filterValues($event)"/>
    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">Company Name</div>
            <div class="col-2">Location</div>
            <div class="col-3">Attention</div>
            <div class="col-4"></div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div class="table-row-container">
            <div class="table-row" v-for="(address, index) in addressBookData" :key="index">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text" @click="getAddressBookClick(address.id)">{{address.companyName}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{address.companyName}}</span> {{address.address1}}, {{address.city}}, {{address.state}}, {{address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{address.attention}}</p></div>
                <div class="col-4"><input type="checkbox" name="" id="" :value="address.id" v-model="addressIDArray"></div>
            </div>
            <div v-if="addressBookData.length == 0" class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Addresses</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
            </div>
        </div>
    </div>
  </div>
  <div class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
<address-book-info v-if="toggleAddressBookInfo" @closeAddressInfo="closeAddressInfo($event)" :id="addressID"/>
<alert-user  v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import AddressBookInfo from '../../ShipmentDashboard/AddressBook/AddressBookInfo.vue'
import jsonexport from 'jsonexport'
import {deleteAddressBook} from '../../../graphql/mutations'
import TableFilter from '../TableFilter.vue'

export default {
    components:{
        AlertUser,
        LoadingCharts,
        AddressBookInfo,
        TableFilter
    },
    props: ['userData', 'jwtToken'],
    watch:{
        'userData':function(){
            if(this.userData)
            {
                this.getAddressBookByUserName();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user data to generate table."
            }
        }
    },
    data(){
        return{
            toggleAddressBookInfo: false,
            user: null,
            addressBookData: [],
            addressIDArray: [],
            addressID: null,
            loadingTable: false,
            toggleAlertBox: '',
            alertMessage: '',
            items: `items{
                id
                address1
                address2
                city
                state
                zipCode
                companyName
                attention
                email
                phone
                deliveryInstructions
                latitude
                longitude
                relevance
            }`,
            filterField: null,
            filterValue: null
        }
    },
    methods:{
        async getAddressBookClick(id){
            this.addressID = id;
            this.toggleAddressBookInfo = true;
        },
        closeAddressInfo(event){
            this.toggleAddressBookInfo = event;
            this.getAddressBookByUserName();
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        filterValues(event){
            this.isFiltered = true;
            this.filterField = event.filterField;
            this.filterValue = event.filterValue;
            switch (event.filterField) {
                case "companyName":
                case "address1":
                case "city":
                case "state":
                case "zipCode":
                    this.filterAddressBookTable();
                    break;
                case "ref1":
                case "service":
                    this.toggleAlertBox = true;
                    this.alertMessage = "Unable to filter by " + this.filterField
                    this.getAddressBookByUserName();
                    break;
                default:
                    this.toggleAlertBox = true;
                    this.alertMessage = "Unable to filter address book"
                    this.getAddressBookByUserName();
                    break;
            }
        },
        async filterAddressBookTable(){
            this.loadingTable = true;

            let graphqlQuery = `query getAddressBook{
                addressBookByUserName(userName: "${this.userData.userName}", filter: {${this.filterField}:{eq: "${this.filterValue}"}}){
                    ${this.items}
                }
            }`
            
            try{
                let addressBookByUserName = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.addressBookData = addressBookByUserName.data.addressBookByUserName.items;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting addresses"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async getAddressBookByUserName(){
            this.loadingTable = true;

            let graphqlQuery = `query getAddressBook{
                addressBookByUserName(userName: "${this.userData.userName}"){
                    ${this.items}
                }
            }`
            
            try{
                let addressBookByUserName = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.addressBookData = addressBookByUserName.data.addressBookByUserName.items;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting addresses"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async deleteAddressBookFunc(){
            let counter = 0;
            for (let i = 0; i < this.addressIDArray.length; i++) {
                counter++;
                this.loadingTable = true;
                const element = this.addressIDArray[i];
                try{
                    let deleteAddressBookItem = await API.graphql({
                    query:  deleteAddressBook,
                    variables:{
                        input:{
                            id: element
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    
                    console.log(deleteAddressBookItem)
                }catch(e){
                    console.log(e)
                    if(e){
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error deleting addresses"
                    }
                }finally{
                    this.loadingTable = false;
                    this.isFiltered = false;
                }
            }
            if(this.addressIDArray.length == counter){
                this.getAddressBookByUserName();
                this.addressIDArray = []
            }
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;
            csvArray = this.addressBookData.map(obj =>{
                return {
                    CompanyName: obj.companyName,
                    Attention: obj.attention,
                    Address1: obj.address1,
                    City: obj.city,
                    State: obj.state,
                    ZipCode: obj.zipCode,
                    Phone: obj.phone,
                    Email: obj.email,
                    DeliveryInstructions: obj.deliveryInstructions
                }
            })
            jsonexport(csvArray, (error, csv) => {
                if (error) {
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error downloading address data"
                }
                const blob = new Blob([csv], { type: 'text/csv' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = 'AddressBook.csv';
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
                anchor.remove();
                this.loadingTable = false;
            })
        }
    }
}
</script>

<style scoped>
h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

.responsive-table{
    width: 100%;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    /* white-space: nowrap; */
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text{
        font-size: 8px;
    }

    .shipmentid-text{
        font-size: 10px;
    }
}

@media only screen and (max-width: 740px) {
   .col-1{
        min-width: 30%;
    }
}
</style>
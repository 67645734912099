<template>
  <loading-charts v-if="loadingData" class="loading-charts"/>
  <table-filter 
  :filterByShipmentID="true"
  @filterValues="filterValues($event)"/>
  <div class="table-container-main">
    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">Shipment ID</div>
            <div class="col-2">Activity Time</div>
            <div class="col-3">Status ID</div>
            <div class="col-4"></div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div class="table-row-container">
            <div class="table-row" v-for="(shipment, index) in queuedShipmentActivity" :key="index">
                <div @click="trackShipment(shipment.shipmentID)" class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{shipment.shipmentID}}</p></div>
                <div class="col-2 col-data">{{shipment.activityTime}}</div>
                <div class="col-3 col-data"><p class="service-text">{{shipment.statusID}}</p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="(queuedShipmentActivity.length == 0)" class="table-row-container">
            <div class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Shipments</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
    </div>
  </div>
  <div class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
  

<queued-activity-shipment-info v-if="toggleTrackShipment" @closeShipmentInfo="closeShipmentInfo($event)" :trackShipmentID="trackShipmentID" :cognitoUser="cognitoUser" :jwtToken="jwtToken" :userData="userData"/>
<alert-user v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import QueuedActivityShipmentInfo from './QueuedActivityShipmentInfo.vue'
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import TableFilter from './../TableFilter.vue'
import AlertUser from '../../Popups/AlertUser.vue';
import jsonexport from 'jsonexport'

export default {
    components:{
        QueuedActivityShipmentInfo,
        LoadingCharts,
        TableFilter,
        AlertUser
    },
    props: ['selectedShipment', 'selectedDates', 'userData', 'jwtToken', 'cognitoUser', 'selectedAccountNo'],
    mounted(){
        this.listQueuedActivities()
    },
    data(){
        return{
            queuedShipmentActivity: [],
            inTransitShipments: [],
            deliveredShipments: [],
            CurrentKey: null,
            trackShipmentID: null,
            toggleTrackShipment: false,
            user: null,
            fromDate: null,
            toDate: null,
            sliderValue: null,
            selectedShipmentArray: [],
            labelData: null,
            loadingData: false,
            loadingTable: false,
            isFiltered: false,
            filterField: '',
            filterValue: '',
            toggleAlertBox: '',
            alertMessage: '',
            previousMonth: '',
            currentMonth: '',
            items: `items{
                    shipmentID
                    service
                    username
                    accountNo
                    ref1
                    description
                    deliveryInstructions
                    serviceAddress{
                        address{
                        companyName
                        address1
                        city
                        state
                        zipCode
                        }
                    }
                    deliveryPickup{
                        address{
                        companyName
                        address1
                        city
                        state
                        zipCode
                        }
                    }
                    notify{
                        delivery{
                        phone
                        email
                        }
                        nonDelivery{
                        phone
                        email
                        }
                    }
                    additionalServices
                }`
        }
    },
    methods:{
        filterValues(event){
            this.isFiltered = true;
            this.filterField = event.filterField;
            this.filterValue = event.filterValue;
            switch (event.filterField) {
                case "shipmentID":
                    this.filterQueuedActivities();
                    this.isFiltered = false;
                    break;
                default:
                    this.listQueuedActivities();
                    this.isFiltered = false;
                    break;
            }
        },
        async filterQueuedActivities(){
            this.loadingTable = true;
            this.queuedShipmentActivity = [];
            
            try{
                let filterQueuedActivitiesItems = await API.graphql({
                query:  `query queuedActivityByShipmentID {
                    queuedActivityByShipmentID(shipmentID: "${this.filterValue}") {
                        items{
                            id
                            shipmentID
                            code
                            description
                            statusID
                            shipmentStage
                            shipmentPoint
                            shippingEvent
                            activityTime
                            recordedTime
                            comments
                            internalComments
                            notes
                            latitude
                            longitude
                            driverCode
                            manifestSource
                            imageKeyID
                            imageKey
                            ignoreActivity
                        }
                    }
                }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.queuedShipmentActivity = filterQueuedActivitiesItems.data.queuedActivityByShipmentID.items;
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting queued activities"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async listQueuedActivities(){
            this.loadingTable = true;
            this.queuedShipmentActivity = [];
            
            try{
                let listQueuedActivitiesItems = await API.graphql({
                query:  `query listQueuedActivities{
                            listQueuedActivities{
                                items{
                                    id
                                    shipmentID
                                    code
                                    description
                                    statusID
                                    shipmentStage
                                    shipmentPoint
                                    shippingEvent
                                    activityTime
                                    recordedTime
                                    comments
                                    internalComments
                                    notes
                                    
                                    latitude
                                    longitude
                                    driverCode
                                    manifestSource
                                    imageKeyID
                                    imageKey
                                    ignoreActivity
                                }
                            }
                        }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.queuedShipmentActivity = listQueuedActivitiesItems.data.listQueuedActivities.items;
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting queued activities"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        trackShipment(shipmentID){
            this.toggleTrackShipment = true;
            this.trackShipmentID = shipmentID;
        },
        closeShipmentInfo(event){
            this.toggleTrackShipment = event;
            this.listQueuedActivities();
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;

            csvArray = this.queuedShipmentActivity.map(obj =>{
                return {
                    shipmentID: obj.shipmentID,
                    code: obj.code,
                    description: obj.description,
                    statusID: obj.statusID,
                    shipmentStage: obj.shipmentStage,
                    shipmentPoint: obj.shipmentPoint,
                    shippingEvent: obj.shippingEvent,
                    activityTime: obj.activityTime,
                    recordedTime: obj.recordedTime,
                    comments: obj.comments,
                    internalComments: obj.internalComments,
                    notes: obj.notes,
                    latitude: obj.latitude,
                    longitude: obj.longitude,
                    driverCode: obj.driverCode,
                    manifestSource: obj.manifestSource,
                    imageKeyID: obj.imageKeyID,
                    imageKey: obj.imageKey,
                    ignoreActivity: obj.ignoreActivity
                }
            })
            jsonexport(csvArray, (error, csv) => {
                if (error) {
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error downloading current shipments"
                }
                const blob = new Blob([csv], { type: 'text/csv' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = 'CurrentShipments.csv';
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
                anchor.remove();
                this.loadingTable = false;
            })
        }
    }
}
</script>

<style scoped>
h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

.responsive-table{
    width: 100%;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    white-space: nowrap;
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text{
        font-size: 8px;
    }
}

@media only screen and (max-width: 1135px) {
   .col-1{
        min-width: 30%;
    }
}
</style>
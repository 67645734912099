/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShipmentLREX = /* GraphQL */ `
  mutation CreateShipmentLREX($input: ShipmentInputLREX!) {
    createShipmentLREX(input: $input) {
      username
      shipmentID
      labelImage
      __typename
    }
  }
`;
export const createShipmentLambda = /* GraphQL */ `
  mutation CreateShipmentLambda($input: ShipmentInputLambda!) {
    createShipmentLambda(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const voidShipmentLambda = /* GraphQL */ `
  mutation VoidShipmentLambda($input: voidShipmentInputLambda!) {
    voidShipmentLambda(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const getShipmentHistoryLambda = /* GraphQL */ `
  mutation GetShipmentHistoryLambda($input: HistoryInputLambda!) {
    getShipmentHistoryLambda(input: $input) {
      shipmentHistory {
        shipmentid
        description
        shipmentStatusId
        processedDate
        processed
        notes
        createdBy
        latitude
        longitude
        signatureId
        imageURL
        __typename
      }
      __typename
    }
  }
`;
export const getAccessTokenLambda = /* GraphQL */ `
  mutation GetAccessTokenLambda($input: AccessTokenInputLambda!) {
    getAccessTokenLambda(input: $input) {
      accessToken
      __typename
    }
  }
`;
export const createShipmentLabelLambda = /* GraphQL */ `
  mutation CreateShipmentLabelLambda($input: jsreportLabelInput!) {
    createShipmentLabelLambda(input: $input) {
      labelImage
      __typename
    }
  }
`;
export const filterCurrentShipments = /* GraphQL */ `
  mutation FilterCurrentShipments($input: filterShipmentInputLambda) {
    filterCurrentShipments(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const filterInTransitShipments = /* GraphQL */ `
  mutation FilterInTransitShipments(
    $input: filterInTransitShipmentInputLambda
  ) {
    filterInTransitShipments(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const filterDeliveredShipments = /* GraphQL */ `
  mutation FilterDeliveredShipments(
    $input: filterDeliveredShipmentInputLambda
  ) {
    filterDeliveredShipments(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const updateShipmentActivity = /* GraphQL */ `
  mutation UpdateShipmentActivity($input: updateShipmentActivityInputLambda) {
    updateShipmentActivity(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const updateShipmentData = /* GraphQL */ `
  mutation UpdateShipmentData($input: updateShipmentDataInput) {
    updateShipmentData(input: $input) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($input: addUserToGroupInput!) {
    addUserToGroup(input: $input) {
      username
      groupname
      __typename
    }
  }
`;
export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($input: removeUserFromGroupInput!) {
    removeUserFromGroup(input: $input) {
      username
      groupname
      __typename
    }
  }
`;
export const getUsersGroupData = /* GraphQL */ `
  mutation GetUsersGroupData($input: getUsersGroupDataInput!) {
    getUsersGroupData(input: $input) {
      Groups {
        GroupName
        Description
        __typename
      }
      __typename
    }
  }
`;
export const listAllUserGroups = /* GraphQL */ `
  mutation ListAllUserGroups($input: getUsersGroupDataInput!) {
    listAllUserGroups(input: $input) {
      Groups {
        GroupName
        Description
        __typename
      }
      __typename
    }
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser($input: createCognitoUserInput!) {
    createCognitoUser(input: $input) {
      username
      groupname
      __typename
    }
  }
`;
export const getShipmentDataREST = /* GraphQL */ `
  mutation GetShipmentDataREST($input: getShipmentDataRESTInput!) {
    getShipmentDataREST(input: $input) {
      shipmentID
      pickupCompanyName
      pickupAttention
      pickupAddress1
      pickupAddress2
      pickupCity
      pickupState
      pickupZipCode
      deliveryCompanyName
      deliveryAttention
      deliveryAddress1
      deliveryAddress2
      deliveryCity
      deliveryState
      deliveryZipCode
      deliveryPhone
      shipmentWeight
      deliveryInstructions
      numOfPackages
      deliveryNotifyemail
      description
      __typename
    }
  }
`;
export const createParcelP2P = /* GraphQL */ `
  mutation CreateParcelP2P($input: createParcelP2PInput!) {
    createParcelP2P(input: $input) {
      status
      UniqueParcelID
      ParcelID
      errors
      __typename
    }
  }
`;
export const createLabelP2P = /* GraphQL */ `
  mutation CreateLabelP2P($input: createLabelP2PInput!) {
    createLabelP2P(input: $input) {
      status
      UniqueParcelID
      TrackingNumber
      CarrierName
      LabelType
      Label
      Invoice
      errors
      __typename
    }
  }
`;
export const trackParcelP2P = /* GraphQL */ `
  mutation TrackParcelP2P($input: trackParcelP2PInput!) {
    trackParcelP2P(input: $input) {
      TrackParcel {
        trackingEventDate
        trackingEventName
        trackingEventExtra
        trackingEventCode
        trackingEventLocation
        __typename
      }
      __typename
    }
  }
`;
export const queryManifestShipment = /* GraphQL */ `
  mutation QueryManifestShipment($input: queryManifestShipmentInput!) {
    queryManifestShipment(input: $input) {
      shipmentID
      address {
        address1
        address2
        attention
        city
        companyName
        phone
        latitude
        longitude
        relevance
        routeCode
        state
        zipCode
        __typename
      }
      isDelivery
      isPriority
      latitude
      longitude
      route
      status
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const queryManifestWaypoint = /* GraphQL */ `
  mutation QueryManifestWaypoint($input: queryManifestWaypointInput!) {
    queryManifestWaypoint(input: $input) {
      routeCode
      waypoint
      arrival
      assignmentRule
      deliveries
      departure
      fromPreviousDistance
      fromPreviousTravelTime
      id
      isDelivery
      isDropBox
      latitude
      locations
      longitude
      pickups
      radius
      route
      sequence
      serviceTime
      shipments {
        shipmentID
        __typename
      }
      stop
      stopType
      violationTime
      waitTime
      windowEnd
      windowStart
      created
      distanceCost
      endTime
      error
      geometry
      jobID
      orderCount
      overTimeCost
      processed
      regularTimeCost
      routeEnd
      routeStart
      startTime
      status
      totalBreakServiceTime
      totalCost
      totalDistance
      totalOrderServiceTime
      totalTime
      totalTravelTime
      totalViolationTime
      totalWaitTime
      violatedConstraints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      accountNo
      accountType
      contact1
      contact2
      contact3
      defaultWeight
      balance
      creditLimit
      billable
      contractedLocations
      specialPricing
      billRunDate
      billFrequency
      industry
      product {
        productCode
        serviceCode
        description
        additionalService
        serviceNetwork
        vendor
        price
        productName
        restServiceCode
        descriptionUI
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        productFamily
        serviceGroup
        serviceGroupSequence
        productSequence
        __typename
      }
      billingAddress {
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        phone
        phoneExt
        email
        relevance
        latitude
        longitude
        routeCode
        __typename
      }
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      accountNo
      accountType
      contact1
      contact2
      contact3
      defaultWeight
      balance
      creditLimit
      billable
      contractedLocations
      specialPricing
      billRunDate
      billFrequency
      industry
      product {
        productCode
        serviceCode
        description
        additionalService
        serviceNetwork
        vendor
        price
        productName
        restServiceCode
        descriptionUI
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        productFamily
        serviceGroup
        serviceGroupSequence
        productSequence
        __typename
      }
      billingAddress {
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        phone
        phoneExt
        email
        relevance
        latitude
        longitude
        routeCode
        __typename
      }
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      accountNo
      accountType
      contact1
      contact2
      contact3
      defaultWeight
      balance
      creditLimit
      billable
      contractedLocations
      specialPricing
      billRunDate
      billFrequency
      industry
      product {
        productCode
        serviceCode
        description
        additionalService
        serviceNetwork
        vendor
        price
        productName
        restServiceCode
        descriptionUI
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        productFamily
        serviceGroup
        serviceGroupSequence
        productSequence
        __typename
      }
      billingAddress {
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        phone
        phoneExt
        email
        relevance
        latitude
        longitude
        routeCode
        __typename
      }
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddressBook = /* GraphQL */ `
  mutation CreateAddressBook(
    $input: CreateAddressBookInput!
    $condition: ModelAddressBookConditionInput
  ) {
    createAddressBook(input: $input, condition: $condition) {
      id
      userName
      accountNo
      companyName
      attention
      address1
      address2
      city
      state
      zipCode
      routeCode
      plusFour
      phone
      phoneExt
      email
      latitude
      longitude
      deliveryInstructions
      relevance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAddressBook = /* GraphQL */ `
  mutation UpdateAddressBook(
    $input: UpdateAddressBookInput!
    $condition: ModelAddressBookConditionInput
  ) {
    updateAddressBook(input: $input, condition: $condition) {
      id
      userName
      accountNo
      companyName
      attention
      address1
      address2
      city
      state
      zipCode
      routeCode
      plusFour
      phone
      phoneExt
      email
      latitude
      longitude
      deliveryInstructions
      relevance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAddressBook = /* GraphQL */ `
  mutation DeleteAddressBook(
    $input: DeleteAddressBookInput!
    $condition: ModelAddressBookConditionInput
  ) {
    deleteAddressBook(input: $input, condition: $condition) {
      id
      userName
      accountNo
      companyName
      attention
      address1
      address2
      city
      state
      zipCode
      routeCode
      plusFour
      phone
      phoneExt
      email
      latitude
      longitude
      deliveryInstructions
      relevance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      accountNo
      serviceAddress {
        type
        location
        __typename
      }
      labelImage
      isContractedPickup
      isCustomerPickup
      isContractedDelivery
      isUser
      contractedLocation {
        pickupWindowEnd
        pickupWindowStart
        username
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        __typename
      }
      placeID
      place {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      placePickupTime
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      accountNo
      serviceAddress {
        type
        location
        __typename
      }
      labelImage
      isContractedPickup
      isCustomerPickup
      isContractedDelivery
      isUser
      contractedLocation {
        pickupWindowEnd
        pickupWindowStart
        username
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        __typename
      }
      placeID
      place {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      placePickupTime
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      accountNo
      serviceAddress {
        type
        location
        __typename
      }
      labelImage
      isContractedPickup
      isCustomerPickup
      isContractedDelivery
      isUser
      contractedLocation {
        pickupWindowEnd
        pickupWindowStart
        username
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        __typename
      }
      placeID
      place {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      placePickupTime
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPlace = /* GraphQL */ `
  mutation CreatePlace(
    $input: CreatePlaceInput!
    $condition: ModelPlaceConditionInput
  ) {
    createPlace(input: $input, condition: $condition) {
      placeCode
      address1
      address2
      city
      state
      zipCode
      relevance
      latitude
      longitude
      pickupRoute
      radius
      pickupWindowEnd
      pickupWindowStart
      serviceTime
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePlace = /* GraphQL */ `
  mutation UpdatePlace(
    $input: UpdatePlaceInput!
    $condition: ModelPlaceConditionInput
  ) {
    updatePlace(input: $input, condition: $condition) {
      placeCode
      address1
      address2
      city
      state
      zipCode
      relevance
      latitude
      longitude
      pickupRoute
      radius
      pickupWindowEnd
      pickupWindowStart
      serviceTime
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePlace = /* GraphQL */ `
  mutation DeletePlace(
    $input: DeletePlaceInput!
    $condition: ModelPlaceConditionInput
  ) {
    deletePlace(input: $input, condition: $condition) {
      placeCode
      address1
      address2
      city
      state
      zipCode
      relevance
      latitude
      longitude
      pickupRoute
      radius
      pickupWindowEnd
      pickupWindowStart
      serviceTime
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      productCode
      serviceCode
      description
      additionalService
      serviceNetwork
      vendor
      price
      productName
      restServiceCode
      descriptionUI
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      productFamily
      serviceGroup
      serviceGroupSequence
      productSequence
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      productCode
      serviceCode
      description
      additionalService
      serviceNetwork
      vendor
      price
      productName
      restServiceCode
      descriptionUI
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      productFamily
      serviceGroup
      serviceGroupSequence
      productSequence
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      productCode
      serviceCode
      description
      additionalService
      serviceNetwork
      vendor
      price
      productName
      restServiceCode
      descriptionUI
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      productFamily
      serviceGroup
      serviceGroupSequence
      productSequence
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProductCode = /* GraphQL */ `
  mutation CreateProductCode(
    $input: CreateProductCodeInput!
    $condition: ModelProductCodeConditionInput
  ) {
    createProductCode(input: $input, condition: $condition) {
      productCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProductCode = /* GraphQL */ `
  mutation UpdateProductCode(
    $input: UpdateProductCodeInput!
    $condition: ModelProductCodeConditionInput
  ) {
    updateProductCode(input: $input, condition: $condition) {
      productCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProductCode = /* GraphQL */ `
  mutation DeleteProductCode(
    $input: DeleteProductCodeInput!
    $condition: ModelProductCodeConditionInput
  ) {
    deleteProductCode(input: $input, condition: $condition) {
      productCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createServiceCode = /* GraphQL */ `
  mutation CreateServiceCode(
    $input: CreateServiceCodeInput!
    $condition: ModelServiceCodeConditionInput
  ) {
    createServiceCode(input: $input, condition: $condition) {
      serviceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateServiceCode = /* GraphQL */ `
  mutation UpdateServiceCode(
    $input: UpdateServiceCodeInput!
    $condition: ModelServiceCodeConditionInput
  ) {
    updateServiceCode(input: $input, condition: $condition) {
      serviceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteServiceCode = /* GraphQL */ `
  mutation DeleteServiceCode(
    $input: DeleteServiceCodeInput!
    $condition: ModelServiceCodeConditionInput
  ) {
    deleteServiceCode(input: $input, condition: $condition) {
      serviceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createServiceNetwork = /* GraphQL */ `
  mutation CreateServiceNetwork(
    $input: CreateServiceNetworkInput!
    $condition: ModelServiceNetworkConditionInput
  ) {
    createServiceNetwork(input: $input, condition: $condition) {
      serviceNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateServiceNetwork = /* GraphQL */ `
  mutation UpdateServiceNetwork(
    $input: UpdateServiceNetworkInput!
    $condition: ModelServiceNetworkConditionInput
  ) {
    updateServiceNetwork(input: $input, condition: $condition) {
      serviceNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteServiceNetwork = /* GraphQL */ `
  mutation DeleteServiceNetwork(
    $input: DeleteServiceNetworkInput!
    $condition: ModelServiceNetworkConditionInput
  ) {
    deleteServiceNetwork(input: $input, condition: $condition) {
      serviceNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      vendor
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      vendor
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      vendor
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRestServiceCode = /* GraphQL */ `
  mutation CreateRestServiceCode(
    $input: CreateRestServiceCodeInput!
    $condition: ModelRestServiceCodeConditionInput
  ) {
    createRestServiceCode(input: $input, condition: $condition) {
      restServiceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRestServiceCode = /* GraphQL */ `
  mutation UpdateRestServiceCode(
    $input: UpdateRestServiceCodeInput!
    $condition: ModelRestServiceCodeConditionInput
  ) {
    updateRestServiceCode(input: $input, condition: $condition) {
      restServiceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRestServiceCode = /* GraphQL */ `
  mutation DeleteRestServiceCode(
    $input: DeleteRestServiceCodeInput!
    $condition: ModelRestServiceCodeConditionInput
  ) {
    deleteRestServiceCode(input: $input, condition: $condition) {
      restServiceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProductFamily = /* GraphQL */ `
  mutation CreateProductFamily(
    $input: CreateProductFamilyInput!
    $condition: ModelProductFamilyConditionInput
  ) {
    createProductFamily(input: $input, condition: $condition) {
      productFamily
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProductFamily = /* GraphQL */ `
  mutation UpdateProductFamily(
    $input: UpdateProductFamilyInput!
    $condition: ModelProductFamilyConditionInput
  ) {
    updateProductFamily(input: $input, condition: $condition) {
      productFamily
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProductFamily = /* GraphQL */ `
  mutation DeleteProductFamily(
    $input: DeleteProductFamilyInput!
    $condition: ModelProductFamilyConditionInput
  ) {
    deleteProductFamily(input: $input, condition: $condition) {
      productFamily
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createServiceGroup = /* GraphQL */ `
  mutation CreateServiceGroup(
    $input: CreateServiceGroupInput!
    $condition: ModelServiceGroupConditionInput
  ) {
    createServiceGroup(input: $input, condition: $condition) {
      serviceGroup
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateServiceGroup = /* GraphQL */ `
  mutation UpdateServiceGroup(
    $input: UpdateServiceGroupInput!
    $condition: ModelServiceGroupConditionInput
  ) {
    updateServiceGroup(input: $input, condition: $condition) {
      serviceGroup
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteServiceGroup = /* GraphQL */ `
  mutation DeleteServiceGroup(
    $input: DeleteServiceGroupInput!
    $condition: ModelServiceGroupConditionInput
  ) {
    deleteServiceGroup(input: $input, condition: $condition) {
      serviceGroup
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRoute = /* GraphQL */ `
  mutation CreateRoute(
    $input: CreateRouteInput!
    $condition: ModelRouteConditionInput
  ) {
    createRoute(input: $input, condition: $condition) {
      routeCode
      serviceNetwork
      breakDuration
      breakWindowEnd
      breakWindowStart
      costPerUnitDistance
      costPerUnitTime
      earliestStartTime
      endDepot
      latestStartTime
      maxTotalTime
      startDepot
      products {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRoute = /* GraphQL */ `
  mutation UpdateRoute(
    $input: UpdateRouteInput!
    $condition: ModelRouteConditionInput
  ) {
    updateRoute(input: $input, condition: $condition) {
      routeCode
      serviceNetwork
      breakDuration
      breakWindowEnd
      breakWindowStart
      costPerUnitDistance
      costPerUnitTime
      earliestStartTime
      endDepot
      latestStartTime
      maxTotalTime
      startDepot
      products {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRoute = /* GraphQL */ `
  mutation DeleteRoute(
    $input: DeleteRouteInput!
    $condition: ModelRouteConditionInput
  ) {
    deleteRoute(input: $input, condition: $condition) {
      routeCode
      serviceNetwork
      breakDuration
      breakWindowEnd
      breakWindowStart
      costPerUnitDistance
      costPerUnitTime
      earliestStartTime
      endDepot
      latestStartTime
      maxTotalTime
      startDepot
      products {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGeoCodes = /* GraphQL */ `
  mutation CreateGeoCodes(
    $input: CreateGeoCodesInput!
    $condition: ModelGeoCodesConditionInput
  ) {
    createGeoCodes(input: $input, condition: $condition) {
      geoCode
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGeoCodes = /* GraphQL */ `
  mutation UpdateGeoCodes(
    $input: UpdateGeoCodesInput!
    $condition: ModelGeoCodesConditionInput
  ) {
    updateGeoCodes(input: $input, condition: $condition) {
      geoCode
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGeoCodes = /* GraphQL */ `
  mutation DeleteGeoCodes(
    $input: DeleteGeoCodesInput!
    $condition: ModelGeoCodesConditionInput
  ) {
    deleteGeoCodes(input: $input, condition: $condition) {
      geoCode
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDropBox = /* GraphQL */ `
  mutation CreateDropBox(
    $input: CreateDropBoxInput!
    $condition: ModelDropBoxConditionInput
  ) {
    createDropBox(input: $input, condition: $condition) {
      dropBoxCode
      isActive
      serviceAddress {
        type
        location
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDropBox = /* GraphQL */ `
  mutation UpdateDropBox(
    $input: UpdateDropBoxInput!
    $condition: ModelDropBoxConditionInput
  ) {
    updateDropBox(input: $input, condition: $condition) {
      dropBoxCode
      isActive
      serviceAddress {
        type
        location
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDropBox = /* GraphQL */ `
  mutation DeleteDropBox(
    $input: DeleteDropBoxInput!
    $condition: ModelDropBoxConditionInput
  ) {
    deleteDropBox(input: $input, condition: $condition) {
      dropBoxCode
      isActive
      serviceAddress {
        type
        location
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      vehicleCode
      isActive
      plateNumber
      vehicleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      vehicleCode
      isActive
      plateNumber
      vehicleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      vehicleCode
      isActive
      plateNumber
      vehicleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTripSheet = /* GraphQL */ `
  mutation UpdateTripSheet(
    $input: UpdateTripSheetInput!
    $condition: ModelTripSheetConditionInput
  ) {
    updateTripSheet(input: $input, condition: $condition) {
      TripSheetId
      DriverId
      RouteId
      VehicleId
      TripDate
      SignOffDate
      StartingMileage
      SignOffId
      EndingMileage
      ManifestSource
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTripSheet = /* GraphQL */ `
  mutation DeleteTripSheet(
    $input: DeleteTripSheetInput!
    $condition: ModelTripSheetConditionInput
  ) {
    deleteTripSheet(input: $input, condition: $condition) {
      TripSheetId
      DriverId
      RouteId
      VehicleId
      TripDate
      SignOffDate
      StartingMileage
      SignOffId
      EndingMileage
      ManifestSource
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShipment = /* GraphQL */ `
  mutation CreateShipment(
    $input: CreateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    createShipment(input: $input, condition: $condition) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const updateShipment = /* GraphQL */ `
  mutation UpdateShipment(
    $input: UpdateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    updateShipment(input: $input, condition: $condition) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const deleteShipment = /* GraphQL */ `
  mutation DeleteShipment(
    $input: DeleteShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    deleteShipment(input: $input, condition: $condition) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userName
      owner
      accountNo
      fullname
      userEmail
      createdBy
      createdDate
      updatedBy
      updatedDate
      location
      labelImage
      webKey
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userName
      owner
      accountNo
      fullname
      userEmail
      createdBy
      createdDate
      updatedBy
      updatedDate
      location
      labelImage
      webKey
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userName
      owner
      accountNo
      fullname
      userEmail
      createdBy
      createdDate
      updatedBy
      updatedDate
      location
      labelImage
      webKey
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTripSheet = /* GraphQL */ `
  mutation CreateTripSheet(
    $input: CreateTripSheetInput!
    $condition: ModelTripSheetConditionInput
  ) {
    createTripSheet(input: $input, condition: $condition) {
      TripSheetId
      DriverId
      RouteId
      VehicleId
      TripDate
      SignOffDate
      StartingMileage
      SignOffId
      EndingMileage
      ManifestSource
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQueuedActivities = /* GraphQL */ `
  mutation CreateQueuedActivities(
    $input: CreateQueuedActivitiesInput!
    $condition: ModelQueuedActivitiesConditionInput
  ) {
    createQueuedActivities(input: $input, condition: $condition) {
      id
      shipmentID
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      ignoreActivity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQueuedActivities = /* GraphQL */ `
  mutation UpdateQueuedActivities(
    $input: UpdateQueuedActivitiesInput!
    $condition: ModelQueuedActivitiesConditionInput
  ) {
    updateQueuedActivities(input: $input, condition: $condition) {
      id
      shipmentID
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      ignoreActivity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQueuedActivities = /* GraphQL */ `
  mutation DeleteQueuedActivities(
    $input: DeleteQueuedActivitiesInput!
    $condition: ModelQueuedActivitiesConditionInput
  ) {
    deleteQueuedActivities(input: $input, condition: $condition) {
      id
      shipmentID
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      ignoreActivity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDepot = /* GraphQL */ `
  mutation CreateDepot(
    $input: CreateDepotInput!
    $condition: ModelDepotConditionInput
  ) {
    createDepot(input: $input, condition: $condition) {
      name
      depotCode
      latitude
      longitude
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDepot = /* GraphQL */ `
  mutation UpdateDepot(
    $input: UpdateDepotInput!
    $condition: ModelDepotConditionInput
  ) {
    updateDepot(input: $input, condition: $condition) {
      name
      depotCode
      latitude
      longitude
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDepot = /* GraphQL */ `
  mutation DeleteDepot(
    $input: DeleteDepotInput!
    $condition: ModelDepotConditionInput
  ) {
    deleteDepot(input: $input, condition: $condition) {
      name
      depotCode
      latitude
      longitude
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManifestShipment = /* GraphQL */ `
  mutation CreateManifestShipment(
    $input: CreateManifestShipmentInput!
    $condition: ModelManifestShipmentConditionInput
  ) {
    createManifestShipment(input: $input, condition: $condition) {
      shipmentID
      address {
        address1
        address2
        attention
        city
        companyName
        phone
        latitude
        longitude
        relevance
        routeCode
        state
        zipCode
        __typename
      }
      isDelivery
      isPriority
      latitude
      longitude
      route
      status
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManifestShipment = /* GraphQL */ `
  mutation UpdateManifestShipment(
    $input: UpdateManifestShipmentInput!
    $condition: ModelManifestShipmentConditionInput
  ) {
    updateManifestShipment(input: $input, condition: $condition) {
      shipmentID
      address {
        address1
        address2
        attention
        city
        companyName
        phone
        latitude
        longitude
        relevance
        routeCode
        state
        zipCode
        __typename
      }
      isDelivery
      isPriority
      latitude
      longitude
      route
      status
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManifestShipment = /* GraphQL */ `
  mutation DeleteManifestShipment(
    $input: DeleteManifestShipmentInput!
    $condition: ModelManifestShipmentConditionInput
  ) {
    deleteManifestShipment(input: $input, condition: $condition) {
      shipmentID
      address {
        address1
        address2
        attention
        city
        companyName
        phone
        latitude
        longitude
        relevance
        routeCode
        state
        zipCode
        __typename
      }
      isDelivery
      isPriority
      latitude
      longitude
      route
      status
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTripShipment = /* GraphQL */ `
  mutation CreateTripShipment(
    $input: CreateTripShipmentInput!
    $condition: ModelTripShipmentConditionInput
  ) {
    createTripShipment(input: $input, condition: $condition) {
      tripSheetID
      shipmentID
      activityTime
      recordedTime
      status
      statusID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTripShipment = /* GraphQL */ `
  mutation UpdateTripShipment(
    $input: UpdateTripShipmentInput!
    $condition: ModelTripShipmentConditionInput
  ) {
    updateTripShipment(input: $input, condition: $condition) {
      tripSheetID
      shipmentID
      activityTime
      recordedTime
      status
      statusID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTripShipment = /* GraphQL */ `
  mutation DeleteTripShipment(
    $input: DeleteTripShipmentInput!
    $condition: ModelTripShipmentConditionInput
  ) {
    deleteTripShipment(input: $input, condition: $condition) {
      tripSheetID
      shipmentID
      activityTime
      recordedTime
      status
      statusID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTripWaypoint = /* GraphQL */ `
  mutation CreateTripWaypoint(
    $input: CreateTripWaypointInput!
    $condition: ModelTripWaypointConditionInput
  ) {
    createTripWaypoint(input: $input, condition: $condition) {
      tripSheetID
      activityTime
      recordedTime
      status
      statusID
      routeCode
      waypoint
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTripWaypoint = /* GraphQL */ `
  mutation UpdateTripWaypoint(
    $input: UpdateTripWaypointInput!
    $condition: ModelTripWaypointConditionInput
  ) {
    updateTripWaypoint(input: $input, condition: $condition) {
      tripSheetID
      activityTime
      recordedTime
      status
      statusID
      routeCode
      waypoint
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTripWaypoint = /* GraphQL */ `
  mutation DeleteTripWaypoint(
    $input: DeleteTripWaypointInput!
    $condition: ModelTripWaypointConditionInput
  ) {
    deleteTripWaypoint(input: $input, condition: $condition) {
      tripSheetID
      activityTime
      recordedTime
      status
      statusID
      routeCode
      waypoint
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManifestWaypoint = /* GraphQL */ `
  mutation CreateManifestWaypoint(
    $input: CreateManifestWaypointInput!
    $condition: ModelManifestWaypointConditionInput
  ) {
    createManifestWaypoint(input: $input, condition: $condition) {
      routeCode
      waypoint
      arrival
      assignmentRule
      deliveries
      departure
      fromPreviousDistance
      fromPreviousTravelTime
      id
      isDelivery
      isDropBox
      latitude
      locations
      longitude
      pickups
      radius
      route
      sequence
      serviceTime
      shipments {
        shipmentID
        __typename
      }
      stop
      stopType
      violationTime
      waitTime
      windowEnd
      windowStart
      created
      distanceCost
      endTime
      error
      geometry
      jobID
      orderCount
      overTimeCost
      processed
      regularTimeCost
      routeEnd
      routeStart
      startTime
      status
      totalBreakServiceTime
      totalCost
      totalDistance
      totalOrderServiceTime
      totalTime
      totalTravelTime
      totalViolationTime
      totalWaitTime
      violatedConstraints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManifestWaypoint = /* GraphQL */ `
  mutation UpdateManifestWaypoint(
    $input: UpdateManifestWaypointInput!
    $condition: ModelManifestWaypointConditionInput
  ) {
    updateManifestWaypoint(input: $input, condition: $condition) {
      routeCode
      waypoint
      arrival
      assignmentRule
      deliveries
      departure
      fromPreviousDistance
      fromPreviousTravelTime
      id
      isDelivery
      isDropBox
      latitude
      locations
      longitude
      pickups
      radius
      route
      sequence
      serviceTime
      shipments {
        shipmentID
        __typename
      }
      stop
      stopType
      violationTime
      waitTime
      windowEnd
      windowStart
      created
      distanceCost
      endTime
      error
      geometry
      jobID
      orderCount
      overTimeCost
      processed
      regularTimeCost
      routeEnd
      routeStart
      startTime
      status
      totalBreakServiceTime
      totalCost
      totalDistance
      totalOrderServiceTime
      totalTime
      totalTravelTime
      totalViolationTime
      totalWaitTime
      violatedConstraints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManifestWaypoint = /* GraphQL */ `
  mutation DeleteManifestWaypoint(
    $input: DeleteManifestWaypointInput!
    $condition: ModelManifestWaypointConditionInput
  ) {
    deleteManifestWaypoint(input: $input, condition: $condition) {
      routeCode
      waypoint
      arrival
      assignmentRule
      deliveries
      departure
      fromPreviousDistance
      fromPreviousTravelTime
      id
      isDelivery
      isDropBox
      latitude
      locations
      longitude
      pickups
      radius
      route
      sequence
      serviceTime
      shipments {
        shipmentID
        __typename
      }
      stop
      stopType
      violationTime
      waitTime
      windowEnd
      windowStart
      created
      distanceCost
      endTime
      error
      geometry
      jobID
      orderCount
      overTimeCost
      processed
      regularTimeCost
      routeEnd
      routeStart
      startTime
      status
      totalBreakServiceTime
      totalCost
      totalDistance
      totalOrderServiceTime
      totalTime
      totalTravelTime
      totalViolationTime
      totalWaitTime
      violatedConstraints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivities = /* GraphQL */ `
  mutation CreateActivities(
    $input: CreateActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    createActivities(input: $input, condition: $condition) {
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActivities = /* GraphQL */ `
  mutation UpdateActivities(
    $input: UpdateActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    updateActivities(input: $input, condition: $condition) {
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActivities = /* GraphQL */ `
  mutation DeleteActivities(
    $input: DeleteActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    deleteActivities(input: $input, condition: $condition) {
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      createdAt
      updatedAt
      __typename
    }
  }
`;

<template>
  <div class="popup">
      <div class="popup-inner">
          <div class="closeDropBox-container">
                    <h1>Drop Box Locations</h1>
                    <div class="close-popup-container"><i class="fa fa-times-circle" @click="DropBoxTogglePopup()"></i></div>
                    <!-- <div class="closeDropBox" @click="DropBoxTogglePopup()"></div> -->
                </div>
            <div class="DropBox-container">
                
                <div class="box-schedule-table">
                    <h2>Pickup Schedule</h2>
                        <table>
                            <tr>
                                <th>Placed in Box by 6pm</th>
                                <th>Delivered</th>
                            </tr>
                            <tr>
                                <td>Monday - Friday</td>
                                <td>Next Day, excluding Legal Holidays</td>
                            </tr>
                        </table>
                    <p>** unless indicated on the chart below, all drop box pick-up times are 6pm</p>
                </div>

                <div class="locationsHeader">
                    <h2>Box Locations</h2>
                </div>
                

                <div class="main-table-container">
                 <table class="dropbox-table">
                    <!-- BERGEN -->
                    <tr v-on:click="showBergen = !showBergen" class="tabStructure">
                    <h2 id="countyName">Bergen</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showBergen != true"></i>
                    <!-- <p v-if="showBergen == true" v-on:click="showBergen = !showBergen"  class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showBergen == true" v-on:click="showBergen = !showBergen" ></i>
                    </tr>
                    <table class="inner-table" v-if="showBergen==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Bergen'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Bergen'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Bergen'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- BURLINGTON -->
                    <tr v-on:click="showBurlington = !showBurlington" class="tabStructure">
                    <h2 >Burlington</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showBurlington != true"></i>
                    <!-- <p v-if="showBurlington == true" v-on:click="showBurlington = !showBurlington" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showBurlington == true" v-on:click="showBurlington = !showBurlington" ></i>
                    </tr>
                    <table class="inner-table" v-if="showBurlington==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Burlington'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Burlington'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Burlington'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- CAMDEN -->
                    <tr v-on:click="showCamden = !showCamden" class="tabStructure">
                    <h2>Camden</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showCamden != true"></i>
                    <!-- <p v-if="showCamden == true" v-on:click="showCamden = !showCamden" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showCamden == true" v-on:click="showCamden = !showCamden" ></i>

                    </tr>
                    <table class="inner-table" v-if="showCamden==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Camden'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Camden'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Camden'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- CUMBERLAND -->
                    <tr v-on:click="showCumberland = !showCumberland" class="tabStructure">
                    <h2>Cumberland</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showCumberland != true"></i>
                    <!-- <p v-if="showCumberland == true" v-on:click="showCumberland = !showCumberland" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showCumberland == true" v-on:click="showCumberland = !showCumberland" ></i>
                    </tr>
                    <table class="inner-table" v-if="showCumberland==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Cumberland'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Cumberland'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Cumberland'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- ESSEX -->
                    <tr v-on:click="showEssex = !showEssex" class="tabStructure">
                    <h2>Essex</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showEssex != true"></i>
                    <!-- <p v-if="showEssex == true" v-on:click="showEssex = !showEssex" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showEssex == true" v-on:click="showEssex = !showEssex" ></i>
                    </tr>
                    <table class="inner-table" v-if="showEssex==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Essex'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Essex'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Essex'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- HUDSON -->
                    <tr v-on:click="showHudson = !showHudson" class="tabStructure">
                    <h2>Hudson</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showHudson != true"></i>
                    <!-- <p v-if="showHudson == true" v-on:click="showHudson = !showHudson" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showHudson == true" v-on:click="showHudson = !showHudson" ></i>
                    </tr>
                    <table class="inner-table" v-if="showHudson==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Hudson'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Hudson'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Hudson'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- HUNTERDON -->
                    <tr v-on:click="showHunterdon = !showHunterdon" class="tabStructure">
                    <h2 >Hunterdon</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showHunterdon != true"></i>
                    <!-- <p v-if="showHunterdon == true" v-on:click="showHunterdon = !showHunterdon" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showHunterdon == true" v-on:click="showHunterdon = !showHunterdon" ></i>
                    </tr>
                    <table class="inner-table" v-if="showHunterdon==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Hunterdon'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Hunterdon'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Hunterdon'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- MERCER -->
                    <tr v-on:click="showMercer = !showMercer" class="tabStructure">
                    <h2>Mercer</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showMercer != true"></i>
                    <!-- <p v-if="showMercer == true" v-on:click="showMercer = !showMercer" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showMercer == true" v-on:click="showMercer = !showMercer" ></i>
                    </tr>
                    <table class="inner-table" v-if="showMercer==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Mercer'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Mercer'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Mercer'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- MIDDLESEX -->
                    <tr v-on:click="showMiddlesex = !showMiddlesex" class="tabStructure">
                    <h2 >Middlesex</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showMiddlesex != true"></i>
                    <!-- <p v-if="showMiddlesex == true" v-on:click="showMiddlesex = !showMiddlesex" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showMiddlesex == true" v-on:click="showMiddlesex = !showMiddlesex" ></i>
                    </tr>
                    <table class="inner-table" v-if="showMiddlesex==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Middlesex'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Middlesex'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Middlesex'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- MONMOUTH -->
                    <tr v-on:click="showMonmouth = !showMonmouth" class="tabStructure">
                    <h2 >Monmouth</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showMonmouth != true"></i>
                    <!-- <p v-if="showMonmouth == true" v-on:click="showMonmouth = !showMonmouth" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showMonmouth == true" v-on:click="showMonmouth = !showMonmouth" ></i>
                    </tr>
                    <table class="inner-table" v-if="showMonmouth==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Monmouth'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Monmouth'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Monmouth'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- MORRIS -->
                    <tr v-on:click="showMorris = !showMorris" class="tabStructure">
                    <h2 >Morris</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showMorris != true"></i>
                    <!-- <p v-if="showMorris == true" v-on:click="showMorris = !showMorris" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showMorris == true" v-on:click="showMorris = !showMorris" ></i>
                    </tr>
                    <table class="inner-table" v-if="showMorris==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Morris'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Morris'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Morris'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- OCEAN -->
                    <tr v-on:click="showOcean = !showOcean" class="tabStructure">
                    <h2>Ocean</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showOcean != true"></i>
                    <!-- <p v-if="showOcean == true" v-on:click="showOcean = !showOcean" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showOcean == true" v-on:click="showOcean = !showOcean" ></i>
                    </tr>
                    <table class="inner-table" v-if="showOcean==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Ocean'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Ocean'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Ocean'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- PASSAIC -->
                    <tr v-on:click="showPassaic = !showPassaic" class="tabStructure">
                    <h2>Passaic</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showPassaic != true"></i>
                    <!-- <p v-if="showPassaic == true" v-on:click="showPassaic = !showPassaic" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showPassaic == true" v-on:click="showPassaic = !showPassaic" ></i>
                    </tr>
                    <table class="inner-table" v-if="showPassaic==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Passaic'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Passaic'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Passaic'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- SOMERSET -->
                    <tr v-on:click="showSomerset = !showSomerset" class="tabStructure">
                    <h2>Somerset</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showSomerset != true"></i>
                    <!-- <p v-if="showSomerset == true" v-on:click="showSomerset = !showSomerset" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showSomerset == true" v-on:click="showSomerset = !showSomerset" ></i>
                    </tr>
                    <table class="inner-table" v-if="showSomerset==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Somerset'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Somerset'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Somerset'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- UNION -->
                    <tr v-on:click="showUnion = !showUnion" class="tabStructure">
                    <h2>Union</h2>
                    <i class="down-arrow" id="down-arrow" v-if="showUnion != true"></i>
                    <!-- <p v-if="showUnion == true" v-on:click="showUnion = !showUnion" class="closeTab">Close Tab</p> -->
                    <i class="up-arrow" id="up-arrow" v-if="showUnion == true" v-on:click="showUnion = !showUnion" ></i>
                    </tr>
                    <table class="inner-table" v-if="showUnion==true">
                        <thead>
                            <tr>
                            <th>City</th>
                            <th>Address</th>
                            <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dropbox in dropboxDataJSON" :key="dropbox.id">
                            <td v-if="dropbox.county == 'Union'">{{dropbox.city}}</td>
                            <td v-if="dropbox.county == 'Union'">{{dropbox.address}}</td>
                            <td v-if="dropbox.county == 'Union'"><a v-bind:href="dropbox.map" target="_blank" id="map-location">Map</a></td>
                            </tr>
                        </tbody>
                    </table>
                </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// import dropboxData from '/dropboxData.json'
import axios from 'axios'

export default {
    props: ['DropBoxTogglePopup'],

    data(){
        return{
            showEssex: false,
            showBergen: false,
            showUnion: false,
            showBurlington: false,
            showCamden: false,
            showCumberland: false,
            showHudson: false,
            showHunterdon: false,
            showMercer: false,
            showMiddlesex: false,
            showMonmouth: false,
            showMorris: false,
            showOcean: false,
            showPassaic: false,
            showSomerset: false,
            // dropboxData,
            dropboxDataJSON: []
        }
    },
    methods:{
        async getDropBoxData(){
            await axios.get('../dropboxData.json').then((response)=>{
              this.dropboxDataJSON = response.data;
            })
        },
        async getDropBoxDataZip(){
            await axios.get('../../dropboxData.json').then((response)=>{
              this.dropboxDataJSON = response.data;
            })
        }
    },
    // mounted(){
    //     this.getDropBoxData();
    // },
    mounted(){
        if(this.$route.params.shipZip){
            this.getDropBoxDataZip();
        }else{
            this.getDropBoxData();
        }
    }
}
</script>

<style scoped>

.popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: drop .5s ease forwards;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    font-family: 'Work Sans', sans-serif;
}


.popup-inner{
    width: fit-content;
    height: auto;
    padding-bottom: 10px;
    border-radius: 15px;
    background-color: white;

    display: flex;
    flex-direction: column;
    text-align: left;

    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    overflow-y: hidden;
}

.popup-close{
    position: absolute;
    top: 5px;
    justify-content: center;
    margin-left: 2.5vw;
}

.DropBox-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

@keyframes drop {
  from{margin-top: -5%;}
  to{margin-top: 1%;}
}

.close-popup-container{
    margin-left: auto;
    margin-right: 5px;
}

.fa-times-circle{
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
    transition-duration: .5s;
}

.fa-times-circle:hover{
    color: rgb(218, 218, 218);
    cursor: pointer;
    transition-duration: .5s;
}

.closeDropBox-container{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2cb6e4;
    border-radius: 15px 15px 0 0;
}

.closeDropBox-container h1{
    color: #fff;
    text-align: center;
    font-size: 1.8rem;
    flex: 1;
}


/*Table Structure*/
table {
  width: 100%;
  border-collapse: collapse;
}

th{
  background-color: #32ccfe;
}

.closeTab{
  margin-left: 5vw;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tabStructure h2{
  cursor: pointer;
}

.tabStructure{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #b3b3b3;
  transition-duration: .25s;
  cursor: pointer;
}

.tabStructure:hover{
    background-color: #fdfdfd;
    transition-duration: .25s;
}

.tabStructure h2{
    margin-bottom: 0;
}

.inner-table th:first-child, .box-schedule-table th:first-child{
    border-top-left-radius: 10px;
}

.inner-table th:last-child, .box-schedule-table th:last-child{
    border-top-right-radius: 10px;
}

.inner-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.inner-table th{
    margin-top: 5px;
    padding: 5px;
}

.down-arrow {
  border: solid #32ccfe;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-bottom: 3px;
  margin-left: auto;
}

.up-arrow {
  border: solid #32ccfe;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  cursor: pointer;
  margin-left: auto;
}

.dropbox-table{
    margin-top: 0px;
    margin-bottom: 5vw;
}

.main-table-container{
    margin-top: 10px;
    padding: 5px;
    height: 55vh;
    width: 100%;
    overflow-y: auto;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

@keyframes tabledrop {
  from{margin-top: -5%;}
  to{margin-top: 5px;}
}

.inner-table{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    animation: tabledrop .5s ease forwards;
}

.inner-table td{
    padding: 5px;
    border-bottom: 1px solid #ddd;
}

.box-schedule-table table{
    border-collapse: separate;
    width: 100%;
}

.box-schedule-table td, .box-schedule-table th{
    padding: 5px;
    border: 1px solid #ddd;
}

.box-schedule-table th{
    background-color: #32ccfe;
}

.box-schedule-table{
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
}

.box-schedule-table h2{
    margin-top: 0px;
    margin-bottom: 0px;
}

.locationsHeader{
    width: 100%;
    text-align: left;
    margin-bottom: 0;
}

.locationsHeader h2{
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px 10px;
    background-color: #fe804d;
    color: #fff;
    border-radius: 50px;
    width: fit-content;
}

@media only screen and (max-width: 1000px){

    .main-table-container{
        height: 60vh;
    }

}

@media only screen and (max-width: 640px){
    .popup-inner{
        margin-right: 5px;
        margin-left: 5px;
    }
}

@media only screen and (max-width: 500px){
    .closeDropBox-container h1{
        font-size: 1.4rem;
    }

    .dropbox-table td, .box-schedule-table td{
        padding: 10px 2px 10px 2px;
        font-size: 12px;
    }
}

</style>
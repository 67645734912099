<template>
<div class="main-container">
    <div class="inner-container">
        <div id="automaticPickup" class="list-account-select">
            <div style="margin-top: 10px" class="header-track-shipment">
                <div v-if="selectedAccountNo != ''" class="header-track-inner">
                    <p style="font-weight: bold">Current Selected Account: </p>
                    <p>{{selectedAccountNo}}</p>
                </div>
                <div v-else class="header-track-inner">
                    <p style="font-weight: bold">Select Account From Table</p>
                </div>
            </div>
            
            <div v-if="(AdminUser || InternalAdmin)" class="track-shipment account-selection">
                <account-table @selectAccountNo="selectAccountNo($event)" :key="refreshKey" :jwtToken="jwtToken" :userData="userData"/>
            </div>
        </div>
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <loading-charts v-if="loadingUser" class="loading-charts"/>
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                    <div v-if="(AdminUser || InternalAdmin)">
                        <admin-user-management-table :selectedAccountNo="selectedAccountNo" :jwtToken="jwtToken" :userData="userData"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="track-shipment create-user">
            <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <h2>Create User</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="createCognitoUserInput.username" style="margin-bottom: 5px;" placeholder="User Name">
                                    </div>
                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="createCognitoUserInput.fullName" style="margin-bottom: 5px;" placeholder="Full Name">
                                    </div>
                                    <!-- <div class="confirmLabelContainer">
                                        <input type="text" v-model="createCognitoUserInput.temporaryPassword" disabled style="margin-bottom: 5px;" placeholder="Temporary Password">
                                    </div> -->
                                    <!-- {{createCognitoUserInput}} -->
                                </div>
                            </div>

                            <div class="confirmContainerInner2">
                                <div class="confirm-svg notifications">
                                    <svg data-name="Livello 1" viewBox="10 12.5 40 40" xmlns="http://www.w3.org/2000/svg"><path d="M1 33A32 32 0 1 0 33 1 32 32 0 0 0 1 33Z" fill="none" class="fill-000000"></path><rect height="24.18" rx="1.48" ry="1.48" width="32" x="17" y="21" stroke-miterlimit="10" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></rect><path d="M48.44 21.83 37.53 33.54a6.27 6.27 0 0 1-9.07 0l-10.9-11.7M39.19 31.75l8.96 12.76M26.98 31.88 17.7 44.51" stroke-linejoin="round" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></path></svg>
                                    <h2>Notifications</h2>
                                </div>

                                <div class="confirmLabelContainer2">
                                    <input type="text" v-model="createCognitoUserInput.email" placeholder="Email">
                                </div>

                                <div style="margin-top: 5px;" class="confirm-svg notifications">
                                    <h2>Location</h2>
                                </div>

                                <div class="confirmLabelContainer2">
                                    <div class="notificationArray" name="deliveryphone">
                                        <input type="text" disabled v-model="createCognitoUserInput.locationID" placeholder="LocationID">
                                    </div>
                                </div>
                            </div>
                        </div>
                    <loading-charts v-if="loadingUser" class="loading-charts"/>
                    <button class="update-user-button" @click="createCognitoUserFunc()">Create User</button>   
                </div>
            </div>

            <div v-if="(AdminUser || InternalAdmin)" class="track-shipment create-user">
                <account-locations @checkBoxSelectID="checkBoxSelectID($event)" :selectedAccountNo="selectedAccountNo" :key="refreshKey" :jwtToken="jwtToken" :userData="userData" :selectedUserData="selectedUserData"/>
            </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {createCognitoUser} from '../../../graphql/mutations'
import {Auth, API} from 'aws-amplify'
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import AdminUserManagementTable from '../AdminUserManagementDashboard/AdminUserManagementTable.vue';
import AccountLocations from '../LocationDashboard/AccountLocations.vue'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'
import AccountTable from '../AccountDashboard/AccountTable.vue'

export default {
    data(){
        return{
            user: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            userDataCognito: {},
            refreshKey: 0,
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            createCognitoUserInput: {
                email: "",
                username: "",
                accountNo: "",
                locationID: "",
                fullName: "",
                temporaryPassword: ""
            },
            selectedUserData:{
                locationID: ""
            },
            selectedAccountNo: ""
        }
    },
    components:{
        LoadingCharts,
        AlertUser,
        AdminUserManagementTable,
        AccountLocations,
        SignedInUserInfo,
        AccountTable
    },
    watch:{
        'createCognitoUserInput.username'(){
            this.createCognitoUserInput.temporaryPassword = this.userData.accountNo + '.' + this.createCognitoUserInput.username
        }
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to user management dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        selectAccountNo(accountNo){
            this.selectedAccountNo = accountNo;
        },
        async isPasswordValid(password) {
            // Check length requirements
            if (password.length < 6 || password.length > 99) {
                this.toggleAlertBox = true;
                this.alertMessage = "Password must be longer than 6 characters."
                return false; // Password length does not meet requirements
            }

            // Check for at least one number
            const hasNumber = /[0-9]/.test(password);

            // Check for at least one special character
            const hasSpecialChar = /[^\w\s]|_/.test(password);

            return hasNumber && hasSpecialChar;
        },
        async checkBoxSelectID(event){
            this.createCognitoUserInput.locationID = event;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($UserName: String!){
                        getUser(userName: $UserName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            UserName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createCognitoUserFunc(){
            if(this.createCognitoUserInput.locationID == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Select a location for user."
                return;
            }

            if(this.createCognitoUserInput.email == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter an email for user."
                return;
            }

            if(this.createCognitoUserInput.username == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter user name."
                return;
            }

            if(this.createCognitoUserInput.fullName == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter full name."
                return;
            }

            if(this.createCognitoUserInput.temporaryPassword == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter temporary password."
                return;
            }

            if(this.selectedAccountNo == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Select account number."
                return;
            }

            let passwordValid = await this.isPasswordValid(this.createCognitoUserInput.temporaryPassword)

            if(passwordValid){
                this.loadingUser = true;
                try {
                    let userReturnData = await API.graphql({
                        query: createCognitoUser,
                        variables: {
                            input:{
                                username: this.createCognitoUserInput.username,
                                email: this.createCognitoUserInput.email,
                                accountNo: this.selectedAccountNo,
                                locationID: this.createCognitoUserInput.locationID,
                                fullName: this.createCognitoUserInput.fullName,
                                temporaryPassword: this.createCognitoUserInput.temporaryPassword
                            }
                        },
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    this.userDataCognito = userReturnData;
                    this.GetUserGraphQL();
                    this.toggleAlertBox = true;
                    this.alertMessage = "Created user."
                    let obj = this.createCognitoUserInput;
                    for (const key in obj) {
                        if (Object.prototype.hasOwnProperty.call(obj, key)) {
                            obj[key] = '';
                        }
                    }
                } catch (error) {
                    console.log(error.errors[0].message)
                    this.toggleAlertBox = true;
                    this.alertMessage = error.errors[0].message
                    this.GetUserGraphQL();
                }finally{
                    this.loadingUser = false;
                }
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "You password must contain either a number or a special character (ex. $ * . ? ! @ # % &)"
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.list-account-select{
    width: 80%;
    display: block;
    margin: auto;
}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.inner-overflow-container{
    width: 100%;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-selection{
    width: 100% !important;
}

.create-user{
    flex-basis: 40%;
}

.update-user{
    flex-basis: 60%;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
<template>
  <div class="alert-container">
      <div class="inner-container">
          <div class="text-container">
            <!-- <h2>Alert</h2> -->
            <p v-html="message"></p>
            <button @click="closeAlert" class="main-button-blue-white">Okay</button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['message'],
    methods:{
        closeAlert(){
            this.$emit('closeAlertBox', false)
        }
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
@import "../../styles/button-styles.css";

    .alert-container{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 99;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 90%, rgba(236, 236, 236, 0.05) 100%);
    }

    .inner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .main-button-blue-white{
        margin: 5px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .text-container{
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.475);
        border-radius: 15px;
        width: 30%;
        z-index: 99;
        text-align: center;
        position: absolute;
        top: 15%;
        animation: animate-alert .5s ease;
        max-height: 70vh;
        overflow-y: auto;

    }

    .text-container h2{
        margin-top: 0;
        background-color: #fe804d;
        color: #fff;
        border-radius: 15px 15px 0 0;
    }

    @keyframes animate-alert {
        from{
            top: 10%;
            opacity: 0;
        }
        to{
            top: 15%;
            opacity: 1;
        }
    }

    @media screen and (max-width: 1000px) {
        .text-container{
            width: 70%;
        }
    }
</style>
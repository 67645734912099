<template>
  <div class="track-shipment">
    <div class="header-track-shipment">
        <div v-if="user.username" class="header-track-inner">
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                    <g id="_Ñëîé_1" data-name="Ñëîé 1">
                        <g>
                        <g>
                            <polyline class="cls-1" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                            <line class="cls-1" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                            <polyline class="cls-1" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                            <line class="cls-1" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                            <line class="cls-1" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                        </g>
                        <path class="cls-1" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                        <path class="cls-1" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                        <path class="cls-1" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                        <path class="cls-1" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                        <path class="cls-1" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                        <path class="cls-1" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                        </g>
                    </g>
                </svg>
            <p>Hello, {{user.username}}</p>
        </div>
        <div v-else class="header-track-inner">
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                    <g id="_Ñëîé_1" data-name="Ñëîé 1">
                        <g>
                        <g>
                            <polyline class="cls-1" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                            <line class="cls-1" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                            <polyline class="cls-1" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                            <line class="cls-1" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                            <line class="cls-1" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                        </g>
                        <path class="cls-1" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                        <path class="cls-1" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                        <path class="cls-1" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                        <path class="cls-1" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                        <path class="cls-1" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                        <path class="cls-1" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                        </g>
                    </g>
                </svg>
            <p>No User Data</p>
        </div>
    </div>
    <loading-skeleton :loadingHeight="'100px'" v-if="loadingUser" />

    <div v-if="userData.locationAddress" class="user-info">
        <!-- {{userData.locationAddress.serviceAddress.address}} -->
        <p style="font-weight: bold;">{{userData.locationAddress.serviceAddress.address.companyName}}</p>
        <p>{{userData.locationAddress.serviceAddress.address.address1}}</p>
        <p>{{userData.locationAddress.serviceAddress.address.city}}, {{userData.locationAddress.serviceAddress.address.state}} {{userData.locationAddress.serviceAddress.address.zipCode}}</p>
    </div>
    <div v-if="(!userData && !userData.locationAddress) && !loadingUser" class="user-info">
        <p style="font-weight: bold;">No User Data</p>
    </div>
</div>
</template>

<script>
import LoadingSkeleton from '../LoadingSkeleton.vue'
export default {
    props:['user', 'userData', 'loadingUser'],
    components:{
        LoadingSkeleton
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.svg-container{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-track-inner svg{
    height: 18px;
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

@media only screen and (max-width: 1000px) {
    .track-shipment{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .track-shipment{
        flex-basis: 80%;
    }
}
</style>
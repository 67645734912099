<template>
    <loading-skeleton :loadingHeight="'300px'" v-if="!chartActive"/>

    <div v-show="(inTransitCount > 0) || (deliveredCount > 0) || (currentCount > 0)" class="donut-container">
        <canvas ref="myChart" class="donut-chart" id="donut"></canvas>
    </div>
    <p v-if="(inTransitCount == 0) && (deliveredCount == 0) && (currentCount == 0)" class="selected-range no-shipments">No Shipments</p>
    <p v-if="sliderValue >= 1 && sliderValue < 2" class="selected-range">7 days of shipments</p>
    <p v-if="sliderValue >= 2 && sliderValue < 3" class="selected-range">{{currentMonthName}} shipments</p>
    <p v-if="sliderValue == 3" class="selected-range">{{previousMonthName}}-{{currentMonthName}} shipments</p>
    <div v-if="false" class="slidecontainer">
        <input type="range" v-model="sliderValue" min="1" max="3" step="1" class="slider" id="myRange">
        <div class="slider-range">
            <div>Week</div>
            <div class="middle-range">Month</div>
            <div>Two Months</div>
        </div>
    </div>
    <div class="slidecontainer">
        <ol class="steplist">
            <li :class="{ active: index === 0 }" v-for="(item, index) in sliderValueArray" :key="item.value" @click="SetSliderValue(item.value)">{{item.label}}</li>
        </ol>
    </div>
    <br>
    <AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 
</template>

<script>
import Chart from 'chart.js/auto'
import {API} from 'aws-amplify';
import LoadingSkeleton from '../../ShipmentDashboard/LoadingSkeleton.vue'
import AlertUser from '../../Popups/AlertUser.vue';

export default {
    components:{
        AlertUser,
        LoadingSkeleton
    },
    props:['refreshAfterVoidBool', 'userData', 'selectedAccountNo'],
    data(){
        return{
            sliderValue: 1,
            sliderValueArray:[
                {value: 1, label: "Week"},
                {value: 2, label: "Month"},
                {value: 3, label: "Two Months"}
            ],
            fromDate: "",
            toDate: "",
            deliveredCount: null,
            inTransitCount: null,
            currentCount: null,
            currentShipmentCount: 10,
            changeDataArrayInput: [],
            changeDataLabelInput: null,
            donutChart: null,
            chartOptions: {
                        responsive: true,
                        fullSize: true
                    },
            chartData : {
                labels: [
                    'Current',
                    'In Transit',
                    'Delivered'
                ],
                datasets: [{
                    label: 'My First Dataset',
                    data: [10, 15, 30],
                    backgroundColor: [
                    '#33f18a',
                    '#32ccfe',
                    '#fe804d'
                    ],
                    hoverOffset: 4
                }]
            },
            toggleAlertBox: false,
            alertMessage: "",
            CurrentKey: null,
            beforeTodaysDate: null,
            todaysDate: null,
            sevenDaysDate: null,
            thirtyDaysDate: null,
            sixtyDaysDate: null,
            chartActive: false,
            currentMonthName: '',
            currentMonth: '',
            previousMonth: '',
            previousMonthName: '',
            items: `items{
                    shipmentID
                    }`
        }
    },
    watch:{
        'selectedAccountNo':function(){
            if(this.selectedAccountNo != "")
            {
                this.CurrentKey = this.selectedAccountNo;
                this.chartActive = false;
                if(this.donutChart){
                    this.donutChart.destroy();
                }
                
                this.createMyChartDoughnut();
                this.getInTransitShipmentsGraphQL();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get account data to generate table."
            }
        },
        chartDataComputed(newDataSet){
            this.donutChart.destroy();
            this.chartData.datasets.data = newDataSet;
            this.createMyChartDoughnut();
        },
        'sliderValue':function() {
            this.chartActive = false;
            if(this.sliderValue == 1){
                this.toDate = this.todaysDate;
                this.fromDate = this.sevenDaysDate;
                this.$emit('selectedBetweenDates', {fromDate: this.fromDate, toDate: this.toDate, sliderValue: this.sliderValue})
            }else if(this.sliderValue == 2){
                this.toDate = this.todaysDate;
                this.fromDate = this.thirtyDaysDate;
                this.$emit('selectedBetweenDates', {fromDate: this.fromDate, toDate: this.toDate, sliderValue: this.sliderValue})
            }else if(this.sliderValue == 3){
                this.toDate = this.todaysDate;
                this.fromDate = this.sixtyDaysDate;
                this.$emit('selectedBetweenDates', {fromDate: this.fromDate, toDate: this.toDate, sliderValue: this.sliderValue})
            }

            this.donutChart.destroy();
            this.createMyChartDoughnut();
        },
        'refreshAfterVoidBool':function(){
            this.chartActive = false;
            this.donutChart.destroy();
            this.createMyChartDoughnut();
        }
    },
    computed:{
        chartDataComputed(){
            return this.chartData.datasets[0].data, this.chartData.labels;
        }
    },
    methods:{
        async getCurrentShipmentsGraphQL(){
            let graphqlQuery;

            graphqlQuery = `query shipmentByCurrentKey{
                        shipmentByCurrentKey(currentKey: "${this.CurrentKey}", limit: 1000){
                            ${this.items}
                        }
                    }`
                    
            try{
                let shipmentByCurrentKey = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.currentCount = shipmentByCurrentKey.data.shipmentByCurrentKey.items.length;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting current shipments"
                }
            }finally{
                this.loading = false;
            }
        },
        async getInTransitShipmentsGraphQL(){
            let graphqlQuery;
            
            graphqlQuery = `query shipmentByInTransitKey{
                        shipmentByInTransitKey(inTransitKey: "${this.CurrentKey}", limit: 1000){
                            ${this.items}
                        }
                    }`

            try{
                let shipmentByInTransitKey = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.inTransitCount = shipmentByInTransitKey.data.shipmentByInTransitKey.items.length;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting in transit shipments"
                }
            }finally{
                this.loading = false;
            }
        },
        async getDeliveredShipmentsGraphQL(){
            let deliveredCount;
            let deliveredCountSecond;
            let HistoryKey = this.CurrentKey + '-' + this.currentMonth;
            let DeliveryDayTo = this.toDate.slice(8,10);
            let DeliveryDayFrom = this.fromDate.slice(8,10);
            const thisMonthDate = new Date();
            const previousMonthDate = new Date();
            // Set the current date to the first day of the current month
            previousMonthDate.setDate(1);
            // Subtract one day to get to the last day of the previous month
            previousMonthDate.setDate(0);
            let previousMonthKey = this.CurrentKey + '-' + previousMonthDate.toJSON().slice(0,7)
            //const numberOfDays = previousMonthDate.getDate(); // Get the number of days in the previous month
            
            let previousMonthHistoryKey = this.CurrentKey + "-" + this.fromDate.slice(0, 7)
            let thisMonthName = thisMonthDate.toLocaleString('default', { month: 'long' })
            let previousMonthName = previousMonthDate.toLocaleString('default', { month: 'long' })

            this.currentMonthName = thisMonthName;
            this.previousMonthName = previousMonthName;

            switch (this.sliderValue) {
                case 1:
                    if(DeliveryDayFrom > DeliveryDayTo){
                        let shipmentByHistoryKeyPrevMonth = await API.graphql({
                        query:  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${previousMonthHistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        })
                        let counter = 0;
                         shipmentByHistoryKeyPrevMonth.data.shipmentByHistoryKey.items.forEach(element => {
                            counter++
                            console.log(counter)
                            console.log(element)
                        });
                        let deliveredCountPreMonth = shipmentByHistoryKeyPrevMonth.data.shipmentByHistoryKey.items.length;

                        let shipmentByHistoryKeyCurrentMonth = await API.graphql({
                        query:  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        })

                        let deliveredCountCurrentMonth  = shipmentByHistoryKeyCurrentMonth.data.shipmentByHistoryKey.items.length;

                        this.deliveredCount = deliveredCountPreMonth + deliveredCountCurrentMonth;
                    }else{
                        try{
                            let shipmentByHistoryKey = await API.graphql({
                            query:  `query shipmentByHistoryKey{
                                shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                                ${this.items}
                            }
                            }`,
                            authMode: "AMAZON_COGNITO_USER_POOLS",
                            })
                            this.deliveredCount = shipmentByHistoryKey.data.shipmentByHistoryKey.items.length;
                        }catch(e){
                            console.log(e)
                            if(e){
                                this.toggleAlertBox = true;
                                this.alertMessage = "Error getting delivered shipments"
                            }
                        }finally{
                            this.loading = false;
                        }
                    }
                    break;
                case 2:
                    try{
                        let shipmentByHistoryKey = await API.graphql({
                        query:  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        })
                        this.deliveredCount = shipmentByHistoryKey.data.shipmentByHistoryKey.items.length;
                    }catch(e){
                        console.log(e)
                        if(e){
                            this.toggleAlertBox = true;
                            this.alertMessage = "Error getting delivered shipments"
                        }
                    }finally{
                        this.loading = false;
                    }
                    break;
                case 3:
                    try{
                        let shipmentByHistoryKey = await API.graphql({
                        query:  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${previousMonthKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        })
                        deliveredCount = shipmentByHistoryKey.data.shipmentByHistoryKey.items.length;
                    }catch(e){
                        console.log(e)
                        if(e){
                            this.toggleAlertBox = true;
                            this.alertMessage = "Error getting delivered shipments"
                        }
                    }finally{
                        this.loading = false;
                    }

                    try{
                        let shipmentByHistoryKey = await API.graphql({
                        query:  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        })
                        deliveredCountSecond = shipmentByHistoryKey.data.shipmentByHistoryKey.items.length;
                    }catch(e){
                        console.log(e)
                        if(e){
                            this.toggleAlertBox = true;
                            this.alertMessage = "Error getting delivered shipments"
                        }
                    }finally{
                        this.loading = false;
                    }

                    this.deliveredCount = deliveredCount + deliveredCountSecond;
                    break;
                default:
                    break;
            }
            
        },
        async createMyChartDoughnut(){
            await this.getCurrentShipmentsGraphQL(this.CurrentKey);
            await this.getInTransitShipmentsGraphQL(this.CurrentKey);
            await this.getDeliveredShipmentsGraphQL(this.CurrentKey);
            this.chartData.datasets[0].data = [this.currentCount, this.inTransitCount, this.deliveredCount]
            this.donutChart = new Chart(
                this.$refs.myChart,
                {
                    type: 'doughnut',
                    data: this.chartData,
                    options: this.chartOptions
                }
            );
            this.chartActive = true;
        },
        SetSliderValue(value){
            this.sliderValue = value;
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        }
    },
    mounted(){
        this.todaysDate = new Date(new Date().setDate(new Date().getDate())).toJSON().slice(0, 10);
        this.sevenDaysDate =  new Date(new Date().setDate(new Date().getDate() - 7)).toJSON().slice(0, 10);
        this.thirtyDaysDate = new Date(new Date().setDate(new Date().getDate() - 29)).toJSON().slice(0, 10);
        this.sixtyDaysDate = new Date(new Date().setDate(new Date().getDate() - 59)).toJSON().slice(0, 10);
        this.fromDate = this.sevenDaysDate;
        this.toDate = this.todaysDate;
        this.currentMonth = this.toDate.slice(0,7);

        let previousMonthDate = new Date();
        // Set the current date to the first day of the current month
        previousMonthDate.setDate(1);
        // Subtract one day to get to the last day of the previous month
        previousMonthDate.setDate(0);
        this.previousMonth = previousMonthDate.toJSON().slice(0,7);

        let canvas = this.$refs.myChart;

        canvas.onclick = (evt) => {
            const res = this.donutChart.getElementsAtEventForMode(
                evt,
                'nearest',
                { intersect: true },
                true
            );
            // If didn't click on a bar, `res` will be an empty array
            if (res.length === 0) {
                return;
            }
            this.$emit('selectedShipmentType', this.donutChart.data.labels[res[0].index])
            this.$emit('selectedBetweenDates', {fromDate: this.fromDate, toDate: this.toDate, sliderValue: this.sliderValue})
        };

        document.querySelectorAll('.steplist').forEach(steplist => {
            steplist.addEventListener('click', function(event) {
                if (event.target.nodeName !== 'LI') return;
                const items = event.target.closest('.steplist').querySelectorAll('li');
                for (const item of items) {
                item.classList.toggle('active', item === event.target);
                }
            });
        });
    }
}
</script>

<style scoped>
.change-data-button{
    background-color: #32ccfe;
    color: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition-duration: .5s;
    margin-top: 15px;
}

.change-data-button:hover{
    transition-duration: .5s;
    background-color: #2eb5e2;
}

.data-container-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.inner-track-shipment-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.data-container{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    width: 25%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.donut-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.donut-chart{
    width: 100%;
    cursor: pointer;
}

/* Slider Pricing Styles */
.slidecontainer {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fe804d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fe804d;
  cursor: pointer;
}

.selected-range{
    width: fit-content;
    background-color: #32ccfe;
    color: #fff;
    padding: 5px 15px;
    border-radius: 99px;
    margin-left: 0%;
    transition-duration: .5s;
    animation: animate-selected-range .5s ease;
}

.steplist {
  border-top: 2px solid #ccc;
  display: flex;
  list-style-type: none;
  gap: 30%;
  margin: 0;
  padding: 0;
  --circle-radius: 8px;
  width: 80%;
}

.steplist li {
  color: #999;
  cursor: pointer;
  padding: calc(5px + var(--circle-radius)) 0 0;
  margin: 0;
  position: relative;
  text-align: center;
  font-size: 10px;
}

.steplist li::before {
  background-color: #ccc;
  border-radius: 50%;
  content: '';
  position: absolute;
  height: calc(2 * var(--circle-radius));
  width: calc(2 * var(--circle-radius));
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.steplist .active {
  color: black;
}

.steplist .active::before {
  background-color: #33f18a;
  box-shadow: 0 0 0 3px #2ac26eae;
  z-index: 2;
}

.steplist li:first-child::after {
  background-color: #fff;
  content: '';
  position: absolute;
  height: 2px;
  width: 50%;
  top: -2px;
  right: calc(50% + var(--circle-radius));
}


.steplist li:last-child::after {
  background-color: #000;
  content: '';
  position: absolute;
  height: 2px;
  width: 50%;
  top: -2px;
  left: calc(50% + 8px);
  background-color: #fff;
}

.no-shipments{
    background-color: #fe804d;
}

@keyframes animate-selected-range {
    from{
        margin-left: -50%;
    }
    to{
        margin-left: 0%;
    }
}

.slider-range{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
}

.middle-range{
    margin-left: 10%;
}

.loading-charts{
    margin-top: 25%;
}
</style>
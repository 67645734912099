import shipmentTrack from '../components/TrackShipment.vue'
import createShipment from '../components/CreateShipment.vue'
import shipmentCenter from '../components/ShipmentCenter/ShipmentCenter.vue'
import AddressBook from '../components/ShipmentCenter/AddressBook.vue'
import CreateAnnouncement from '../components/ShipmentCenter/CreateAnnouncement.vue'
import ShipmentDashboard from '../components/ShipmentDashboard/ShipmentDashboard.vue'
import AnnouncementBoard from '../components/ExtraPages/AnnouncementPage.vue'
import ContractorPage from '../components/ExtraPages/ContractorPage.vue'
import TermsConditions from '../components/ExtraPages/TermsConditions.vue'
import ShippingTC from '../components/ExtraPages/ShippingTC.vue'
import PrivacyPolicy from '../components/ExtraPages/PrivacyPolicy.vue'
import CookiesPolicy from '../components/ExtraPages/CookiesPolicy.vue'
import CriticalMotionDates from '../components/ExtraPages/CriticalMotionDates.vue'
import HolidaySchedule from '../components/ExtraPages/HolidaySchedule.vue'
import FuelSurcharge from '../components/ExtraPages/FuelSurcharge.vue'
import AdditionalCharges from '../components/ExtraPages/AdditionalCharges.vue'
import ContractorServices from '../components/ExtraPages/ContractorServices.vue'
import ZipShipContact from '../components/ExtraPages/ZipShipContact.vue'
import Login from '../components/ExtraPages/Login.vue'
import AddressBookDashboard from '../components/ShipmentDashboard/AddressBook/AddressBookDashboard.vue'
import UserDashboard from '../components/ShipmentDashboard/UserDashboard/UserDashboard.vue'
import UserManagementDashboard from '../components/ShipmentDashboard/UserManagementDashboard/UserManagementDashboard.vue'
import DropboxDashboard from '../components/ShipmentDashboard/DropboxDashboard/DropboxDashboard.vue'
import VehicleDashboard from '../components/ShipmentDashboard/VehicleDashboard/VehicleDashboard.vue'
import LocationDashboard from '../components/ShipmentDashboard/LocationDashboard/LocationDashboard.vue'
import PlaceDashboard from '../components/ShipmentDashboard/PlaceDashboard/PlaceDashboard.vue'
import GeoCodeDashboard from '../components/ShipmentDashboard/GeoCodeDashboard/GeoCodeDashboard.vue'
import RouteDashboard from '../components/ShipmentDashboard/RouteDashboard/RouteDashboard.vue'
import ProductDashboard from '../components/ShipmentDashboard/ProductDashboard/ProductDashboard.vue'
import AccountDashboard from '../components/ShipmentDashboard/AccountDashboard/AccountDashboard.vue'
import PickupDashboard from '../components/ShipmentDashboard/PickupDashboard/PickupDashboard.vue'
import PartnerShippingDashboard from '../components/ShipmentDashboard/PartnerShippingDashboard/PartnerShippingDashboard.vue'
import AdminShipmentDashboard from '../components/ShipmentDashboard/AdminShipmentDashboard/AdminShipmentDashboard.vue'
import AdminUserManagementDashboard from '../components/ShipmentDashboard/AdminUserManagementDashboard/AdminUserManagementDashboard.vue'
import CustomerAdminShipmentDashboard from '../components/ShipmentDashboard/CustomerAdminShipmentDashboard/CustomerAdminShipmentDashboard.vue'
import ManifestDashboard from '../components/ShipmentDashboard/ManifestDashboard/ManifestDashboard.vue'
import QueuedActivitiesDashboard from '../components/ShipmentDashboard/QueuedActivitiesDashboard/QueuedActivitiesDashboard.vue'

import {Auth} from 'aws-amplify';

import  { createWebHistory, createRouter }  from 'vue-router'

const routes = [
    {
        path: '/Track/:shipId/:shipZip?',
        name: 'Tracking',
        component: shipmentTrack
    },
    {
        path: '/PartnerShippingDashboard',
        name: 'PartnerShippingDashboard',
        component: PartnerShippingDashboard
    },
    {
        path: '/QueuedActivitiesDashboard',
        name: 'QueuedActivitiesDashboard',
        component: QueuedActivitiesDashboard
    },
    {
        path: '/ManifestDashboard',
        name: 'ManifestDashboard',
        component: ManifestDashboard
    },
    {
        path: '/CreateAnnouncement',
        name: 'CreateAnnouncement',
        component: CreateAnnouncement,
        meta: { auth: true }
    },
    {
        path: '/Ship',
        name: 'Ship',
        component: createShipment,
        meta: { auth: true }
    },
    {
        path: '/ShipmentCenter',
        name: 'ShipmentCenter',
        component: shipmentCenter,
        meta: { auth: true }
    },
    {
        path: '/ShipmentDashboard',
        name: 'ShipmentDashboard',
        component: ShipmentDashboard,
        meta: { auth: true }
    },
    {
        path: '/AdminShipmentDashboard',
        name: 'AdminShipmentDashboard',
        component: AdminShipmentDashboard,
        meta: { auth: true }
    },
    {
        path: '/CustomerAdminShipmentDashboard',
        name: 'CustomerAdminShipmentDashboard',
        component: CustomerAdminShipmentDashboard,
        meta: { auth: true }
    },
    {
        path: '/AdminUserManagementDashboard',
        name: 'AdminUserManagementDashboard',
        component: AdminUserManagementDashboard,
        meta: { auth: true }
    },
    {
        path: '/AnnouncementBoard',
        name: 'AnnouncementBoard',
        component: AnnouncementBoard
    },
    {
        path: '/PickupDashboard',
        name: 'PickupDashboard',
        component: PickupDashboard
    },
    {
        path: '/IndependentContractor',
        name: 'IndependentContractor',
        component: ContractorPage
    },
    {
        path: '/TermsConditions',
        name: 'TermsConditions',
        component: TermsConditions
    },
    {
        path: '/ShippingTC',
        name: 'ShippingTC',
        component: ShippingTC
    },
    {
        path: '/PrivacyPolicy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/CookiesPolicy',
        name: 'CookiesPolicy',
        component: CookiesPolicy
    },
    {
        path: '/CriticalMotionDates',
        name: 'CriticalMotionDates',
        component: CriticalMotionDates
    },
    {
        path: '/HolidaySchedule',
        name: 'HolidaySchedule',
        component: HolidaySchedule
    },
    {
        path: '/FuelSurcharge',
        name: 'FuelSurcharge',
        component: FuelSurcharge
    },
    {
        path: '/AdditionalCharges',
        name: 'AdditionalCharges',
        component: AdditionalCharges
    },
    {
        path: '/ContractorServices',
        name: 'ContractorServices',
        component: ContractorServices
    },
    {
        path: '/ZipShip',
        name: 'ZipShip',
        component: ZipShipContact
    },
    {
        path: '/AddressBook',
        name: 'AddressBook',
        component: AddressBook
    },
    {
        path: '/AddressBookDashboard',
        name: 'AddressBookDashboard',
        component: AddressBookDashboard
    },
    {
        path: '/UserDashboard',
        name: 'UserDashboard',
        component: UserDashboard
    },
    {
        path: '/UserManagementDashboard',
        name: 'UserManagementDashboard',
        component: UserManagementDashboard
    },
    {
        path: '/GeoCodeDashboard',
        name: 'GeoCodeDashboard',
        component: GeoCodeDashboard
    },
    {
        path: '/RouteDashboard',
        name: 'RouteDashboard',
        component: RouteDashboard
    },
    {
        path: '/ProductDashboard',
        name: 'ProductDashboard',
        component: ProductDashboard
    },
    {
        path: '/AccountDashboard',
        name: 'AccountDashboard',
        component: AccountDashboard
    },
    {
        path: '/DropboxDashboard',
        name: 'DropboxDashboard',
        component: DropboxDashboard
    },
    {
        path: '/LocationDashboard',
        name: 'LocationDashboard',
        component: LocationDashboard
    },
    {
        path: '/PlaceDashboard',
        name: 'PlaceDashboard',
        component: PlaceDashboard
    },
    {
        path: '/VehicleDashboard',
        name: 'VehicleDashboard',
        component: VehicleDashboard
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeResolve((to, from, next)=>{
    if(to.meta.auth){
        Auth.currentAuthenticatedUser().then(()=>{
            //Should be redirected
            next()
        }).catch(()=>{
            //The user is not authenticated
            next({
                path: '/Login'
              });
        });
    }else{
        next();
    }
    
});

export default router
<template>
  <embed id="embeded-pdf" :src="baseLabelData" type="application/pdf" width="100%" height="400px">
  <div class="button-container">
    <div class="print-selected">
        <p class="print-selected-text" @click="OpenPDFWindow()">Open label in another window</p>
    </div>
  </div>
</template>

<script>
export default {
    props:['baseLabelData'],
    methods:{
      OpenPDFWindow(){
        const newWindow = window.open();
        newWindow.document.write(
          `<html>
            <head>
              <title>LREX Label</title>
            </head>
            <body>
                <embed src="${this.baseLabelData}" type="application/pdf" width="100%" height="100%">
            </body>
          </html>`
        );
      }
    }
}
</script>

<style scoped>
embed{
    border-radius: 25px;
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}
</style>
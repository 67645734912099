<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <h2>Create Place</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="auto-complete-container">
                            <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                        </div>
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                        <g id="image">
                                            <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                        </g>
                                    </svg>
                                        <h2>Address</h2>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="input.placeCode" placeholder="Place Code">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <select name="routeCodeSelect" v-model="input.pickupRoute" id="routeCodeSelect">
                                            <option disabled selected value="">Pickup Route</option>
                                            <option v-for="(route, index) in routeData.items" :key="index" :value="route.routeCode">{{route.routeCode}}</option>
                                        </select>
                                    </div> 

                                    <!-- <div class="confirmLabelContainer">
                                        <input type="text" disabled v-model="input.pickupRoute" placeholder="Pickup Route">
                                    </div> -->

                                    <div class="confirmLabelContainer">
                                        <input type="text" id="address1" disabled v-model="input.address1" placeholder="Address">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="input.address2" placeholder="Suite/Apt.">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" id="city" disabled v-model="input.city" placeholder="City">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <select disabled required name="state" id="state" v-model="input.state">
                                            <option value="" disabled selected>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" id="zipcode" disabled v-model="input.zipCode" placeholder="Zip Code">
                                    </div>
                                </div>
                            </div>

                            <div class="confirmContainerInner2">
                                <div style="opacity: 0" class="confirm-svg notifications">
                                    <svg data-name="Livello 1" viewBox="10 12.5 40 40" xmlns="http://www.w3.org/2000/svg"><path d="M1 33A32 32 0 1 0 33 1 32 32 0 0 0 1 33Z" fill="none" class="fill-000000"></path><rect height="24.18" rx="1.48" ry="1.48" width="32" x="17" y="21" stroke-miterlimit="10" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></rect><path d="M48.44 21.83 37.53 33.54a6.27 6.27 0 0 1-9.07 0l-10.9-11.7M39.19 31.75l8.96 12.76M26.98 31.88 17.7 44.51" stroke-linejoin="round" fill="none" stroke="#000" stroke-width="2px" class="stroke-ffffff"></path></svg>
                                    <h2>Notifications</h2>
                                </div>

                                <div class="confirmLabelContainer2 checkbox-label-container">
                                    <label for="radius">Radius</label>
                                    <input type="number" id="radius" v-model="input.radius" placeholder="Radius">
                                </div>

                                <div class="confirmLabelContainer2 checkbox-label-container">
                                    <label for="serviceTime">Service Time</label>
                                    <input type="number" id="serviceTime" v-model="input.serviceTime" placeholder="Service Time">
                                </div>

                                <div class="confirmLabelContainer2 checkbox-label-container">
                                    <label for="pickupWindowStart">Pickup Window Start</label>
                                    <input id="pickupWindowStart" type="time" v-model="input.pickupWindowStart">
                                </div>

                                <div class="confirmLabelContainer2 checkbox-label-container">
                                    <label for="pickupWindowEnd">Pickup Window End</label>
                                    <input id="pickupWindowEnd" type="time" v-model="input.pickupWindowEnd">
                                </div>
                            </div>
                        </div>

                    </div>
                <button class="update-user-button" v-if="validateCustomAddress == false" @click="CreatePlaceProcess()">Create Place</button>
                <button class="update-user-button" style="background-color: #33f18a;" @click="checkAddressCorrection()" v-if="validateCustomAddress == true">Check Address</button>
            </div>
        </div>
        <div v-if="(AdminUser || InternalAdmin)" class="track-shipment update-user">
            <place-table :key="refreshKey" :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>

<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="input"/>
</template>

<script>
import {listPlaces} from '../../../graphql/queries'
import {createPlace} from '../../../graphql/mutations'
import {Auth, API} from 'aws-amplify'
import AWSAutoComplete from '../../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import PlaceTable from '../PlaceDashboard/PlaceTable.vue'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'

export default {
    data(){
        return{
            user: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            input:{
                placeCode: "",
                pickupRoute: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                radius: 0,
                serviceTime: 0,
                relevance: "",
                latitude: "",
                longitude: "",
                pickupWindowEnd: "",
                pickupWindowStart: ""
            },
            isContractedPickup: false,
            isCustomerPickup: false,
            isUser: false,
            placeID: null,
            placePickupTime: null,
            refreshKey: 0,
            uploadFile: null,
            uploadFileNoSpaces: "",
            getFileStorageURL: null,
            logoUploadSuccess: false,
            placeData: null,
            routeData: {}
        }
    },
    components:{
        AWSAutoComplete,
        AWSAddressCorrection,
        SignedInUserInfo,
        AlertUser,
        PlaceTable
    },
    watch:{
        'isCustomerPickup'(newValue){
            if(newValue == true){
                this.ListPlaceGraphQL();
            }
        },
        'input.zipCode'(){
            // this.GetGeoCodeGraphQL();
            this.listGeoCodeRouteCodes();
        }
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();
            // this.listRouteCodes();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to place dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async GetGeoCodeGraphQL(){
            try {
                let query = `query getGeoCode{
                    getGeoCodes(geoCode:"${this.input.zipCode}"){
                        routeCode
                    }
                }`
                let geoCodeData = await API.graphql({
                    query: query,
                    authMode: "API_KEY",
                })
                if(geoCodeData.data.getGeoCodes == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "No route code available"
                    return;
                }else{
                    this.input.pickupRoute = geoCodeData.data.getGeoCodes.routeCode;
                }
            } catch (error) {
                console.log(error)
            }
        },
        async listGeoCodeRouteCodes(){
            this.loadingUser = true;
            try {
                let listGeoCodeRouteData = await API.graphql({
                    query: `query listGeoCodes{
                        listGeoCodes(geoCode: "${this.input.zipCode}"){
                            items{
                                routeCode
                            }
                        }
                    }`,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.routeData = listGeoCodeRouteData.data.listGeoCodes;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get route information"
            }finally{
                this.loadingUser = false;
            }
        },
        async CreatePlaceProcess(){
            this.loadingUser = true;
            await this.CreatePlace();
            this.toggleAlertBox = true;
            this.alertMessage = "Created Place"
            document.getElementById('address1').disabled = true;
            document.getElementById('city').disabled = true;
            document.getElementById('state').disabled = true;
            document.getElementById('zipcode').disabled = true;
            this.validateCustomAddress = false;
            this.loadingUser = false;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async ListPlaceGraphQL(){
            // this.loadingUser = true;
            try {
                let placesReturnData = await API.graphql({
                    query: listPlaces,
                    variables: {
                            UserName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.placeData = placesReturnData.data.listPlaces.items;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get places"
            }finally{
                // this.loadingUser = false;
            }
        },
        async CreatePlace(){
            this.loadingUser = true;

            if((this.input.address1.length == 0) || (this.input.city.length == 0) || (this.input.state.length == 0) || (this.input.zipCode.length == 0)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter valid address information."
                this.loadingUser = false;
                throw new Error("Invalid Phone Number");
            }

            try {
                await API.graphql({
                    query: createPlace,
                    variables: {
                        input: this.input
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.refreshKey++
                let obj = this.input;
                for (const key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        obj[key] = '';
                    }
                }
                this.placeID = null;
                this.placePickupTime = null;
                this.isCustomerPickup = false;
                this.isContractedPickup = false;
                
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create place"
                this.loadingUser = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        GetSelectedAddressData(selectedAddress){
            this.input.latitude = selectedAddress.latitude;
            this.input.longitude = selectedAddress.longitude;
            this.input.address1 = selectedAddress.address1;
            this.input.address2 = selectedAddress.address2;
            this.input.zipCode = selectedAddress.zipCode;
            this.input.city = selectedAddress.city;
            this.input.state = selectedAddress.state;
            this.input.relevance = selectedAddress.relevance;
        },
        customAddressToggle(toggleCustom){
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";

            document.getElementById('address1').disabled = false;
            document.getElementById('city').disabled = false;
            document.getElementById('state').disabled = false;
            document.getElementById('zipcode').disabled = false;
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            let customAddressString = this.input.address1 + " " + this.input.city + " " + this.input.state + " " + this.input.zipCode;
            //AddressCorrection Child Component is triggered when customAddress changes values
            this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            this.input.relevance = event.shipmentAddressData.relevance;
            this.input.latitude = event.shipmentAddressData.latitude;
            this.input.longitude = event.shipmentAddressData.longitude;
            this.input.address1 = event.shipmentAddressData.Address1;
            this.input.address2 = event.shipmentAddressData.Address2;
            this.input.zipCode = event.shipmentAddressData.ZipCode;
            this.input.city = event.shipmentAddressData.City;
            this.input.state = event.shipmentAddressData.State;

            if(event.callInsertAddressBook == true){
                // this.CreatePlace();
                this.CreatePlaceProcess();
            }
        },
        useCorrectedAddress(event){
            this.input.relevance = event.shipmentDataValue.relevance;
            this.input.latitude = event.shipmentDataValue.latitude;
            this.input.longitude = event.shipmentDataValue.longitude;
            this.input.address1 = event.shipmentDataValue.Address1;
            this.input.address2 = event.shipmentDataValue.Address2;
            this.input.zipCode = event.shipmentDataValue.ZipCode;
            this.input.city = event.shipmentDataValue.City;
            this.input.state = event.shipmentDataValue.State;

            if(event.callInsertAddressBook == true){
                // this.CreatePlace();
                this.CreatePlaceProcess();
            }
        },
        useEnteredAddress(event){
            if(event.callInsertAddressBook == true){
                // this.CreatePlace();
                this.CreatePlaceProcess();
            }
        },
        cleanAndValidatePhoneNumber(inputPhoneNumber) {
            // Remove all non-digit characters from the input phone number
            const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, '');

            // Check if the cleaned phone number is exactly 10 digits
            if(cleanedPhoneNumber.length == 0){
                return true;
            }else if(cleanedPhoneNumber.length !== 10) {
                return false; // Invalid phone number
            }else{
                this.input.phone = cleanedPhoneNumber;
                return true;
            }
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input[type="time"] {
    padding: 2px;
}


input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    padding: 0;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.auto-pickup-label{
    font-weight: bold;
}

.checkbox-label-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.checkbox-label-container input{
    margin-left: auto;
}

.checkbox-label-container select{
    margin-left: auto;
}

.file-input{
    width: 80%;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.update-user{
    flex-basis: 60%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
<template>
  <div class="our_story">
    <div class="text_our_story">
        <h1>Woman Driven Again</h1>
        <p>Back in 1929, fresh out of NYU Law School, our founder, Frieda Pickman, was bold and undeterred, 
          when she perceived the need of New Jersey’s legal community for a reliable courier service: she 
          founded New Jersey Lawyers Service, NJLS for short. For nearly a century till today NJLS has served 
          the legal community with quick secure local shipping.
            <br>
            <br>
          In 2019 Mary Beth Dixon revived Frieda’s tenacious spirit bringing passion for our client’s business 
          and obsession for every delivery. LRex, Local and Regional Express was born in 2021, a partnership 
          of a century worth of shipping experience and two decades of shipping technology. Our name NJLS was 
          our only box, beyond New Jersey beyond Lawyers Service LRex goes the extra mile for your last-mile.
        </p>
    </div>
  </div>

  <div class="main-about-section" style="display: none">
    <div class="about-section">
      <h1><span>Our founder, </span>Frieda</h1>
      <div class="about-section-text">
        <img src="../assets/FinalImages/Vic_Profile.jpg" alt="">
          <p>Frieda Pickman was born in 1909. She graduated NYU Law School when she was 19. She couldn’t practice 
            law until she turned 21.  The family lore is that in her first case, she represented a Chinese 
            launderer who was being sued by a wealthy customer. The customer’s employee gave the laundered a 
            fur coat to clean. He washed it and ruined it. The defendant won the case.  She worked at a legal courier in NYC.
          </p>

          <p>It was virtually impossible as a woman to find employment as an attorney at that time. Her future husband, 
            Aaron Skinder worked at a competitive legal courier. The two of them, in 1929, crossed the river into NJ and 
            started New Jersey Lawyers Service as partners, servicing the legal community in Essex, Hudson and Union counties. 
            We don’t know whether love came first and then the business, or vice versa, but they married on June 17, 1930.  
            Today, Frieda’s fierce ambition still inspires the LREX team to conquer new territories.
          </p>
      </div>
    </div>
  </div>

  <div class="about-section-container">
  <div class="about-section-inner">
    <div class="about-section-one">
      <div class="about-header-container">
        <img src="../assets/MBbio.jpg" alt="">
      </div>

      <div class="title-container">
        <h2>Mary Beth Dixon<span style="font-weight: 400">, CEO & Owner</span></h2>
        <p>908-557-5170</p>
        <p>mbdixon@lrex.com</p>
      </div>

      <div class="about-text-container">
        <p>Before joining NJLS as 
          its President in 2019, Mary Beth earned a decade 
          of logistic experience with UPS and i-parcel.
        </p>
        <p class="extra-about-text-one">
          An avid entrepreneur, 
              she co-owned and managed a chain of Subway franchises out of college,
              and jumped at the chance to launch LREX with her partner, Vic.
              Mary Beth is privileged to 
              lead the daily operations with her team of talented Rexperts. Mary Beth spends her time away 
              from LREX at the Lake House with her husband, Kevin and her dog, Cassian.
        </p>
      </div>

      <div class="see-more-container">
        <p @click="ShowExtraTextOne()">{{toggleAboutSectionOne}}</p>
      </div>
    </div>

    <div class="about-section-two">
      <div class="about-header-container-two">
        <img src="../assets/FinalImages/Vic_Profile.jpg" alt="">
      </div>

      <div class="title-container">
        <h2>Vic Kanwar<span style="font-weight: 400">, CIO & Owner</span></h2>
        <p>vkanwar@lrex.com</p>
        <br>
      </div>

      <div class="about-text-container">
        <p>After launching eLaw.com in 2000 and revolutionizing electronic 
              calendar tracking, Vic turned his 
              attention to NJLS.
        </p>
        <p class="extra-about-text-two">
          Since then as CIO of Skinder 
              Strauss Vic focused primarily on building and managing platforms for 
              complex cross-functional processes for eLaw 
              and PM Legal. Vic is now back spearheading the 
              development of the LREX platform. No longer an evolutionary change but 
              a transformation “Beyond the box”
        </p>
      </div>

      <div class="see-more-container">
        <p @click="ShowExtraTextTwo()">{{toggleAboutSectionTwo}}</p>
      </div>
    </div>

    <div class="about-section-three">
      <div class="about-header-container-three">
        <img src="../assets/FinalImages/Frank_Napp.jpg" alt="">
      </div>

      <div class="title-container">
        <h2>Frank Nappi<span style="font-weight: 400;">, CSO</span></h2>
        <p>908-686-4651</p>
        <p>frankn@lrex.com</p>
      </div>

      <div class="about-text-container">
        <p>Frank has been with NJLS for over 20 years, 
          building courier sales. Frank was also vice president at Guaranteed Subpoena for 14 years.
        </p>
        <p class="extra-about-text-three">
          Frank is a powerful force in the workplace and uses his positive 
            attitude and tireless energy to encourage others to work hard and 
            succeed. Frank is inspired daily by his wife and their dog Picasso. 
            In his free time, Frank likes to exercise, work on his cars and long walks.
        </p>
      </div>

      <div class="see-more-container">
        <p @click="ShowExtraTextThree()">{{toggleAboutSectionThree}}</p>
      </div>
    </div>
  </div>
</div>

    <div class="headline_recruiting">
      <h1>Putting women behind the wheel.</h1>
      <p>Ready to conquer new territory?</p>
      <img loading="lazy" src="../assets/FinalImages/AdobeStock_WomanDriver.jpeg" alt="">
      <div class="lets-go-button"  @click="()=> DriveWithUsTogglePopup('DriveWithUsButtonTrigger')"><a>Lets Go</a></div>
    </div>

    <div class="popup-container">
      <DriveWithUsPopup 
        v-if="DriveWithUsPopupTriggers.DriveWithUsButtonTrigger" 
        :DriveWithUsTogglePopup="()=> DriveWithUsTogglePopup('DriveWithUsButtonTrigger')"
        class="DriveWithUs-popup">
          
      </DriveWithUsPopup>
    </div>
</template>

<script>
import DriveWithUsPopup from './Popups/DriveWithUsPopup.vue';
import {ref} from 'vue';

export default{
  data(){
    return{
      toggleAboutSectionOne: "See More",
      toggleAboutSectionTwo: "See More",
      toggleAboutSectionThree: "See More"
    }
  },
  methods:{
    ShowExtraTextOne(){
      if(!document.querySelector('.extra-about-text-one').classList.contains('animate-extra-text')){
        document.querySelector('.extra-about-text-one').classList.add('animate-extra-text')
        document.querySelector('.extra-about-text-one').classList.remove('animate-close-extra-text')
        this.toggleAboutSectionOne = "See Less"
      }else{
        setTimeout(() => {
          document.querySelector('.extra-about-text-one').classList.remove('animate-extra-text');
        }, 800);
        document.querySelector('.extra-about-text-one').classList.add('animate-close-extra-text')
        this.toggleAboutSectionOne = "See More"
      }
    },
    ShowExtraTextTwo(){
      if(!document.querySelector('.extra-about-text-two').classList.contains('animate-extra-text')){
        document.querySelector('.extra-about-text-two').classList.add('animate-extra-text')
        document.querySelector('.extra-about-text-two').classList.remove('animate-close-extra-text')
        this.toggleAboutSectionTwo = "See Less"
      }else{
        setTimeout(() => {
          document.querySelector('.extra-about-text-two').classList.remove('animate-extra-text');
        }, 800);
        document.querySelector('.extra-about-text-two').classList.add('animate-close-extra-text');
        this.toggleAboutSectionTwo = "See More"
      }
    },
    ShowExtraTextThree(){
      if(!document.querySelector('.extra-about-text-three').classList.contains('animate-extra-text')){
        document.querySelector('.extra-about-text-three').classList.add('animate-extra-text')
        document.querySelector('.extra-about-text-three').classList.remove('animate-close-extra-text')
        this.toggleAboutSectionThree = "See Less"
      }else{
        setTimeout(() => {
          document.querySelector('.extra-about-text-three').classList.remove('animate-extra-text');
        }, 800);
        document.querySelector('.extra-about-text-three').classList.add('animate-close-extra-text');
        this.toggleAboutSectionThree = "See More"
      }
    },
  },
  components:{
      DriveWithUsPopup
    },
    setup(){
    //Get in touch Popup
    const DriveWithUsPopupTriggers = ref({
      DriveWithUsButtonTrigger: false
    });

    const DriveWithUsTogglePopup = (trigger) =>{
      DriveWithUsPopupTriggers.value[trigger] = !DriveWithUsPopupTriggers.value[trigger]
    }

    return{
      DriveWithUsTogglePopup,
      DriveWithUsPopupTriggers
    }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/**************************/    
/* || Our Story Syles */
.our_story{
  font-family: 'Work Sans', sans-serif;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.text_our_story{
  width: 90vw;
  border-top: black solid 2px;
}

.our_story h1{
  font-size: 4vw;
}

.our_story p{
  font-size: 2vw;
}

/* About Section */
.about-section-container{
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.about-section-inner{
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.about-section-one, .about-section-two, .about-section-three{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-radius: 25px;
  height: 100%;
  width: 30%;
  min-width: 300px;
  min-height: 500px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 15px;
  animation-delay: .5s;
}

.about-header-container{
  background-color: #33f18a;
  border-radius: 25px 25px 0px 0px;
  margin: 0;
  margin-bottom: 50px;

  position: relative;
}

.about-header-container-two{
  background-color: #32ccfe;
  border-radius: 25px 25px 0px 0px;
  margin: 0;
  margin-bottom: 50px;

  position: relative;
}

.about-header-container-three{
  background-color: #fe804d;
  border-radius: 25px 25px 0px 0px;
  margin: 0;
  margin-bottom: 50px;

  position: relative;
}

.about-header-container img, .about-header-container-two img, .about-header-container-three img{
  width: 30%;
  border-radius: 50%;

  border: 4px solid #fff;

  position: relative;
  top: 50px;
}

.title-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title-container h2{
  margin: 2px;
  margin-bottom: 10px;
  font-size: 1.5em;
  padding: 5px 10px;
  background-color: hsla(0,0%,93.7%,.9372549019607843);
  width: fit-content;
  border-radius: 999px;
}

.title-container p{
  margin: 0;
  color: rgb(153, 153, 153);
}

.about-text-container{
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.4em;
}

.extra-about-text-one, .extra-about-text-two, .extra-about-text-three{
  display: none;
  overflow: hidden;
  margin-top: 0;
}

.animate-extra-text{
  margin-top: 0;
  display: block;
  animation: animate-about-text 1s ease;
}

.animate-close-extra-text{
  animation: animate-close-about-text 1s ease;
}

@keyframes animate-about-text{
  0%{
    margin-top: -50%;
    opacity: 0;
  }
  40%{
    opacity: 0;
  }
  100%{
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes animate-close-about-text{
  0%{
    margin-top: 0%;
    opacity: 1;
  }
  20%{
    opacity: 0;
  }
  100%{
    margin-top: -180%;
    opacity: 0;
  }
}

.see-more-container{
  margin-top: auto;
}

.see-more-container p{
  color: rgb(153, 153, 153);
  text-decoration: underline;
  cursor: pointer;
}

/**************************/
/* || Recruiting Syles */
.headline_recruiting{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 15vw;
  position: relative;
}

.headline_recruiting img{
  width: 60vw;
  border-radius: 999px 999px 0 0;
  z-index: 1;
  position: relative;
}

/* || Recruiting Syles */
.headline_recruiting{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  position: relative;
}

.headline_recruiting h1{
  font-family: 'Work Sans', sans-serif;
  font-size: 4.5vw;
  font-weight: bold;
  color: black;
  margin-bottom: 0;
}

.headline_recruiting p{
  justify-content: flex-start;
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  color: black;
}

.headline_recruiting img{
  width: 60vw;
  border-radius: 999px 999px 0 0;
  z-index: 1;
  position: relative;
}

.lets-go-button{
  width: 15vw;
  height: 15vw;
  background-color: #33f18a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  position: absolute;
  top: 38vw;
  z-index: 5;
  transition-duration: .25s;
  cursor: pointer;
}

.lets-go-button:hover{
  background-color: #30e280;
  transition-duration: .25s;
}

.lets-go-button a{
  text-decoration: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  color: black;
}

.sign_up{
  width: 15vw;
  height: 15vw;
  background-color: #33f18a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  position: absolute;
  top: 24vw;
  z-index: 5;
}

.sign_up router-link{
  text-decoration: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  color: black;
  width: 190px;
}

.sign_up a{
  text-decoration: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  color: black;
  width: 190px;
}


/**************************/
@media only screen and (max-width: 675px){
  .about-section-one, .about-section-two, .about-section-three{
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px){
  .about-section-one, .about-section-two, .about-section-three{
    width: 70%;
  }
/**************************/
/* || Header Syles */
  .nav_links li{
        padding: 0px 10px;
        margin-right: auto;
    }

/**************************/
/* || Shipment Tracking Syles */
  .ship_with_us{
    margin-top: 10vh;
    height: 40vh;
    overflow: hidden;
  }

  .ship_with_us h1{
    font-size: 8vw;
  }

  .ship_with_us p{
    font-size: 4vw;
  }

  .ship_with_us img{
    object-fit: cover;
    height: 40vh;
  }

  .ship_with_us_layout{
        right: 15vw;
        margin-bottom: 15vw;
    }

    .ship_with_us_layout button{
        font-size: 4vw;
        width: 40vw;
    }

/**************************/    
/* || Our Story Syles */
.our_story{
  padding-top: 5%;
}

.our_story h1{
  font-size: 8vw;
}

.our_story p{
  font-size: 4vw;
}

/**************************/
/* || Recruiting Syles */
.headline_recruiting{
  margin-bottom: 25vw;
}

.headline_recruiting h1{
  font-size: 8vw;
}

.headline_recruiting p{
  font-size: 4vw;
}

.lets-go-button{
  top: 58vw;
  width: 18vw;
  height: 18vw;
}

.lets-go-button a{
  font-size: 4vw;
  width: 10vw;
}

.sign_up{
  top: 22vw;
  width: 18vw;
  height: 18vw;
}

.sign_up router-link{
  text-decoration: none;
  font-size: 4vw;
  width: 10vw;
}
/**************************/
}

@media only screen and (max-width: 950px){
/**************************/
/* || Shipment Tracking Syles */
  .form_shiptrack{
        right: 15vw;
    }
}
</style>

<template>

<div class="main-container" v-if="showData">
<div class="container">
    <div class="shipment-details-button-container">
      <!-- <button class="shipment-details-button" v-if="showData && shipments.length > 0" @click="ToggleShowData">Close</button> -->
      <div v-if="showData && shipments.length > 0" @click="ToggleShowData" class="x-button-container"><div class="x-button"></div></div>
    </div>
    <div id="shipmentTrackingContents" class="shipment_data" v-for="ship in shipments" v-bind:key="ship"> 
                <img class="logo" src="../../assets/LREXHeaderLogo.jpg" alt="LREX" style="width: 80px;">
                <h3 v-if="shipments[0].shipmentId">Tracking #: {{shipments[0].shipmentId}}</h3>
                <div v-if="shipments[0].priorityService == true">
                  <p>Priority Service</p>
                </div>
                <!-- <div v-if="(shipments[0].priorityService == false) && (shipments[0].pickup == false) && (shipments[0].sameDay == 0)">
                  <p>Next Day Standard</p>
                </div> -->
                <div v-if="shipments[0].sameDay != 0">
                  <p>Same Day</p>
                </div>
                <div v-if="shipments[0].signatureRequired == true">
                  <p>Signature Required</p>
                </div>
                <div v-show="shipments[0].pickup == true">
                  <p>Pickup Service - NJ only</p>
                </div>
                <div v-if="coldStorageData">
                  <p>Cold Storage</p>
                </div>
                <div v-if="shipments[0].shipmentWeight >= 16">
                  <p>Shipment Weight: {{shipments[0].shipmentWeight}}(lbs)</p>
                </div>
                <h3>Ship By</h3>
                <!-- {{shipByAddress}} -->
                <div class="address-container">
                  <div class="address-container-text">
                    <div class="display-address" v-if="toggleEditAddress == false">
                      <p>{{shipments[0].pickupCompanyName}}</p>
                      <p>{{shipments[0].pickupAttention}}</p>
                      <p>{{shipments[0].pickupPhone}}</p>
                      <p>{{shipments[0].pickupAddress1}}</p>
                      <p>{{shipments[0].pickupAddress2}}</p>
                      <p>{{shipments[0].pickupCity}}, {{shipments[0].pickupState}}, {{shipments[0].pickupZipCode}} </p>
                    </div>
                    <div class="address-input" v-if="toggleEditAddress == true">
                      <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder"/>
                      <!-- {{shipByAddress}} -->
                      <label for="companyname">Company Name</label>
                      <input v-model="shipByAddress.pickupCompanyName" id="shipByCompanyName" name="companyname" type="text">
                      <label for="attention">Contact Name</label>
                      <input v-model="shipByAddress.pickupAttention" id="shipByPickupAttention" name="attention" type="text">
                      <label for="phone">Phone</label>
                      <input v-model="shipByAddress.pickupPhone" id="shipByPickupPhone" name="phone" type="text">
                      <label for="address1">Address</label>
                      <input disabled v-model="shipByAddress.pickupAddress1" id="shipByPickupAddress1" name="address1" type="text">
                      <label for="address2">Suite/Floor/Bldg.</label>
                      <input v-model="shipByAddress.pickupAddress2" id="shipByPickupAddress2" name="address2" type="text">
                      <label for="city">City</label>
                      <input disabled v-model="shipByAddress.pickupCity" id="shipByPickupCity" name="city" type="text">
                      <label for="state">State</label>
                      <select disabled required name="state" id="shipByPickupstate" v-model="shipByAddress.pickupState">
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                      </select>
                      <label for="zipcode">Zip Code</label>
                      <input disabled v-model="shipByAddress.pickupZipCode" id="shipByZipCode" name="zipcode" type="text">

                    </div>
                    
                  </div>
                  <div class="address-container-button">
                    <!-- {{shipments[0].shipmentStatusId}} -->
                    <button v-if="toggleEditAddress == false && (IsInTransit) && (Group == 'EditorGroup')" class="main-button-blue-white" @click="toggleShipByEdit()">Edit</button>
                    <button v-if="toggleEditAddress == true && (IsInTransit) && (Group == 'EditorGroup')" class="main-button-blue-white" @click="cancelShipByEdit(), GetAllShipmentData()">Cancel</button>
                    <button v-if="toggleEditAddress == true && (IsInTransit) && (Group == 'EditorGroup')" class="main-button-orange-white" @click="UpdateShipByLogic()">Update</button>
                  </div>
                </div>
                
                <h3>Ship To</h3>
                <!-- {{shipToAddress}} -->
                <div class="address-container">
                  <div class="address-container-text">
                    <div class="display-address" v-if="toggleEditAddressShipTo == false">
                      <p>{{shipments[0].deliveryCompanyName}}</p>
                      <p>{{shipments[0].deliveryAttention}}</p>
                      <p>{{shipments[0].deliveryPhone}}</p>
                      <p>{{shipments[0].deliveryAddress1}}</p>
                      <p>{{shipments[0].deliveryAddress2}}</p>
                      <p>{{shipments[0].deliveryCity}}, {{shipments[0].deliveryState}}, {{shipments[0].deliveryZipCode}} </p>
                    </div>
                    <div class="address-input" v-if="toggleEditAddressShipTo == true">
                      <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                      <!-- {{shipToAddress}} -->
                      <label for="companyname">Company Name</label>
                      <input v-model="shipToAddress.deliveryCompanyName" id="shipToCompanyName" name="companyname" type="text">
                      <label for="attention">Contact Name</label>
                      <input v-model="shipToAddress.deliveryAttention" id="shipToDeliveryAttention" name="attention" type="text">
                      <label for="phone">Phone</label>
                      <input v-model="shipToAddress.deliveryPhone" id="shipToDeliveryPhone" name="phone" type="text">
                      <label for="address1">Address</label>
                      <input disabled v-model="shipToAddress.deliveryAddress1" id="shipToDeliveryAddress1" name="address1" type="text">
                      <label for="address2">Suite/Floor/Bldg.</label>
                      <input v-model="shipToAddress.deliveryAddress2" id="shipToDeliveryAddress2" name="address2" type="text">
                      <label for="city">City</label>
                      <input disabled v-model="shipToAddress.deliveryCity" id="shipToDeliveryCity" name="city" type="text">
                      <label for="state">State</label>
                      <select disabled required name="state" id="shipToDeliverystate" v-model="shipToAddress.deliveryState">
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                      </select>
                      <label for="zipcode">Zip Code</label>
                      <input disabled v-model="shipToAddress.deliveryZipCode" id="shipToZipCode" name="zipcode" type="text">
                    </div>
                  </div>
                  <div class="address-container-button">
                    <button v-if="toggleEditAddressShipTo == false && (IsInTransit) && (Group == 'EditorGroup')" class="main-button-blue-white" @click="toggleShipToEdit()">Edit</button>
                    <button v-if="toggleEditAddressShipTo == true && (IsInTransit) && (Group == 'EditorGroup')" class="main-button-blue-white" @click="cancelShipToEdit(), GetAllShipmentData()">Cancel</button>
                    <button v-if="toggleEditAddressShipTo == true && (IsInTransit) && (Group == 'EditorGroup')" class="main-button-orange-white" @click="UpdateShipToLogic()">Update</button>
                  </div>
                </div>

                <h3 v-if="!shipmentHistoryUnavailable">Package History</h3>

                <table v-if="!shipmentHistoryUnavailable" style="display: none;" class="shipment-table">
                  <tr>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Delivery Image</th>
                  </tr>
                  <tr v-for="(items, index) in shipmentHistoryData" v-bind:key="items">
                    <!-- {{shipmentHistoryData[index]}} -->
                      <td>{{shipmentHistoryData[index].description}}</td>
                      <td>{{shipmentHistoryData[index].processedDate}}</td>
                      <td>{{shipmentHistoryData[index].notes}}</td>
                      <td v-if="!shipmentHistoryData[index].imageURL"></td>
                      <td v-if="shipmentHistoryData[index].imageURL">
                        <div class="delivery-image-container">
                          <button v-if="shipmentHistoryData[index].signatureId > 0" @click="GetDeliveryImages(shipmentHistoryData[index].imageURL, index)" class="main-button-blue-white">Show Image</button>
                          <img loading="lazy" v-if="index == indexImageURL" :src="linkImageURL" alt="">
                        </div>
                      </td>
                  </tr>
                </table>

                <button class="main-button-orange-white" style="display: none;" @click.prevent="PrintDiv()">Print this page</button>

                <div v-if="!shipmentHistoryUnavailable" class="shipment-history-container">
                  <div class="history-container" v-for="(items, index) in shipmentHistoryData" v-bind:key="items">
                    <div class="check-mark-container">
                      <div class="check-mark" :class="{active:index==0, activeLast:index==shipmentHistoryData.length - 1}">
                        <div class="check-mark-item"><img src="../../assets/FinalImages/checkMark.png" alt=""></div>
                      </div>
                    </div>
                    <div class="inner-history-container">
                      <div class="history-description-container">
                        <p class="history-description">{{shipmentHistoryData[index].description}}</p>
                        <p class="history-process-date">{{shipmentHistoryData[index].processedDate}}</p>
                      </div>

                      <div>
                        <p>{{shipmentHistoryData[index].notes}}</p>
                      </div>
                      
                      <div v-if="shipmentHistoryData[index].imageURL && (removeDeliveryImages != true)">
                        <div class="proof-delivery-container">
                          <button v-if="shipmentHistoryData[index].signatureId > 0" @click="GetDeliveryImages(shipmentHistoryData[index].imageURL, index)" class="main-button-blue-white">Show Image</button>
                          <img class="proof-delivery-image" loading="lazy" v-if="index == indexImageURL" :src="linkImageURL" alt="">
                        </div>
                      </div>
                    </div>
                </div>
                <button class="main-button-orange-white" @click.prevent="PrintShipmentPage()">Print this page</button>
                </div>

                <div class="shipmentError">
                  <div v-if="shipmentDataUnavailable">
                      <p>Shipment Data Unavailable.</p>
                  </div>
                  <div v-if="shipmentHistoryUnavailable">
                      <p>Shipment History Unavailable.</p>
                  </div>
              </div>
        </div> 
    </div> 
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 
<AlertUser v-if="toggleCustomAddress" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 
<AWSAddressCorrection @useEnteredAddress="useEnteredAddress($event)" @editEnteredAddress="editEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="shipmentData"/>
</template>

<script>
import axios from 'axios'
import {Auth} from 'aws-amplify';
import AWSAutoComplete from '../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../AWSLocation/AddressCorrection.vue';
import AlertUser from '../Popups/AlertUser.vue';
//http://localhost:8080/Track/24042223

export default {
    components:{
        AWSAutoComplete,
        AlertUser,
        AWSAddressCorrection
    },
    data(){
        return{
            placeHolder: "Search for Update Address",
            shipmentHistoryData: {},
            shipments: {},
            error: {data: []},
            coldStorageData: {},
            showData: false,
            gettingShipmentDetails: true,
            linkImageURL: '',
            indexImageURL: '',
            toggleEditAddress: false,
            toggleEditAddressShipTo: false,
            toggleAlertBox: false,
            alertMessage: 'Error Message',
            shipByAddress: {
              pickupCompanyName: "",
              pickupAttention: "",
              pickupAddress1: "",
              pickupAddress2: "",
              pickupCity: "",
              pickupState: "",
              pickupZipCode: "",
              pickupPhone: "",
              pickupLatitude: "",
              pickupLongitude: "",
              PickupAddressRelevance: "",
              shipmentId: 0
            },
            shipToAddress: {
              deliveryCompanyName: "",
              deliveryAttention: "",
              deliveryAddress1: "",
              deliveryAddress2: "",
              deliveryCity: "",
              deliveryState: "",
              deliveryZipCode: "",
              deliveryPhone: "",
              deliveryLatitude: "",
              deliveryLongitude: "",
              DeliveryAddressRelevance: "",
              shipmentId: 0
            },
            updateShipmentStatus: {
              shipmentId: 0,
              statusId: 0
            },
            selectedAddress: {},
            shipmentHistoryUnavailable: false,
            shipmentDataUnavailable: false,
            toggleCustomAddress: false,
            userCustomAddress: "",
            validateCustomAddress: false,
            shipmentData: {}
        }
    },
    props:{
        shipmentDetailsProp: Object,
        username: String,
        cognitoJWT: String,
        Group: String,
        IsInTransit: Boolean,
        IsInfoReceived: Boolean
    },
    methods: {
      scrollToTop(){
        window.scrollTo(0,0);
      },
      validatePhone(){
        if(this.toggleEditAddress){
          let phone = this.shipByAddress.pickupPhone;
          if((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone) == true)){
              let trimPhone = this.shipByAddress.pickupPhone.replace(/[^0-9]/g, '');
              let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
              if(this.toggleEditAddress){
                this.shipByAddress.pickupPhone = trimPhoneDashes;
              }

              return true;
          }else if(phone === ""){
            if(this.toggleEditAddress){
              this.shipByAddress.pickupPhone = "";
            }
            return true;
          }else{
              this.toggleAlertBox = true;
              this.alertMessage = "Please enter a valid phone number.";
              this.scrollToTop();
              return false;
          }
        }

        if(this.toggleEditAddressShipTo){
          let phone = this.shipToAddress.deliveryPhone;
          if((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone) == true)){
              let trimPhone = this.shipToAddress.deliveryPhone.replace(/[^0-9]/g, '');
              let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
              if(this.toggleEditAddress){
                this.shipToAddress.deliveryPhone = trimPhoneDashes;
              }

              return true;
          }else if(phone === ""){
            if(this.toggleEditAddress){
              this.shipToAddress.deliveryPhone = "";
            }
            return true;
          }else{
              this.toggleAlertBox = true;
              this.alertMessage = "Please enter a valid phone number.";
              this.scrollToTop();
              return false;
          }
        }
      },
      validateZipCode(){
        if(this.toggleEditAddress){
          let zipCode = this.shipByAddress.pickupZipCode;
          if(zipCode.length == 5){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid zip code.";
            this.scrollToTop();
            return false;
          }
        }

        if(this.toggleEditAddressShipTo){
          let zipCode = this.shipToAddress.deliveryZipCode;
          if(zipCode.length == 5){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid zip code.";
            this.scrollToTop();
            return false;
          }
        }
      },
      validateAddress(){
        if(this.toggleEditAddress){
          let address1 = this.shipByAddress.pickupAddress1;
          if(address1 != ""){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid address.";
            this.scrollToTop();
            return false;
          }
        }

        if(this.toggleEditAddressShipTo){
          let address1 = this.shipToAddress.deliveryAddress1;
          if(address1 != ""){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid address.";
            this.scrollToTop();
            return false;
          }
        }
      },
      validateCity(){
        if(this.toggleEditAddress){
          let city = this.shipByAddress.pickupCity;
          if(city != ""){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid city.";
            this.scrollToTop();
            return false;
          }
        }

        if(this.toggleEditAddressShipTo){
          let city = this.shipToAddress.deliveryCity;
          if(city != ""){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid city.";
            this.scrollToTop();
            return false;
          }
        }
      },
      validateState(){
        if(this.toggleEditAddress){
          let state = this.shipByAddress.pickupState;
          if(state != ""){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid state.";
            this.scrollToTop();
            return false;
          }
        }

        if(this.toggleEditAddressShipTo){
          let state = this.shipToAddress.deliveryState;
          if(state != ""){
            return true;
          }else{
            this.toggleAlertBox = true;
            this.alertMessage = "Please enter a valid state.";
            this.scrollToTop();
            return false;
          }
        }
      },
      closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
            this.toggleCustomAddress = toggleAlertBox;
            //this.ToggleShowData();
      },
      /**
       * If Ship To Edit is toggled set shipToAddress to current shipment data
       */
      toggleShipToEdit(){
        this.toggleEditAddressShipTo = true;
        this.toggleEditAddress = false;
        this.toggleCustomAddress = false;

        this.shipToAddress.deliveryCompanyName = this.shipments[0].deliveryCompanyName;
        this.shipToAddress.deliveryAttention = this.shipments[0].deliveryAttention;
        this.shipToAddress.deliveryAddress1 = this.shipments[0].deliveryAddress1;
        this.shipToAddress.deliveryAddress2 = this.shipments[0].deliveryAddress2;
        this.shipToAddress.deliveryCity = this.shipments[0].deliveryCity;
        this.shipToAddress.deliveryState = this.shipments[0].deliveryState;
        this.shipToAddress.deliveryZipCode = this.shipments[0].deliveryZipCode;
        this.shipToAddress.deliveryPhone = this.shipments[0].deliveryPhone;
        this.shipToAddress.deliveryLatitude = this.shipments[0].deliveryLatitude;
        this.shipToAddress.deliveryLongitude = this.shipments[0].deliveryLongitude;
        this.shipToAddress.shipmentId = this.shipments[0].shipmentId;
      },
      cancelShipToEdit(){
        this.toggleEditAddressShipTo = false;
        this.toggleCustomAddress = false;

        Object.keys(this.shipToAddress).forEach(key => {
          this.shipToAddress[key] = "";
        });
      },
      /**
       * If Ship By Edit is toggled set shipToAddress to current shipment data
       */
      toggleShipByEdit(){
        this.toggleEditAddressShipTo = false;
        this.toggleEditAddress = true;
        this.toggleCustomAddress = false;

        this.shipByAddress.pickupCompanyName = this.shipments[0].pickupCompanyName;
        this.shipByAddress.pickupAttention = this.shipments[0].pickupAttention;
        this.shipByAddress.pickupAddress1 = this.shipments[0].pickupAddress1;
        this.shipByAddress.pickupAddress2 = this.shipments[0].pickupAddress2;
        this.shipByAddress.pickupCity = this.shipments[0].pickupCity;
        this.shipByAddress.pickupState = this.shipments[0].pickupState;
        this.shipByAddress.pickupZipCode = this.shipments[0].pickupZipCode;
        this.shipByAddress.pickupPhone = this.shipments[0].pickupPhone;
        this.shipByAddress.pickupLatitude = this.shipments[0].pickupLatitude;
        this.shipByAddress.pickupLongitude = this.shipments[0].pickupLongitude;
        this.shipByAddress.shipmentId = this.shipments[0].shipmentId;
      },
      cancelShipByEdit(){
        this.toggleEditAddress = false;
        this.toggleCustomAddress = false;

        Object.keys(this.shipByAddress).forEach(key => {
          this.shipByAddress[key] = "";
        });
      },
      setCustomAddress(event){
        if(this.toggleEditAddress){
          this.shipByAddress.PickupAddressRelevance = event.shipmentAddressData.relevance;
          this.shipByAddress.pickupAddress1 = event.shipmentAddressData.Address1;
          this.shipByAddress.pickupCity = event.shipmentAddressData.City;
          this.shipByAddress.pickupState = event.shipmentAddressData.State;
          this.shipByAddress.pickupZipCode = event.shipmentAddressData.ZipCode;
          this.shipByAddress.pickupLatitude = event.shipmentAddressData.latitude;
          this.shipByAddress.pickupLongitude = event.shipmentAddressData.longitude;

          if(event.callStepNext){
            this.EditShipBy();
            this.validateCustomAddress = false;
          }
        }

        if(this.toggleEditAddressShipTo){
          this.shipToAddress.DeliveryAddressRelevance = event.shipmentAddressData.relevance;
          this.shipToAddress.deliveryAddress1 = event.shipmentAddressData.Address1;
          this.shipToAddress.deliveryCity = event.shipmentAddressData.City;
          this.shipToAddress.deliveryState = event.shipmentAddressData.State;
          this.shipToAddress.deliveryZipCode = event.shipmentAddressData.ZipCode;
          this.shipToAddress.deliveryLatitude = event.shipmentAddressData.latitude;
          this.shipToAddress.deliveryLongitude = event.shipmentAddressData.longitude;

          if(event.callStepNext){
            this.EditShipTo();
            this.validateCustomAddress = false;
          }
        }
      },
      useCorrectedAddress(event){
        if(this.toggleEditAddress){
          this.shipByAddress.PickupAddressRelevance = event.shipmentDataValue.relevance;
          this.shipByAddress.pickupLatitude = event.shipmentDataValue.latitude;
          this.shipByAddress.pickupLongitude = event.shipmentDataValue.longitude;
          this.shipByAddress.pickupAddress1 = event.shipmentDataValue.Address1;
          this.shipByAddress.pickupZipCode = event.shipmentDataValue.ZipCode;
          this.shipByAddress.pickupCity = event.shipmentDataValue.City;
          this.shipByAddress.pickupState = event.shipmentDataValue.State;

          if(event.callStepNext == true){
            this.EditShipBy();
            this.validateCustomAddress = false;
          }
        }

        if(this.toggleEditAddressShipTo){
          this.shipToAddress.DeliveryAddressRelevance = event.shipmentDataValue.relevance;
          this.shipToAddress.deliveryLatitude = event.shipmentDataValue.latitude;
          this.shipToAddress.deliveryLongitude = event.shipmentDataValue.longitude;
          this.shipToAddress.deliveryAddress1 = event.shipmentDataValue.Address1;
          this.shipToAddress.deliveryZipCode = event.shipmentDataValue.ZipCode;
          this.shipToAddress.deliveryCity = event.shipmentDataValue.City;
          this.shipToAddress.deliveryState = event.shipmentDataValue.State;

          if(event.callStepNext == true){
            this.EditShipTo();
            this.validateCustomAddress = false;
          }
        }
      },
      useEnteredAddress(event){
        this.toggleCustomAddress = true;
        this.alertMessage = "Using custom addresses may incur additional costs.";
        if(this.toggleEditAddress){
          if(event.callStepNext == true){
            this.EditShipBy();
            this.toggleCustomAddressWarning = true;
          }
        }

        if(this.toggleEditAddressShipTo){
          if(event.callStepNext == true){
            this.EditShipTo();
            this.toggleCustomAddressWarning = true;
          }
        }
      },
      editEnteredAddress(event){
        this.editAddressSelected = true;
        if(event.customAddress){
          this.userCustomAddress = event.customAddress;
        }
      },
      /**
       * Call to AddressAutoComplete child component
       * Set shipToAddress object to given user input
       * Set shipByAddress object to given user input
       */
      GetSelectedAddressData(selectedAddress){
        if(this.toggleEditAddressShipTo == true){
          this.shipToAddress.deliveryAddress1 = selectedAddress.address1;
          this.shipToAddress.deliveryCity = selectedAddress.city;
          this.shipToAddress.deliveryState = selectedAddress.state;
          this.shipToAddress.deliveryZipCode = selectedAddress.zipCode;
          this.shipToAddress.deliveryLatitude = selectedAddress.latitude.toString();
          this.shipToAddress.deliveryLongitude = selectedAddress.longitude.toString();
          this.shipToAddress.DeliveryAddressRelevance = selectedAddress.relevance;
          this.ShipToAddressEdit();
        }

        if(this.toggleEditAddress == true){
          this.shipByAddress.pickupAddress1 = selectedAddress.address1;
          this.shipByAddress.pickupCity = selectedAddress.city;
          this.shipByAddress.pickupState = selectedAddress.state;
          this.shipByAddress.pickupZipCode = selectedAddress.zipCode;
          this.shipByAddress.pickupLatitude = selectedAddress.latitude.toString();
          this.shipByAddress.pickupLongitude = selectedAddress.longitude.toString();
          this.shipByAddress.PickupAddressRelevance = selectedAddress.relevance;
          this.ShipByAddressEdit()
        }
      },
      customAddressToggle(toggleCustom){
        this.validateCustomAddress = toggleCustom;
        this.scrollToTop();

        if(this.toggleEditAddress == true){
          document.getElementById('shipByPickupAddress1').disabled = false;
          document.getElementById('shipByPickupCity').disabled = false;
          document.getElementById('shipByPickupstate').disabled = false;
          document.getElementById('shipByZipCode').disabled = false;

          Object.keys(this.shipByAddress).forEach(key => {
            if(key === "pickupLatitude" || key === "pickupLongitude" || key === "pickupAddress1" ||  key === "pickupCity" ||  key === "pickupZipCode" ||  key === "pickupState" || key === "PickupAddressRelevance"){
              this.shipByAddress[key] = "";
            }
          });
        }
        if(this.toggleEditAddressShipTo == true){
          document.getElementById('shipToDeliveryAddress1').disabled = false;
          document.getElementById('shipToDeliveryCity').disabled = false;
          document.getElementById('shipToDeliverystate').disabled = false;
          document.getElementById('shipToZipCode').disabled = false;

          Object.keys(this.shipToAddress).forEach(key => {
            if(key === "deliveryLatitude" || key === "deliveryLongitude" || key === "deliveryAddress1" ||  key === "deliveryCity" ||  key === "deliveryZipCode" ||  key === "deliveryState" || key === "DeliveryAddressRelevance"){
              this.shipToAddress[key] = "";
            }
          });
        }
      },
      UpdateAddressStatus(shipmentId, statusId){
        axios.post('https://api.stage.njls.com/api/Rest/UpdateShipmentStatus', { shipmentId: shipmentId, statusId: statusId}, 
              {headers:{
                'User': this.username,
                // get the user's JWT token given to it by AWS cognito 
                'Authorization': `Bearer ${this.cognitoJWT}`
              }})
            .then((response) => {
              if(response){
                this.GetAllShipmentData();
                }
              })
            .catch(error => {
              if(error.response.status == '401'){
                  Auth.signOut({global: true})
              }else{
                  alert(error)
              }
            })
      },
      UpdateShipByLogic(){
        if(this.validateCustomAddress == true){
          this.shipmentData = this.shipByAddress;
          this.userCustomAddress = this.shipByAddress.pickupAddress1 + " " + this.shipByAddress.pickupCity + " " + this.shipByAddress.pickupState + " " + this.shipByAddress.pickupZipCode;
        }else{
          this.EditShipBy();
        }
      },
      UpdateShipToLogic(){
        if(this.validateCustomAddress == true){
          this.shipmentData = this.shipToAddress;
          this.userCustomAddress = this.shipToAddress.deliveryAddress1 + " " + this.shipToAddress.deliveryCity + " " + this.shipToAddress.deliveryState + " " + this.shipToAddress.deliveryZipCode;
        }else{
          this.EditShipTo();
        }
      },
      //Edit Address Information
      async EditShipBy(){
        let validPhone = this.validatePhone();
        let validZipCode = this.validateZipCode();
        let validState = this.validateState();
        let validCity = this.validateCity();
        let validAddress = this.validateAddress();
        
        if(validPhone && validZipCode && validState && validCity && validAddress){
          await axios.post('https://api.stage.njls.com/api/Rest/UpdateShipBy', this.shipByAddress, 
                {headers:{
                  'User': this.username,
                  // get the user's JWT token given to it by AWS cognito 
                  'Authorization': `Bearer ${this.cognitoJWT}`
                }})
              .then((response) => {
                if(response){
                  //53 is statusId for 'Edited'
                  this.UpdateAddressStatus(this.shipByAddress.shipmentId, 53)
                  //this.GetAllShipmentData();
                  this.toggleEditAddress = false;
                  }
                })
              .catch(error =>{
                if(error.response.status == '401'){
                    Auth.signOut({global: true})
                }else{
                    alert(error)
                }
              }
          );
        }
      },
      async EditShipTo(){
        let validPhone = this.validatePhone();
        let validZipCode = this.validateZipCode();
        let validState = this.validateState();
        let validCity = this.validateCity();
        let validAddress = this.validateAddress();
        
        if(validPhone && validZipCode && validState && validCity && validAddress){
          await axios.post('https://api.stage.njls.com/api/Rest/UpdateShipTo', this.shipToAddress, 
                {headers:{
                  'User': this.username,
                  // get the user's JWT token given to it by AWS cognito 
                  'Authorization': `Bearer ${this.cognitoJWT}`
                }})
              .then((response) => {
                if(response){
                  //53 is statusId for 'Edited'
                  this.UpdateAddressStatus(this.shipToAddress.shipmentId, 53)
                  //this.GetAllShipmentData();
                  this.toggleEditAddressShipTo = false;
                  }
                })
              .catch(error => {
              if(error.response.status == '401'){
                  Auth.signOut({global: true})
              }else{
                  alert(error)
              }
            }
          )
        }
      },
      ToggleShowData(){
        this.$emit('toggleShowData', this.showData = false)
      },
      PrintDiv(){
        var divContents = document.getElementById("shipmentTrackingContents").innerHTML;
        var a = window.open('', '', 'height=1000, width=1000');
        a.document.write('<html>');
        a.document.write('<head><style> body{font-family: sans-serif; font-size: 16px;} h3{border-bottom:2px solid black} button,.proofDelivery,.locationDelivery{display: none;} th,td{border: 1px solid black;} th{background-color: #33f18a} table{width: 70%;}</style></head>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
      },
      PrintShipmentPage(){
        var divContents = document.getElementById("shipmentTrackingContents").innerHTML;
        var a = window.open('LREX', 'LREX', 'height=1000, width=1000');
        a.document.write('<html>');
        a.document.write('<head>'
          + '<title>LREX Shipment Information</title>'
          +'<style> '
          + 'body{font-family: sans-serif; font-size: 16px;}'
          + '.shipment-information-inner p{ margin-top: 0 !important; margin-bottom: 0 !important; }'
          + '.shipment-information{display: flex !important; padding: 0 !important;}'
          + '.shipment-information p{margin-right: 5px; padding-right: 5px; border-right: 2px solid black;}'
          + '.additional-information{display: none;}'
          + '.input-zip-container{display: none;}'
          + '.proof-delivery-image{width: 15%;}' 
          + '.inner-history-container{border: 1px solid #b3b3b3;border-radius: 15px;padding: 15px;margin: 15px;margin-left: 12.5%; width: 50%;}'
          + '.check-mark.active{display: none;}'
          + '.check-mark.activeLast{display: none;}'
          + '.check-mark{display: none;}'
          + '.check-mark-item{display: none;}'
          + '.check-mark-item img{ display: none;}'
          + '.check-mark-container{display: none;}'
          + '.history-description{font-weight: bold;}'
          + '.history-description-container{display: flex;flex-direction: row;align-items: center;justify-content: space-between;}'
          + '.history-process-date{background: #f0f0f0;color: #858585;border-radius: 2.5px;padding: 2px;}'
          + '.history-container{position: relative;display: flex;justify-content: flex-start;align-items: center;}'
          + 'h3{border-bottom:2px solid black}'
          + 'button,.proofDelivery,.locationDelivery{display: none;}'
          + 'th,td{border: 1px solid black;}'
          + 'th{background-color: #33f18a}' 
          + 'table{width: 70%;}'
          + '</style>'
          + '</head>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');

        setTimeout(function () {
          a.document.close();
          a.focus();
          a.print();
          a.close(); 
        }, 1000);
      },
      GetDeliveryImages(imageURL, indexImageURL){
        axios.get(imageURL).then((response)=>{
          this.indexImageURL = indexImageURL;
          this.linkImageURL = response.data;
        }).catch((err)=>{alert(err + "\n" + "Request file does not exist.")})
      },
      GetShipmentHistoryByID() {
        axios.post('https://api.stage.njls.com/api/rest/GetShipmentHistoryByShipmentIdCognito', this.shipmentDetailsProp, 
          {headers:{
            'User': this.username,
            // get the user's JWT token given to it by AWS cognito 
            'Authorization': `Bearer ${this.cognitoJWT}`
          }})
        .then((response) => {
          console.log(response.data)
          if(response.data.shipmentHistory){
            this.shipmentHistoryData = response.data.shipmentHistory;
            this.coldStorageData = response.data.coldstorageTable[0];
          }else{
            this.shipmentHistoryUnavailable = true;
          }
          })
        .catch(error => {
            if(error.response.status == '401'){
                Auth.signOut({global: true})
            }else{
                alert(error)
            }
          })
        },
      GetShipmentByID() {
        //this.headerMessage = "Getting Shipment Details";
        axios.post('https://api.stage.njls.com/api/rest/GetShipmentByShipmentIdCognito', this.shipmentDetailsProp, 
            {headers:{
                'User': this.username,
                // get the user's JWT token given to it by AWS cognito 
                'Authorization': `Bearer ${this.cognitoJWT}`
              }})
            .then((response) => {
              if(response.data.shipment){
                this.shipments = response.data.shipment;
                this.showData = true;
              }else if(response.data.error != 0){
                this.error = response.data.error;
                this.shipmentDataUnavailable = true;
                // alert("Unable to get shipment data.")
                // window.location.reload();
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get shipment data."
              }
              })
            .catch((error) => {
                if(error.response.status == '401'){
                    Auth.signOut({global: true})
                }else{
                    alert(error)
                }
            })
            .finally(()=> this.gettingShipmentDetails = false)
        },
        GetAllShipmentData(){
            this.GetShipmentByID();
            this.GetShipmentHistoryByID();
        }
    },
    mounted(){
        this.GetAllShipmentData();
    }
}
</script>

<style scoped>
@import "../../styles/button-styles.css";

/* Main Container */
    .main-container{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 2.5%;
      margin-top: 2.5%;
      z-index: 10;
      animation: shipment-details-animate 1s ease;
    }

    @keyframes shipment-details-animate {
        from{
            margin-top: -2%;
        }
        to{
            margin-top: 2.5%;
        }
    }

    .container{
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: left;
      flex-direction: column;
    }

    .shipment_data{
      display: block;
      margin: auto;
      margin-bottom: 10%;
      width: 65%;
      padding: 15px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 100px rgba(0, 0, 0, 0.9);
    } 

    .shipment_data h3{
      padding: 5px 10px;
      border-radius: 50px;
      background-color: #33f18a;
      border-bottom: 1px solid #33f18a;
      width: 95%;
    }

    /* Address Inforamation */
    .address-container{
      width: 95%;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }

    .address-container-text{
      margin-right: auto;
      width: 50%;
    }

    .shipment_data p{
      margin-top: 0;
      margin-bottom: 0;
    }

    .address-container-button{
      width: 25%;
      display: flex;
      justify-content: space-evenly;
    }

    .address-input{
      display: flex;
      flex-direction: column;
    }

    .address-input label{
      margin-top: 10px;
    }

    .address-input input, select{
      width: 60%;
      padding: 10px;
      font-size: 15px;
      border: 1px solid rgba(0, 0, 0, 0.336);
      border-radius: 5px;
      outline: none;
    }

    /* History Check List */

    .inner-history-container{
      border: 1px solid #b3b3b3;
      border-radius: 15px;
      padding: 15px;
      margin: 15px;
      margin-left: 12.5%;
      width: 50%;
    }

.history-container{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.check-mark.active{
  background: linear-gradient(
    to bottom, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.check-mark.activeLast{
  background: linear-gradient(
    to top, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.proof-delivery-image{
  width: 50%;
  border-radius: 5px;
}

.proof-delivery-container{
  display: flex;
  flex-direction: column;
}

.proof-delivery-container button{
  width: 140px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.check-mark{
  background-color:#33f18a;
  color: #fff;
  width: 2.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}

.check-mark-item{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #33f18a;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.check-mark-item img{
  width: 20px;
}

.check-mark-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-description{
  font-weight: bold;
}

.history-process-date{
  background: #f0f0f0;
  color: #858585;
  border-radius: 2.5px;
  padding: 2px;
}

.history-description-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

p{
  margin-top: 0;
  margin-bottom: 0;
}

.delivery-image-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delivery-image-container img{
  width: 40%;
  margin-top: 10px;
}

    /* Shipment Error */

    .shipmentError{
      padding: 15px;
    }

    .shipment-table{
      width: 100%;
      margin-bottom: 15px;
    }

    .shipment-table th{
      background-color: #33f18a;
    }

    .shipment-table td, th{
      padding: 5px;
      border: 1px solid #ddd;
    }

    .logo{
      width: 80px;
      margin-bottom: -10px;
      margin-left: 5px;
    }

    .shipment-details-button-container{
      width: 80%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: 30px;
    }

    .shipment-details-button{
      z-index: 11;
      border: none;
      margin: 1px;
      background-color: #32ccfe;
      padding: 12px 15px;
      color: #ffffff;
      border-radius: 50px;
      cursor: pointer;
      transition-duration: .5s;
    }

    .shipment-details-button:hover{
      background-color: #2cb3e0;
      transition-duration: .5s;
    }

    .delivery-image-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .delivery-image-container img{
      width: 40%;
      margin-top: 10px;
    }

    /* AWS Location Service */
    .fa-map-pin{
      color: #999;
      margin-right: 5px;
      font-size: 1em;
    }
    .autocomplete-result{
      background-color: #fff;
      width: 90%;
      border-radius: 5px;
      text-align: left;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.062);
      margin-bottom: 10px;
    }

    @keyframes animate-result {
      from{
          margin-top: -5px;
      }
      to{
          margin-top: 0;
      }
    }

    .autocomplete-result p{
      cursor: pointer;
      transition-duration: .5s;
      margin: 0;
      padding: 10px;
      font-size: 12px;
      animation: animate-result .5s ease;
      border-bottom: 1px solid rgb(235, 235, 235);
    }

    .autocomplete-result p:hover{
      background-color: rgb(235, 235, 235);
      transition-duration: .5s;
    }

    @media only screen and (max-width: 600px){
      .container{
        font-size: 12px;
      }

      .address-container-button{
        width: 30%;
        height: 50px;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
      }

      .address-container-text{
        width: 70%;
      }

      .delivery-image-container img{
        width: 80%;
      }
    }

    @media only screen and (max-width: 1000px){
      .shipment_data{
        width: 75%;
      }

      .address-input input, select{
        width: 80%;
      }
    }
</style>
<template>
  <div class="main-container">
    <div class="inner-container">
            <div class="inner-track-shipment-container">
                <signed-in-user-info :user="user" :userData="userData" />
                <div class="track-shipment">
                    <div class="header-track-shipment">
                        <div class="header-track-inner">
                                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91 128.83">
                                    <g id="Layer_2-2" data-name="Layer 2">
                                        <g id="_Ñëîé_1" data-name=" Ñëîé 1">
                                        <g>
                                            <path d="M87,45.5c0,33.5-41.5,77.5-41.5,77.5,0,0-41.5-44-41.5-77.5C4,22.58,22.58,4,45.5,4s41.5,18.58,41.5,41.5Z" style="fill: none; stroke: #000; stroke-miterlimit: 10; stroke-width: 8px;"/>
                                            <circle cx="45" cy="45" r="19" style="fill: none; stroke: #000; stroke-miterlimit: 10; stroke-width: 8px;"/>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            <p>Track Shipment</p>
                        </div>
                    </div>
                    <div class="shipment-id-container">
                        <div class="input-shipment-id">
                            <form @submit.prevent="trackShipment()">
                                <input v-model="trackShipmentID" type="text">
                            </form>
                            <button @click="trackShipment()">Track</button>
                        </div>
                    </div>
                </div>
                <div class="track-shipment weather">
                    <weather-widget :userData="userData"/>
                </div>
            </div>
        <div class="data-container-main">
            <div class="table-container">
                <queued-activities-table @refreshAfterVoid="refreshAfterVoid($event)" :selectedAccountNo="selectedAccountNo" :cognitoUser="user" :selectedShipment="selectedShipment" :selectedDates="selectedDates" :jwtToken="jwtToken" :userData="userData"/>
            </div>
        </div>
    </div>
  </div>

  <AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 
  <shipment-info v-if="toggleTrackShipment" @closeShipmentInfo="closeShipmentInfo($event)" :cognitoUser="user" :trackShipmentID="trackShipmentID"/>
</template>

<script>
import QueuedActivitiesTable from './QueuedActivitiesTable.vue'
import WeatherWidget from './../WeatherWidget.vue'
import ShipmentInfo from './../ShipmentInfo.vue'
import SignedInUserInfo from '../../ShipmentDashboard/UserDashboard/SignedInUserInfo.vue'
import {Auth, API} from 'aws-amplify'
import AlertUser from '../../Popups/AlertUser.vue'

export default {
    components:{
        QueuedActivitiesTable,
        WeatherWidget,
        ShipmentInfo,
        AlertUser,
        SignedInUserInfo
    },
    data(){
        return{
            selectedShipment: 'In Transit',
            user: {},
            userData: {},
            trackShipmentID: null,
            toggleTrackShipment: false,
            selectedDates: null,
            refreshAfterVoidBool: false,
            loadingUser: false,
            jwtToken: '',
            toggleAlertBox: false,
            alertMessage: "",
            allAccountNumbers: [],
            selectedAccountNo: "",
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false
        }
    },
    methods:{
        selectAccountNo(accountNo){
            this.selectedAccountNo = accountNo;
        },
        trackShipment(){
            this.toggleTrackShipment = true;
        },
        async closeShipmentInfo(event){
            this.toggleTrackShipment = event;
        },
        selectedShipmentType(event){
            this.selectedShipment = event;
        },
        selectedBetweenDates(event){
            this.selectedDates = event;
        },
        refreshAfterVoid(event){
            this.refreshAfterVoidBool = event;
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: 
                    `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        }
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })

                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to admin dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
}
</script>

<style scoped>
select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

.list-account-select{
    width: 80%;
    display: block;
    margin: auto;
}

.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}
html{
    background-color: #f3f3f3;
}

.dashboard-container{
    display: flex;
    flex-direction: row;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
}

.data-container-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.inner-track-shipment-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


.table-container{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    height: fit-content;
    flex-basis: 50%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.data-container{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.weather{
    padding: 0;
    width: fit-content;
    background-color: transparent;
    box-shadow: none;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.svg-container{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-track-inner svg{
    height: 18px;
    margin: 0;
}

.shipment-id-container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.input-shipment-id{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.input-shipment-id input{
    border: 1px solid #e2e2e2;
    border-right: none;
    padding: 5px;
    border-radius: 25px 0px 0px 25px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    outline: none;
}

.input-shipment-id button{
    cursor: pointer;
    border: 1px solid #e2e2e2;
    background-color: #32ccfe;
    color: #fff;
    padding: 5px;
    border-radius: 0px 10px 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    transition-duration: .5s;
}

.input-shipment-id button:hover{
    background-color: #2baeda;
    transition-duration: .5s;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}


@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 40%;
    }

    .data-container{
        flex-basis: 40%;
    }

    .table-container{
        flex-basis: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .table-container{
        flex-basis: 90%;
        font-size: 10px;
    }

    .track-shipment{
        flex-basis: 80%;
    }
}


</style>
<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.75 148.87">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M154.27,12.53c.87-.17,1.78,0,2.54,.46,1.29,.53,2.2,1.79,2.2,3.28l.74,103.46c.07,1.27-.56,2.54-1.73,3.22l-42.25,25.21c-.6,.46-1.34,.72-2.15,.72-.2,0-.39-.01-.57-.05L3.39,136.62c-1.89-.08-3.39-1.64-3.39-3.55V27.85H0c-.04-1.4,.75-2.77,2.13-3.37L57.14,.31h0c.57-.26,1.22-.36,1.9-.27l95.23,12.49h0Zm-37.27,30.93v96.48l36-21.47-.7-97.26-35.3,22.25h0Zm-7,97.91V45l-46.8-5.97-1.54,44.72-16.43-11.19-16.43,9.29,3.4-46.78-25.19-3.21v98.07l103,11.44h0ZM102.56,12.88l-34.52,19.59,44.9,5.72,31.47-19.83-41.86-5.49h0ZM36.56,28.46L70.02,8.61l-11.03-1.44L16.4,25.89l20.15,2.56h0Z"/>
                                            </g>
                                        </svg>
                                        <h2>Create Product</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="service-levels-inner">
                                        <div class="confirmLabelContainer">
                                            <select name="routeCodeSelect" v-model="productInput.productCode" id="routeCodeSelect">
                                                <option disabled selected value="">Product Code</option>
                                                <option v-for="(product, index) in productCodeData.items" :key="index" :value="product.productCode">{{product.productCode}}</option>
                                            </select>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <select name="routeCodeSelect" v-model="productInput.serviceCode" id="routeCodeSelect">
                                                <option disabled selected value="">Service Code</option>
                                                <option v-for="(service, index) in serviceCodeData.items" :key="index" :value="service.serviceCode">{{service.serviceCode}}</option>
                                            </select>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <select name="routeCodeSelect" v-model="productInput.serviceNetwork" id="routeCodeSelect">
                                                <option disabled selected value="">Service Network</option>
                                                <option v-for="(network, index) in serviceNetworkData.items" :key="index" :value="network.serviceNetwork">{{network.serviceNetwork}}</option>
                                            </select>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <select name="routeCodeSelect" v-model="productInput.vendor" id="routeCodeSelect">
                                                <option disabled selected value="">Vendor</option>
                                                <option v-for="(vendor, index) in vendorData.items" :key="index" :value="vendor.vendor">{{vendor.vendor}}</option>
                                            </select>
                                        </div>

                                        <div v-if="productInput.additionalService == false" class="confirmLabelContainer">
                                            <select name="routeCodeSelect" v-model="productInput.productFamily" id="routeCodeSelect">
                                                <option disabled selected value="null">Product Family</option>
                                                <option v-for="(family, index) in productFamilyData.items" :key="index" :value="family.productFamily">{{family.productFamily}}</option>
                                            </select>
                                        </div>

                                        <div v-if="productInput.additionalService == false" class="confirmLabelContainer">
                                            <input disabled type="text" v-model="productSequence" placeholder="Product Sequence">
                                        </div>

                                        <div v-if="productInput.additionalService" class="confirmLabelContainer">
                                            <select name="routeCodeSelect" v-model="productInput.serviceGroup" id="routeCodeSelect">
                                                <option disabled selected value="null">Service Group</option>
                                                <option v-for="(group, index) in serviceGroupData.items" :key="index" :value="group.serviceGroup">{{group.serviceGroup}}</option>
                                            </select>
                                        </div>

                                        <div v-if="productInput.serviceGroup == 'Delivery Window'" class="confirmLabelContainer">
                                            <label for="">Delivery Window Start</label>
                                            <input type="time" v-model="productInput.deliveryWindowStart" placeholder="Delivery Window Start">
                                        </div>

                                        <div v-if="productInput.serviceGroup == 'Delivery Window'" class="confirmLabelContainer">
                                            <label for="">Delivery Window End</label>
                                            <input type="time" v-model="productInput.deliveryWindowEnd" placeholder="Delivery Window End">
                                        </div>

                                        <div v-if="productInput.serviceGroup == 'Delivery Window'" class="confirmLabelContainer">
                                            <input type="text" v-model="productInput.deliveryWindowLabel" placeholder="Delivery Window Label">
                                        </div>
                                        
                                        <div v-if="productInput.additionalService" class="confirmLabelContainer">
                                            <input type="text" disabled v-model="serviceGroupSequence" placeholder="Service Group Sequence">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="productInput.productName" placeholder="Product Name">
                                        </div>
                                    </div>
                                    <div class="confirmContainerInner2">
                                        <div class="confirmLabelContainer">
                                            <textarea name="" id="" cols="30" rows="10" v-model="productInput.description" placeholder="Description"></textarea>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <textarea name="" id="" cols="30" rows="10" v-model="productInput.descriptionUI" placeholder="Description UI"></textarea>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <label for="">Price</label>
                                            <input type="number" v-model="productInput.price" placeholder="Price">
                                        </div>

                                        <div class="confirmLabelContainer check-box-container">
                                            <label for="additionalService">Additional Service</label>
                                            <input id="additionalService" type="checkbox" v-model="productInput.additionalService">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                <button class="update-user-button" @click="createProductItem()">Create Product</button>
            </div>
        </div>
        <div v-if="(AdminUser || InternalAdmin)" class="track-shipment update-user">
            <product-table :key="refreshKey" :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {createProduct} from '../../../graphql/mutations'
import {listProductCodes, listServiceNetworks, listServiceCodes, listVendors, listProductFamilies, listServiceGroups} from '../../../graphql/queries'
import {Auth, API} from 'aws-amplify'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'
import AlertUser from '../../../components/Popups/AlertUser.vue';
import ProductTable from '../ProductDashboard/ProductTable.vue'

export default {
    data(){
        return{
            user: {},
            productSequence: null,
            serviceGroupSequence: null,
            serviceNetworkData: {},
            productCodeData: {},
            serviceCodeData: {},
            vendorData: {},
            productFamilyData: {},
            productSequenceData: {},
            serviceGroupData: {},
            serviceGroupSequenceData: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            productInput: {
                productCode: "",
                serviceCode: "",
                description: "",
                additionalService: false,
                serviceNetwork: "",
                vendor: "",
                price: 0,
                productName: "",
                descriptionUI: "",
                productFamily: null,
                productSequence: null,
                serviceGroup: null,
                serviceGroupSequence: null,
                deliveryWindowEnd: null,
                deliveryWindowStart: null,
                deliveryWindowLabel: null
            },
            refreshKey: 0
        }
    },
    components:{
        AlertUser,
        ProductTable,
        SignedInUserInfo
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();
            this.listServiceNetworks();
            this.listProductCode();
            this.listServiceCode();
            this.listVendor();
            this.listServiceGroups();
            this.listProductFamilies();
            this.listProductData();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to product dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    watch:{
        'productInput.additionalService'(){
            if(this.productInput.additionalService == true){
                this.productInput.serviceGroupSequence = this.serviceGroupSequence
                this.productInput.productFamily = null
                this.productInput.productSequence = null
            }

            if(this.productInput.additionalService == false){
                this.productInput.serviceGroupSequence = null
                this.productInput.productSequence = this.productSequence
                this.productInput.serviceGroup = null
                this.productInput.deliveryWindowEnd = null
                this.productInput.deliveryWindowStart = null
                this.productInput.deliveryWindowLabel = null
            }
        },
        'productInput.serviceGroup'(){
            this.productInput.deliveryWindowEnd = null
            this.productInput.deliveryWindowStart = null
            this.productInput.deliveryWindowLabel = null
        }
    },
    methods:{
        async listProductData(){
            let graphqlQuery = `query listProducts{
                listProducts{
                    items{
                        productSequence
                        serviceGroupSequence
                    }
                }
            }`
            
            try{
                let listProductDataReturn = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                let highestProductSequence = null;
                let highestServiceGroupSequence = null;

                for (const item of listProductDataReturn.data.listProducts.items) {
                    const productSequence = item.productSequence !== null ? item.productSequence : null;
                    const serviceGroupSequence = item.serviceGroupSequence !== null ? item.serviceGroupSequence : null;

                    if (highestProductSequence === null || highestProductSequence < productSequence) {
                        highestProductSequence = productSequence;
                    }

                    if (highestServiceGroupSequence === null || highestServiceGroupSequence < serviceGroupSequence) {
                        highestServiceGroupSequence = serviceGroupSequence;
                    }
                }
                this.productSequence = this.incrementWithLeadingZeros(highestProductSequence)
                this.serviceGroupSequence = this.incrementWithLeadingZeros(highestServiceGroupSequence)
                this.productInput.productSequence = this.productSequence
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting product sequence codes"
                }
            }
    },
    incrementWithLeadingZeros(str) {
        const number = parseInt(str, 10);
        const incrementedNumber = number + 1;
        const formattedResult = String(incrementedNumber).padStart(str.length, '0');
        return formattedResult;
    },
        async listServiceGroups(){
            this.loadingUser = true;
            try {
                let listServiceGroupsData = await API.graphql({
                    query: listServiceGroups,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.serviceGroupData = listServiceGroupsData.data.listServiceGroups;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get service group codes"
            }finally{
                this.loadingUser = false;
            }
        },
        async listProductFamilies(){
            this.loadingUser = true;
            try {
                let listProductFamiliesData = await API.graphql({
                    query: listProductFamilies,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.productFamilyData = listProductFamiliesData.data.listProductFamilies;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get product family codes"
            }finally{
                this.loadingUser = false;
            }
        },
        async listVendor(){
            this.loadingUser = true;
            try {
                let listVendorData = await API.graphql({
                    query: listVendors,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.vendorData = listVendorData.data.listVendors;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get vendor codes"
            }finally{
                this.loadingUser = false;
            }
        },
        async listServiceCode(){
            this.loadingUser = true;
            try {
                let listServiceCodeData = await API.graphql({
                    query: listServiceCodes,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.serviceCodeData = listServiceCodeData.data.listServiceCodes;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get service codes"
            }finally{
                this.loadingUser = false;
            }
        },
        async listProductCode(){
            this.loadingUser = true;
            try {
                let listProductCodeData = await API.graphql({
                    query: listProductCodes,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.productCodeData = listProductCodeData.data.listProductCodes;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get product codes"
            }finally{
                this.loadingUser = false;
            }
        },
        async listServiceNetworks(){
            this.loadingUser = true;
            try {
                let listServiceNetworkData = await API.graphql({
                    query: listServiceNetworks,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.serviceNetworkData = listServiceNetworkData.data.listServiceNetworks;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get service network information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createProductItem(){
            this.loadingUser = true;
            await this.createProduct();
            this.loadingUser = false;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createProduct(){
            this.loadingUser = true;
            if(this.productInput.additionalService == true){
                if(this.productInput.serviceGroup == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "You must add a service group"
                    return
                }
            }

            if(this.productInput.additionalService == false){
                if(this.productInput.productFamily == null){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a product family"
                return
            }
            }

            if(this.productInput.serviceGroup == 'Delivery Window'){
                if(this.productInput.deliveryWindowLabel == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "You must add a delivery label"
                    return
                }
                if(this.productInput.deliveryWindowEnd == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "You must add a delivery window end"
                    return
                }
                if(this.productInput.deliveryWindowStart == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "You must add a delivery window start"
                    return
                }
            }

            if(this.productInput.productCode == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a product code"
                return
            }

            if(this.productInput.serviceCode == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a service code"
                return
            }

            if(this.productInput.serviceNetwork == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a service network"
                return
            }

            if(this.productInput.vendor == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a vendor"
                return
            }

            if(this.productInput.productName == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a product name"
                return
            }

            if(this.productInput.description == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a description"
                return
            }

            if(this.productInput.descriptionUI == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "You must add a UI description"
                return
            }

            try {
                await API.graphql({
                    query: createProduct,
                    variables: {
                        input: this.productInput
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.refreshKey++
                this.toggleAlertBox = true;
                this.alertMessage = "Created Product"
            } catch (error) {
                console.log(error)
                if(error.errors[0].message){
                    this.toggleAlertBox = true;
                    this.alertMessage = error.errors[0].message
                    this.loadingUser = false;
                }else{
                    this.toggleAlertBox = true;
                    this.alertMessage = "Unable to create product."
                    this.loadingUser = false;
                }
                
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

.check-box-container{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.check-box-container input{
    margin-left: 10px !important;
}

input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    margin: 0;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    padding: 0;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input, select, textarea{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.file-input{
    width: 80%;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.service-input-container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    padding: 10px;
    margin-top: 5px;
    flex-basis: 30%;
}

.service-levels-inner{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

.confirmContainerInner1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.update-user{
    flex-basis: 60%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.service-levels{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.add-service-button{
    margin: 0;
    margin-top: 5px;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
<template>
<div class="main-container">
    <div class="inner-container">
        <h2 class="styled-header">Shipping Terms and Conditions</h2>

        <p>
            Every Shipment or Service, Including But Not Limited to Next Day, Same Day, Priority Today, Saturday, Partner Network, Out of State, Zip Ship, is Subject to Following Terms and Conditions
            <br>
            <br>
            In tendering this shipment for delivery and/or the performance of additional services, YOU, the Shipper, agree to these terms and conditions which no agent, servant, member or employee of NJ Lawyers Service, LLC DBA LREX (hereafter LREX) may alter or modify:
        </p>

        <p>
            <span style="font-weight: bold;">a)</span> NEITHER PARTY HERETO SHALL BE LIABLE TO THE OTHER FOR ANY INDIRECT, SPECIAL, INCIDENTAL, LOSS OF USE, INTERRUPTION OF BUSINESS, CONSEQUENTIAL OR OTHER DAMAGES, INCLUDING LOSS OF INCOME OR PROFIT, WHETHER OR NOT A PARTY HERETO KNEW OR SHOULD HAVE FORESEEN SUCH POSSIBLE DAMAGES AND WHETHER OR NOT SUCH PARTY WAS NEGLIGENT OR ENGAGED IN WILLFUL MISCONDUCT, REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY, OR OTHERWISE, EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL LREX’S AGGREGATE LIABILITY UNDER THIS AGREEMENT EXCEED THE FEES PAID TO LREX FOR SHIPMENT.
            <br>
            <br>
            THE MAXIMUM AMOUNT OF LREX LIABILITY WITH RESPECT TO ANY SHIPMENT IS $100 PER SHIPMENT. LREX SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE, DELAY OR FAILURE TO DELIVER A SHIPMENT THAT IS BEYOND LREX'S CONTROL (SUCH AS BUT NOT LIMITED TO WEATHER, SIGNATURE REQUIRED-RECIPIENT NOT AVAILABLE, & NO ACCESS TO DELIVERY LOCATION); ANY DEFECT OR CHARACTERISTIC RELATED TO THE SHIPMENT, EVEN IF KNOWN TO LREX; ANY ACT OR OMISSION BY THIRD PARTIES (E.G. SHIPPER, RECEIVER, AGENT, OR CONTRACTOR) OR FORCE MAJEURE.
            <br>
            <br>
            <span style="font-weight: bold;">a)</span> LREX HEREBY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THIS SHIPMENT.
            <br>
            <br>
            <span style="font-weight: bold;">b)</span> Every Shipment is transported on a limited liability basis. LREX’s maximum liability for damages to or loss of any Item shall not exceed US$100 per Item. For purposes of establishing the amount of LREX’s liability, the value of an Item shall be determined by reference to its replacement value at the time the Shipment was made (but not more than $100). Customer is advised to carry its own insurance for any losses or damages not expressly accepted by LREX. None of LREX, its employees, designated carriers, contractors, sub-contractors, shall have any other responsibility for any loss with respect to any Item.
            <br>
            <br>
            <span style="font-weight: bold;">c)</span> Shipper's damages for LREX’s failure to deliver or perform the requested service within the guaranteed transit time shall be limited to a refund of the charge for such delivery or service.
            <br>
            <br>
            <span style="font-weight: bold;">d)</span> "Service Types" set forth on the Create New Shipment Page of the Shipment Center may be subject to additional fees, surcharges, or limitations and availability.
            <br>
            <br>
            <span style="font-weight: bold;">e)</span> LREX shall not be liable for loss or damage to shipments improperly packed or labeled, or for loss or damage caused by delay in delivery and/or service. A shipment sent without a request for a delivery receipt signature means that you have released LREX and given us your permission to
            leave the shipment at the recipient's address even if no person is there at the time of delivery to sign for it and the LREX delivery record shall be conclusive proof that delivery was completed and LREX will not be liable for any damages arising from a claim of improper delivery, non-delivery, loss or damage to the shipment.
            <br>
            <br>
            <span style="font-weight: bold;">f)</span> Any and all credit requests, complaints, claims or demands regarding loss or damage arising out of any delivery, mis-delivery, non-delivery, non-performance, delay or any other claimed failure by LREX must be reported, in writing, to New Jersey Lawyers Service, LLC DBA LREX, P.O. Box 36, Union, NJ 07083, or via email to customerservice@LREX.com, within 15 days of the delivery and/or service date which Shipper claims should have been the delivery and/or service date. Supporting documentation of loss or damage may be required, such as: Photos of package and damaged goods; proof of value documentation such as copies of original invoices from supplier and/or appraisals. LREX reserves the right to reject claims received later than 15 days. If Customer has outstanding balance due to LREX, credit will be applied to Customer account. No checks, ACH, or wire transfers will be issued for accounts with an open receivable balance.
        </p>

        <h2>Transit Times</h2>
        <p>
            LREX will endeavor to make delivery of Items in accordance with transit time commitments set forth in the shipment center following acceptance by LREX of Items as indicted by shipment label generated by LREX user.
        </p>

        <h2>Shipment Preparation</h2>
        <p>
            Customer is responsible for the proper packaging and labeling of Customer’s documents and/or goods for pick-up by LREX and placing them into any container or one that may be supplied by LREX according to the defined pick-up process. Customer represents, warrants and covenants that all Items will be properly marked, addressed, and packaged to withstand ground transport. LREX is not liable to Customer for loss, damage, expense or delay due to the Customer’s failure to comply with any of the required term herein. LREX shall have no responsibility for loss or damage to documents or goods caused by inadequate or inappropriate packaging, labeling or documentation. Any documents or goods submitted by Customer to LREX shall become “Items” when LREX has accepted such for Shipment. LREX is not responsible for delays caused by Customer’s failure to properly package or label Items or to provide the necessary documentation.
            <br>
            <br>
            In preparing items for Shipment, Customer will: <span style="font-weight: bold;">(i)</span> enter all required delivery information on the LREX shipment entry system, <span style="font-weight: bold;">(ii)</span> prepare the item for transport to or pick up by LREX according to LREX’s instructions (ensuring that no prohibited items are included and that proper packaging and related documentation is provided to LREX); <span style="font-weight: bold;">(iii)</span> print a barcode label (or other shipping label) and other documentation generated by the LREX Portal and affix it to each package submitted for Shipment. Additional information may be required for transport and on time delivery. LREX will contact the Customer if necessary and any delays in response from Customer may cause a delay in completing the Shipment of an Item.
            <br>
            <br>
            LREX can refuse materials proposed for shipment for any reason or no reason, such as <span style="font-weight: bold;">(i)</span> exceeding maximum dimensions or weight, <span style="font-weight: bold;">(ii)</span> exceeding maximum value.
        </p>

        <h2>Routes Assignment</h2>
        <p>
            LREX will transport Items accepted for Shipment to the destinations included in LREX’s destination markets. LREX will select <span style="font-weight: bold;">(i)</span> the means, routes, and procedures for receiving, handling, transporting, loading, unloading, storing, clearing, entering, delivering, distributing, or otherwise dealing with all Items, and <span style="font-weight: bold;">(ii)</span> the employees, partners, and third parties to perform these services.
        </p>

        <h2>Shipment Charges</h2>
        <p>
            LREX shipment charges are calculated by using a negotiated base rate plus fuel surcharge. Additional surcharges may apply for recipient signatures, remote signatures, priority service, Saturday service, additional weight, cold storage, Incorrect address, labels, and wait time. Shipment rates and discounts are based on average daily volume. Fuel Surcharge percentage for LREX is subject to monthly adjustment based on the rounded average of the retail price of gasoline – all grades, in the Central Atlantic region, as published by the U.S. Energy Information Administration. LREX reserves the right to change prices at any time.
        </p>

        <h2>Payment Terms</h2>
        <p>
            LREX will bill Customer weekly or monthly as defined during on boarding. LREX reserves the right to determine which accounts are billable based on credit worthiness. Non-Billable accounts are required to keep a credit card on file in order to use LREX services. Credit Card Payments will be automatically processed immediately upon invoice generation. Billable account invoices are due upon receipt of invoices. LREX may in its sole discretion increase, decrease, suspend or revoke credit at any time for any reason and without prior notice. Except to the extent LREX otherwise expressly agrees in writing, LREX has no obligation to make or incur any expense, guarantee or advance for any purpose on behalf of the Customer. Failure to pay on time may result in suspension of service. In addition, an administrative fee of $25 may be charged to suspended & closed accounts prior to resuming service. All LREX invoices are emailed to the billing contact designated by Customer. Customer is responsible for updating billing contact in LREX portal. Billing disputes by Customer for over and undercharges must be made in writing within sixty (60) days from the date of the invoice. Billing claims should be submitted to <span style="font-weight: bold;">billing@lrex.com</span>. All amounts are due in US Dollars. LREX may in its sole discretion require that Customer maintain a security deposit for Services. LREX may increase rates at any time.
            <br>
            <br>
            LREX reserves the right to charge credit card processing fees or a “checkout fee” for any and all credit card payment methods. Fee shall not exceed 4% of total payment.
            <br>
            <br>
            Any amounts due from Customer not paid within thirty (30) days of invoice date will incur interest at the rate of 8% per month until paid in full.
            <br>
            <br>
            All Credit Memos on Account must be used within 90 days from the date of issue.
        </p>

        <h2>Indemnification</h2>
        <p>
            Customer shall defend, exonerate, indemnify and hold harmless LREX, its employees, directors, managers and members, and its designated agents, (such as Clearance Agents), carriers and their successors and assigns from and against all charges, claims, damages, liabilities, judgments, costs, expenses, payments or losses of any kind (such as for purchase price, freight, storage, demurrage,
            detention, duties, taxes, fines, penalties, and reasonable attorneys’ fees and costs) arising from or related to: <span style="font-weight: bold;">(a)</span> any breach by Customer of any representation, warranty, covenant, agreement, undertaking, consent, or waiver by Customer; <span style="font-weight: bold;">(b)</span> any failure of Customer to pay or perform when due its obligations to LREX or to any other person or entity; <span style="font-weight: bold;">(c)</span> Customer’s violation of any Rule or failure to disclose, correct, or complete any entry, or other data or documents; <span style="font-weight: bold;">(d)</span> any other claim by any such other person or entity, in each case, except as may be caused by the gross negligence or intentional misconduct of LREX.
        </p>

        <h2>HIPPA, Protected Health Information, and Business Associate Agreements.</h2>
        <p>
            LREX is acting strictly as a courier service to its clients, picking up sealed packages from one location and delivering those sealed packages to another location (similar to the services provided by the United States Postal Service, United Parcel Service, Federal Express and similar courier providers), no HIPAA business associate relationship is created between LREX and its clients, even if those packages contain medical records or other “protected health information” as defined under HIPAA. The fact that LREX may sort and store those packages overnight at its shipping warehouse (much like the USPS, UPS, FedEx and other courier services) does not mean LREX has any meaningful access to any information that may be contained in those sealed packages. This is consistent with commentary to the HIPAA Omnibus Rule. As such, Customer agrees not to transfer any protected health information to LREX when creating labels for shipment. All shipment labels will be in public view and should only contain details required to ensure transport and delivery. Customer agrees not to share, expose, or communicate any protected health information with LREX employees, agents, contractors, or third parties.
        </p>

        <h2>General</h2>
        <p>
            Customer Service. LREX Customer Service is available Monday through Friday 8-8pm EST. Saturday 10-2pm. Customer Service can be reached at <span style="font-weight: bold;">customerservice@lrex.com</span>. Or by phone <span style="font-weight: bold;">908-686-7000</span>. Customer calls are answered on a first come first serve basis. Due to the nature of our business, tracking shipments often requires investigation. This can increase Customer wait time. Our Customer Service team works diligently to solve all shipment queries and appreciates Customer’s patience during the investigation process.
            <br>
            <br>
            Force Majeure. Neither party shall be liable to the other party or any other person for any failure or delay in the performance of any obligations (except for the payment of money) due to events beyond its reasonable control, including, but not limited to, fire, storm, flood, earthquake, explosion, accident, acts of terrorism or public enemy (or responses thereto), sabotage, strikes, lockouts, labor disputes, work stoppages, transportation embargoes or delays, failure or shortage of materials, supplies or machinery, acts of God, civil disturbance, restraint by court order or public authority, acts or regulations or priorities of the federal, state or local government or/and agencies thereof. If any such event occurs, the time for performance hereunder shall be appropriately extended.
        </p>
    </div>
</div>
</template>

<script>
export default {
    methods:{
        scrollToTop(){
            window.scrollTo(0,0);
        }
    },
    mounted(){
        this.scrollToTop();
    }
}
</script>

<style scoped>
    .main-container{
        display: flex;
        justify-content: center;
    }

    .inner-container{
        width: 80%;
        text-align: left;
    }

    .inner-list-container{
        border-bottom: 1px solid #ffcccc;
    }

    .styled-header{
        color:#ffffff;
        background-color: #fe804d;
        text-align: center;
        
        padding: 10px;
        width: fit-content;
        border-radius: 200px;
    }

    .final-styled-header{
        color:#ffffff;
        background-color: #32ccfe;
        text-align: center;
        padding-top: 2vw;
        padding-bottom: 2vw;
        width: 20%;
        clip-path: polygon(15% 5%, 85% 5%, 100% 50%, 85% 95%, 15% 95%, 0% 50%);
    }

    @media screen and (max-width: 1000px) {

        .inner-container{
            width: 90%;
        }

        .styled-header, .final-styled-header{
            width: 200px;
        }
    }
</style>
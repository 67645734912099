<template>
  <div v-if="userData" class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <svg class="close-shipment-info" @click="closeAddressInfo()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">User Details</h1>
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div v-if="userData.locationAddress" class="confirmContainerInner locationContainer">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                            <g id="image">
                                                <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                            </g>
                                        </svg>
                                        <h2>Location</h2>
                                    </div>

                                    <div v-show="userData.locationAddress.labelImage" class="confirmLabelContainer">
                                        <img :src="userData.locationAddress.labelImage" alt="">
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <p name="companyName">{{userData.locationAddress.serviceAddress.address.companyName}}</p>
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <p name="address1">{{userData.locationAddress.serviceAddress.address.address1}}</p>
                                    </div>
                                    
                                    <div v-if="userData.locationAddress.serviceAddress.address.address2" class="confirmLabelContainer">
                                        <p name="address2">{{userData.locationAddress.serviceAddress.address.address2}}</p>
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <p name="city">{{userData.locationAddress.serviceAddress.address.city}}, {{userData.locationAddress.serviceAddress.address.state}} {{userData.locationAddress.serviceAddress.address.zipCode}}</p>
                                    </div>

                                    <div v-if="userData.locationAddress.serviceAddress.address.phone" class="confirmLabelContainer">
                                        <label for="contactName">User Phone:</label>
                                        <p style="white-space: nowrap;" name="contactName">{{userData.locationAddress.serviceAddress.address.phone}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="AdminUser || InternalAssignable || CustomerAssignable" class="weight-container">
                            <div class="confirmLabelContainer service-data">
                                <div class="add-service-container">
                                    <div>
                                        <p style="font-weight: bold;">{{userData.userName}}</p>
                                    </div>

                                    <loading-charts v-if="removingUser"/>
                                    <loading-charts v-if="addingUserToGroup"/>
                                    <loading-charts v-if="loadingTable"/>

                                    <div v-for="group in listAllGroups" :key="group.GroupName">
                                            <div class="confirmLabelContainer group-remove-add">
                                                <div>
                                                    <label for="">{{group.GroupName}}</label>
                                                    <p style="font-size: 10px;">{{group.Description}}</p>
                                                </div>
                                                <button id="remove-button" :disabled="removingUser == true" @click="removeUserFromGroupFunc(group.GroupName)" style="margin-bottom: 0px;" class="print-button" v-if="userIsInGroup(group)">Remove</button>
                                                <button id="add-button" :disabled="addingUserToGroup == true" @click="addUserToGroupFunc(group.GroupName)" style="margin-bottom: 0px;" class="print-button create-button"  v-else>Add</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
    </div>
  </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API, Auth} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import { addUserToGroup, getUsersGroupData, listAllUserGroups, removeUserFromGroup } from '../../../graphql/mutations';
import { getUser } from '../../../graphql/queries';

export default {
    props:['username', 'jwtToken'],
    components:{
        AlertUser,
        LoadingCharts
    },
    data(){
        return{
            userData: null,
            toggleAlertBox: false, 
            alertMessage: null,
            loadingData: false,
            validateCustomAddress: false,
            userCustomAddress: "",
            userServices: null,
            userServicesAWSJSON: "",
            serviceName: "",
            priceName: "",
            priceValue: "",
            routeCode: "",
            serviceLevelName: "",
            addNewService: false,
            addServiceObject: null,
            user: null,
            userDataAdmin: null,
            jwtTokenAdmin: "",
            AdminUser: false,
            CustomerAssignable: false,
            InternalAssignable: false,
            selectedLocationID: null,
            selectedUserData: null,
            usersGroups: [],
            listAllGroups: [],
            removingUser: false,
            addingUserToGroup: false
        }
    },
    computed:{
        userIsInGroup() {
            return group => {
                return this.usersGroups.some(item => item.GroupName === group.GroupName);
            };
        }
    },
    methods:{
        async checkBoxSelectID(event){
            this.selectedLocationID = event;
            await this.updateUserLocationID();
        },
        async getAccountUserClick(){
            this.loadingTable = true;
            let graphqlQuery = `query getUser{
                getUser(userName: "${this.username}"){
                    userName
                    locationID
                    accountNo
                    userEmail
                    locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                            }
                        }
                    }
                }
            }`
            
            try{
                let getUser = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.userData = getUser.data.getUser;
                this.selectedUserData = getUser.data.getUser;
                if(this.AdminUser || this.InternalAssignable || this.CustomerAssignable){
                    await this.getUsersGroupDataFunc();
                }
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting user"
                }
            }finally{
                this.loadingTable = false;
            }
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: getUser,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userDataAdmin = userReturnData.data.getUser;
            } catch (error) {
                console.log(error)
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async addUserToGroupFunc(groupName){
            this.addingUserToGroup = true;
            try{
                let addUserToCognitoGroup = await API.graphql({
                query:  addUserToGroup,
                variables:{
                    input:{
                        username: this.userData.userName,
                        groupname: groupName
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "User group updated"
                console.log(addUserToCognitoGroup)
                await this.getUsersGroupDataFunc();
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error updating user group"
                }
            }finally{
                this.addingUserToGroup = false;
            }
        },
        async removeUserFromGroupFunc(groupName){
            this.removingUser = true;
            try{
                let removeUserFromGroupData = await API.graphql({
                query:  removeUserFromGroup,
                variables:{
                    input:{
                        username: this.userData.userName,
                        groupname: groupName
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "User removed from group"
                console.log(removeUserFromGroupData)
                await this.getUsersGroupDataFunc();
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error removing user from group"
                }
            }finally{
                this.removingUser = false;
            }
        },
        async getUsersGroupDataFunc(){
            this.loadingTable = true;
            try{
                let getUsersGroups = await API.graphql({
                query:  getUsersGroupData,
                variables:{
                    input:{
                        username: this.userData.userName
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.usersGroups = getUsersGroups.data.getUsersGroupData.Groups;
                console.log(getUsersGroups)
                await this.listAllUserGroupsFunc();
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = e.errors[0].message
                }
            }finally{
                this.loadingTable = false;
            }
        },
        async listAllUserGroupsFunc(){
            this.loadingTable = true;
            try{
                let listAllUserGroupsData = await API.graphql({
                query:  listAllUserGroups,
                variables:{
                    input:{
                        username: this.userData.userName
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.listAllGroups = listAllUserGroupsData.data.listAllUserGroups.Groups;
                console.log(listAllUserGroupsData)
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting groups"
                }
            }finally{
                this.loadingTable = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeAddressInfo(){
            this.$emit('closeAddressInfo', false)
        },
        AddUserServiceToggle(){
            this.addNewService = !this.addNewService;
            this.serviceName = "";
            this.priceName = "";
            this.priceValue = "";
            this.routeCode = "";
            this.serviceLevelName = "";
        },
        async AddUserService(){
            const priceObject = {
                [this.priceName]: this.priceValue
            };

            this.userServices[this.serviceName] = {
                Price: priceObject,
                RouteCode: this.routeCode,
                ServiceLevelName: this.serviceLevelName
            }

            await this.addUserToGroupFunc();

            this.serviceName = "";
            this.priceName = "";
            this.priceValue = "";
            this.routeCode = "";
            this.serviceLevelName = "";
            this.addNewService = false;
        },
        async RemoveUserService(serviceName){
            delete this.userServices[serviceName]
            await this.addUserToGroupFunc();
        }
    },
    mounted(){
        this.getAccountUserClick();

        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtTokenAdmin = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                        this.AdminUser = true;
                    }
                    if(group == 'CustomerAssignable'){
                        this.CustomerAssignable = true;
                    }
                    if(group == 'InternalAssignable'){
                        this.InternalAssignable = true;
                    }
                })
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

    input, select{
        /* padding: 10px; */
        font-size: 15px;
        border: 1px solid rgba(0,0,0,.336);
        border-radius: 15px;
        outline: none;
        margin-top: 5px;
    }

.confirmLabelContainer img{
    width: 50%;
    max-width: 200px;
    margin-top: 5px;
    border-radius: 5px;
}

.group-remove-add{
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,.336);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    overflow-x: auto;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.weight-container{
    background-color: #fff;
    margin: 15px;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
    width: fit-content;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    overflow-y: auto;
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.locationContainer{
    padding-top: 0px;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.service-container{
    display: flex;
    margin: 5px;
    flex-direction: column;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.create-button{
    background-color: #33f18a;
    margin-left: 20px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 530px){
    .service-data{
        flex-direction: column;
    }
}
</style>
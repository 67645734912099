<template>
    <div v-if="toggleCustomAddressCorrection" class="custom-address-suggestion-container">
        <div class="custom-address-inner-container">
            <div class="custom-address-text">
                <h3>You entered</h3>
                <div class="entered-address-container">
                    <p v-if="(shipmentData.serviceAddress) && (currentActive == 1)">{{shipmentData.serviceAddress.address.address1}}, {{shipmentData.serviceAddress.address.city}}, {{shipmentData.serviceAddress.address.state}} {{shipmentData.serviceAddress.address.zipCode}}</p>
                    <p v-if="(shipmentData.deliveryPickup) && (currentActive == 3) && (showPickupLogic == true)">{{shipmentData.deliveryPickup.address.address1}}, {{shipmentData.deliveryPickup.address.city}}, {{shipmentData.deliveryPickup.address.state}} {{shipmentData.deliveryPickup.address.zipCode}}</p>
                    <p v-if="shipmentData.address1">{{shipmentData.address1}}, {{shipmentData.city}}, {{shipmentData.state}} {{shipmentData.zipCode}}</p>
                    <p v-if="shipmentData.pickupAddress1">{{shipmentData.pickupAddress1}}, {{shipmentData.pickupCity}}, {{shipmentData.pickupState}} {{shipmentData.pickupZipCode}}</p>
                    <p v-if="shipmentData.deliveryAddress1">{{shipmentData.deliveryAddress1}}, {{shipmentData.deliveryCity}}, {{shipmentData.deliveryState}} {{shipmentData.deliveryZipCode}}</p>
                    <p v-if="shipmentData.Address1">{{shipmentData.Address1}}, {{shipmentData.City}}, {{shipmentData.State}} {{shipmentData.ZipCode}}</p>
                    <button class="main-button-blue-white" @click="editEnteredAddress()">Edit</button>
                </div>
                <h3 v-if="customAddressSuggestion.Place">Corrected Address</h3>
                <h3 v-else>Address Validation Error</h3>
                <p v-if="customAddressSuggestion.Place">{{customAddressSuggestion.Place.Label}}</p>
                <p v-else>We could not validate the address you entered.</p>
            </div>
            <button v-if="customAddressSuggestion.Place" @click="useCorrectedAddress()" class="main-button-blue-white">Use Corrected Address</button>
            <button v-else class="main-button-blue-white" @click="editAddress()">I want to edit the address</button>
            <button @click="useEnteredAddress()" class="main-button-orange-white">Use Address As Entered</button>
        </div>
    </div>
</template>

<script>
import Location from "aws-sdk/clients/location";
import {Auth} from 'aws-amplify';
export default {
    props:['shipmentData', 'customAddress', 'currentActive', 'showPickupLogic'],
    data(){
        return{
            toggleCustomAddressCorrection: false,
            customAddressSuggestion: {},
            shipmentAddressData: {
                latitude: '',
                longitude: '',
                relevance: '',
                Address1: '',
                State: '',
                ZipCode: '',
                City: ''
            }
        }
    },
    watch:{
        'customAddress': function(){
            // console.log("Custom Address")
            // console.log(this.customAddress)

            // console.log("Shipment Data")
            // console.log(this.shipmentData)
            this.getLocationData();
        }
    },
    methods:{
        async editAddress(){
            this.toggleCustomAddressCorrection = false;
        },
        /**
         * Calls searchPlaceIndexForText to get address data
         * If data is returned set autoCompleteData
         */
        async getLocationData(){
            const credentials = await Auth.currentCredentials();

            const locationClient = new Location({
                credentials,
                region: 'us-east-1'
            });

            const params = {
                IndexName: "lrex-place",
                Text: this.customAddress,
                FilterCountries: ["USA"],
                BiasPosition: [-74.1724, 40.7357],
                MaxResults: 5
            };


            locationClient.searchPlaceIndexForText(params,(err,data)=>{
                if(err){
                    alert("Error with Location Services.")
                    //console.log(err)
                    //console.log(credentials)
                    }
                if(data){
                    // console.log(data.Results);
                    this.setCustomAddress(data.Results);
                }
            });
        },
        setCustomAddress(addressData){
            //console.log(addressData[0])
            //Suggested address needs a street and address number
            if((addressData[0].Place.AddressNumber == null) && (addressData[0].Place.Street == null)){
                // console.log(this.shipmentData)
                this.toggleCustomAddressCorrection = true;
                this.$emit('editEnteredAddress', {customAddress: ""});
                this.scrollToTop();
                return;
            }

            if(addressData[0].Relevance <= .95){
                this.toggleCustomAddressCorrection = true;
                if(addressData[0].Place.AddressNumber || addressData[0].Place.Street){
                    this.customAddressSuggestion = addressData[0];
                }
                this.scrollToTop();
            }else if(addressData[0].Relevance > .95){
                this.shipmentAddressData.latitude = addressData[0].Place.Geometry.Point[1];
                this.shipmentAddressData.longitude = addressData[0].Place.Geometry.Point[0];
                this.shipmentAddressData.relevance = addressData[0].Relevance;
                if((addressData[0].Place.AddressNumber != null) && (addressData[0].Place.Street != null)){
                    this.shipmentAddressData.Address1 = addressData[0].Place.AddressNumber + " " + addressData[0].Place.Street;
                }else if((addressData[0].Place.AddressNumber != null) && (addressData[0].Place.Street == null)){
                    this.shipmentAddressData.Address1 = addressData[0].Place.AddressNumber;
                }else if((addressData[0].Place.AddressNumber == null) && (addressData[0].Place.Street != null)){
                    this.shipmentAddressData.Address1 = addressData[0].Place.Street;
                }
                this.shipmentAddressData.City = addressData[0].Place.Municipality;
                switch(addressData[0].Place.Region){
                    case "Alabama":
                        this.shipmentAddressData.State = "AL";
                        break;
                    case "Alaska":
                        this.shipmentAddressData.State = "AK";
                        break;
                    case "Arizona":
                        this.shipmentAddressData.State = "AZ";
                        break;
                    case "Arkansas":
                        this.shipmentAddressData.State = "AR";
                        break;
                    case "California":
                        this.shipmentAddressData.State = "CA";
                        break;
                    case "Colorado":
                        this.shipmentAddressData.State = "CO";
                        break;
                    case "Connecticut":
                        this.shipmentAddressData.State = "CT";
                        break;
                    case "Florida":
                        this.shipmentAddressData.State = "FL";
                        break;
                    case "Georgia":
                        this.shipmentAddressData.State = "GA";
                        break;
                    case "Hawaii":
                        this.shipmentAddressData.State = "HI";
                        break;
                    case "Idaho":
                        this.shipmentAddressData.State = "ID";
                        break;
                    case "Illinois":
                        this.shipmentAddressData.State = "IL";
                        break;
                    case "Indiana":
                        this.shipmentAddressData.State = "IN";
                        break;
                    case "Iowa":
                        this.shipmentAddressData.State = "IA";
                        break;
                    case "Kansas":
                        this.shipmentAddressData.State = "KS";
                        break;
                    case "Kentucky":
                        this.shipmentAddressData.State = "KY";
                        break;
                    case "Louisiana":
                        this.shipmentAddressData.State = "LA";
                        break;
                    case "Michigan":
                        this.shipmentAddressData.State = "MI";
                        break;
                    case "Minnesota":
                        this.shipmentAddressData.State = "MN";
                        break;
                    case "Mississippi":
                        this.shipmentAddressData.State = "MS";
                        break;
                    case "Missouri":
                        this.shipmentAddressData.State = "MO";
                        break;
                    case "Montana":
                        this.shipmentAddressData.State = "MT";
                        break;
                    case "Nebraska":
                        this.shipmentAddressData.State = "NE";
                        break;
                    case "Nevada":
                        this.shipmentAddressData.State = "NV";
                        break;
                    case "New Mexico":
                        this.shipmentAddressData.State = "NM";
                        break;
                    case "North Carolina":
                        this.shipmentAddressData.State = "NC";
                        break;
                    case "North Dakota":
                        this.shipmentAddressData.State = "ND";
                        break;
                    case "Ohio":
                        this.shipmentAddressData.State = "OH";
                        break;
                    case "Oklahoma":
                        this.shipmentAddressData.State = "OK";
                        break;
                    case "Oregon":
                        this.shipmentAddressData.State = "OR";
                        break;
                    case "South Carolina":
                        this.shipmentAddressData.State = "SC";
                        break;
                    case "South Dakota":
                        this.shipmentAddressData.State = "SD";
                        break;
                    case "Tennessee":
                        this.shipmentAddressData.State = "TN";
                        break;
                    case "Texas":
                        this.shipmentAddressData.State = "TX";
                        break;
                    case "Utah":
                        this.shipmentAddressData.State = "UT";
                        break;
                    case "Vermont":
                        this.shipmentAddressData.State = "VT";
                        break;
                    case "Washington":
                        this.shipmentAddressData.State = "WA";
                        break;
                    case "West Virginia":
                        this.shipmentAddressData.State = "WV";
                        break;
                    case "Wisconsin":
                        this.shipmentAddressData.State = "WI";
                        break;
                    case "Wyoming":
                        this.shipmentAddressData.State = "WY";
                        break;
                    case "District of Columbia":
                        this.shipmentAddressData.State = "DC";
                        break;
                    case "Delaware":
                        this.shipmentAddressData.State = "DE";
                        break;
                    case "Massachusetts":
                        this.shipmentAddressData.State = "MA";
                        break;
                    case "Maryland":
                        this.shipmentAddressData.State = "MD";
                        break;
                    case "Maine":
                        this.shipmentAddressData.State = "ME";
                        break;
                    case "New Hampshire":
                        this.shipmentAddressData.State = "NH";
                        break;
                    case "New Jersey":
                        this.shipmentAddressData.State = "NJ";
                        break;
                    case "New York":
                        this.shipmentAddressData.State = "NY";
                        break;
                    case "Pennsylvania":
                        this.shipmentAddressData.State = "PA";
                        break;
                    case "Rhode Island":
                        this.shipmentAddressData.State = "RI";
                        break;
                    case "Virginia":
                        this.shipmentAddressData.State = "VA";
                        break;
                    default:
                        alert("Error with State Input")
                }
                this.shipmentAddressData.ZipCode = addressData[0].Place.PostalCode.substring(0, 5);
                this.$emit('setCustomAddress', {shipmentAddressData: this.shipmentAddressData, callStepNext: true, callInsertAddressBook: true})
                //if(this.$parent.stepNext()){this.$parent.stepNext();}
                //if(this.$parent.InsertAddressBook()){this.$parent.InsertAddressBook();}
                this.customAddressSuggestion = {};
            }
        },
        scrollToTop(){
            window.scrollTo(0,0);
        },
        useCorrectedAddress(){
            this.shipmentAddressData.latitude = this.customAddressSuggestion.Place.Geometry.Point[1];
            this.shipmentAddressData.longitude = this.customAddressSuggestion.Place.Geometry.Point[0];
            this.shipmentAddressData.relevance = 1;
            if((this.customAddressSuggestion.Place.AddressNumber != null) && (this.customAddressSuggestion.Place.Street != null)){
                this.shipmentAddressData.Address1 = this.customAddressSuggestion.Place.AddressNumber + " " + this.customAddressSuggestion.Place.Street;
            }else if((this.customAddressSuggestion.Place.AddressNumber != null) && (this.customAddressSuggestion.Place.Street == null)){
                this.shipmentAddressData.Address1 = this.customAddressSuggestion.Place.AddressNumber;
            }else if((this.customAddressSuggestion.Place.AddressNumber == null) && (this.customAddressSuggestion.Place.Street != null)){
                this.shipmentAddressData.Address1 = this.customAddressSuggestion.Place.Street;
            }
            
            this.shipmentAddressData.City = this.customAddressSuggestion.Place.Municipality;
            switch(this.customAddressSuggestion.Place.Region){
                case "Alabama":
                    this.shipmentAddressData.State = "AL";
                    break;
                case "Alaska":
                    this.shipmentAddressData.State = "AK";
                    break;
                case "Arizona":
                    this.shipmentAddressData.State = "AZ";
                    break;
                case "Arkansas":
                    this.shipmentAddressData.State = "AR";
                    break;
                case "California":
                    this.shipmentAddressData.State = "CA";
                    break;
                case "Colorado":
                    this.shipmentAddressData.State = "CO";
                    break;
                case "Connecticut":
                    this.shipmentAddressData.State = "CT";
                    break;
                case "Florida":
                    this.shipmentAddressData.State = "FL";
                    break;
                case "Georgia":
                    this.shipmentAddressData.State = "GA";
                    break;
                case "Hawaii":
                    this.shipmentAddressData.State = "HI";
                    break;
                case "Idaho":
                    this.shipmentAddressData.State = "ID";
                    break;
                case "Illinois":
                    this.shipmentAddressData.State = "IL";
                    break;
                case "Indiana":
                    this.shipmentAddressData.State = "IN";
                    break;
                case "Iowa":
                    this.shipmentAddressData.State = "IA";
                    break;
                case "Kansas":
                    this.shipmentAddressData.State = "KS";
                    break;
                case "Kentucky":
                    this.shipmentAddressData.State = "KY";
                    break;
                case "Louisiana":
                    this.shipmentAddressData.State = "LA";
                    break;
                case "Michigan":
                    this.shipmentAddressData.State = "MI";
                    break;
                case "Minnesota":
                    this.shipmentAddressData.State = "MN";
                    break;
                case "Mississippi":
                    this.shipmentAddressData.State = "MS";
                    break;
                case "Missouri":
                    this.shipmentAddressData.State = "MO";
                    break;
                case "Montana":
                    this.shipmentAddressData.State = "MT";
                    break;
                case "Nebraska":
                    this.shipmentAddressData.State = "NE";
                    break;
                case "Nevada":
                    this.shipmentAddressData.State = "NV";
                    break;
                case "New Mexico":
                    this.shipmentAddressData.State = "NM";
                    break;
                case "North Carolina":
                    this.shipmentAddressData.State = "NC";
                    break;
                case "North Dakota":
                    this.shipmentAddressData.State = "ND";
                    break;
                case "Ohio":
                    this.shipmentAddressData.State = "OH";
                    break;
                case "Oklahoma":
                    this.shipmentAddressData.State = "OK";
                    break;
                case "Oregon":
                    this.shipmentAddressData.State = "OR";
                    break;
                case "South Carolina":
                    this.shipmentAddressData.State = "SC";
                    break;
                case "South Dakota":
                    this.shipmentAddressData.State = "SD";
                    break;
                case "Tennessee":
                    this.shipmentAddressData.State = "TN";
                    break;
                case "Texas":
                    this.shipmentAddressData.State = "TX";
                    break;
                case "Utah":
                    this.shipmentAddressData.State = "UT";
                    break;
                case "Vermont":
                    this.shipmentAddressData.State = "VT";
                    break;
                case "Washington":
                    this.shipmentAddressData.State = "WA";
                    break;
                case "West Virginia":
                    this.shipmentAddressData.State = "WV";
                    break;
                case "Wisconsin":
                    this.shipmentAddressData.State = "WI";
                    break;
                case "Wyoming":
                    this.shipmentAddressData.State = "WY";
                    break;
                case "District of Columbia":
                    this.shipmentAddressData.State = "DC";
                    break;
                case "Delaware":
                    this.shipmentAddressData.State = "DE";
                    break;
                case "Massachusetts":
                    this.shipmentAddressData.State = "MA";
                    break;
                case "Maryland":
                    this.shipmentAddressData.State = "MD";
                    break;
                case "Maine":
                    this.shipmentAddressData.State = "ME";
                    break;
                case "New Hampshire":
                    this.shipmentAddressData.State = "NH";
                    break;
                case "New Jersey":
                    this.shipmentAddressData.State = "NJ";
                    break;
                case "New York":
                    this.shipmentAddressData.State = "NY";
                    break;
                case "Pennsylvania":
                    this.shipmentAddressData.State = "PA";
                    break;
                case "Rhode Island":
                    this.shipmentAddressData.State = "RI";
                    break;
                case "Virginia":
                    this.shipmentAddressData.State = "VA";
                    break;
                default:
                    alert("Error with State Input")
            }
            this.shipmentAddressData.ZipCode = this.customAddressSuggestion.Place.PostalCode.substring(0, 5);
            this.toggleCustomAddressCorrection = false;
            this.$emit('useCorrectedAddress', {shipmentDataValue: this.shipmentAddressData, callStepNext: true, callInsertAddressBook: true});
            //if(this.$parent.stepNext()){this.$parent.stepNext();}
            this.customAddressSuggestion = {};
        },
        useEnteredAddress(){
            this.toggleCustomAddressCorrection = false;
            this.$emit('useEnteredAddress', {callStepNext: true, callInsertAddressBook: true});
            //if(this.$parent.stepNext()){this.$parent.stepNext();}
        },
        editEnteredAddress(){
            this.toggleCustomAddressCorrection = false;
            this.$emit('editEnteredAddress', {customAddress: ""});
        }
    }
}
</script>

<style scoped>
@import "../../styles/button-styles.css";

/* Custom Address Popup */
.entered-address-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
}

.entered-address-container p{
    margin: 0;
    margin-right: auto;
}

.entered-address-container button{
    padding-top: 5px;
    padding-bottom: 5px;
}

.main-button-orange-white{
    padding-top: 5px;
    padding-bottom: 5px;
}

button{
    margin: 1px;
}

.custom-address-suggestion-container{
    width: 100%;
    height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background: linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 90%, rgba(236, 236, 236, 0.05) 100%);
}

.custom-address-inner-container{
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.9);
    z-index: 15;
    position: absolute;
    top: 2.5%;
}

.custom-address-text{
    text-align: left;
}

.custom-address-text h3{
    background-color: #33f18a;
    border-radius: 50px;
    margin-top: 0;
}

h2, h3{
    width: 100%;
    text-align: center;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
</style>
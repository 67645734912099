<template>
  <div class="amplify-container">
        <amplify-authenticator>
          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <amplify-sign-in slot="sign-in"
                header-text="Sign in to create a shipment."
                hideSignUp
          ></amplify-sign-in>


          <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
          <amplify-sign-up slot="sign-up"
                  v-if="authState === 'signup'"
                  headerText="Sign up to create a shipment."
                  :formFields="formFields"
          ></amplify-sign-up>

          <!-- <div class="sign-out-container"> -->
            <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
            <!-- <amplify-sign-out slot="sign-out"
                id="signout-button"
                v-if="authState === 'signedin'">
            </amplify-sign-out>
          </div> -->
              
        </amplify-authenticator>
    </div>
</template>

<script>
/**
 * AWS Amplify Login Page
 */
import { Auth } from 'aws-amplify';
import { Hub } from "aws-amplify";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";
export default {
/**
 * Using amplify Hub listen for signIn
 * If 'signIn' push user to shipping page
 */
    mounted(){
        Hub.listen('auth', (data) => {
            console.log(data.payload.event)
        switch (data.payload.event) {
            case 'signIn':
                this.$router.push('ShipmentDashboard')
                break;
            case 'verifyContact':
                this.$router.push('Login')
                break;
            case 'signedin':
                this.$router.push('ShipmentDashboard')
                break;
            }
        });

        onAuthUIStateChange((nextAuthState, authData) => {
            if(nextAuthState === 'verifyContact'){
                this.$router.push('Login')
            }

            if(nextAuthState === 'signedin'){
                if(authData){
                    if(authData.signInUserSession.idToken.payload['cognito:groups']){
                        authData.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                            if(group == 'Admin'){
                                this.$router.push('AdminShipmentDashboard')
                            }

                            if(group == 'InternalAdmin'){
                                this.$router.push('AdminShipmentDashboard')
                            }

                            if(group == 'Customer'){
                                this.$router.push('ShipmentDashboard')
                            }

                            if(group == 'CustomerAdmin'){
                                this.$router.push('CustomerAdminShipmentDashboard')
                            }
                        })
                    }else{
                        this.$router.push('ShipmentDashboard')
                    }
                }else{
                    this.$router.push('ShipmentDashboard')
                }
            }
        });
    },
/**
 * Before Login page is loaded check if user is already signed in
 * Fixes issue when user trys to go back to login page
 */
    beforeMount(){
        //Check if user is signed in before loading page.
        Auth.currentSession()
        .then(data => {
            //User is logged in
            if(data){
                this.$router.push('ShipmentDashboard');
            }
        })
        .catch(err => {
            //User is not logged in
            if(err){
                Auth.signOut({global: false});
            }
        });
    }
}
</script>

<style scoped>
/* Amplify Authenticator */
    .amplify-container{
        margin-top: -180px;
    }

    amplify-authenticator{
        --width: 450px;
        --height: 600px;
        --amplify-primary-color: #32ccfe;
        --amplify-secondary-tint: #2cb6e4;
        --amplify-primary-shade: #32ccfe;
        --amplify-primary-tint: #32ccfe;
    }
</style>
<template>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 

<LoadingData v-if="loading" :headerMessage="headerMessage"/>


  <div v-if="!loading" class="popup">
      <div class="popup-inner">
        <div class="closeShipmentTracking-container">
          <h1>Shipment Details</h1>
          <div class="close-popup-container"><i class="fa fa-times-circle" @click="ShipmentTrackingTogglePopup()"></i></div>
        </div>
        <div class="ShipmentTracking-container" id="ShipmentTracking-container">

          <div class="shipment_data" >
            <img v-if="labelImage" class="logo" loading="lazy" :src="labelImage" alt="LREX">
            <img v-if="(labelImage == '') && (showShipmentData)" class="logo" src="./../../assets/LREXHeaderLogo.jpg" alt="LREX" style="width: 100px; margin-left: 5px !important;">
            <div v-if="showShipmentData">
              <!-- <img class="logo" src="./../../assets/LREXHeaderLogo.jpg" alt="LREX" style="width: 80px;"> -->
              <h3 class="tracking-header">Tracking #: {{shipments[0].shipmentId}}</h3>
              
              <div class="shipment-info-container">
                <div class="shipment-info-container-inner">
                  <h3>Ship By</h3>
                  <p>{{shipments[0].pickupAttention}}</p>
                  <p>{{shipments[0].pickupAddress1}}</p>
                  <p>{{shipments[0].pickupAddress2}}</p>
                  <p>{{shipments[0].pickupCity}}, {{shipments[0].pickupState}}, {{shipments[0].pickupZipCode}} </p>
                </div>

                <div class="shipment-info-container-inner">
                  <h3>Ship To</h3>
                  <p>{{shipments[0].deliveryCompanyName}}</p>
                  <p>{{shipments[0].deliveryAddress1}}</p>
                  <p>{{shipments[0].deliveryCity}}, {{shipments[0].deliveryState}}, {{shipments[0].deliveryZipCode}} </p>

                  <div v-if="(shipments[0].processService == true) || (shipments[0].signatureRequired == true) || (shipments[0].priorityService == true) || (shipments[0].pickup == true) || (shipments[0].sameDay != 0)" @click="ShowShipmentInformation()" class="additional-information">
                    <p>Additional Information</p>
                    <i v-if="downArrow" class="down-arrow"></i>
                    <i v-if="upArrow" class="up-arrow"></i>
                  </div>
                  
                  <div v-show="showAdditionalInformation" class="shipment-information">
                    <div class="shipment-information-inner" v-show="shipments[0].priorityService == true">
                      <p>Priority Service</p>
                    </div>
                    <div class="shipment-information-inner" v-show="shipments[0].processService == true">
                      <p>Process Service</p>
                    </div>
                    <div class="shipment-information-inner" v-show="shipments[0].sameDay != 0">
                      <p>Same Day</p>
                    </div>
                    <div class="shipment-information-inner" v-show="shipments[0].signatureRequired == true">
                      <p>Signature Required</p>
                    </div>
                    <div class="shipment-information-inner" v-show="shipments[0].pickup == true">
                      <p>Pickup Service - NJ only</p>
                    </div>
                  </div>
                </div>
                
              </div>

              <div v-if="shipments[0].remarks != null" class="shipment-info-container-inner remarks">
                <p for="remarks" style="font-weight: bold;">Remarks:</p>
                <p name="remarks">{{shipments[0].remarks}}</p>
              </div>
            </div>

            <div class="input-zip-container" v-if="toggleEnterZip && (shipments || showShipmentHistoryData) && !loading">
              <p>Want more shipment information?</p>
              <div class="input-zip-inner">
                
                <form @submit.prevent="GetShipmentHistoryByID(); GetShipmentByID();">
                  <div class="zip-form-input">
                    <input type="text" placeholder="Enter ZipCode" v-model="posts.ZipCode">
                  </div>
                </form>
                <button class="main-button-blue-white" @click="GetShipmentHistoryByID(); GetShipmentByID();">Search</button>
              </div>
            </div>

            <div v-if="showShipmentHistoryData">
              <div class="shipment-history-container">
                <h3>Package History</h3>

                <div class="history-container" v-for="(items, index) in shipmentHistoryData" v-bind:key="items">
                  <div class="check-mark-container">
                    <div class="check-mark" :class="{active:index==0, activeLast:index==shipmentHistoryData.length - 1}">
                      <div class="check-mark-item"><img src="../../assets/FinalImages/checkMark.png" alt=""></div>
                    </div>
                  </div>
                  <div class="inner-history-container">
                    <div class="history-description-container">
                      <p class="history-description">{{shipmentHistoryData[index].description}}</p>
                      <p class="history-process-date">{{shipmentHistoryData[index].processedDate}}</p>
                    </div>
                    
                    <div v-if="shipmentHistoryData[index].description != 'Scanned out to partner network'">
                      <p>{{shipmentHistoryData[index].notes}}</p>
                    </div>
                    
                    <div v-if="shipmentHistoryData[index].imageURL && (removeDeliveryImages != true)">
                      <div class="proof-delivery-container">
                        <button v-if="(shipmentHistoryData[index].signatureId > 0)" @click="GetDeliveryImages(shipmentHistoryData[index].imageURL, index)" class="main-button-blue-white">Show Image</button>
                        <img class="proof-delivery-image" loading="lazy" v-if="index == indexImageURL" :src="linkImageURL" alt="">
                      </div>
                    </div>
                  </div>
              </div>
              </div>
                
            </div>
            <button v-if="shipments || shipmentHistoryData" class="main-button-green-black" @click.prevent="PrintShipmentPage()">Print this page</button>
        </div>  
        
            <div v-if="!shipments || !shipmentHistoryData" class="shipmentError">
              <div>
                <img src="../../assets/FinalImages/confusedLREXDino.png" alt="">
              </div>
                <div v-if="!shipments">
                    <p>Shipment Data Unavailable.</p>
                </div>
                <div v-if="!shipmentHistoryData">
                    <p>Shipment History Unavailable.</p>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import AlertUser from './AlertUser.vue';
import LoadingData from './LoadingData.vue';
export default {
    props: {
        ['ShipmentTrackingTogglePopup']: String,
        shipmentIdProp: String
    },
    components:{
        AlertUser,
        LoadingData
    },
    data(){
        return{
            posts:{
              shipmentId: null,
              IncludeImageURL: true,
              ZipCode: undefined
            },
            labelImage: '',
            shipments: {},
            shipmentHistoryData: {},
            showShipmentHistoryData: false,
            error: {},
            loading: false,
            showShipmentData: false,
            headerMessage: "Loading Shipment Data",
            toggleAlertBox: false,
            alertMessage: "Invalid Zip Code",
            showAdditionalInformation: false,
            downArrow: true,
            upArrow: false,
            indexImageURL: '',
            linkImageURL: '',
            toggleEnterZip: true,
            GetShipmentHistoryByShipmentIdEndpoint: "",
            GetShipmentByShipmentIdEndpoint: ""
        }
    },
    methods:{
      scrollToTop(){
        window.scrollTo(0,0);
      },
      GetDeliveryImages(imageURL, indexImageURL){
        axios.get(imageURL).then((response)=>{
          this.indexImageURL = indexImageURL;
          this.linkImageURL = response.data;
        }).catch((err)=>{alert(err + "\n" + "Requested file does not exist.")})
      },
      closeAlertBox(toggleAlertBox){
        this.toggleAlertBox = toggleAlertBox;
      },
      ShowShipmentInformation(){
        this.showAdditionalInformation = !this.showAdditionalInformation;
        this.downArrow = !this.downArrow;
        this.upArrow = !this.upArrow;
      },
      ErrorSetShipmentObjects(){
        this.toggleAlertBox = true;
        this.shipments = null;
        this.shipmentHistoryData = null;
        this.showShipmentHistoryData = false;
      },
      async GetShipmentHistoryByID() {
        this.loading = true;
        
        if(this.shipmentIdProp != null){
          if(this.shipmentIdProp.length == 8){
            this.posts.shipmentId = this.shipmentIdProp;
          }else if(this.shipmentIdProp.length >= 8){
            this.posts.shipmentId = this.shipmentIdProp.substring(0,8);
          }else{
            this.posts.shipmentId = 0;
            this.toggleAlertBox = true;
            this.alertMessage = "Invalid Shipment ID";
          }

          if(this.posts.shipmentId.length == 8){
            //https://api.stage.njls.com/api/rest/GetShipmentHistoryByShipmentIdNoAuth
            //https://localhost:44368/api/Rest/GetShipmentHistoryByShipmentIdNoAuth
            //https://api.lrex.com/api/rest/GetShipmentHistoryByShipmentIdNoAuth
            //this.GetShipmentHistoryByShipmentIdEndpoint
            await axios.post(this.GetShipmentHistoryByShipmentIdEndpoint, this.posts)
            .then((response) => {
              // console.log(response)
              this.shipmentHistoryData = response.data.shipmentHistory;
              if(response.data.shipmentHistory){
                this.showShipmentHistoryData = true;
              }

              if(response.data.companyLogoImage[0].companyLogoImage[0] != null){
                this.labelImage = 'data:image/png;base64,' + response.data.companyLogoImage[0].companyLogoImage[0].LabelImage;
              }else{
                this.labelImage = "";
              }

              this.error = response.data.error;
              })
            .catch(error => {
              if(error){
                this.alertMessage = "Error Getting Shipment Data";
                this.ErrorSetShipmentObjects();
              }
              }).finally(()=> this.loading = false)
          }else{
            this.loading = false;
            this.alertMessage = "Invalid Shipment ID";
            this.ErrorSetShipmentObjects();
          }
        }else{
          //Shipment ID was blank
          this.alertMessage = "Enter a valid Shipment ID";
          this.loading = false;
          this.ErrorSetShipmentObjects();
        }
      },
      async GetShipmentByID() {
        this.loading = true;
        this.removeDeliveryImages = false;
        
        if(this.shipmentIdProp != null){
          if(this.shipmentIdProp.length == 8){
            this.posts.shipmentId = this.shipmentIdProp;
          }else if(this.shipmentIdProp.length >= 8){
            this.posts.shipmentId = this.shipmentIdProp.substring(0,8);
          }else{
            //Invalid Id
            this.posts.shipmentId = 0;
            this.toggleAlertBox = true;
            this.alertMessage = "Invalid Shipment ID";
          }

          if(this.posts.shipmentId.length == 8){
            //https://api.stage.njls.com/api/rest/GetShipmentByShipmentIdNoAuth
            //https://api.lrex.com/api/rest/GetShipmentByShipmentIdNoAuth
            await axios.post(this.GetShipmentByShipmentIdEndpoint, this.posts)
            .then((response) => {
              // console.log(response.data)
              if((response.data.zipCodeError != null) && (response.data.zipCodeError[0].zipCodeError == true)){
                this.toggleAlertBox = true;
                this.removeDeliveryImages = true;
              }else{
                this.showShipmentData= true;
                this.toggleEnterZip = false;
                this.shipments = response.data.shipment
                this.error = response.data.error
              }
            })
          .catch((error) => {
            if(error){
              this.alertMessage = "Error Getting Shipment Data";
              this.ErrorSetShipmentObjects();
            }
            })
          .finally(()=> this.loading = false)
          }else{
            this.loading = false;
            this.alertMessage = "Invalid Shipment ID";

            this.ErrorSetShipmentObjects();
          }
        }else{
          this.loading = false;
          this.alertMessage = "Enter a valid Shipment ID";

          this.ErrorSetShipmentObjects();
        }
      },
      PrintDiv(){
        var divContents = document.getElementById("ShipmentTracking-container").innerHTML;
        var a = window.open('', '', 'height=1000, width=1000');
        a.document.title = "LREX Shipment Information"
        a.document.write('<html>');
        a.document.write('<head><style> body{font-family: sans-serif; font-size: 16px;} h3{border-bottom:2px solid black} button,.proofDelivery,.locationDelivery{display: none;} th,td{border: 1px solid black;} th{background-color: #33f18a} table{width: 70%;}</style></head>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
      },
      PrintShipmentPage(){
        var divContents = document.getElementById("ShipmentTracking-container").innerHTML;
        var a = window.open('LREX', 'LREX', 'height=1000, width=1000');
        a.document.write('<html>');
        a.document.write('<head>'
          + '<title>LREX Shipment Information</title>'
          +'<style> '
          + '.shipment_data img{width: 150px;}'
          + 'body{font-family: sans-serif; font-size: 16px;}'
          + '.shipment-information-inner p{ margin-top: 0 !important; margin-bottom: 0 !important; }'
          + '.shipment-information{display: flex !important; padding: 0 !important;}'
          + '.shipment-information p{margin-right: 5px; padding-right: 5px; border-right: 2px solid black;}'
          + '.additional-information{display: none;}'
          + '.input-zip-container{display: none;}'
          + '.proof-delivery-image{width: 15%;}' 
          + '.inner-history-container{border: 1px solid #b3b3b3;border-radius: 15px;padding: 15px;margin: 15px;margin-left: 12.5%; width: 50%;}'
          + '.check-mark.active{display: none;}'
          + '.check-mark.activeLast{display: none;}'
          + '.check-mark{display: none;}'
          + '.check-mark-item{display: none;}'
          + '.check-mark-item img{ display: none;}'
          + '.check-mark-container{display: none;}'
          + '.history-description{font-weight: bold;}'
          + '.history-description-container{display: flex;flex-direction: row;align-items: center;justify-content: space-between;}'
          + '.history-process-date{background: #f0f0f0;color: #858585;border-radius: 2.5px;padding: 2px;}'
          + '.history-container{position: relative;display: flex;justify-content: flex-start;align-items: center;}'
          + 'h3{border-bottom:2px solid black}'
          + 'button,.proofDelivery,.locationDelivery{display: none;}'
          + 'th,td{border: 1px solid black;}'
          + 'th{background-color: #33f18a}' 
          + 'table{width: 70%;}'
          + '</style>'
          + '</head>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');

        setTimeout(function () {
          a.document.close();
          a.focus();
          a.print();
          a.close(); 
        }, 1000);
      },
      async getShipmentData(){
            await axios.get('../runtimeConfig.json').then((response)=>{
              // console.log(response.data)
              this.GetShipmentByShipmentIdEndpoint = response.data.apiEndpoints[0].GetShipmentByShipmentId;
              this.GetShipmentHistoryByShipmentIdEndpoint = response.data.apiEndpoints[0].GetShipmentHistoryByShipmentId;
            })

            this.GetShipmentHistoryByID()
        }
    },
    mounted(){
      this.scrollToTop();
      // this.GetShipmentHistoryByID();
      this.getShipmentData();
    },
}
</script>

<style scoped>
@import "../../styles/button-styles.css";
.main-button-blue-white{
  padding-top: 5px;
  padding-bottom: 5px;
}

.main-button-green-black{
  font-size: 15px;
  padding: 12px;
  margin-bottom: 15px;
}

.input-zip-container p{
  padding: 5px 10px;
  border-radius: 50px;
  margin-bottom: 10px !important;
  background-color: #fe804d !important;
  width: fit-content;
  font-weight: bold;
  margin-bottom: 15px;
  color: white;
}

.input-zip-inner input{
  padding: 10px;
  margin-left: 10px;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.336);
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
}

.zip-form-input{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-zip-inner{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 50px;
}

.input-zip-container{
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Shipment Information */

.shipment-info-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.shipment-info-container-inner{
  width: 48%;
}

.shipment-info-container-inner.remarks{
  border: 1px solid #b3b3b3;
  border-radius: 15px;
  padding: 5px;
}

.shipment-info-container-inner p{
  margin-left: 10px;
}

.additional-information p, .shipment-information-inner p{
  margin-left: 0;
}
/* History Check Mark */

.inner-history-container{
  border: 1px solid #b3b3b3;
  border-radius: 15px;
  padding: 15px;
  margin: 15px;
  margin-left: 12.5%;
  width: 50%;
}

.history-container{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.check-mark.active{
  background: linear-gradient(
    to bottom, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.check-mark.activeLast{
  background: linear-gradient(
    to top, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.proof-delivery-image{
  width: 100px;
  border-radius: 5px;
}

.proof-delivery-container{
  display: flex;
  flex-direction: column;
}

.proof-delivery-container button{
  width: 140px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.check-mark{
  background-color:#33f18a;
  color: #fff;
  width: 2.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}

.check-mark-item{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #33f18a;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.check-mark-item img{
  width: 20px;
}

.check-mark-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-description{
  font-weight: bold;
}

.history-process-date{
  background: #f0f0f0;
  color: #858585;
  border-radius: 2.5px;
  padding: 2px;
}

.history-description-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

p{
  margin-top: 0;
  margin-bottom: 0;
}

.delivery-image-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delivery-image-container img{
  width: 40%;
  margin-top: 10px;
}

.down-arrow{
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-bottom: 3px;
  margin-left: 3px;
}

.up-arrow{
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  margin-top: 3px;
  margin-left: 3px;
}

.additional-information{
  background-color: #32ccfe;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 4px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 50px;
  cursor: pointer;
  transition-duration: .25s;
}

.additional-information:hover{
  background-color: #2db4e0;
  transition-duration: .25s;
}

.shipment-information{
  padding: 4px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #b3b3b3;
  border-radius: 15px;
  width: fit-content;
  animation: animate-additional-info .35s ease;
}

th:first-child{
  border-top-left-radius: 15px;
}

th:last-child{
  border-top-right-radius: 15px;
}

.tracking-header{
  padding: 5px 10px;
  border-radius: 50px;
  background-color: #fe804d !important;
  border-bottom: 1px solid #fe804d !important;
  width: fit-content !important;
  color: white;
}

@keyframes animate-additional-info {
  from{margin-top: -15px}
  to{margin-top: 0;}
}

.popup{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: drop .5s ease forwards;
  
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 98;

  font-family: 'Work Sans', sans-serif;
}

.popup-inner{
  width: fit-content;
  height: auto;
  max-height: 95vh;
  border-radius: 15px;
  background-color: white;

  display: flex;
  flex-direction: column;
  text-align: left;

  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  /* overflow-y: auto; */
  overflow-y: hidden;
  resize: both;

  scrollbar-color: #33f18a;
  scrollbar-base-color: white;
  scrollbar-width: thin;
}

.popup-inner::-webkit-scrollbar {
  background-color: white;
  border-radius: 10px;
  width: 5px;
  height: 5px;
}

.popup-inner::-webkit-scrollbar-thumb{
  background-color: #33f18a;
  border-radius: 10px;
}

.popup-close{
  position: absolute;
  top: 5px;
  justify-content: center;
  margin-left: 2.5vw;
}

.ShipmentTracking-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

@keyframes drop {
  0%{}
  70%{transform: translateY(20px);}
  100%{transform: translateY(1px);}
}

.close-popup-container{
  margin-left: auto;
  margin-right: 5px;
}

.fa-times-circle{
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  transition-duration: .5s;
}

.fa-times-circle:hover{
  color: rgb(218, 218, 218);
  cursor: pointer;
  transition-duration: .5s;
}

.closeShipmentTracking-container{
  width: 100%;
  height: 50px;
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2cb6e4;
  border-radius: 15px 0px 0 0;
}

.closeShipmentTracking-container h1{
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  flex: 1;
}

.ShipmentTrackingButton{
  background-color: #33f18a;
  color: rgb(255, 255, 255);
  width: 42%;
  height: 30px;
  margin-top: 0vw;
  margin-bottom: 1vw;
  text-shadow: 1px 1px 4px #696969;
  font-weight: 600;
  border-radius: 50px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Popup */
.shipment_data{
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 5%;
}

.shipment_data h3{
  padding: 5px 10px;
  border-bottom: 1px solid #33f18a;
  border-radius: 50px;
  background-color: #33f18a;
  margin-bottom: 5px;
  margin-top: 5px;
}

.shipment-table{
  width: 65%;
  margin-bottom: 15px;
}

.shipment-table th{
  background-color: #33f18a;
}

.shipment-table td, th{
  padding: 5px;
  border: 1px solid #ddd;
}

.logo{
  width: 175px;
  height: auto;
  margin-top: 5px !important;
}

.shipmentError{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px;
}

.shipmentError img{
  width: 100px;
}

.delivery-image-link{
  text-decoration: none;
  color: #ffffff;
  background-color: #32ccfe;
  border-radius: 50%;
  padding: 2.5px;
  transition-duration: .5s ease;
}

.delivery-image-link:hover{
  background-color: #2877d1;
  transition-duration: .5s ease;
}

@media only screen and (max-width: 1000px){

  .ShipmentTrackingButton{
      margin-bottom: 5vw;
      margin-top: 1vw;
      width: 70%;
  }

  .shipment_data h3{
    margin-bottom: 5px;
    width: 95%;
  }

  .inner-history-container{
      width: 70%;
    }

  .shipment-info-container{
    width: 95%;
  }
}

@media only screen and (max-width: 650px){
  .popup-inner{
    margin-left: 5px;
    margin-right: 5px;
  }

  .shipment-info-container{
    flex-direction: column;
    width: 100%;
  }

  .shipment-info-container-inner{
    width: 100%;
  }
}
</style>
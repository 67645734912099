<template>
  <div v-if="shipmentData" class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <!-- <button class="close-shipment-info" @click="closeShipmentInfo()">Close</button> -->
                    <svg class="close-shipment-info" @click="closeShipmentInfo()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">Package Details {{shipmentData[0].shipmentID}}</h1>
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="overflow-container-outer">

                <div class="inner-overflow-container" id="inner-overflow-container">
                    <div class="confirm-svg">
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                            <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                <g>
                                <g>
                                    <polyline class="cls-2-alt" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                                    <line class="cls-2-alt" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                                    <polyline class="cls-2-alt" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                                    <line class="cls-2-alt" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                                    <line class="cls-2-alt" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                                </g>
                                <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                </g>
                            </g>
                        </svg>
                        <h2>Processed Activities</h2>
                    </div>
                    <div>
                        <div class="shipment-history-container">
                            <div class="history-container" v-for="(items, index) in getShipmentActivitiesData" v-bind:key="items">
                                <div class="check-mark-container">
                                    <div class="check-mark" :class="{active:index==0, activeLast:index==getShipmentActivitiesData.length - 1}">
                                        <div class="check-mark-item"><img src="../../../assets/FinalImages/checkMark.png" alt=""></div>
                                    </div>
                                </div>
                                <div class="inner-history-container">
                                    <div class="history-description-container">
                                        <p class="history-description" style="margin-right: 10px;"><span style="font-weight: bold;">Status ID: </span>{{items.statusID}}</p>
                                        <p class="history-process-date">{{items.activityTime}}</p>
                                    </div>
                                    <p>{{items.internalComments}}</p>
                                    <div v-if="items.imageKeyID > 0">
                                        <div class="proof-delivery-container">
                                            <button v-if="(items.imageKeyID > 0)" @click="GetDeliveryImages(items.imageKeyID, index)" class="main-button-blue-white">Show Image</button>
                                            <img class="proof-delivery-image" loading="lazy" v-if="index == indexImageURL" :src="linkImageURL" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                    <div class="confirm-svg">
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                            <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                <g>
                                <g>
                                    <polyline class="cls-2-alt" points="213.95 310.25 213.95 280.72 192.99 240.34 66.07 240.34 45.11 284.23 45.11 310.25"/>
                                    <line class="cls-2-alt" x1="48.63" y1="284.23" x2="213.95" y2="284.23"/>
                                    <polyline class="cls-2-alt" points="150.64 284.23 150.64 323.54 129.53 314.13 108.43 323.54 108.43 284.23"/>
                                    <line class="cls-2-alt" x1="115.46" y1="240.34" x2="108.43" y2="284.23"/>
                                    <line class="cls-2-alt" x1="150.64" y1="284.23" x2="143.6" y2="240.34"/>
                                </g>
                                <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                </g>
                            </g>
                        </svg>
                        <h2>Queued Activities</h2>
                    </div>
                    <div>
                        <div class="shipment-history-container">
                            <div class="history-container" v-for="(items, index) in shipmentData" v-bind:key="items">
                                <div class="check-mark-container">
                                    <div class="check-mark" :class="{active:index==0, activeLast:index==shipmentData.length - 1}">
                                        <div class="check-mark-item"><img src="../../../assets/FinalImages/checkMark.png" alt=""></div>
                                    </div>
                                </div>
                                <div class="inner-history-container">
                                    <div class="history-description-container">
                                        <p class="history-description" style="margin-right: 10px;"><span style="font-weight: bold;">Status ID: </span>{{items.statusID}}</p>
                                        <p class="history-process-date">{{items.activityTime}}</p>
                                    </div>
                                    <p>{{items.internalComments}}</p>
                                    <div v-if="items.imageKeyID > 0">
                                        <div class="proof-delivery-container">
                                            <button v-if="(items.imageKeyID > 0)" @click="GetDeliveryImages(items.imageKeyID, index)" class="main-button-blue-white">Show Image</button>
                                            <img class="proof-delivery-image" loading="lazy" v-if="index == indexImageURL" :src="linkImageURL" alt="">
                                        </div>
                                    </div>
                                    <div class="remove-activity-container">
                                       <span style="font-weight: bold;">Remove activity:</span> <input type="checkbox" v-model="selectedActivities" :value="items" name="" id=""> 
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- {{selectedActivities}} -->
                
                </div>
                <div class="button-container">
                    <button class="process-activities-button" @click="processActivities()">Process Activities</button>
                </div>
                
                <loading-charts v-if="loadingData"/>
            </div>
    </div>
  </div>
   <AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/> 
</template>

<script>
import {API} from 'aws-amplify';
import axios from 'axios'
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import AlertUser from '../../Popups/AlertUser.vue'

export default {
    props:['trackShipmentID', 'userData', 'jwtToken', 'cognitoUser'],
    components:{
        LoadingCharts,
        AlertUser
    },
    data(){
        return{
            shipmentData: null,
            toggleAlertBox: false, 
            alertMessage: null,
            labelData: null,
            loadingData: false,
            updateServiceAddressToggleValue: false,
            updatePickupAddressToggleValue: false,
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            internalCommentInput: "",
            linkImageURL: '',
            indexImageURL: 0,
            getShipmentActivitiesData: [],
            selectedActivities: [],
            finalProcessedActivities: []
        }
    },
    methods:{
        async processActivities(){
            const newArray = this.shipmentData.filter(item1 => !this.selectedActivities.some(item2 => item1.id === item2.id)).map(({ id, shipmentID, ...rest }) => {
                console.log(id)
                console.log(shipmentID)
                return rest
            });

            let concatArray = this.getShipmentActivitiesData.concat(newArray)
            this.finalProcessedActivities = concatArray.sort((a, b) => new Date(b.activityTime) - new Date(a.activityTime))
            await this.updateShipmentActivities();

            this.shipmentData.forEach(element => {
                this.DeleteQueuedActivities(element.id) 
            });
        },
        async GetDeliveryImages(imageKeyId, indexImageURL){
            let dataFound = false;
            const endpoints = [
                'https://s2ai.njls.com/s2ai/s2ai/read/4988C3F4-5619-4E41-A82B-2138586ECEA9/NJLS/DeliveryImages/0/Images/'+ imageKeyId + '.jpg',
                'https://s2ai.njls.com/s2ai/s2ai/read/4988C3F4-5619-4E41-A82B-2138586ECEA9/NJLS/DeliveryImages/0/Images/'+ imageKeyId + '.png'
            ]
            this.indexImageURL = indexImageURL;
            for(const endpoint of endpoints){
                try {
                    const response = await axios.get(endpoint);
                    if(response.data) {
                        this.linkImageURL = response.data;
                        dataFound = true;
                        break;
                    }
                }catch(error){
                    console.log(error)
                }
            }

            if(!dataFound){
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to access delivery image."
            }
        },
        async updateShipmentActivities(){
            let input = {
                shipmentID: this.shipmentData[0].shipmentID,
                activity: this.finalProcessedActivities
            }
            try{
                let updateShipment = await API.graphql({
                query:  `mutation updateShipment($input: UpdateShipmentInput!){
                            updateShipment(input: $input){
                                shipmentID
                            }
                        }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: {input}
                })
                if(updateShipment.data.updateShipment.shipmentID){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Updated shipment " + updateShipment.data.updateShipment.shipmentID
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error updating shipment"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }
            }
        },
        async DeleteQueuedActivities(queuedActivityID){
            let input = {
                id: queuedActivityID
            }
            try{
                let deleteQueuedActivities = await API.graphql({
                query:  `mutation deleteQueuedActivities($input: DeleteQueuedActivitiesInput!){
                            deleteQueuedActivities(input: $input){
                                shipmentID
                            }
                        }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: {input}
                })

                if(deleteQueuedActivities.data){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Queue Cleared."
                }
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error deleting queued activity."
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }
            }
        },
        async getShipmentActivities(){
            try{
                let getShipment = await API.graphql({
                query:  `query getShipment{
                    getShipment(shipmentID: "${this.trackShipmentID}"){
                        activity{
                            code
                            description
                            statusID
                            shipmentStage
                            shipmentPoint
                            shippingEvent
                            activityTime
                            recordedTime
                            comments
                            internalComments
                            notes
                            latitude
                            longitude
                            driverCode
                            manifestSource
                            imageKeyID
                            imageKey
                            ignoreActivity
                        }
                    }
                }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                if(getShipment.data.getShipment.activity == 0){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting activities"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }else{
                    this.getShipmentActivitiesData = getShipment.data.getShipment.activity;
                }
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting shipment"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }
            }
        },
        async listShipmentQueuedActivities(){
            try{
                let listQueuedActivities = await API.graphql({
                query:  `query MyQuery {
                    listQueuedActivities(filter: {shipmentID: {eq: "${this.trackShipmentID}"}}) {
                        items {
                            id
                            shipmentID
                            code
                            description
                            statusID
                            shipmentStage
                            shipmentPoint
                            shippingEvent
                            activityTime
                            recordedTime
                            comments
                            internalComments
                            notes
                            
                            latitude
                            longitude
                            driverCode
                            manifestSource
                            imageKeyID
                            imageKey
                            ignoreActivity
                        }
                    }
                }`,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                if(listQueuedActivities.data.listQueuedActivities.items.length == 0){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting activities"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }else{
                    this.shipmentData = listQueuedActivities.data.listQueuedActivities.items;
                }
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting shipment"
                    setTimeout(() => {
                        this.closeShipmentInfo();
                    }, 1000);
                }
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeShipmentInfo(){
            this.$emit('closeShipmentInfo', false)
        }
    },
    mounted(){
        this.listShipmentQueuedActivities();
        this.getShipmentActivities();
        if(this.cognitoUser.signInUserSession.idToken.payload['cognito:groups']){
            this.cognitoUser.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    /* margin-top: 5px; */
    cursor: pointer;
    padding: 0;
    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.process-activities-button{
    width: fit-content;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: #32ccfe;
    border-radius: 99px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition-duration: .25s;
}

.process-activities-button:hover{
    background-color: #2bb0dc;
    transition-duration: .25s;
}

.button-container{
    display: flex;
    justify-content: center;
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.overflow-container-outer{
    display: flex;
    max-height: 80vh;
    flex-wrap: wrap;
    max-height: 90%;
    height: fit-content;
    overflow-y: auto;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    max-width: 95vw;
    overflow-x: auto;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.legend-header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-legend-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 5px;
    border-radius: 25px;
    background-color: #f5f5f5;
    margin-bottom: 5px;
}

.map-legend{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-legend p{
    margin-left: 5px;
}

.weight-container{
    background-color: #fff;
    margin: 0px 5% 2% 5%;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    height: fit-content;
    /* overflow-y: auto; */
    margin-bottom: 10px;
    margin: 5px;
    /* border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid; */
    border: 1px #d5d5d5 solid;
    /* flex-basis: ; */
    border-radius: 25px;
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.auto-complete-container{
    margin-bottom: 5px !important;
    width: 90%;
    display: block;
    margin: auto;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}

.notifications{
    background-color: #f5f5f5;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin: 5px;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainerInner2{
    background-color: #fff;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.confirmContainerInner2 h2{
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

.add-comment-container{
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: row;
}

.add-comment-container textarea{
    border: 1px solid #e2e2e2;
    border-right: none;
    padding: 5px;
    border-radius: 10px 0px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    outline: none;
    margin-top: 0;
}

.add-comment-container button{
    cursor: pointer;
    border: 1px solid #e2e2e2;
    background-color: #32ccfe;
    color: #fff;
    padding: 5px;
    border-radius: 0px 10px 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    transition-duration: .5s;
}

.add-comment-container button:hover{
    background-color: #2baeda;
    transition-duration: .5s;
}


.notificationArray p{
    display: flex;
    flex-direction: column;
    margin: 2px;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.create-button{
    background-color: #33f18a;
    margin-left: 25px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.cancel-button{
    background-color: #fe804d;
    margin-left: 25px;
}

.cancel-button:hover{
    transition-duration: .5s;
    background-color: #e27144;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

/* History Check Mark */
.inner-history-container p{
    margin: 0;
}

.inner-history-container{
  border: 1px solid #b3b3b3;
  border-radius: 15px;
  padding: 15px;
  margin: 15px;
  margin-left: 12.5%;
  /* width: 50%; */
}

.history-container{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.check-mark.active{
  background: linear-gradient(
    to bottom, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.check-mark.activeLast{
  background: linear-gradient(
    to top, 
    #ffffff 0%, 
    #ffffff 50%, 
    #33f18a 50%, 
    #33f18a 100%
  );
}

.main-button-blue-white{
    padding-top: 5px;
    padding-bottom: 5px;
}

.proof-delivery-image{
  width: 100px;
  border-radius: 5px;
}

.proof-delivery-container{
  display: flex;
  flex-direction: column;
}

.proof-delivery-container button{
  width: 140px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.check-mark{
  background-color:#33f18a;
  color: #fff;
  width: 2.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}

.check-mark-item{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #33f18a;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.check-mark-item img{
  width: 20px;
}

.check-mark-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-description{
  font-weight: bold;
}

.history-process-date{
  background: #f0f0f0;
  color: #858585;
  border-radius: 2.5px;
  padding: 2px;
}

.history-description-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.remove-activity-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f0f0f0;
  border-radius: 25px;
  padding: 2px 5px;
}

.label-container{
    margin-top: 5px;
    width: 100%;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1, .confirmContainerInner2{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }

    .inner-history-container{
        margin: 15px;
        margin-left: 12.5%;
        width: 70%;
    }
}
</style>
<template>
<div class="main-container-contact-us">
  <div class="contact-info-main-container">
      <div class="contact-info-container">
          <div class="svg-container">
              <svg data-name="Livello 1" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><path d="M1 33A32 32 0 1 0 33 1 32 32 0 0 0 1 33Z" fill="#33f18a" class="fill-000000"></path><rect height="24.18" rx="1.48" ry="1.48" width="32" x="17" y="21" stroke-miterlimit="10" fill="none" stroke="#404040" stroke-width="2px" class="stroke-ffffff"></rect><path d="M48.44 21.83 37.53 33.54a6.27 6.27 0 0 1-9.07 0l-10.9-11.7M39.19 31.75l8.96 12.76M26.98 31.88 17.7 44.51" stroke-linejoin="round" fill="none" stroke="#404040" stroke-width="2px" class="stroke-ffffff"></path></svg>
          </div>
          <p>customerservice@lrex.com</p>
      </div>
      <div class="contact-info-container">
          <div class="svg-container">
              <svg data-name="Livello 1" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><path d="M1 34A32 32 0 1 0 33 2 32 32 0 0 0 1 34Z" fill="#33f18a" class="fill-000000"></path><path d="M34.93 40.84c1-.09 2.95-1.92 4-2a4 4 0 0 1 2.78.8l2.68 2L46 42.92a2.52 2.52 0 0 1 .43 3.62l-3.11 4a1.12 1.12 0 0 1-1 .43c-13.5-1.21-24.24-12.26-24.24-25.68a25 25 0 0 1 1-7 .66.66 0 0 1 .5-.45l5.91-.83a2.63 2.63 0 0 1 3.12 2L29 21l.65 3.26a3.75 3.75 0 0 1-.53 2.78c-.54.84-3.09 1.68-3.64 2.53h0A19.39 19.39 0 0 0 34.93 41v-.16Z" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" class="stroke-ffffff"></path></svg>
          </div>
          <p>(908) 686-7300</p>
      </div>
  </div>
  <div class="contact-us-container">
      <div @click="AnimateContactButton()" class="contact-us-button">
          <div class="svg-container">
              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 249">
                <g id="Layer_2-2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1-2">
                    <g>
                        <g>
                        <g id="_010" data-name=" 010">
                            <circle cx="124.5" cy="124.5" r="124.5" style="fill: #33f18a;"/>
                        </g>
                        <g id="_010-2" data-name=" 010-2">
                            <circle cx="124.5" cy="124.5" r="110.5" style="fill: #282828;"/>
                        </g>
                        </g>
                        <path d="M113,171.89c15.25,14.7,36.81,19.15,58.37,9.96l18.63,31.14-6.53-38.3c20.1-16.11,16.55-38.24,1.7-53.32-.99,5.75-2.93,11.3-5.76,16.41-4.16,7.4-9.82,13.84-16.63,18.91-6.99,5.28-14.86,9.28-23.24,11.82-8.61,2.57-17.57,3.72-26.55,3.43v-.05h0Zm-26.03-46.89c-2.21-.13-3.9-2.02-3.77-4.23,.12-2.03,1.74-3.66,3.77-3.77h38.56c2.21,.13,3.9,2.02,3.77,4.23-.12,2.03-1.74,3.66-3.77,3.77h-38.56Zm-.68-20c-2.21-.13-3.9-2.02-3.77-4.23,.12-2.03,1.74-3.66,3.77-3.77h55.09c2.21,.13,3.9,2.02,3.77,4.23-.12,2.03-1.74,3.66-3.77,3.77h-55.09Zm29.1-44h0c17.18,.56,32.53,6.64,43.51,16.06,11.22,9.61,17.93,22.7,17.52,36.96h0c-.44,14.35-7.94,27.01-19.73,35.96-11.56,8.77-27.27,13.94-44.4,13.44-4.64-.13-9.25-.69-13.78-1.67-3.98-.85-7.89-2.03-11.67-3.55l-27.71,10.88,9.26-22.02c-5.13-4.39-9.35-9.73-12.44-15.73-3.31-6.47-4.93-13.67-4.73-20.93,.45-14.29,7.96-26.94,19.74-35.92,11.56-8.75,27.28-13.95,44.4-13.48h.03Zm-.18,7h-.06c-15.46-.41-29.48,4.18-39.76,11.99-10.07,7.64-16.48,18.35-16.83,30.36-.16,6.04,1.2,12.02,3.96,17.39,3.05,5.88,7.33,11.02,12.55,15.08l2.16,1.73-4.63,11.08,14.28-5.61,1.38,.59c3.78,1.6,7.7,2.84,11.71,3.7,4.12,.89,8.31,1.4,12.52,1.52,15.37,.45,29.55-4.15,39.79-11.96,10.06-7.64,16.47-18.33,16.83-30.3h0c.34-11.95-5.39-22.99-14.96-31.21-9.79-8.43-23.54-13.84-38.91-14.31l-.02-.04h0Z" style="fill: #33f18a; fill-rule: evenodd;"/>
                    </g>
                    </g>
                </g>
              </svg>
          </div>
          <p v-if="contactInfoToggle == false">Need more help?</p>
          <p v-if="contactInfoToggle == true">Click to see less.</p>
      </div>
  </div>

</div>
</template>

<script>
export default {
    data(){
        return{
            contactInfoToggle: false
        }
    },
    methods:{
        AnimateContactButton(){
            let contactInfo = document.querySelector('.contact-info-main-container');
            let contactButton = document.querySelector('.contact-us-container');
            let mainContainer = document.querySelector('.main-container-contact-us')

            if(this.contactInfoToggle == false){
                contactButton.style.borderTopLeftRadius = '0px';
                contactButton.style.borderTopRightRadius = '0px';
                contactButton.style.borderBottomLeftRadius = '25px';
                contactButton.style.borderBottomRightRadius = '25px';
                mainContainer.classList.remove("animate-contact-us-container-shrink");
                mainContainer.classList.add("animate-contact-us-container");
                contactInfo.classList.remove("animate-contact-info-down");
                
                contactInfo.classList.add("animate-contact-info-up");
                
                this.contactInfoToggle = true;
                return;
            }
            if(this.contactInfoToggle == true){
                contactButton.classList.remove("animate-contact-us-container");
                contactInfo.classList.add("animate-contact-info-down");
                mainContainer.classList.remove("animate-contact-us-container");
                mainContainer.classList.add("animate-contact-us-container-shrink");
                setTimeout(() => {
                    contactInfo.classList.remove("animate-contact-info-up");
                    contactButton.style.borderRadius = '999px';
                }, 500);
                this.contactInfoToggle = false;
                return;
            }
        }
    }
}
</script>

<style scoped>
    .main-container-contact-us{
        position: fixed;
        bottom: 5px;
        right: 5px;
        z-index: 99;
        overflow: hidden;
        border-radius: 25px;
    }

    .contact-us-container{
        background-color: #282828;
        border-radius: 999px;
        padding: 10px 5px;
        color: #fff;
        cursor: pointer;
        position: relative;
        z-index: 99;
        overflow: hidden;
        margin-left: auto;
    }

    .animate-contact-us-container-shrink{
        animation: 1s animate-contact-us-container-shrink ease;
    }

    @keyframes animate-contact-us-container-shrink{
        from{ width: 275.906px; }
        to{ width: 198.719px; }
    }

    .animate-contact-us-container{
        animation: 1s animate-contact-us-container ease;
    }

    @keyframes animate-contact-us-container{
        from{ width: 198.719px; }
        to{ width: 275.906px; }
    }

    .svg-container{
        margin-right: 5px;
        position: relative;
        z-index: 98;
    }

    .svg-container svg{
        width: 50px;
    }

    .contact-us-button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        height: 50px;
    }

    .contact-info-main-container{
        background-color: #404040;
        padding: 5px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 97;
        display: none;
    }

    .animate-contact-info-up{
        display: flex;
        animation: 1s animate-contact-info-up ease;
    }

    .animate-contact-info-down{
        animation: .5s animate-contact-info-down ease;
    }

    @keyframes animate-contact-info-up {
        0%{ height: 0px; }
        100%{ height: 110px; }
    }

    @keyframes animate-contact-info-down {
        0%{ height: 110px;}
        100%{ height: 0px;}
    }

    .contact-info-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
    }
</style>
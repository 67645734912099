<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="table-container">
            <div class="header-track-shipment">
                    <div class="header-track-inner">
                        <svg class="address-book-svg" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <g>
                                <path d="m21,5H4c-.6,0-1-.4-1-1s.4-1,1-1h17c.6,0,1,.4,1,1s-.4,1-1,1Z" style="fill: #000;"/>
                                <path d="m21,6H4c-1.1,0-2-.9-2-2s.9-2,2-2h17c.6,0,1-.4,1-1s-.4-1-1-1H4C1.8,0,0,1.8,0,4v20c0,2.2,1.8,4,4,4h17c.6,0,1-.4,1-1V7c0-.6-.4-1-1-1Z" style="fill: #000;"/>
                                </g>
                                <path d="m6.85,14.05c0-2.29,1.86-4.15,4.15-4.15s4.15,1.86,4.15,4.15" style="fill: none; stroke: #e2e2e2; stroke-miterlimit: 10; stroke-width: .8px;"/>
                                <path d="m15.15,14.05c0,3.35-4.15,7.75-4.15,7.75,0,0-4.15-4.4-4.15-7.75" style="fill: none; stroke: #e2e2e2; stroke-miterlimit: 10; stroke-width: .8px;"/>
                                <circle cx="10.95" cy="14" r="1.9" style="fill: none; stroke: #e2e2e2; stroke-miterlimit: 10; stroke-width: .8px;"/>
                            </g>
                        </svg>
                        <p>Your address book</p>
                    </div>
                    <div class="create-address-button">
                        <button @click="createAddressToggle()">Create Address</button>
                        <button @click="importAddressToggle()">Import Address</button>
                    </div>
                </div>
            <address-book-table :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<import-address-book @closeImportAddress="closeImportAddress($event)" :userData="userData" v-if="toggleImportAddress"/>
<create-address-book @closeCreateAddress="closeCreateAddress($event)" :userData="userData" v-if="toggleCreateAddress"/>
</template>

<script>
import {Auth, API} from 'aws-amplify'
import AddressBookTable from '../AddressBook/AddressBookTable.vue'
import CreateAddressBook from '../AddressBook/CreateAddress.vue'
import ImportAddressBook from '../AddressBook/ImportAddress.vue'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'

export default {
    components:{
        AddressBookTable,
        CreateAddressBook,
        ImportAddressBook,
        SignedInUserInfo
    },
    data(){
        return{
            user: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            toggleCreateAddress: false,
            toggleImportAddress: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false
        }
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })

                if (!this.AdminUser && !this.InternalAdmin && !this.Customer && !this.CustomerAdmin) {
                    alert("Access denied to adress book dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
            this.GetUserGraphQL();
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "API_KEY",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        createAddressToggle(){
            this.toggleCreateAddress = true;
        },
        closeCreateAddress(event){
            this.toggleCreateAddress = event;
            //Call Get User to refresh table after creation
            this.GetUserGraphQL();
        },
        importAddressToggle(){
            this.toggleImportAddress = true;
        },
        closeImportAddress(event){
            this.toggleImportAddress = event;
            //Call Get User to refresh table after creation
            this.GetUserGraphQL();
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
}

.table-container{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    height: fit-content;
    flex-basis: 50%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.create-address-button{
    margin-left: auto;
}

.create-address-button button{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.create-address-button button:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.svg-container{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 40%;
    }

    .data-container{
        flex-basis: 40%;
    }

    .table-container{
        flex-basis: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .table-container{
        flex-basis: 90%;
        font-size: 10px;
    }

    .track-shipment{
        flex-basis: 80%;
    }
}
</style>
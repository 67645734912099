<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <h2>Create Account</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="service-levels-inner">
                                        <div class="confirm-svg">
                                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                                                <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                                    <g>
                                                    <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                                    <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                                    <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                                    <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                                    <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                                    <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <h2>Account</h2>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.accountNo" placeholder="Account No.">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.contact1" placeholder="Contact 1">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.contact2" placeholder="Contact 2">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.contact3" placeholder="Contact 3">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="number" v-model="accountInput.defaultWeight" placeholder="Default Weight">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="number" v-model="accountInput.creditLimit" placeholder="Credit Limit">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" disabled v-model="accountInput.locationID" placeholder="Location ID">
                                        </div>

                                        <div class="confirmLabelContainer">
                                                <label for="specialPricing" class="special-pricing-label">Special Pricing</label>
                                                <input type="checkbox" v-model="accountInput.specialPricing" id="specialPricing">
                                            </div>
                                    </div>

                                    <div class="service-levels-inner billing-address">
                                        <div style="margin-top: 5px; margin-bottom: 5px;" class="confirm-svg">
                                            <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                                <g id="image">
                                                    <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                                </g>
                                            </svg>
                                            <h2>Billing Address</h2>
                                        </div>

                                        <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.billingAddress.companyName" placeholder="Company Name">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.billingAddress.attention" placeholder="Attention">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" id="address1Account" disabled v-model="accountInput.billingAddress.address1" placeholder="Address 1">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.billingAddress.address2" placeholder="Address 2">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" id="cityAccount" disabled v-model="accountInput.billingAddress.city" placeholder="City">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <select disabled required name="state" id="stateAccount" v-model="accountInput.billingAddress.state">
                                                <option value="" disabled selected>State</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                            </select>
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" id="zipcodeAccount" disabled v-model="accountInput.billingAddress.zipCode" placeholder="Zip Code">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.billingAddress.phone" placeholder="Phone">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <input type="text" v-model="accountInput.billingAddress.email" placeholder="Email">
                                        </div>

                                        <button v-if="validateCustomAddress" class="update-user-button" @click="checkAddressCorrection()">Check Address</button>

                                        <div class="confirmLabelContainer">
                                            <label for="isActive" style="font-weight: bold; margin-top: 5px; margin-right: 5px;">Set as location</label>
                                            <input id="isActive" name="set-create-location" type="radio" v-model="setAsLocation" :value="true">
                                        </div>

                                        <div v-if="setAsLocation" class="confirmLabelContainer">
                                            <input class="file-input" type="file" accept=".jpg, .jpeg, .png" id="fileInput" @change="setUploadData()">
                                        </div>

                                        <div class="confirmLabelContainer">
                                            <img id="imgPreview" alt="">
                                        </div>

                                        <div v-for="routeCode in routeCodeOptions" :key="routeCode" class="confirmLabelContainer">
                                            <label for="routeOption" style="font-weight: bold; margin-top: 5px; margin-right: 5px;">{{routeCode.routeCode}}</label>
                                            <input id="routeOption" name="set-route-code" type="radio" v-model="routeCodeFromGeoCode" :value="routeCode.routeCode">
                                        </div>

                                        <div v-if="setAsLocation" class="confirmLabelContainer">
                                            <input type="text" id="routeCode" disabled v-model="routeCodeFromGeoCode" placeholder="Route Code">
                                        </div>

                                        <div v-if="accountInput.billingAddress.address1.length > 0" class="confirmLabelContainer">
                                            <label for="isActive" style="font-weight: bold; margin-top: 5px; margin-right: 5px;">Create new location</label>
                                            <input id="isActive" name="set-create-location" type="radio" v-model="createNewLocation" :value="true">
                                        </div>

                                        <div v-if="createNewLocation">
                                            <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />

                                            <div class="confirmLabelContainer">
                                                <input class="file-input" type="file" accept=".jpg, .jpeg, .png" id="fileInput" @change="setUploadData()">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <img id="imgPreview" alt="">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="routeCode" disabled v-model="routeCodeFromGeoCode" placeholder="Route Code">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="newLocation.companyName" placeholder="Company Name">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="newLocation.attention" placeholder="Attention">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="address1Account-newLocation" disabled v-model="newLocation.address1" placeholder="Address 1">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="newLocation.address2" placeholder="Address 2">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="cityAccount-newLocation" disabled v-model="newLocation.city" placeholder="City">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <select disabled required name="state" id="stateAccount-newLocation" v-model="newLocation.state">
                                                    <option value="" disabled selected>State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="DC">District Of Columbia</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                </select>
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="zipcodeAccount-newLocation" disabled v-model="newLocation.zipCode" placeholder="Zip Code">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="newLocation.phone" placeholder="Phone">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="newLocation.email" placeholder="Email">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <product-options :key="refreshProducts" :specialPricing="accountInput.specialPricing" @getSelectedProducts="getSelectedProducts($event)"/>
                        </div>
                    </div>
                <button class="update-user-button" @click="CreateAccountLocationProcess()">Create Account</button>
            </div>
        </div>
        <div v-if="(AdminUser || InternalAdmin)" class="track-shipment update-user">
            <account-table :key="refreshKey" :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<LoadingData v-if="creatingAccountLocation" :headerMessage="headerMessage"/>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="accountInput.billingAddress"/>
</template>

<script>
import {createAccount, createLocation, updateLocation} from '../../../graphql/mutations'
import {Auth, API, Storage} from 'aws-amplify'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'
import AlertUser from '../../../components/Popups/AlertUser.vue'
import AccountTable from '../AccountDashboard/AccountTable.vue'
import ProductOptions from '../ProductDashboard/ProductOptions.vue'
import AWSAutoComplete from '../../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';
import LoadingData from '../../Popups/LoadingData.vue'

export default {
    data(){
        return{
            setAsLocation: true,
            refreshProducts: 0,
            createNewLocation: false,
            placeHolder: "Search for address",
            headerMessage: "Creating Account",
            user: {},
            selectedProducts: [],
            serviceNetworkData: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            accountInput: {
                accountNo: "",
                contact1: "",
                contact2: "",
                contact3: "",
                specialPricing: false,
                defaultWeight: null,
                creditLimit: null,
                billable: false,
                locationID: "",
                billingAddress:{
                    companyName: "",
                    attention: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    phone: "",
                    email: ""
                },
                product: []
            },
            newLocation: {
                companyName: "",
                attention: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                phone: "",
                email: ""
            },
            refreshKey: 0,
            routeCodeFromGeoCode: "",
            routeCodeOptions: [],
            uploadFile: null,
            uploadFileNoSpaces: "",
            logoLocationID: "",
            creatingAccountLocation: false
        }
    },
    components:{
        AlertUser,
        AccountTable,
        SignedInUserInfo,
        AWSAutoComplete,
        ProductOptions,
        AWSAddressCorrection,
        LoadingData
    },
    watch:{
        'setAsLocation'(newValue){
            if(newValue == true){
                this.routeCodeOptions = [];
                this.routeCodeFromGeoCode = "";
                this.setAsLocation = true;
                this.createNewLocation = false;
            }
        },
        'createNewLocation'(newValue){
            if(newValue == true){
                this.routeCodeOptions = [];
                this.routeCodeFromGeoCode = "";
                this.createNewLocation = true;
                this.setAsLocation = false;
            }
        },
        'accountInput.billingAddress.zipCode'(){
            this.GetGeoCodeGraphQL();
        },
        'newLocation.zipCode'(){
            this.GetGeoCodeGraphQL();
        }
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();
            this.listServiceNetworks();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })

                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to account dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async CreateAccountLocationProcess(){
            this.creatingAccountLocation = true;
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            if(this.uploadFile == null){
                this.loadingUser = false;
                this.toggleAlertBox = true;
                this.alertMessage = "Add a company logo."
                this.creatingAccountLocation = false;
                return;
            }

            const fileNameNoSpaces = this.uploadFile.name.split(" ").join("");
            this.uploadFileNoSpaces = this.logoLocationID + fileNameNoSpaces;

            if(this.fileNameNoSpaces == ""){
                this.loadingUser = false;
                this.toggleAlertBox = true;
                this.alertMessage = "Add a company logo."
                this.creatingAccountLocation = false;
                return;
            }

            try{
                if(this.setAsLocation){
                    await this.CreatAccountLocation();
                }
                
                if(this.createNewLocation){
                    this.CreatAccountNewLocation();
                }
                
                await this.PutItemStorage();
                await this.GetItemStorage();
                await this.UpdateLocationForLabelImage();
                await this.createAccountItem();
            }catch(error){
                console.log(error)
                this.creatingAccountLocation = false;
                return
            }

            this.toggleAlertBox = true;
            this.alertMessage = "Created Location"
            document.getElementById('address1Account').disabled = true;
            document.getElementById('cityAccount').disabled = true;
            document.getElementById('stateAccount').disabled = true;
            document.getElementById('zipcodeAccount').disabled = true;
            let obj = this.accountInput.billingAddress;
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    obj[key] = '';
                }
            }
            let newLocationObj = this.newLocation;
            for (const key in newLocationObj) {
                if (Object.prototype.hasOwnProperty.call(newLocationObj, key)) {
                    newLocationObj[key] = '';
                }
            }
            this.accountInput.accountNo = "";
            this.accountInput.contact1 = "";
            this.accountInput.contact2 = "";
            this.accountInput.contact3 = "";
            this.accountInput.specialPricing = false;
            this.accountInput.defaultWeight = null;
            this.accountInput.creditLimit = null;
            this.accountInput.billable = false;
            this.accountInput.locationID = "";
            this.accountInput.product = [];
            this.validateCustomAddress = false;
            this.creatingAccountLocation = false;
            this.createNewLocation = false;
            this.refreshProducts++;
        },
        async CreatAccountLocation(){
            this.loadingUser = true;
            let phoneInput = this.accountInput.billingAddress.phone;
            if(!this.cleanAndValidatePhoneNumber(phoneInput)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter a valid phone number."
                this.loadingUser = false;
                throw new Error("Invalid Phone Number");
            }

            if((this.accountInput.billingAddress.companyName.length == 0) || (this.accountInput.billingAddress.address1.length == 0) || (this.accountInput.billingAddress.city.length == 0) || (this.accountInput.billingAddress.state.length == 0) || (this.accountInput.billingAddress.zipCode.length == 0)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter valid address information."
                this.loadingUser = false;
                throw new Error("Invalid Address");
            }

            if(this.accountInput.accountNo == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter an account number."
                this.loadingUser = false;
                throw new Error("Invalid Account Number");
            }

            try {
                let locationData = await API.graphql({
                    query: createLocation,
                    variables: {
                        input:{
                            accountNo: this.accountInput.accountNo,
                            serviceAddress: { address: 
                                this.accountInput.billingAddress
                            },
                            routeCode: this.routeCodeFromGeoCode
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.logoLocationID = locationData.data.createLocation.id;
                this.refreshKey++
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create location"
                this.loadingUser = false;
                throw new Error("Unable to create location");
            }
        },
        async CreatAccountNewLocation(){
            this.loadingUser = true;
            let phoneInput = this.newLocation.phone;
            if(!this.cleanAndValidatePhoneNumber(phoneInput)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter a valid phone number."
                this.loadingUser = false;
                throw new Error("Invalid Phone Number");
            }

            if((this.newLocation.companyName.length == 0) || (this.newLocation.address1.length == 0) || (this.newLocation.city.length == 0) || (this.newLocation.state.length == 0) || (this.newLocation.zipCode.length == 0)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter valid address information."
                this.loadingUser = false;
                throw new Error("Invalid Address");
            }

            if(this.accountInput.accountNo == ""){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter an account number."
                this.loadingUser = false;
                throw new Error("Invalid Account Number");
            }

            try {
                let locationData = await API.graphql({
                    query: createLocation,
                    variables: {
                        input:{
                            accountNo: this.accountInput.accountNo,
                            serviceAddress: { address: 
                                this.newLocation
                            },
                            routeCode: this.routeCodeFromGeoCode
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.logoLocationID = locationData.data.createLocation.id;
                this.refreshKey++
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create location"
                this.loadingUser = false;
                throw new Error("Unable to create location");
            }
        },
        async PutItemStorage(){
            const fileNameNoSpaces = this.uploadFile.name.split(" ").join("");
            this.uploadFileNoSpaces = this.logoLocationID + fileNameNoSpaces;

            await Storage.put(this.uploadFileNoSpaces, this.uploadFile, {
                contentType: this.uploadFile.type,
            }).then(()=>{
                this.GetItemStorage();
            }).catch(()=>{
                this.toggleAlertBox = true;
                this.alertMessage = "Logo unable to upload"
                this.loadingUser = false;
                throw new Error("Logo unable to upload");
            })
        },
        async GetItemStorage(){
            const imageAccessURL = await Storage.get(this.uploadFileNoSpaces);
            const targetSubstring = this.uploadFileNoSpaces;
            const splitOnPublic = imageAccessURL.split("/public/")
            if (splitOnPublic.length >= 1) {
                const trimmedUrl = splitOnPublic[0] + '/public/' + targetSubstring;
                this.getFileStorageURL = trimmedUrl;
                // this.logoUploadSuccess = true;
            } else {
                this.toggleAlertBox = true;
                this.alertMessage = "Issue processing label image."
                // this.logoUploadSuccess = false;
            }
        },
        async UpdateLocationForLabelImage(){
            try{
                await API.graphql({
                query:  updateLocation,
                variables:{
                    input:{
                        id: this.logoLocationID,
                        labelImage: this.getFileStorageURL
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error updating location"
                    throw new Error("Error updating location");
                }
            }
            const imagePreview = document.getElementById('imgPreview');
            imagePreview.src = ""
        },
        setUploadData(){
            const locationLogo = document.getElementById("fileInput");
            const fileInput = locationLogo.files[0];
            this.uploadFile = fileInput;
            const imagePreview = document.getElementById('imgPreview');
            if (fileInput) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    imagePreview.src = e.target.result;
                };
                reader.readAsDataURL(fileInput);
            }
        },
        cleanAndValidatePhoneNumber(inputPhoneNumber) {
            // Remove all non-digit characters from the input phone number
            const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, '');

            // Check if the cleaned phone number is exactly 10 digits
            if(cleanedPhoneNumber.length == 0){
                return true;
            }else if(cleanedPhoneNumber.length !== 10) {
                return false; // Invalid phone number
            }else{
                this.accountInput.billingAddress.phone = cleanedPhoneNumber;
                return true;
            }
        },
        async getSelectedProducts(event){
            this.selectedProducts = event;
            this.accountInput.product = this.selectedProducts;
        },
        async checkBoxSelectID(event){
            console.log(event)
            this.accountInput.locationID = event;
        },
        async GetGeoCodeGraphQL(){
            if(this.setAsLocation){
                try {
                    let query = `query listGeoCodes{
                        listGeoCodes(geoCode:"${this.accountInput.billingAddress.zipCode}"){
                            items{
                                geoCode
                                routeCode
                            }
                        }
                    }`
                    let geoCodeData = await API.graphql({
                        query: query,
                        authMode: "API_KEY",
                    })
                    if(geoCodeData.data.listGeoCodes.items.length == 0){
                        this.toggleAlertBox = true;
                        this.alertMessage = "No route code available"
                        this.routeCodeFromGeoCode = "";
                        this.routeCodeOptions = [];
                        return;
                    }else{
                        this.routeCodeFromGeoCode = "";
                        this.routeCodeOptions = geoCodeData.data.listGeoCodes.items;
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            
            if(this.createNewLocation){
                try {
                    let query = `query listGeoCodes{
                        listGeoCodes(geoCode:"${this.newLocation.zipCode}"){
                            items{
                                geoCode
                                routeCode
                            }
                        }
                    }`
                    let geoCodeData = await API.graphql({
                        query: query,
                        authMode: "API_KEY",
                    })
                    if(geoCodeData.data.listGeoCodes.items.length == 0){
                        this.toggleAlertBox = true;
                        this.alertMessage = "No route code available"
                        this.routeCodeFromGeoCode = "";
                        this.routeCodeOptions = [];
                        return;
                    }else{
                        this.routeCodeFromGeoCode = "";
                        this.routeCodeOptions = geoCodeData.data.listGeoCodes.items;
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async listServiceNetworks(){
            this.loadingUser = true;
            try {
                let listServiceNetworkData = await API.graphql({
                    query: `query listServiceNetworks{
                        listServiceNetworks{
                            items{
                                serviceNetwork
                            }
                        }
                    }`,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.serviceNetworkData = listServiceNetworkData.data.listServiceNetworks;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get service network information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createAccountItem(){
            this.loadingUser = true;
            await this.createAccount();
            this.loadingUser = false;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
                // console.log(this.userData)
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createAccount(){
            this.loadingUser = true;
            this.accountInput.locationID = this.logoLocationID;
            try {
                await API.graphql({
                    query: createAccount,
                    variables: {
                        input: this.accountInput
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.refreshKey++
                this.toggleAlertBox = true;
                this.alertMessage = "Created Account"
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create account."
                this.loadingUser = false;
                throw new Error("Unable to create account.");
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        GetSelectedAddressData(selectedAddress){
            // this.selectedPlace = null;
            if(this.setAsLocation){
                this.accountInput.billingAddress.latitude = selectedAddress.latitude;
                this.accountInput.billingAddress.longitude = selectedAddress.longitude;
                this.accountInput.billingAddress.address1 = selectedAddress.address1;
                this.accountInput.billingAddress.address2 = selectedAddress.address2;
                this.accountInput.billingAddress.zipCode = selectedAddress.zipCode;
                this.accountInput.billingAddress.city = selectedAddress.city;
                this.accountInput.billingAddress.state = selectedAddress.state;
                this.accountInput.billingAddress.relevance = selectedAddress.relevance;
            }

            if(this.createNewLocation){
                this.newLocation.latitude = selectedAddress.latitude;
                this.newLocation.longitude = selectedAddress.longitude;
                this.newLocation.address1 = selectedAddress.address1;
                this.newLocation.address2 = selectedAddress.address2;
                this.newLocation.zipCode = selectedAddress.zipCode;
                this.newLocation.city = selectedAddress.city;
                this.newLocation.state = selectedAddress.state;
                this.newLocation.relevance = selectedAddress.relevance;
            }
        },
        customAddressToggle(toggleCustom){
            // this.selectedPlace = null;
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";
            if(this.setAsLocation){
                document.getElementById('address1Account').disabled = false;
                document.getElementById('cityAccount').disabled = false;
                document.getElementById('stateAccount').disabled = false;
                document.getElementById('zipcodeAccount').disabled = false;
            }

            if(this.createNewLocation){
                document.getElementById('address1Account-newLocation').disabled = false;
                document.getElementById('cityAccount-newLocation').disabled = false;
                document.getElementById('stateAccount-newLocation').disabled = false;
                document.getElementById('zipcodeAccount-newLocation').disabled = false;
            }
            
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            if(this.setAsLocation){
                let customAddressString = this.accountInput.billingAddress.address1 + " " + this.accountInput.billingAddress.city + " " + this.accountInput.billingAddress.state + " " + this.accountInput.billingAddress.zipCode;
                //AddressCorrection Child Component is triggered when customAddress changes values
                this.userCustomAddress = customAddressString;
            }

            if(this.createNewLocation){
                let customAddressString = this.newLocation.address1 + " " + this.newLocation.city + " " + this.newLocation.state + " " + this.newLocation.zipCode;
                //AddressCorrection Child Component is triggered when customAddress changes values
                this.userCustomAddress = customAddressString;
            }
            
        },
        setCustomAddress(event){
            if(this.setAsLocation){
                this.accountInput.billingAddress.relevance = event.shipmentAddressData.relevance;
                this.accountInput.billingAddress.latitude = event.shipmentAddressData.latitude;
                this.accountInput.billingAddress.longitude = event.shipmentAddressData.longitude;
                this.accountInput.billingAddress.address1 = event.shipmentAddressData.Address1;
                this.accountInput.billingAddress.address2 = event.shipmentAddressData.Address2;
                this.accountInput.billingAddress.zipCode = event.shipmentAddressData.ZipCode;
                this.accountInput.billingAddress.city = event.shipmentAddressData.City;
                this.accountInput.billingAddress.state = event.shipmentAddressData.State;
            }

            if(this.createNewLocation){
                this.newLocation.relevance = event.shipmentAddressData.relevance;
                this.newLocation.latitude = event.shipmentAddressData.latitude;
                this.newLocation.longitude = event.shipmentAddressData.longitude;
                this.newLocation.address1 = event.shipmentAddressData.Address1;
                this.newLocation.address2 = event.shipmentAddressData.Address2;
                this.newLocation.zipCode = event.shipmentAddressData.ZipCode;
                this.newLocation.city = event.shipmentAddressData.City;
                this.newLocation.state = event.shipmentAddressData.State;
            }
        },
        useCorrectedAddress(event){
            if(this.setAsLocation){
                this.accountInput.billingAddress.relevance = event.shipmentDataValue.relevance;
                this.accountInput.billingAddress.latitude = event.shipmentDataValue.latitude;
                this.accountInput.billingAddress.longitude = event.shipmentDataValue.longitude;
                this.accountInput.billingAddress.address1 = event.shipmentDataValue.Address1;
                this.accountInput.billingAddress.address2 = event.shipmentDataValue.Address2;
                this.accountInput.billingAddress.zipCode = event.shipmentDataValue.ZipCode;
                this.accountInput.billingAddress.city = event.shipmentDataValue.City;
                this.accountInput.billingAddress.state = event.shipmentDataValue.State;
            }

            if(this.createNewLocation){
                this.newLocation.relevance = event.shipmentDataValue.relevance;
                this.newLocation.latitude = event.shipmentDataValue.latitude;
                this.newLocation.longitude = event.shipmentDataValue.longitude;
                this.newLocation.address1 = event.shipmentDataValue.Address1;
                this.newLocation.address2 = event.shipmentDataValue.Address2;
                this.newLocation.zipCode = event.shipmentDataValue.ZipCode;
                this.newLocation.city = event.shipmentDataValue.City;
                this.newLocation.state = event.shipmentDataValue.State;
            }
        },
        useEnteredAddress(){
            return
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    padding: 0 !important;
    margin-top: 5px;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.special-pricing-label{
    margin-top: 5px;
    margin-right: 10px;
}

input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    margin-top: 5px;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #c4c4c4;
    border-radius: 999px;
    padding: 0;

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="radio"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #33f18a;
    border-radius: 999px;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

.file-input{
    width: 80%;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.service-input-container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    padding: 10px;
    margin-top: 5px;
    flex-basis: 30%;
}

.service-levels-inner{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

.billing-address h2{
    white-space: nowrap;
}

.confirmContainerInner1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.confirm-container-address{
    margin-bottom: 5px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.update-user{
    flex-basis: 60%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.service-levels{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.add-service-button{
    margin: 0;
    margin-top: 5px;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
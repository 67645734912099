<template>
<div class="weather-container">
    <div class="weather-container-inner">
        <loading-skeleton :loadingHeight="'150px'" v-if="loadingWeather"/>
        <div v-if="loadingWeather == false" class="location-container-main">
            <div class="location-container">
                <h3>{{location}}</h3>
            </div>
            <div class="weather-header">
                <p>{{todaysDate}}</p>
                <!-- <h3>{{temperature}}<span>&#176;</span></h3> -->
            </div>
            <div class="weather-image">
                <img v-if="mainDescription == 'Thunderstorm'" src="../../assets/FinalImages/WeatherWidget/storm.png" alt="">
                <img v-else-if="mainDescription == 'Drizzle'" src="../../assets/FinalImages/WeatherWidget/rain.png" alt="">
                <img v-else-if="mainDescription == 'Rain'" src="../../assets/FinalImages/WeatherWidget/rain.png" alt="">
                <img v-else-if="mainDescription == 'Snow'" src="../../assets/FinalImages/WeatherWidget/snow.png" alt="">
                <img v-else-if="mainDescription == 'Mist'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Smoke'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Haze'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Dust'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Fog'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Sand'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Ash'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Squall'" src="../../assets/FinalImages/WeatherWidget/foggy.png" alt="">
                <img v-else-if="mainDescription == 'Tornado'" src="../../assets/FinalImages/WeatherWidget/tornado.png" alt="">
                <img v-else-if="mainDescription == 'Clear'" src="../../assets/FinalImages/WeatherWidget/sunny.png" alt="">
                <img v-else-if="mainDescription == 'Clouds'" src="../../assets/FinalImages/WeatherWidget/clouds.png" alt="">
                <h3>{{temperature}}<span>&#176;</span></h3>
            </div>
        </div>
        
        <div v-if="loadingWeather == false" class="weather-info">
            <div class="weather-info-inner">
                <h3>{{weatherDescription}}</h3>
            </div>
            <div class="weather-info-inner">
                <h3>Humidity</h3>
                <p>{{humidity}}<span>&#37;</span></p>
            </div>
            <div class="weather-info-inner">
                <h3>Wind</h3>
                <p>{{wind}} mph</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import LoadingSkeleton from '../ShipmentDashboard/LoadingSkeleton.vue'


export default {
    props: ['userData'],
    components:{
        LoadingSkeleton
    },
    watch:{
        'userData':function(){
            if(this.userData)
            {            
                this.GetWeatherData(this.userData.locationAddress.serviceAddress.address.city, this.userData.locationAddress.serviceAddress.address.state)
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user data to generate weather."
            }
        }
    },
    data(){
        return{
            weatherDescription: '',
            mainDescription: "",
            temperature: '',
            wind: "",
            humidity: "",
            latitude: '',
            longitude: '',
            todaysDate: "",
            location: '',
            loadingWeather: true
        }
    },
    methods:{
        async GetWeatherData(city, state){
            this.loadingWeather = true;
            let lat = undefined;
            let lon = undefined;

            let geoCode = await axios.get('https://api.openweathermap.org/geo/1.0/direct?q=' + city + ',' + state + ',USA&appid=5afb314d8ef4094a42eb11e6bee894ae&units=imperial')
            if(geoCode.data[0])
            {
                lat = geoCode.data[0].lat;
                lon = geoCode.data[0].lon;
            }

            let weatherData;
            if((lat != undefined) && (lon != undefined)){
                weatherData = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat=' + lat + '&lon=' + lon + '&appid=5afb314d8ef4094a42eb11e6bee894ae&units=imperial')
                this.loadingWeather = false;
            }else{
                weatherData = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat=' + '40.735679' + '&lon=-' + '74.172452' + '&appid=5afb314d8ef4094a42eb11e6bee894ae&units=imperial')
                this.loadingWeather = false;
            }
            this.mainDescription = weatherData.data.weather[0].main;
            let descriptionString = weatherData.data.weather[0].description;
            this.weatherDescription = descriptionString.charAt(0).toUpperCase() + descriptionString.slice(1);
            this.wind = Math.floor(weatherData.data.wind.speed);
            this.humidity = Math.floor(weatherData.data.main.humidity);
            this.temperature = Math.floor(weatherData.data.main.temp);
            this.location = weatherData.data.name;

            let currentDate = new Date().toJSON().slice(0, 10);
            this.todaysDate = currentDate;

        }
    }
}
</script>

<style scoped>
.weather-container-inner{
    width: fit-content;
    height: fit-content;
    min-height: 175px;
    min-width: 225px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
    padding: 10px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #000 0%, #282828 100%);
}

.location-container-main{
    width: 50%;
}

.location-container{
    width: 100%;
    text-align: left;
    color: rgb(240, 240, 240);
}

.location-container h3{
    margin-bottom: 5px;
}

.weather-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: fit-content;
    color: rgb(240, 240, 240);
}

.weather-header p{
    white-space: nowrap;
}

.weather-header h3{
    font-size: 35px;
    margin: 0;
}

.weather-image{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.weather-image img{
    width: 50%;
}

.weather-image h3{
    color: #fff;
    font-size: 35px;
    margin: 0;
}

.weather-info{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 40%;
}

.weather-info h3{
    font-size: .9em;
}

.weather-info-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgb(240, 240, 240);
}
</style>
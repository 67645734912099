<template>
  <div v-if="accountNoData" class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <svg class="close-shipment-info" @click="closeAddressInfo()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">Account Details</h1>
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.75 148.87">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path d="M154.27,12.53c.87-.17,1.78,0,2.54,.46,1.29,.53,2.2,1.79,2.2,3.28l.74,103.46c.07,1.27-.56,2.54-1.73,3.22l-42.25,25.21c-.6,.46-1.34,.72-2.15,.72-.2,0-.39-.01-.57-.05L3.39,136.62c-1.89-.08-3.39-1.64-3.39-3.55V27.85H0c-.04-1.4,.75-2.77,2.13-3.37L57.14,.31h0c.57-.26,1.22-.36,1.9-.27l95.23,12.49h0Zm-37.27,30.93v96.48l36-21.47-.7-97.26-35.3,22.25h0Zm-7,97.91V45l-46.8-5.97-1.54,44.72-16.43-11.19-16.43,9.29,3.4-46.78-25.19-3.21v98.07l103,11.44h0ZM102.56,12.88l-34.52,19.59,44.9,5.72,31.47-19.83-41.86-5.49h0ZM36.56,28.46L70.02,8.61l-11.03-1.44L16.4,25.89l20.15,2.56h0Z"/>
                                            </g>
                                        </svg>
                                        <h2>Account</h2>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <p name="geoCodeID">Account No: {{accountNoData.accountNo}}</p>
                                    </div>

                                    <div class="account-info-container">
                                        <div class="account-info-input">
                                            <div class="confirm-svg">
                                                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.67 328.54">
                                                    <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                                        <g>
                                                        <path class="cls-2" d="M5,263.23l4.07-31.12c1.49-11.39,9.48-20.87,20.45-24.26l41.72-12.89c4.68-1.45,9.78-.46,13.58,2.62h0c23.9,19.38,58.11,19.38,82.01,0h0c3.81-3.09,8.9-4.07,13.58-2.62l41.72,12.89c10.97,3.39,18.97,12.87,20.45,24.26l4.07,31.12"/>
                                                        <path class="cls-2" d="M179.67,133.69v1.49c0,17.17-9.99,32.77-25.58,39.95l-16.31,7.52c-7.79,3.59-16.76,3.59-24.54,0l-16.31-7.52c-15.59-7.18-25.58-22.78-25.58-39.95v-1.49"/>
                                                        <path class="cls-2" d="M66.66,77.38l2.08-34.12c.68-11.22,7.8-21.02,18.25-25.15l28.02-11.08c6.83-2.7,14.44-2.7,21.28,0l28.02,11.08c10.45,4.13,17.56,13.94,18.25,25.15l2.08,34.12"/>
                                                        <path class="cls-2" d="M182.17,75.94l-.69-.59c-31.61-27.16-78.16-27.68-110.37-1.23l-1.41,1.16c-5.33,4.37-6.62,12-3.03,17.89l8.7,14.27h0c30.19-23.72,72.69-23.72,102.89,0h0s3.95-8.06,7.32-14.94c2.77-5.66,1.38-12.46-3.4-16.56Z"/>
                                                        <path class="cls-2" d="M57.76,116.69l-.15,.9c-1.37,8.44,5.15,16.11,13.7,16.11h.03"/>
                                                        <path class="cls-2" d="M193.24,116.69l.15,.9c1.37,8.44-5.15,16.11-13.7,16.11h0"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <h2>Account</h2>
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.contact1" placeholder="Contact 1">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.contact2" placeholder="Contact 2">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.contact3" placeholder="Contact 3">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="number" v-model="accountNoData.defaultWeight" placeholder="Default Weight">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="number" v-model="accountNoData.creditLimit" placeholder="Credit Limit">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" disabled v-model="accountNoData.locationID" placeholder="Location ID">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <label for="specialPricing" class="special-pricing-label">Special Pricing</label>
                                                <input type="checkbox" v-model="accountNoData.specialPricing" id="specialPricing">
                                            </div>
                                        </div>
                                        <div class="account-info-input">
                                            <div style="margin-top: 5px; margin-bottom: 5px;" class="confirm-svg">
                                                <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                                    <g id="image">
                                                        <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                                    </g>
                                                </svg>
                                                <h2>Billing Address</h2>
                                            </div>

                                            <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.billingAddress.companyName" placeholder="Company Name">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.billingAddress.attention" placeholder="Attention">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="address1Info" disabled v-model="accountNoData.billingAddress.address1" placeholder="Address 1">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.billingAddress.address2" placeholder="Address 2">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="cityInfo" disabled v-model="accountNoData.billingAddress.city" placeholder="City">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <select disabled required name="state" id="stateInfo" v-model="accountNoData.billingAddress.state">
                                                    <option value="" disabled selected>State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="DC">District Of Columbia</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                </select>
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" id="zipcodeInfo" disabled v-model="accountNoData.billingAddress.zipCode" placeholder="Zip Code">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.billingAddress.phone" placeholder="Phone">
                                            </div>

                                            <div class="confirmLabelContainer">
                                                <input type="text" v-model="accountNoData.billingAddress.email" placeholder="Email">
                                            </div>

                                            <button v-if="validateCustomAddress" class="print-button check-address" @click="checkAddressCorrection()">Check Address</button>
                                        </div>
                                        <div class="account-info-input locations-table">
                                            <account-locations @checkBoxSelectID="checkBoxSelectID($event)" :jwtToken="jwtToken" :userData="accountNoData" :selectedUserData="accountNoData"/>
                                        </div>
                                    </div>
                                    
                                    <div class="product-options">
                                        <product-options :key="refreshProducts" :selectedAccount="geoCodeID" :specialPricing="accountNoData.specialPricing" :updateAccountProducts="true" :currentAccountProducts="accountNoData.product" @getSelectedProducts="getSelectedProducts($event)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <loading-charts v-if="loadingData"/>
                    <button class="print-button" @click="updateAccountInfo()">Update Account</button>
                    
            </div>
    </div>
  </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="accountNoData.billingAddress"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import { updateAccount } from '../../../graphql/mutations';
import ProductOptions from '../ProductDashboard/ProductOptions.vue'
import AWSAutoComplete from '../../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';
import AccountLocations from '../LocationDashboard/AccountLocations.vue'

export default {
    props:['geoCodeID', 'jwtToken'],
    components:{
        AlertUser,
        LoadingCharts,
        ProductOptions,
        AWSAutoComplete,
        AWSAddressCorrection,
        AccountLocations
    },
    data(){
        return{
            accountNoData: null,
            isActive: undefined,
            toggleAlertBox: false, 
            alertMessage: null,
            loadingData: false,
            toggleServiceData: "",
            serviceNetworkData: {},
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: ""
        }
    },
    methods:{
        async listRouteCodes(){
            try {
                let listServiceNetworkData = await API.graphql({
                    query: `query listServiceNetworks{
                        listServiceNetworks{
                            items{
                                serviceNetwork
                            }
                        }
                    }`,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.serviceNetworkData = listServiceNetworkData.data.listServiceNetworks;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get service network information"
            }
        },
        toggleServiceDataOption(selectedService){
            this.toggleServiceData = selectedService
        },
        async getAccountData(){
            let graphqlQuery = `query getAccount{
                getAccount(accountNo: "${this.geoCodeID}"){
                    accountNo
                    creditLimit
                    contact1
                    contact2
                    contact3
                    defaultWeight
                    creditLimit
                    locationID
                    specialPricing
                    billingAddress {
                        companyName
                        attention
                        address1
                        address2
                        city
                        state
                        zipCode
                        phone
                        phoneExt
                        email
                        relevance
                        latitude
                        longitude
                        routeCode
                    } 
                    product {
                        productCode
                        productSequence
                        serviceCode
                        description
                        serviceNetwork
                        additionalService
                        price
                        productName
                        vendor
                        restServiceCode
                        descriptionUI
                        deliveryWindowEnd
                        deliveryWindowStart
                        deliveryWindowLabel
                        productFamily
                        serviceGroup
                        serviceGroupSequence
                    }
                }
            }`
            
            try{
                let getAccountInfo = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.accountNoData = getAccountInfo.data.getAccount;
                // console.log(this.accountNoData)
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting account"
                }
            }
        },
        async checkBoxSelectID(event){
            // console.log(event)
            this.accountNoData.locationID = event;
        },
        async getSelectedProducts(event){
            // console.log(event)
            this.accountNoData.product = event;
        },
        async updateAccountInfo(){
            // console.log(this.accountNoData)
            try{
                await API.graphql({
                query:  updateAccount,
                variables:{
                    input: this.accountNoData
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "Account updated"
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error updating account"
                    throw new Error("Error updating account")
                }
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeAddressInfo(){
            this.$emit('closeAddressInfo', false)
        },
        GetSelectedAddressData(selectedAddress){
            this.accountNoData.billingAddress.latitude = selectedAddress.latitude;
            this.accountNoData.billingAddress.longitude = selectedAddress.longitude;
            this.accountNoData.billingAddress.address1 = selectedAddress.address1;
            this.accountNoData.billingAddress.address2 = selectedAddress.address2;
            this.accountNoData.billingAddress.zipCode = selectedAddress.zipCode;
            this.accountNoData.billingAddress.city = selectedAddress.city;
            this.accountNoData.billingAddress.state = selectedAddress.state;
            this.accountNoData.billingAddress.relevance = selectedAddress.relevance;
        },
        customAddressToggle(toggleCustom){
            // this.selectedPlace = null;
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";

            document.getElementById('address1Info').disabled = false;
            document.getElementById('cityInfo').disabled = false;
            document.getElementById('stateInfo').disabled = false;
            document.getElementById('zipcodeInfo').disabled = false;
            
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            let customAddressString = this.accountNoData.billingAddress.address1 + " " + this.accountNoData.billingAddress.city + " " + this.accountNoData.billingAddress.state + " " + this.accountNoData.billingAddress.zipCode;
            //AddressCorrection Child Component is triggered when customAddress changes values
            this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            this.accountNoData.billingAddress.relevance = event.shipmentAddressData.relevance;
            this.accountNoData.billingAddress.latitude = event.shipmentAddressData.latitude;
            this.accountNoData.billingAddress.longitude = event.shipmentAddressData.longitude;
            this.accountNoData.billingAddress.address1 = event.shipmentAddressData.Address1;
            this.accountNoData.billingAddress.address2 = event.shipmentAddressData.Address2;
            this.accountNoData.billingAddress.zipCode = event.shipmentAddressData.ZipCode;
            this.accountNoData.billingAddress.city = event.shipmentAddressData.City;
            this.accountNoData.billingAddress.state = event.shipmentAddressData.State;
        },
        useCorrectedAddress(event){
            this.accountNoData.billingAddress.relevance = event.shipmentDataValue.relevance;
            this.accountNoData.billingAddress.latitude = event.shipmentDataValue.latitude;
            this.accountNoData.billingAddress.longitude = event.shipmentDataValue.longitude;
            this.accountNoData.billingAddress.address1 = event.shipmentDataValue.Address1;
            this.accountNoData.billingAddress.address2 = event.shipmentDataValue.Address2;
            this.accountNoData.billingAddress.zipCode = event.shipmentDataValue.ZipCode;
            this.accountNoData.billingAddress.city = event.shipmentDataValue.City;
            this.accountNoData.billingAddress.state = event.shipmentDataValue.State;
        },
        useEnteredAddress(){
            return
        }
    },
    mounted(){
        this.getAccountData();
        this.listRouteCodes();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

    input, select{
        padding: 10px;
        font-size: 15px;
        border: 1px solid rgba(0,0,0,.336);
        border-radius: 15px;
        outline: none;
        margin-top: 5px;
    }


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.account-info-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-container-main{
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    margin: 5px;
}

.account-info-input{
    flex-basis: 20%;
    margin: 2px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    padding: 10px;
    height: fit-content;
}

.locations-table{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    padding: 10px;
    height: fit-content;
    flex-basis: 45%;
}

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    overflow-x: auto;
    max-width: 80%;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.service-option{
    padding: 5px 10px;
    border-radius: 999px;
    background-color: #e4e4e4;
    cursor: pointer;
    transition-duration: .5s;
}

.service-option:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.file-input{
    width: 80%;
}

.weight-container{
    background-color: #fff;
    margin: 0px 5% 2% 5%;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    overflow-y: auto;
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    /* margin: 5px; */
    padding: 10px;
    border-radius: 25px;
    /* flex-basis: 80%; */
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.update-location{
    flex-basis: 100%;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.special-pricing-label{
    margin-top: 5px;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    padding: 0 !important;
    margin-top: 5px;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.geoCode-services{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.auto-complete-container{
    margin-top: 5px;
}

.service-container{
    display: flex;
    margin: 5px;
    flex-direction: column;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.check-address{
    margin-left: 0;
    margin-top: 5px;
}

.add-price{
    margin-left: 0;
    margin-top: 5px;
    background-color: #33f18a;
}


.add-price:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.create-button{
    background-color: #33f18a;
    margin-left: 20px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

.product-container{
    flex-basis: 25%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    min-width: 12em;
}

.product-container p{
    margin: 0 !important;
}

.button-container{
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product-name{
    background-color: #e4e4e4;
    color: #000;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

@media only screen and (max-width: 1200px){
    .locations-table{
        flex-basis: 80%;
        display: block;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 530px){
    .service-data{
        flex-direction: column;
    }
}
</style>
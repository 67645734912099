<template>
  <div ref="awsmap" id="map" />
</template>

<script>
import { Auth, Signer } from 'aws-amplify'
import maplibregl from 'maplibre-gl'
import AWS from '../../aws-exports'
import 'maplibre-gl/dist/maplibre-gl.css';
export default {
    data(){
        return{
            credentials: null,
            mapCenter: []
        }
    },
    props:['shipmentData', 'showPickupLogic'],
    watch:{
        'shipmentData':function(oldValue, newValue) {
            console.log(oldValue.serviceAddress.address.longitude)
            console.log(newValue.serviceAddress.address.longitude)
        }
    },
    mounted: async function () {
        // console.log(this.shipmentData.serviceAddress.address.longitude)
        this.credentials = await Auth.currentCredentials()
        if((this.shipmentData.serviceAddress.address.longitude != "") || (this.shipmentData.deliveryPickup.address.longitude != "")){
            this.mapCreate();
        }
    },
    unmounted(){
        //Reset hash allow map to adjust to address changes
        window.location.hash = ""
    },
    methods:{
        mapCreate: function() {
                if((this.shipmentData.serviceAddress.address.longitude != "") && (this.shipmentData.deliveryPickup.address.longitude == "")){
                    this.mapCenter = [this.shipmentData.serviceAddress.address.longitude, this.shipmentData.serviceAddress.address.latitude]
                }else if((this.shipmentData.deliveryPickup.address.longitude != "") && (this.shipmentData.serviceAddress.address.longitude == "")){
                    this.mapCenter = [this.shipmentData.deliveryPickup.address.longitude, this.shipmentData.deliveryPickup.address.latitude]
                }else if((this.shipmentData.serviceAddress.address.longitude != "") && (this.shipmentData.deliveryPickup.address.longitude != "")){
                    this.mapCenter = [this.shipmentData.serviceAddress.address.longitude, this.shipmentData.serviceAddress.address.latitude]
                }


                const map = new maplibregl.Map({
                    container: 'map',
                    style: 'LREXSite-Map',
                    center: this.mapCenter,
                    zoom: 15,
                    bearing: 64.8,
                    pitch: 60,
                    hash: true,
                    transformRequest: this.transformRequest,
                });

                if((this.shipmentData.serviceAddress.address.longitude != "")){
                    new maplibregl.Marker({
                    color: "#32ccfe"
                    }).setLngLat([this.shipmentData.serviceAddress.address.longitude, this.shipmentData.serviceAddress.address.latitude])
                    .addTo(map);
                }


                
                if((this.shipmentData.deliveryPickup.address.longitude != "") && (this.showPickupLogic == true)){
                    new maplibregl.Marker({
                    color: "#33f18a"
                    }).setLngLat([this.shipmentData.deliveryPickup.address.longitude, this.shipmentData.deliveryPickup.address.latitude])
                    .addTo(map);
                }



                if(((this.shipmentData.serviceAddress.address.longitude != "") && (this.shipmentData.deliveryPickup.address.longitude != "")) && this.showPickupLogic == true){
                    map.fitBounds([
                    [this.shipmentData.serviceAddress.address.longitude, this.shipmentData.serviceAddress.address.latitude], // southwestern corner of the bounds
                    [this.shipmentData.deliveryPickup.address.longitude, this.shipmentData.deliveryPickup.address.latitude] // northeastern corner of the bounds
                    ], {padding: 100});
                }

                // map.fitBounds([
                // [this.shipmentData.serviceAddress.address.longitude, this.shipmentData.serviceAddress.address.latitude], // southwestern corner of the bounds
                // [this.shipmentData.deliveryPickup.address.longitude, this.shipmentData.deliveryPickup.address.latitude] // northeastern corner of the bounds
                // ]);

                map.addControl(new maplibregl.NavigationControl());
            },
            transformRequest: function (url, resourceType) {
                if (resourceType === 'Style' && !url.includes('://')) {
                    url = `https://maps.geo.${AWS.aws_project_region}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`
                }
                if (url.includes('amazonaws.com')) {
                    return {
                        url: Signer.signUrl(url, {
                            access_key: this.credentials.accessKeyId,
                            secret_key: this.credentials.secretAccessKey,
                            session_token: this.credentials.sessionToken,
                        }),
                    }
                }
                return { url }
            },
    }
}
</script>

<style scoped>
/* @import 'node_modules/maplibre-gl/dist/maplibre-gl.css'; */
body { margin: 0; }
#map { 
    height: 50vh; 
    border-radius: 25px;
}

</style>
<template>
  <div class="main-container">
    <div class="inner-container">
        <div class="filter-input-container">
            <div class="inner-filter-container">
                <select id="filterField" v-model="selectedField">
                    <option disabled selected value="">Filter Options</option>
                    <option v-show="filterByService" value="service">Service</option>
                    <option v-show="filterByReference" value="ref1">Reference</option>
                    <option v-show="filterByCompanyName" value="companyName">Company Name</option>
                    <option v-show="filterByAddress" value="address1">Address</option>
                    <option v-show="filterByCity" value="city">City</option>
                    <option v-show="filterByState" value="state">State</option>
                    <option v-show="filterByZipCode" value="zipCode">ZipCode</option>
                    <option v-show="filterByGeoCode" value="zipCode">GeoCode</option>
                    <option v-show="filterByRouteCode" value="routeCode">RouteCode</option>
                    <option v-show="filterByAccountNo" value="accountNo">Account No.</option>
                    <option v-show="filterByUserName" value="username">Username</option>
                    <option v-show="filterByServiceNetwork" value="serviceNetwork">Service Network</option>
                    <option v-show="filterByContact1" value="contact1">Contact 1</option>
                    <option v-show="filterByShipmentID" value="shipmentID">ShipmentID</option>
                </select>
            </div>

            <form @submit.prevent="filterValues()">

            
            <div class="inner-filter-container" v-if="selectedField == 'service'">
                <select style="margin-left: 5px;" id="filterField" v-model="filterValue">
                    <option disabled selected value="">Service Options</option>
                    <option value="SSLRLOC">Standard Service</option>
                    <option value="PSLRLOC">Priority Service</option>
                    <option value="SSLRPICK">Pickup Service</option>
                </select>
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'ref1'">
                <input placeholder="Reference" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'companyName'">
                <input placeholder="Company Name" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'address1'">
                <input placeholder="Address" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'city'">
                <input placeholder="City" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'state'">
                <input placeholder="State (ex. NJ)" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'zipCode'">
                <input placeholder="ZipCode (ex. 07103)" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'routeCode'">
                <input placeholder="Route Code" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'accountNo'">
                <input placeholder="Account No." type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'username'">
                <input placeholder="Username" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'serviceNetwork'">
                <input placeholder="Service Network" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'contact1'">
                <input placeholder="Contact Name" type="text" id="filterValue" v-model="filterValue">
            </div>

            <div class="inner-filter-container" v-if="selectedField == 'shipmentID'">
                <input placeholder="ShipmentID" type="text" id="filterValue" v-model="filterValue">
            </div>

            </form>
            <button @click="filterValues()"><i class="fa fa-filter"></i> Filter Table</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            selectedField: '',
            filterValue: ''
        }
    },
    methods:{
        filterValues(){
            this.$emit('filterValues', {filterField: this.selectedField, filterValue: this.filterValue})
        }
    },
    props:['filterByShipmentID', 'filterByAccountNo', 'filterByService', 'filterByReference', 'filterByCompanyName', 'filterByAddress', 'filterByCity', 'filterByState', 'filterByZipCode', 'filterByGeoCode', 'filterByRouteCode', 'filterByUserName', 'filterByContact1', 'filterByServiceNetwork']
}
</script>

<style scoped>
.main-container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 5px;
}

.inner-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 20px);
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
    border-radius: 25px;
}

.filter-input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.filter-input-container button{
    background-color: #33f18a;
    color: #fff;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    transition-duration: .5s;
    margin-left: auto;
}

.filter-input-container button:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.inner-filter-container{
    margin: 5px;
}

.inner-filter-container select{
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    /* margin-left: 5px; */
    cursor: pointer;

    border: 1px solid #e2e2e2;
    /* padding: 5px; */
    /* border-radius: 25px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    outline: none;
}

.inner-filter-container select:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

option{
    background-color: #fff;
    padding: 5px 15px;
    font-size: 1.1em;
}

.inner-filter-container input{
    border: 1px solid #e2e2e2;
    padding: 5px;
    border-radius: 25px;
    /* margin-left: 5px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
    outline: none;
}

@media only screen and (max-width: 380px){
    .filter-input-container button{
        font-size: 10px;
    }

    .inner-filter-container select{
        padding: 5px 5px;
    }
}
</style>
<template>
  <nav class="navbar">
  <ul class="navbar__menu">
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/AdminShipmentDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z"/></svg>
            <span>Shipments</span>
        </router-link>
    </li>
    <li v-if="CustomerAdmin" class="navbar__item">
        <router-link to="/CustomerAdminShipmentDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z"/></svg>
            <span>Shipments</span>
        </router-link>
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/AccountDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM7,22V21a5,5,0,0,1,10,0v1Zm15-3a3,3,0,0,1-3,3V21A7,7,0,0,0,5,21v1a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3Z"/><path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z"/></svg>
            <span>Accounts</span>
        </router-link>        
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/PickupDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path d="M20,16V5a3,3,0,0,1,3-3,1,1,0,0,0,0-2,5.006,5.006,0,0,0-5,5V16.279l-2.734.912a3,3,0,0,0-.156-1l-1.553-5.1A3.007,3.007,0,0,0,9.788,9.142l-6.7,2.13A3.013,3.013,0,0,0,1.129,15l1.634,5.373a2.966,2.966,0,0,0,.457.831l-2.536.845a1,1,0,0,0,.632,1.9L16.1,19.023A4.017,4.017,0,0,0,19.974,24C25.232,23.864,25.255,16.169,20,16ZM4.662,19.748,3.043,14.421A1.006,1.006,0,0,1,3.7,13.178l6.7-2.13a1,1,0,0,1,1.252.638L13.2,16.792l.011.031a1,1,0,0,1-.508,1.221l-6.888,2.3A1,1,0,0,1,4.662,19.748ZM19.974,22a2,2,0,0,1,0-4A2,2,0,0,1,19.974,22ZM10.05,14A1,1,0,0,1,9.4,15.256l-2.464.785a1,1,0,0,1-.606-1.907l2.465-.784A1,1,0,0,1,10.05,14Z"/></svg>
            <span>Pickups</span>
        </router-link>       
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/LocationDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z"/><path d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"/></svg>
            <span>Locations</span>
        </router-link>    
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/PlaceDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,6Zm8.66,3.157-.719-.239A8,8,0,0,0,12,0,7.993,7.993,0,0,0,4.086,9.092a5.045,5.045,0,0,0-2.548,1.3A4.946,4.946,0,0,0,0,14v4.075a5.013,5.013,0,0,0,3.6,4.8l2.87.9A4.981,4.981,0,0,0,7.959,24a5.076,5.076,0,0,0,1.355-.186l5.78-1.71a2.987,2.987,0,0,1,1.573,0l2.387.8A4,4,0,0,0,24,19.021V13.872A5.015,5.015,0,0,0,20.66,9.156ZM7.758,3.762a5.987,5.987,0,0,1,8.484,0,6.037,6.037,0,0,1,.011,8.5L12.7,15.717a.992.992,0,0,1-1.389,0L7.758,12.277A6.04,6.04,0,0,1,7.758,3.762ZM22,19.021a1.991,1.991,0,0,1-.764,1.572,1.969,1.969,0,0,1-1.626.395L17.265,20.2a5.023,5.023,0,0,0-2.717-.016L8.764,21.892a3,3,0,0,1-1.694-.029l-2.894-.9A3.013,3.013,0,0,1,2,18.075V14a2.964,2.964,0,0,1,.92-2.163,3.024,3.024,0,0,1,1.669-.806A8.021,8.021,0,0,0,6.354,13.7l3.567,3.453a2.983,2.983,0,0,0,4.174,0l3.563-3.463a7.962,7.962,0,0,0,1.813-2.821l.537.178A3.006,3.006,0,0,1,22,13.872Z"/></svg>
            <span>Places</span>
        </router-link>     
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/DropboxDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path d="M23.621,6.836l-1.352-2.826c-.349-.73-.99-1.296-1.758-1.552L14.214,.359c-1.428-.476-3-.476-4.428,0L3.49,2.458c-.769,.256-1.41,.823-1.759,1.554L.445,6.719c-.477,.792-.567,1.742-.247,2.609,.309,.84,.964,1.49,1.802,1.796l-.005,6.314c-.002,2.158,1.372,4.066,3.418,4.748l4.365,1.455c.714,.238,1.464,.357,2.214,.357s1.5-.119,2.214-.357l4.369-1.457c2.043-.681,3.417-2.585,3.419-4.739l.005-6.32c.846-.297,1.508-.946,1.819-1.79,.317-.858,.228-1.799-.198-2.499ZM10.419,2.257c1.02-.34,2.143-.34,3.162,0l4.248,1.416-5.822,1.95-5.834-1.95,4.246-1.415ZM2.204,7.666l1.327-2.782c.048,.025,7.057,2.373,7.057,2.373l-1.621,3.258c-.239,.398-.735,.582-1.173,.434l-5.081-1.693c-.297-.099-.53-.325-.639-.619-.109-.294-.078-.616,.129-.97Zm3.841,12.623c-1.228-.409-2.052-1.554-2.051-2.848l.005-5.648,3.162,1.054c1.344,.448,2.792-.087,3.559-1.371l.278-.557-.005,10.981c-.197-.04-.391-.091-.581-.155l-4.366-1.455Zm11.897-.001l-4.37,1.457c-.19,.063-.384,.115-.581,.155l.005-10.995,.319,.64c.556,.928,1.532,1.459,2.561,1.459,.319,0,.643-.051,.96-.157l3.161-1.053-.005,5.651c0,1.292-.826,2.435-2.052,2.844Zm4-11.644c-.105,.285-.331,.504-.619,.6l-5.118,1.706c-.438,.147-.934-.035-1.136-.365l-1.655-3.323s7.006-2.351,7.054-2.377l1.393,2.901c.157,.261,.186,.574,.081,.859Z"/></svg>
            <span>Dropboxes</span>
        </router-link>      
    </li>
    <!-- <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/VehicleDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path d="M19,5H17V4a3,3,0,0,0-3-3H3A3,3,0,0,0,0,4V19H2.041A3.465,3.465,0,0,0,2,19.5a3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5h6.082a3.465,3.465,0,0,0-.041.5,3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5H24V10A5.006,5.006,0,0,0,19,5Zm0,2a3,3,0,0,1,3,3v1H17V7ZM7,19.5a1.5,1.5,0,0,1-3,0,1.418,1.418,0,0,1,.093-.5H6.907A1.418,1.418,0,0,1,7,19.5ZM15,17H2V4A1,1,0,0,1,3,3H14a1,1,0,0,1,1,1Zm5,2.5a1.5,1.5,0,0,1-3,0,1.41,1.41,0,0,1,.093-.5h2.814A1.41,1.41,0,0,1,20,19.5ZM17,17V13h5v4Z"/></svg>
            <span>Vehicles</span>
        </router-link>      
    </li> -->
    <!-- <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/RouteDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m17.247,10.279c.483.473,1.118.709,1.753.709s1.27-.236,1.752-.709l1.783-1.744c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591.52-3.536,1.464c-1.949,1.95-1.949,5.122.008,7.079l1.775,1.736Zm-.369-7.4c.567-.567,1.32-.879,2.122-.879s1.555.312,2.121.878c1.17,1.17,1.17,3.073.008,4.235l-1.775,1.736c-.195.192-.513.192-.708,0l-1.767-1.728c-1.169-1.17-1.169-3.073,0-4.243ZM4,22c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Zm10.5-6c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Zm-12.5-3c0-2.206,1.794-4,4-4h7c.552,0,1,.448,1,1s-.448,1-1,1h-7c-1.103,0-2,.897-2,2s.897,2,2,2h2c.552,0,1,.448,1,1s-.448,1-1,1h-2c-2.206,0-4-1.794-4-4Zm22,6c0,2.206-1.794,4-4,4H7c-.552,0-1-.448-1-1s.448-1,1-1h13c1.103,0,2-.897,2-2s-.897-2-2-2h-3c-.552,0-1-.448-1-1s.448-1,1-1h3c2.206,0,4,1.794,4,4Zm-3.999-14.213c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z"/>
            </svg>
            <span>Routes</span>
        </router-link>      
    </li> -->
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/ManifestDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m17.247,10.279c.483.473,1.118.709,1.753.709s1.27-.236,1.752-.709l1.783-1.744c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591.52-3.536,1.464c-1.949,1.95-1.949,5.122.008,7.079l1.775,1.736Zm-.369-7.4c.567-.567,1.32-.879,2.122-.879s1.555.312,2.121.878c1.17,1.17,1.17,3.073.008,4.235l-1.775,1.736c-.195.192-.513.192-.708,0l-1.767-1.728c-1.169-1.17-1.169-3.073,0-4.243ZM4,22c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Zm10.5-6c0,1.105-.895,2-2,2s-2-.895-2-2,.895-2,2-2,2,.895,2,2Zm-12.5-3c0-2.206,1.794-4,4-4h7c.552,0,1,.448,1,1s-.448,1-1,1h-7c-1.103,0-2,.897-2,2s.897,2,2,2h2c.552,0,1,.448,1,1s-.448,1-1,1h-2c-2.206,0-4-1.794-4-4Zm22,6c0,2.206-1.794,4-4,4H7c-.552,0-1-.448-1-1s.448-1,1-1h13c1.103,0,2-.897,2-2s-.897-2-2-2h-3c-.552,0-1-.448-1-1s.448-1,1-1h3c2.206,0,4,1.794,4,4Zm-3.999-14.213c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z"/>
            </svg>
            <span>Manifest</span>
        </router-link>      
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/ProductDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m2,2.5c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5-1.119,2.5-2.5,2.5-2.5-1.119-2.5-2.5Zm5,7.5c-.552,0-1,.447-1,1v4h-2c-1.103,0-2-.897-2-2v-3c0-1.103.897-2,2-2h10c.552,0,1-.447,1-1s-.448-1-1-1H4C1.794,6,0,7.794,0,10v3c0,1.474.81,2.75,2,3.444v6.556c0,.553.448,1,1,1s1-.447,1-1v-6h2v6c0,.553.448,1,1,1s1-.447,1-1v-12c0-.553-.448-1-1-1Zm17,9v2c0,1.654-1.346,3-3,3h-2c-.771,0-1.468-.301-2-.78-.532.48-1.229.78-2,.78h-2c-1.654,0-3-1.346-3-3v-2c0-1.654,1.346-3,3-3h.184c-.112-.314-.184-.648-.184-1v-2c0-1.654,1.346-3,3-3h2c1.654,0,3,1.346,3,3v2c0,.352-.072.686-.184,1h.184c1.654,0,3,1.346,3,3Zm-9-4c0,.552.449,1,1,1h2c.551,0,1-.448,1-1v-2c0-.552-.449-1-1-1h-2c-.551,0-1,.448-1,1v2Zm1,6v-2c0-.552-.449-1-1-1h-2c-.551,0-1,.448-1,1v2c0,.552.449,1,1,1h2c.551,0,1-.448,1-1Zm6-2c0-.552-.449-1-1-1h-2c-.551,0-1,.448-1,1v2c0,.552.449,1,1,1h2c.551,0,1-.448,1-1v-2Z"/>
            </svg>
            <span>Products</span>
        </router-link>      
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/PartnerShippingDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path d="M23,15h-.667c-.25,0-.498,.019-.745,.057l-7.046-5.284,1.688-1.616c.399-.382,.413-1.016,.031-1.414-.383-.399-1.017-.412-1.414-.031l-4.173,3.995c-.208,.208-.491,.315-.788,.29-.298-.024-.56-.175-.739-.425-.274-.38-.19-.975,.168-1.334l4.703-4.429c.891-.837,2.284-1.042,3.374-.495l2.316,1.158c.69,.345,1.464,.527,2.235,.527h1.056c.553,0,1-.447,1-1s-.447-1-1-1h-1.056c-.463,0-.928-.109-1.342-.316l-2.314-1.158c-1.824-.913-4.153-.574-5.641,.828l-.618,.582-.7-.638c-.919-.837-2.109-1.298-3.39-1.298-.771,0-1.54,.182-2.227,.525l-2.314,1.158c-.415,.207-.88,.316-1.343,.316H1c-.553,0-1,.447-1,1s.447,1,1,1h1.056c.771,0,1.545-.183,2.236-.527l2.316-1.158c1.022-.514,2.458-.375,3.374,.462l.587,.535-2.646,2.492c-1.073,1.072-1.244,2.767-.398,3.938,.52,.723,1.553,1.259,2.444,1.259,.793,0,1.554-.312,2.104-.863l1.006-.963,6.346,4.759c-.031,.022-6.198,4.646-6.198,4.646-.723,.562-1.732,.562-2.47-.011l-6.091-4.568c-.859-.645-1.925-1-3-1h-.667c-.553,0-1,.447-1,1s.447,1,1,1h.667c.645,0,1.284,.213,1.8,.6l6.077,4.558c.725,.564,1.594,.846,2.461,.846,.862,0,1.723-.279,2.437-.835l6.093-4.568c.515-.387,1.154-.6,1.799-.6h.667c.553,0,1-.447,1-1s-.447-1-1-1Z"/></svg>
            <span>Partner Shipping</span>
        </router-link>      
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/AdminUserManagementDashboard" class="navbar__link">
            <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z"/></svg>
            <span>Users</span>
        </router-link>      
    </li>
    <li v-if="CustomerAdmin" class="navbar__item">
        <router-link to="/UserManagementDashboard" class="navbar__link">
            <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z"/></svg>
            <span>Users</span>
        </router-link>      
    </li>
    <li v-if="InternalAdmin || AdminUser" class="navbar__item">
        <router-link to="/QueuedActivitiesDashboard" class="navbar__link">
            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"/><path d="M12,5a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V6A1,1,0,0,0,12,5Z"/><rect x="11" y="17" width="2" height="2" rx="1"/></svg>
            <span>Queued Activities</span>
        </router-link>      
    </li>
    </ul>
</nav>
</template>

<script>
export default {
    props:['AdminUser', 'InternalAdmin', 'CustomerAdmin']
}
</script>

<style scoped>
* {
  --border-radius: 10px;
  --spacer: 1rem;
  --primary: #0ca7db;
  --text: #6a778e;
  --link-height: calc(var(--spacer) * 3.5);
  --timing: 250ms;
  --transition: var(--timing) ease all;
}

@keyframes gooeyEffect {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

li{
    list-style: none;
}

svg{
    height: 2rem;
    margin: 0;
    padding: 2.5px;
}

body {
  background: #eaeef6;
  font-family: 'Open Sans', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar {
  margin-top: 180px;
  position: absolute;
  left: var(--spacer);
  margin-top: 20px;
  background: #fff;
  border-radius: var(--border-radius);
  padding: var(--spacer) 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: fit-content;
  z-index: 9;
}

.navbar__link {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--link-height);
  color: var(--text);
  transition: var(--transition);
  border-radius: 5px;
}

.navbar__link span {
  position: absolute;
  left: 100%;
  transform: translate(calc(-1 * var(--spacer) * 3));
  margin-left: 1rem;
  opacity: 0;
  pointer-events: none;
  color: #000;
  background: #f2f2f2;
  padding: calc(var(--spacer) * 0.75);
  transition: var(--transition);
  border-radius: calc(var(--border-radius) * 1.75);
}

.navbar__link:hover {
  color: #f2f2f2;
  background-color: #32ccfe;
}

.navbar:not(:hover) .navbar__link:focus,
.navbar__link:hover span {
  opacity: 1;
  transform: translate(0);
}

.navbar__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}

@media only screen and (max-width: 1000px){
    *{
        display: none;
    }
}
</style>
<template>
  <div v-if="placeData" class="main-container-shipmentinfo">
    <div class="inner-container" id="printable-content">
        <div class="confirm-container-main">
                <div class="close-shipment-info-container">
                    <svg class="close-shipment-info" @click="closeAddressInfo()" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                        <path d="M255.997 460.351c112.685 0 204.355-91.668 204.355-204.348S368.682 51.648 255.997 51.648c-112.68 0-204.348 91.676-204.348 204.355s91.668 204.348 204.348 204.348zm0-376.463c94.906 0 172.123 77.209 172.123 172.115 0 94.898-77.217 172.117-172.123 172.117-94.9 0-172.108-77.219-172.108-172.117-.001-94.906 77.207-172.115 172.108-172.115z" fill="#32ccfe" class="fill-000000"></path>
                        <path d="M172.077 341.508a18.35 18.35 0 0 0 12.903 5.27c4.776 0 9.54-1.84 13.151-5.512l57.865-58.973 57.878 58.973a18.372 18.372 0 0 0 13.146 5.512c4.658 0 9.316-1.746 12.902-5.27 7.264-7.125 7.369-18.793.242-26.051l-58.357-59.453 58.357-59.461c7.127-7.258 7.021-18.92-.242-26.047-7.252-7.123-18.914-7.018-26.049.24l-57.878 58.971-57.865-58.971c-7.135-7.264-18.797-7.363-26.055-.24-7.258 7.127-7.369 18.789-.236 26.047l58.351 59.461-58.351 59.453c-7.131 7.258-7.02 18.926.238 26.051z" fill="#32ccfe" class="fill-000000"></path>
                    </svg>
                </div>
                
                <div class="confirm-header-container">
                    <h1 class="package-details-header">Place Details</h1>
                    <img src="../../../assets/LREXDinoFooter.jpg" alt="">
                </div>

                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
                                            <g id="image">
                                                <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
                                            </g>
                                        </svg>
                                        <h2>Address</h2>
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <p name="companyName">{{placeData.placeCode}}</p>
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <p name="address1">{{placeData.address1}}</p>
                                    </div>
                                    
                                    <div v-if="placeData.address2" class="confirmLabelContainer">
                                        <p name="address2">{{placeData.address2}}</p>
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <p name="city">{{placeData.city}}, {{placeData.state}} {{placeData.zipCode}}</p>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <label for="contactName">Pickup Route:</label>
                                        <p style="white-space: nowrap;" name="contactName">{{placeData.pickupRoute}}</p>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <label for="contactName">Radius:</label>
                                        <p style="white-space: nowrap;" name="contactName">{{placeData.radius}}</p>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <label for="contactName">Service Time:</label>
                                        <p style="white-space: nowrap;" name="contactName">{{placeData.serviceTime}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1 update-location">
                                    <div class="confirm-svg">
                                        <h2>Update Place</h2>
                                    </div>

                                    <div class="auto-complete-container">
                                        <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <input disabled placeholder="Company or Name" v-model="placeData.placeCode" type="text">
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <input placeholder="Address" id="address1Popup" disabled v-model="placeData.address1" type="text">
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <input placeholder="Suite/Building" v-model="placeData.address2" type="text">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input placeholder="City" disabled id="cityPopup" v-model="placeData.city" type="text">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <select disabled required name="state" id="statePopup" v-model="placeData.state">
                                            <option value="" disabled selected>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input placeholder="ZipCode" disabled id="zipCodePopup" v-model="placeData.zipCode" type="text">
                                    </div>

                                    <div class="confirmLabelContainer checkbox-label-container">
                                        <label for="pickupRoute">Pickup Route:</label>
                                        <input type="string" id="pickupRoute" disabled v-model="placeData.pickupRoute">
                                    </div>
                                    <!-- <div class="confirmLabelContainer">
                                        <select name="routeCodeSelect" v-model="placeData.pickupRoute" id="routeCodeSelect">
                                            <option disabled selected value="">Pickup Route</option>
                                            <option v-for="(route, index) in routeData.items" :key="index" :value="route.routeCode">{{route.routeCode}}</option>
                                        </select>
                                    </div> -->

                                    <div class="confirmLabelContainer checkbox-label-container">
                                        <label for="serviceTime">Service Time:</label>
                                        <input type="number" id="serviceTime" v-model="placeData.serviceTime">
                                    </div>

                                    <div class="confirmLabelContainer checkbox-label-container">
                                        <label for="radius">Radius:</label>
                                        <input type="number" id="radius" v-model="placeData.radius">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <loading-charts v-if="loadingData"/>
                    <button v-if="validateCustomAddress == false" class="print-button" @click="UpdatePlaceProcess()">Update Place</button>
                    
                    <button v-if="validateCustomAddress == true" style="background-color: #33f18a;" class="print-button" @click="checkAddressCorrection()">Check Place</button>
            </div>
    </div>
  </div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
<AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="placeData"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue';
import { updatePlace } from '../../../graphql/mutations';
import AWSAutoComplete from '../../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../../AWSLocation/AddressCorrection.vue';

export default {
    props:['id', 'jwtToken'],
    components:{
        AlertUser,
        LoadingCharts,
        AWSAddressCorrection,
        AWSAutoComplete
    },
    data(){
        return{
            placeData: null,
            toggleAlertBox: false, 
            alertMessage: null,
            loadingData: false,
            validateCustomAddress: false,
            userCustomAddress: "",
            userServices: null,
            serviceName: "",
            priceName: "",
            priceValue: "",
            routeCode: "",
            serviceLevelName: "",
            addServiceObject: null,
            placeHolder: "Search for address",
            uploadFile: null,
            uploadFileNoSpaces: "",
            getFileStorageURL: null,
            routeData: {}
        }
    },
    watch:{
        'placeData.zipCode'(){
            this.GetGeoCodeGraphQL();
        }
    },
    methods:{
        async GetGeoCodeGraphQL(){
            try {
                let query = `query getGeoCode{
                    getGeoCodes(GeoCode:"${this.placeData.zipCode}"){
                        routeCode
                    }
                }`
                let geoCodeData = await API.graphql({
                    query: query,
                    authMode: "API_KEY",
                })
                if(geoCodeData.data.getGeoCodes == null){
                    this.toggleAlertBox = true;
                    this.alertMessage = "No route code available"
                    return;
                }else{
                    this.placeData.pickupRoute = geoCodeData.data.getGeoCodes.routeCode;
                }
            } catch (error) {
                console.log(error)
            }
        },
        async listRouteCodes(){
            this.loadingUser = true;
            try {
                let listRouteData = await API.graphql({
                    query: `query listRoutes{
                        listRoutes{
                            items{
                                routeCode
                            }
                        }
                    }`,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.routeData = listRouteData.data.listRoutes;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get route information"
            }finally{
                this.loadingUser = false;
            }
        },
        async UpdatePlaceProcess(){
            this.loadingData = true;

            if((this.placeData.address1.length == 0) || (this.placeData.city.length == 0) || (this.placeData.state.length == 0) || (this.placeData.zipCode.length == 0)){
                this.toggleAlertBox = true;
                this.alertMessage = "Enter valid address information."
                this.loadingData = false;
                return;
            }

            await this.updatePlaceData();
            document.getElementById('address1Popup').disabled = true;
            document.getElementById('cityPopup').disabled = true;
            document.getElementById('statePopup').disabled = true;
            document.getElementById('zipCodePopup').disabled = true;
            this.validateCustomAddress = false;
            this.loadingData = false;
        },
        async getPlaceClick(){
            this.loadingTable = true;
            let graphqlQuery = `query getPlace{
                getPlace(placeCode: "${this.id}"){
                    placeCode
                    pickupRoute
                    address1
                    address2
                    city
                    state
                    zipCode
                    radius
                    serviceTime
                    relevance
                    latitude
                    longitude
                    pickupWindowStart
                    pickupWindowEnd
                }
            }`
            
            try{
                let getPlace = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.placeData = getPlace.data.getPlace;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting place"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async updatePlaceData(){
            this.loadingTable = true;
            try{
                await API.graphql({
                query:  updatePlace,
                variables:{
                    input:{
                        address1: this.placeData.address1,
                        address2: this.placeData.address2,
                        city: this.placeData.city,
                        state: this.placeData.state,
                        zipCode: this.placeData.zipCode,
                        companyName: this.placeData.companyName,
                        attention: this.placeData.attention,
                        phone: this.placeData.phone,
                        phoneExt: this.placeData.phoneExt,
                        relevance: this.placeData.relevance,
                        latitude: this.placeData.latitude,
                        longitude: this.placeData.longitude,
                        placeCode: this.placeData.placeCode,
                        pickupRoute: this.placeData.pickupRoute,
                        radius: this.placeData.radius,
                        serviceTime: this.placeData.serviceTime
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.toggleAlertBox = true;
                this.alertMessage = "Place updated"
                await this.getPlaceClick();
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error updating place"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        closeAddressInfo(){
            this.$emit('closeAddressInfo', false)
        },
        GetSelectedAddressData(selectedAddress){
            this.placeData.latitude = selectedAddress.latitude;
            this.placeData.longitude = selectedAddress.longitude;
            this.placeData.address1 = selectedAddress.address1;
            this.placeData.address2 = selectedAddress.address2;
            this.placeData.zipCode = selectedAddress.zipCode;
            this.placeData.city = selectedAddress.city;
            this.placeData.state = selectedAddress.state;
            this.placeData.relevance = selectedAddress.relevance;
        },
        customAddressToggle(toggleCustom){
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";
            document.getElementById('address1Popup').disabled = false;
            document.getElementById('cityPopup').disabled = false;
            document.getElementById('statePopup').disabled = false;
            document.getElementById('zipCodePopup').disabled = false;
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            let customAddressString = this.placeData.address1 + " " + this.placeData.city + " " + this.placeData.state + " " + this.placeData.zipCode;
            //AddressCorrection Child Component is triggered when customAddress changes values
            this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            this.placeData.relevance = event.shipmentAddressData.relevance;
            this.placeData.latitude = event.shipmentAddressData.latitude;
            this.placeData.longitude = event.shipmentAddressData.longitude;
            this.placeData.address1 = event.shipmentAddressData.Address1;
            this.placeData.address2 = event.shipmentAddressData.Address2;
            this.placeData.zipCode = event.shipmentAddressData.ZipCode;
            this.placeData.city = event.shipmentAddressData.City;
            this.placeData.state = event.shipmentAddressData.State;

            if(event.callInsertAddressBook == true){
                // this.updatePlaceData();
                this.UpdatePlaceProcess();
                this.validateCustomAddress = false;
            }
        },
        useCorrectedAddress(event){
            this.placeData.relevance = event.shipmentDataValue.relevance;
            this.placeData.latitude = event.shipmentDataValue.latitude;
            this.placeData.longitude = event.shipmentDataValue.longitude;
            this.placeData.address1 = event.shipmentDataValue.Address1;
            this.placeData.address2 = event.shipmentDataValue.Address2;
            this.placeData.zipCode = event.shipmentDataValue.ZipCode;
            this.placeData.city = event.shipmentDataValue.City;
            this.placeData.state = event.shipmentDataValue.State;
            
            if(event.callInsertAddressBook == true){
                // this.updatePlaceData();
                this.UpdatePlaceProcess();
                this.validateCustomAddress = false;
            }
        },
        useEnteredAddress(event){
            if(event.callInsertAddressBook == true){
                // this.updatePlaceData();
                this.UpdatePlaceProcess();
                this.validateCustomAddress = false;
            }
        }
    },
    mounted(){
        this.getPlaceClick();
        this.listRouteCodes();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped>
/* SVG Styles */

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .st1{fill:none;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;}


    .cls-1-alt{
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-1 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 8px;
    }

    .cls-2 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 12px;
    }

    .cls-2-alt{
        stroke: #000;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 11px;
    }

    input, select{
        padding: 10px;
        font-size: 15px;
        border: 1px solid rgba(0,0,0,.336);
        border-radius: 15px;
        outline: none;
        margin-top: 5px;
    }


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.checkbox-label-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.checkbox-label-container input{
    margin-left: auto;
}

.checkbox-label-container select{
    margin-left: auto;
}

.main-container-shipmentinfo{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
}

@keyframes shipment-details-animate {
    from{
        margin-top: -2%;
    }
    to{
        margin-top: 2.5%;
    }
}

.inner-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.confirm-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    max-height: 90vh;
    overflow-x: auto;
    animation: shipment-details-animate 1s ease;
    margin-top: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 10px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.confirm-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0 15px;
    justify-content: space-between;
}

.confirm-header-container img{
    width: 40px;
    margin-right: 15px;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.file-input{
    width: 80%;
}

.weight-container{
    background-color: #fff;
    margin: 0px 5% 2% 5%;
    padding: 10px;
    border-radius: 25px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.weight-container label{
    margin-right: 5px;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding: 10px;
}

.inner-overflow-container{
    max-height: 90%;
    overflow-y: auto;
    margin-bottom: 10px;
    margin: 5px;
    border-top: 1px #d5d5d5 solid;
    border-bottom: 1px #d5d5d5 solid;
}

.confirmContainerInner1{
    background-color: #f5f5f5;
    margin: 5px;
    padding: 10px;
    border-radius: 25px;
    flex-basis: 25%;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 10px;
}

.update-location{
    flex-basis: 100%;
}

.confirmContainerInner1 h2{
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.confirm-container-address{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.package-details-header{
    background-color: #32ccfe;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    width: fit-content;
    margin: 0;
}

.confirmContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.confirmContainerInner label{
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
}

.confirmLabelContainer p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.confirmLabelContainer2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 5px;
}

.auto-complete-container{
    margin-top: 5px;
}

.service-container{
    display: flex;
    margin: 5px;
    flex-direction: column;
}

.close-shipment-info{
    width: 30px;
    height: 30px;
    margin-right: 5%;
    margin-top: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.close-shipment-info:hover{
    transition-duration: .5s;
    border-radius: 999px;
    background-color: #e4e4e4;
}

.print-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-left: 25px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.print-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

.create-button{
    background-color: #33f18a;
    margin-left: 20px;
}

.create-button:hover{
    transition-duration: .5s;
    background-color: #31d77b;
}

.close-shipment-info-container{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 820px){
    .confirm-container-main{
        width: 90%;
    }

    .weight-container{
        background-color: #fff;
        margin: 5px 15px;
        width: fit-content;
    }

    .confirmContainerInner{
        flex-direction: column;
        flex-wrap: wrap;
    }

    .confirmContainerInner1{
        width: fit-content;
    }

    .package-details-header{
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 530px){
    .service-data{
        flex-direction: column;
    }
}
</style>
<template>
  <div class="popup">
      <div class="popup-inner">
          <div class="closeDriveWithUs-container">
              <h1>Drive with us</h1>
              <div class="close-popup-container"><i class="fa fa-times-circle" @click="DriveWithUsTogglePopup()"></i></div>
          </div>

          <div class="contact-info">
              <div class="contact-info-inner">
                    <h3>Telephone Numbers:</h3>
                    <p>Union: (908) 686-7300</p>
                    <div class="router-container">
                            <router-link class="router-link-contractor" to="/ContractorServices">Click to Get in touch</router-link>
                    </div>
                    <h3>Address:</h3>
                    <p>
                        LREX <br>
                        2333 Route 22 West <br>
                        Union, NJ 07083 <br>
                    </p>
              </div>

              <div class="router-container">
                    <router-link class="router-link" to="/IndependentContractor">Click for More Details</router-link>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['DriveWithUsTogglePopup']

}
</script>

<style scoped>
.popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: drop .5s ease forwards;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    font-family: 'Work Sans', sans-serif;
}

.popup-inner{
    width: fit-content;
    border-radius: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.popup-inner h3{
    margin: 0;
    border-bottom: 1px solid rgb(218, 218, 218);
}

.contact-info{
    text-align: left;
    width: 100%;
}

.contact-info-inner{
    display: block;
    margin: auto;
    padding-right: 25px;
    padding-left: 25px;
}

.router-container{
    width: 100%;
}

.router-link-contractor{
    display: block;
    margin: auto;
    margin-bottom: 15px;
    color:#ffffff;
    background-color: #fe804d;
    text-align: center;
    padding: 5px;
    text-decoration: none;
    width: fit-content;
    border-radius: 50px;
    transition-duration: .5s;
}

.router-link-contractor:hover{
    background-color: #e27245;
    transition-duration: .5s;
}

.router-link{
    display: block;
    margin: auto;
    margin-bottom: 15px;
    color:#ffffff;
    background-color: #32ccfe;
    text-align: center;
    padding: 5px;
    text-decoration: none;
    width: fit-content;
    border-radius: 50px;
    transition-duration: .5s;
}

.router-link:hover{
    background-color: #2ab5e4;
    transition-duration: .5s;
}

@keyframes drop {
  0%{}
  70%{transform: translateY(100px);}
  100%{transform: translateY(85px);}
}

.close-popup-container{
    margin-left: auto;
    margin-right: 5px;
}

.fa-times-circle{
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
    transition-duration: .5s;
}

.fa-times-circle:hover{
    color: rgb(218, 218, 218);
    cursor: pointer;
    transition-duration: .5s;
}

.closeDriveWithUs-container{
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2cb6e4;
    border-radius: 15px 15px 0 0;
}

.closeDriveWithUs-container h1{
    color: #fff;
    text-align: center;
    font-size: 1.8rem;
    flex: 1;
}


.DriveWithUsButton{
    background-color: #33f18a;
    color: rgb(255, 255, 255);
    width: 42%;
    height: 30px;
    margin-top: 0vw;
    margin-bottom: 1vw;
    text-shadow: 1px 1px 4px #696969;
    font-weight: 600;
    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 1000px){

.DriveWithUsButton{
    margin-bottom: 5vw;
    margin-top: 1vw;
    width: 70%;
}
}
</style>
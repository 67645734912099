<template>
<div class="navbar-container">

<nav class="navbar">
  <ul class="navbar__menu">
    <li class="navbar__item">
        <div @click="toggleOpenManifest()" class="navbar__link">
            <svg overflow="visible" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <path d="M256,341.333c9.934,0.019,19.735-2.274,28.629-6.699l42.667-21.333c21.747-10.798,35.465-33.021,35.371-57.301v-63.296  c0.075-24.259-13.641-46.453-35.371-57.237l-42.667-21.333c-18.027-8.96-39.21-8.96-57.237,0l-42.667,21.333  c-21.738,10.778-35.463,32.974-35.392,57.237V256c-0.078,24.265,13.637,46.467,35.371,57.259l42.667,21.333  C236.261,339.031,246.063,341.339,256,341.333z M192,256v-63.296c0.034-2.176,0.401-4.335,1.088-6.4l46.507,23.253  c10.328,5.162,22.483,5.162,32.811,0l46.507-23.253c0.687,2.065,1.054,4.224,1.088,6.4V256c0.034,8.094-4.543,15.502-11.797,19.093  l-42.667,21.333c-6.016,2.977-13.077,2.977-19.093,0l-42.667-21.333C196.53,271.496,191.962,264.09,192,256z M405.333,469.333  h-57.792l74.389-68.736c91.645-91.642,91.647-240.224,0.005-331.869S181.712-22.918,90.067,68.723S-1.58,308.948,90.062,400.592  c0.201,0.201,74.397,68.741,74.397,68.741h-57.792c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333  h298.667c11.782,0,21.333-9.551,21.333-21.333C426.667,478.885,417.115,469.333,405.333,469.333z M120.235,98.901  c74.816-75.152,196.389-75.424,271.541-0.608s75.424,196.389,0.608,271.541l-85.013,78.528  c-29.016,27.349-74.366,27.19-103.189-0.363l-83.947-77.568C45.37,295.402,45.371,173.931,120.235,98.901z"/>
            </svg>
            <span>Open Manifest</span>
        </div>      
    </li>
    <li class="navbar__item">
        <div @click="toggleOptimizedManifest()" class="navbar__link">
            <svg overflow="visible" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m17.26,10.292c.488.477,1.114.715,1.74.715s1.252-.238,1.74-.715l1.795-1.756c1.949-1.95,1.949-5.122,0-7.071h0c-.945-.944-2.2-1.464-3.535-1.464s-2.592.52-3.535,1.464c-1.949,1.95-1.949,5.122.008,7.079l1.787,1.749Zm-.381-7.414c.566-.566,1.319-.878,2.121-.878s1.555.312,2.121.879c1.17,1.169,1.17,3.073.008,4.235l-1.787,1.748c-.191.188-.492.187-.684,0l-1.779-1.74c-1.17-1.17-1.17-3.073,0-4.243Zm3.122,1.909c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm3.999,13.213c0,2.206-1.794,4-4,4h-7c-.553,0-1-.448-1-1s.447-1,1-1h7c1.103,0,2-.897,2-2s-.897-2-2-2h-8c-2.206,0-4-1.794-4-4s1.794-4,4-4c.553,0,1,.448,1,1s-.447,1-1,1c-1.103,0-2,.897-2,2s.897,2,2,2h8c2.206,0,4,1.794,4,4Zm-14.884-.454c-.604-1.547-2.066-2.546-3.727-2.546H1c-.553,0-1,.448-1,1s.447,1,1,1h2v3H1c-.553,0-1,.448-1,1s.447,1,1,1h1v.5c0,.828.672,1.5,1.5,1.5s1.5-.672,1.5-1.5c0,.828.672,1.5,1.5,1.5s1.5-.672,1.5-1.5v-.5c1.299,0,2-1.03,2-2,0-.125-.023-.248-.068-.363l-.815-2.09Zm-4.116,2.454v-3h.39c.83,0,1.562.5,1.863,1.273l.674,1.727h-2.927Z"/>
            </svg>
            <span>Optimized Manifest</span>
        </div>      
    </li>
  </ul>
</nav>

</div>
</template>

<script>
export default {
    methods:{
        toggleOpenManifest(){
            this.$emit('toggleOpenManifest', true)
        },
        toggleOptimizedManifest(){
            this.$emit('toggleOptimizedManifest', true)
        }
    }
}
</script>

<style scoped>
* {
  --border-radius: 10px;
  --spacer: 1rem;
  --primary: #0ca7db;
  --text: #6a778e;
  --link-height: calc(var(--spacer) * 3.5);
  --timing: 250ms;
  --transition: var(--timing) ease all;
}

@keyframes gooeyEffect {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

li{
    list-style: none;
}

svg{
    height: 2rem;
    margin: 0;
    padding: 2.5px;
}

body {
  background: #eaeef6;
  font-family: 'Open Sans', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar-container{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.navbar {
  /* margin-top: 180px; */
  position: relative;
  margin-top: 20px;
  background: #fff;
  border-radius: var(--border-radius);
  padding: 2.5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: fit-content;
  width: fit-content;
  z-index: 9;
}

.navbar__link {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 1px;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--link-height);
  color: var(--text);
  transition: var(--transition);
  border-radius: 5px;
  cursor: pointer;
}

.navbar__link span {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(calc(-1 * var(--spacer) * 3));
  margin-top: .5rem;
  opacity: 0;
  pointer-events: none;
  color: #000;
  background: #f2f2f2;
  padding: calc(var(--spacer) * 0.75);
  transition: var(--transition);
  border-radius: calc(var(--border-radius) * 1.75);
}

.navbar__link:hover {
  color: #f2f2f2;
  background-color: #32ccfe;
}

.navbar:not(:hover) .navbar__link:focus,
.navbar__link:hover span {
  opacity: 1;
  transform: translateY(0);
}

.navbar__menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}
</style>
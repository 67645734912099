<template>
  <div class="container">
      <div class="inner-container">
          <table class="inner-table">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Subject to Fuel Surcharge</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="AdditionalCharges in AdditionalChargesDataJSON" :key="AdditionalCharges">
                        <td>{{AdditionalCharges.Description}}</td>
                        <td>{{AdditionalCharges.Price}}</td>
                        <td>{{AdditionalCharges["Subject to Fuel Surcharge"]}}</td>
                        <td>{{AdditionalCharges.Notes}}</td>
                    </tr>
                </tbody>
          </table>
          <div class="text-container">
                <h2>2023 LRex Additional Fee Schedule</h2>
                    <p><strong>LRex</strong> additional charges are for services performed outside and beyond the established rates for Next Day or Same Day Delivery Service. 
                        If you have additional questions about additional fees, please contact <strong>customerservice@lrex.com</strong>.
                    </p>
                <p>
                    <small>Additional charges are subject to change without notice.</small>
                </p>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            AdditionalChargesDataJSON: {}
        }
    },
    methods:{
        scrollToTop(){
            window.scrollTo(0,0);
        },
        async getAdditionalChargesData(){
            await axios.get('../AdditionalCharges.json').then((response)=>{
              this.AdditionalChargesDataJSON = response.data;
            })
        }
    },
    mounted(){
        this.scrollToTop();
        this.getAdditionalChargesData();
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .inner-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
    }

    .text-container{
        width: 45%;
        margin: 2.5%;
        padding: 10px;
        text-align: left;
        border-radius: 15px;
        background-color: #fff;
        filter: drop-shadow(15px 10px #f4b204);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .inner-table{
        width: 40%;
        margin-left: 3.5%;
        margin-right: 3.5%;
        border-collapse: collapse;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 0.9em;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        text-align: center;
    }

    .inner-table th:first-child{
        border-top-left-radius: 10px;
    }

    .inner-table th:last-child{
        border-top-right-radius: 10px;
    }

    .inner-table tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    .inner-table tbody tr:last-of-type {
        border-bottom: 2px solid #32ccfe;
    }

    .inner-table th{
        background-color: #33f18a;
        color: #ffffff;
    }

    .inner-table th,
    .inner-table td{
        padding: 1.5%;
    }

    .inner-table tbody tr{
        border-bottom: 1px solid #dddddd;
    }

    @media screen and (max-width: 1000px) {

        .inner-container{
            flex-direction: column;
        }

        .text-container{
            width: 90%;
        }

        .inner-table{
            width: 95%;
        }

        .inner-table th,
        .inner-table td{
            padding: 2.5%;
        }
    }
</style>
<template>
  <div class="table-container-main">
    <div class="confirm-svg">
        <svg style="padding-left: 5px;" height="50px" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 180.36">
            <g id="image">
                <path d="M83.82,107.32c-.8,.6-1.89,.64-2.73,.09-9.02-5.68-17.02-12.82-23.68-21.13-8.16-9.91-13.64-21.75-15.94-34.38-1.89-11.72-.09-22.99,5.78-32.17,2.37-3.73,5.35-7.03,8.8-9.77C63.62,3.64,73.14,.13,82.99,0c9.55,.15,18.69,3.88,25.62,10.45,2.66,2.44,4.96,5.25,6.84,8.33,6.27,10.27,7.62,23.48,4.86,36.86-4.67,21.46-17.84,40.1-36.49,51.68Zm-26.82,1.36v40.6l44,18.97v-52.69l8-7.93v60.34l37-18.63V67.47l-15.02,5.78c1.51-3.98,2.75-8.06,3.72-12.2l14.53-5.61c2.58-.94,5.44,.36,6.42,2.92,.23,.58,.34,1.19,.34,1.81v92.18c0,1.93-1.16,3.68-2.95,4.42l-45.95,23.08c-1.35,.68-2.95,.68-4.3,0l-51.55-22.34L6.89,179.85c-2.34,1.18-5.19,.25-6.37-2.09,0,0,0,0,0,0C.18,177.09,0,176.36,0,175.62V78.41c0-2.22,1.46-4.17,3.59-4.8l25.36-9.78c.94,3.19,2.06,6.33,3.34,9.4l-22.29,8.59v86.19l37-18.63v-51.2c3.11,3.71,6.45,7.22,10,10.51ZM81.22,20.85c11.12,0,20.13,9.01,20.13,20.13s-9.01,20.13-20.13,20.13-20.13-9.01-20.13-20.13,9.01-20.13,20.13-20.13h0Z" style="fill: #000; fill-rule: evenodd;"/>
            </g>
        </svg>
        <h2>Places</h2>
    </div>
    <table-filter :filterByAddress="true" :filterByZipCode="true" :filterByCity="true" @filterValues="filterValues($event)"/>

    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">Code</div>
            <div class="col-2">Address</div>
            <div class="col-3">Route</div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div class="table-row-container">
            <div class="table-row" v-for="(place, index) in placeData" :key="index">
                <div class="col-1" v-if="this.$route.name == 'LocationDashboard'"><p class="shipmentid-text" id="shipmentid-text">{{place.placeCode}}</p></div>
                <div class="col-1" v-else><p class="shipmentid-text" id="shipmentid-text" @click="getUserDataClick(place.placeCode)">{{place.placeCode}}</p></div>
                <div class="col-2 col-data">{{place.address1}}, {{place.city}}, {{place.state}}, {{place.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{place.pickupRoute}}</p></div>
                <!-- <div class="col-4"></div> -->
                <!-- <div class="col-4"><input v-if="this.$route.name == 'PlaceDashboard'" type="checkBox" name="" :checked="isChecked(place.placeCode)" id="selectIDCheckBoxPlace" @click="checkBoxSelectID(place.placeCode)"></div> -->
                <div class="col-4"><input v-if="this.$route.name == 'LocationDashboard'" type="checkBox" name="" id="selectIDCheckBoxPlace" @click="checkBoxSelectID(place.placeCode, index)"></div>
            </div>
            <div v-if="placeData.length == 0" class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Places</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
    </div>
  </div>
  <div v-if="(this.$route.name != 'UserDashboard') && (this.placeData.length < 1)" class="button-container">
    <div class="print-selected close-label">
        <router-link  class="create-place-link" to="/PlaceDashboard">Create Place</router-link>
    </div>
  </div>
  <div v-if="this.$route.name != 'UserDashboard'" class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
<place-info v-if="toggleAddressBookInfo" @closeAddressInfo="closeAddressInfo($event)" :id="id"/>
<alert-user  v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import PlaceInfo from '../PlaceDashboard/PlaceInfo.vue'
import jsonexport from 'jsonexport'
import { listPlaces, placeByAddress1, placeByCity, placeByZipCode } from '../../../graphql/queries'
import TableFilter from '../TableFilter.vue'

export default {
    components:{
        AlertUser,
        LoadingCharts,
        PlaceInfo,
        TableFilter
    },
    props: ['userData', 'jwtToken', 'selectedUserData', 'locationZipCode', 'locationLatitude', 'locationLongitude'],
    watch:{
        'userData':function(){
            if(this.userData && (this.$route.name != 'LocationDashboard'))
            {
                this.GetPlaceData();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user data to generate table."
            }
        },
        'locationLatitude':function(){
            this.GetPlaceDataForLocation(this.locationZipCode, this.locationLatitude, this.locationLongitude);
            let checkbox = document.querySelectorAll("#selectIDCheckBoxPlace");
            checkbox.forEach(element => {
                element.checked = false;
            });
        }
    },
    mounted(){
        if(this.userData && (this.$route.name != 'LocationDashboard')){
            this.GetPlaceData(); 
        }

        if(this.userData && (this.$route.name == 'LocationDashboard')){
            // this.GetPlaceData(); 
            this.GetPlaceDataForLocation(this.locationZipCode, this.locationLatitude, this.locationLongitude);
            let checkbox = document.querySelectorAll("#selectIDCheckBoxPlace");
            checkbox.forEach(element => {
                element.checked = false;
            });
        }
    },
    data(){
        return{
            toggleAddressBookInfo: false,
            id: null,
            placeData: [],
            addressIDArray: [],
            addressID: null,
            loadingTable: false,
            toggleAlertBox: '',
            alertMessage: '',
            filterField: null,
            filterValue: null,
            username: "",
            selectedIndex: null
        }
    },
    methods:{
        async GetPlaceDataForLocation(locationZipCode, locationLatitude, locationLongitude){
            let placeDataWithinRadius = [];
            await this.GetPlaceByZipCode(locationZipCode);
            let locationGeoCoordinates = {
                latitude: locationLatitude,
                longitude: locationLongitude
            }
            this.placeData.forEach(element => {
                let placeGeoCoordinates = {
                    latitude: element.latitude,
                    longitude: element.longitude
                }
                
                let distance = this.calcDistance(locationGeoCoordinates, placeGeoCoordinates)

                if(distance <= 1000){
                    placeDataWithinRadius.push(element)
                }
            });

            this.placeData = placeDataWithinRadius;
        },
        checkBoxSelectID(locationID, index){
            this.$emit('checkBoxSelectID', locationID)
            let checkbox = document.querySelectorAll("#selectIDCheckBoxPlace");
            this.selectedIndex = index;
            checkbox.forEach(element => {
                element.checked = false;
            });

            checkbox[index].checked = true;
        },
        filterValues(event){
            this.isFiltered = true;
            this.filterField = event.filterField;
            this.filterValue = event.filterValue;

            switch (event.filterField) {
                case "address1":
                    this.GetPlaceByAddress1(event.filterValue)
                    break;
                case "city":
                    this.GetPlaceByCity(event.filterValue)
                    break;
                case "zipCode":
                    this.GetPlaceByZipCode(event.filterValue)
                    break;
                default:
                    break;
            }
        },
        async GetPlaceByAddress1(filterValue){
            try{
                let getPlaceAddress1 = await API.graphql({
                query:  placeByAddress1,
                variables:{
                    address1: filterValue
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.placeData = getPlaceAddress1.data.placeByAddress1.items
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting place by address"
                }
            }
        },
        async GetPlaceByCity(filterValue){
            try{
                let getPlaceCity = await API.graphql({
                query:  placeByCity,
                variables:{
                    city: filterValue
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })

                this.placeData = getPlaceCity.data.placeByCity.items
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting place by city"
                }
            }
        },
        async GetPlaceByZipCode(filterValue){
            try{
                let getPlaceZipCode = await API.graphql({
                query:  placeByZipCode,
                variables:{
                    zipCode: filterValue
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })

                this.placeData = getPlaceZipCode.data.placeByZipCode.items
            }catch(e){
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting place by zip code"
                }
            }
        },
        // isChecked(placeCode, index){
        //     if(this.placeData[index].placeCode == placeCode){
        //         return true;
        //     }else{
        //         return false;
        //     }
        // },
        async getUserDataClick(id){
            this.id = id;
            this.toggleAddressBookInfo = true;
        },
        closeAddressInfo(event){
            this.toggleAddressBookInfo = event;
            this.GetPlaceData();
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async GetPlaceData(){
            this.loadingTable = true;
            try {
                let placesReturnData = await API.graphql({
                    query: listPlaces,
                    variables: {
                            UserName: this.userData.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.placeData = placesReturnData.data.listPlaces.items;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get places"
            }finally{
                this.loadingTable = false;
            }
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;
            csvArray = this.placeData.map(obj =>{
                return {
                    PlaceCode: obj.placeCode,
                    PickupRoute: obj.pickupRoute,
                    Address1: obj.address1,
                    Address2: obj.address2,
                    City: obj.city,
                    State: obj.state,
                    ZipCode: obj.zipCode,
                    Radius: obj.radius,
                    ServiceTime: obj.serviceTime,
                    Relevance: obj.relevance,
                    Latitude: obj.latitude,
                    Longitude: obj.longitude,
                    PickupWindowStart: obj.pickupWindowStart,
                    PickupWindowEnd: obj.pickupWindowEnd
                }
            })
            jsonexport(csvArray, (error, csv) => {
                if (error) {
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error downloading place data"
                }
                const blob = new Blob([csv], { type: 'text/csv' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = 'Places.csv';
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
                anchor.remove();
                this.loadingTable = false;
            })
        },
        degreesToRadians(degrees) {
            var radians = (degrees * Math.PI)/180;
            return radians;
        },
        calcDistance (awsCoords, googleCoords){
            let startingLat = this.degreesToRadians(awsCoords.latitude);
            let startingLong = this.degreesToRadians(awsCoords.longitude);
            let destinationLat = this.degreesToRadians(googleCoords.latitude);
            let destinationLong = this.degreesToRadians(googleCoords.longitude);

            // Radius of the Earth in feet
            let radius = 20925721.784777;

            // Haversine equation
            let distanceInFeet = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
            Math.cos(startingLat) * Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)) * radius;
            return distanceInFeet;
        }
    }
}
</script>

<style scoped>
h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

.responsive-table{
    width: 100%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin-bottom: 5px;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    white-space: nowrap;
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.create-place-link{
    text-decoration: none;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #32ccfe;
    border: 1px solid #32ccfe;
    transition-duration: .5s;
    color: #fff;
}

.create-place-link:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-bottom: 5px;
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text, .col-data{
        font-size: 8px;
    }

    .shipmentid-text{
        font-size: 10px;
    }
}

@media only screen and (max-width: 1135px) {
   .col-1{
        min-width: 30%;
    }
}
</style>
<template>
<div class="loading-data-container">
</div>
  
</template>

<script>
export default {
    props:['loadingHeight']
}
</script>

<style scoped>
.loading-data-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: v-bind(loadingHeight);
    border-radius: 25px;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #e4e4e4 18%, #eeeeee 33%);
}


@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}
</style>
<template>
<!-- {{ShipmentData}} -->
<div class="howContainerInner">
    <div v-if="noRouteCodeAvailable == false" class="serviceTypesContainer">
        <div class="serviceTypes" v-for="(product, index) in serviceOptions" :key="product">
            <div v-if="product.additionalService == false" class="serviceContainer" @click="SelectService(index)">
                <div class="main-image-container">
                    <div class="imageHeaderContainer">
                        <div class="text-container-svg">
                            <div v-for="(image, index) in getSVG.imagePath" :key="image">
                                <img v-if="getSVG.imageName[index] == './'+ product.productCode +'.svg'" :src="getSVG.imagePath[index]" alt="">
                            </div>
                        </div>
                        <h3>{{product.productName}}</h3>
                    </div>
                </div>

                <div class="productDescription">
                    <p>{{product.descriptionUI}}</p>
                    <p><span style="font-weight: bold;">Price: </span>{{product.price}}</p>
                </div>
                <div @click="toggleActive(index)" class="arrowContainer"><i class="arrow down"></i></div>
                <div @click="toggleActive(index)" class="arrowContainer"><i class="arrow up"></i></div>
            </div>
        </div>
    </div>

    <div v-if="(noRouteCodeAvailable == false) && (selectedProductCode.length > 0)" class="additionServicesInputs">
        <div>
            <div v-for="(uniqueServiceGroup) in uniqueServiceGroups" :key="uniqueServiceGroup">
            <h2 v-if="shouldShowHeader(uniqueServiceGroup)" style="margin-top: 0; margin-bottom: 10px;">{{ uniqueServiceGroup }}</h2>
            <div v-for="(additionalService) in additionalServiceOptionsForProductCode" :key="additionalService">
                <div class="additional-services-container" v-if="(additionalService.serviceGroup == uniqueServiceGroup) && (shouldShowHeader(uniqueServiceGroup))">
                <div v-if="(additionalService.additionalService == true) && (selectedProductCode == additionalService.productCode) && (additionalService.serviceGroup)" class="signatureInputLabel">
                    <label style="white-space: nowrap;" for="additionalservices">{{ additionalService.description }}</label>
                    <input style="margin-left: 10px;" :id="additionalService.serviceCode" type="radio" :value="additionalService.serviceCode" :name="uniqueServiceGroup" @change="updateSelectedOption(uniqueServiceGroup, additionalService.serviceCode)" class="groupRadio" :checked="isSelectedOption(uniqueServiceGroup, additionalService.serviceCode)">
                </div>
                </div>
            </div>
            </div>
        </div>

        <h2 v-if="(noRouteCodeAvailable == false) && (selectedProductCode.length > 0) && (additionalServiceNoServiceGroup.length > 0)" >Additional Services</h2>
        <div v-for="(additionalService, index) in additionalServiceOptionsForProductCode" :key="index" class="additional-services-container">
            <div v-if="(additionalService.additionalService == true) && (selectedProductCode == additionalService.productCode) && (!additionalService.serviceGroup)" class="signatureInputLabel">
                <label style="white-space: nowrap;" for="additionalservices">{{additionalService.description}}</label>
                <input style="margin-left: 10px;" id="additionalservices" type="checkbox" :value="additionalService.serviceCode" class="checkBox" v-model="ShipmentData.AdditionalServices">
            </div>
        </div>

        <div v-if="isContractedDeliveryProp" class="signatureInputLabel">
            <label style="white-space: nowrap;" for="additionalservices">Contracted Delivery</label>
            <input style="margin-left: 10px;" id="additionalservices" type="checkbox" checked disabled class="checkBox">
        </div>
    </div>
</div>
</template>

<script>

export default {
computed: {
    getSVG(){
        const path = require.context('../../../public/ServiceOptions', false, /\.svg$/)
        return {
           imagePath: path.keys().map(path),
           imageName: path.keys()
        }
    },
    serviceGroups(){
        const serviceGroupSet = new Set();
        this.additionalServiceOptions.forEach(group => serviceGroupSet.add(group.serviceGroup));
        return Array.from(serviceGroupSet); 
    },
    uniqueServiceGroups() {
      const serviceGroupsSet = new Set();
      this.additionalServiceOptionsForProductCode.forEach((additionalService) => {
        if (additionalService.serviceGroup) {
          serviceGroupsSet.add(additionalService.serviceGroup);
        }
      });
      return Array.from(serviceGroupsSet);
    }
},
props:['customerLocationHasPickupService', 'shipmentData', 'ServiceOptionsData', 'UserServiceOptionsData', 'isContractedDeliveryProp', 'geoCodeDataGraphQL', 'geoCodeDataGraphQLDeliveryPickup', 'noRouteCodeAvailable', 'currentActive'],
data(){
    return{
        ShipmentData: {
            Service: "",
            routeCode: "",
            pickupRouteCode: "",
            AdditionalServices: [],
            deliveryWindowStart: "",
            deliveryWindowEnd: "",
            deliveryWindowLabel: "",
            isPickup: false
        },
        testServiceArray: [],
        selectedOptions: {},
        noRestCode: "noRestCode",
        // groupServiceRestServiceCode: "",
        selectedRouteCode: "",
        selectedPickupRouteCode: "",
        selectedProductCode: "",
        selectedServiceGroup: "",
        svgPathName: [],
        isContractedDelivery: this.isContractedDeliveryProp,
        accountGeoCodeCommonProducts: [],
        externalNetworkProduct: {},
        serviceOptions: [],
        additionalServiceOptions: [],
        additionalServiceOptionsForProductCode: [],
        displayedServiceGroups: [],
        additionalServiceNoServiceGroup: [],
        selectedProductIndexFromSequence: 0,
        smallestSequenceArray: []
    }
},
watch:{
    'selectedOptions'(){
        for (const serviceGroup in this.selectedOptions) {
            if(this.customerLocationHasPickupService === false && serviceGroup === "Pickup"){
                //Do Nothing
                break;
            }
            if (this.selectedOptions[serviceGroup].serviceCode) {
                this.ShipmentData.AdditionalServices.push(this.selectedOptions[serviceGroup].serviceCode);
                this.SelectAdditionalService();
            }else{
                this.ShipmentData.AdditionalServices.push(this.selectedOptions[serviceGroup]);
                this.SelectAdditionalService();
            }
        }
    },
    'currentActive'(){
        if(this.currentActive == 2){
            this.setServiceFromSequence(this.selectedProductIndexFromSequence)
        }
    },
    'geoCodeDataGraphQL': function(){
        this.serviceOptions = [];
        this.additionalServiceOptions = [];
        this.selectedProductCode = "";
        const serviceContainers = document.querySelectorAll(".serviceContainer");
        for(let i = 0; i < serviceContainers.length; i++){
            const serviceContainer = serviceContainers[i];
            serviceContainer.classList.remove('active')
        }
        this.getCommonServices(this.ServiceOptionsData.product, this.geoCodeDataGraphQL)
    },
    'geoCodeDataGraphQLDeliveryPickup': function(){
        this.selectedPickupRouteCode = "";
        const pickupGeoCodeRoute = this.geoCodeDataGraphQLDeliveryPickup.filter(geoCodeItem => geoCodeItem.route.serviceNetwork === 'LRPICK');
        if(pickupGeoCodeRoute.length > 0){
            this.selectedPickupRouteCode = pickupGeoCodeRoute[0].routeCode;
            this.ShipmentData.pickupRouteCode = this.selectedPickupRouteCode;
        }
    },
    'ShipmentData.AdditionalServices': function(){
        this.SelectAdditionalService();
    },
    'accountGeoCodeCommonProducts'(){
        for(let i = 0; i < this.accountGeoCodeCommonProducts.length; i++){
            if(this.accountGeoCodeCommonProducts[i].additionalService == true){
                this.additionalServiceOptions.push(this.accountGeoCodeCommonProducts[i])
            }

            if(this.accountGeoCodeCommonProducts[i].additionalService == false){
                this.serviceOptions.push(this.accountGeoCodeCommonProducts[i])
            }
        }
    },
},
methods: {
    updateSelectedOption(groupName, option) {
      this.ShipmentData.AdditionalServices = []
      this.selectedOptions[groupName] = option;
      for (const serviceGroup in this.selectedOptions) {
            if(this.customerLocationHasPickupService === false && serviceGroup === "Pickup"){
                //Do Nothing
                break;
            }
            if (this.selectedOptions[serviceGroup].serviceCode) {
                this.ShipmentData.AdditionalServices.push(this.selectedOptions[serviceGroup].serviceCode);
                this.SelectAdditionalService();
            }else{
                this.ShipmentData.AdditionalServices.push(this.selectedOptions[serviceGroup]);
                this.SelectAdditionalService();
            }
        }
    },
    isSelectedOption(groupName, option) {
        return this.selectedOptions[groupName].serviceCode === option;
    },
    shouldShowHeader(uniqueServiceGroup) {
      return !(this.customerLocationHasPickupService === false && uniqueServiceGroup === "Pickup");
    },
    async setAdditionalServiceGroup() {
        this.additionalServiceOptionsForProductCode = [];
        this.additionalServiceNoServiceGroup = [];

        // Initialize an empty object to store the smallest sequences and serviceCodes for each service group
        const smallestSequences = {};

        for (let i = 0; i < this.additionalServiceOptions.length; i++) {
            const serviceOption = this.additionalServiceOptions[i];

            if (
                serviceOption.serviceGroup &&
                this.selectedProductCode === serviceOption.productCode
            ) {
                // Create an entry for the service group if it doesn't exist in smallestSequences
                if (!smallestSequences[serviceOption.serviceGroup]) {
                    smallestSequences[serviceOption.serviceGroup] = {
                        sequence: Infinity,
                        serviceCode: null,
                    };
                }

                if (serviceOption.serviceGroupSequence < smallestSequences[serviceOption.serviceGroup].sequence) {
                    smallestSequences[serviceOption.serviceGroup].sequence = serviceOption.serviceGroupSequence;
                    smallestSequences[serviceOption.serviceGroup].serviceCode = serviceOption.serviceCode;

                    this.selectedServiceGroup = serviceOption.serviceGroup;
                }
            }

            if (this.selectedProductCode === this.additionalServiceOptions[i].productCode) {
                this.additionalServiceOptionsForProductCode.push(this.additionalServiceOptions[i]);

                if (!serviceOption.serviceGroup) {
                    this.additionalServiceNoServiceGroup.push(this.additionalServiceOptions[i]);
                }
            }
        }

        // Output the smallest sequences and serviceCode for each service group
        this.smallestSequenceArray = smallestSequences;
        this.selectedOptions = this.smallestSequenceArray;
        // for (const serviceGroup in smallestSequences) {
        //     const { sequence, serviceCode } = smallestSequences[serviceGroup];

        //     if (sequence !== Infinity) {
        //         console.log(`The smallest ${serviceGroup} serviceGroupSequence is: ${sequence}`);
        //         console.log(`Associated Service Code: ${serviceCode}`);
        //     } else {
        //         console.log(`No ${serviceGroup} serviceGroupSequence found in the array.`);
        //     }
        // }
    },
    async setServiceFromSequence(){
        let smallestSequence = Infinity; // Initialize with a large value
        for (let i = 0; i < this.serviceOptions.length; i++) {
            const serviceOption = this.serviceOptions[i];
            if (((serviceOption.productSequence !== null) && (serviceOption.productSequence !== ""))) {
                if (serviceOption.productSequence < smallestSequence) {
                    smallestSequence = serviceOption.productSequence;
                    this.selectedProductIndexFromSequence = this.serviceOptions.indexOf(serviceOption);
                }
            }
        }
        if (smallestSequence !== Infinity) {
            // console.log("The smallest service serviceGroupSequence is: " + smallestSequence);
            // console.log(this.selectedProductIndexFromSequence)
            this.SelectService(this.selectedProductIndexFromSequence)
        } else {
            console.log("No serviceGroupSequence found in the array.");
            this.selectedServiceGroup = null;
        }
    },
    getCommonServices(accountProducts,geoCodeProducts){
        // console.log("ACCOUNT PRODUCT: ", accountProducts)
        // console.log("GEOCODE PRODUCTS: ", geoCodeProducts)
        try {
            const accountProductsTest = accountProducts.filter(accountItem => {
            const matchingGeoCodeItems = geoCodeProducts.filter(geoCodeItem => accountItem.serviceNetwork === geoCodeItem.route.serviceNetwork);
            if (matchingGeoCodeItems.length > 0) {
                accountItem.routeCode = matchingGeoCodeItems[0].routeCode;
                return true;
            }
            return false;
            });
            // console.log("COMMON PRODUCTS: ", accountProductsTest)
            this.accountGeoCodeCommonProducts = accountProductsTest;
        } catch (error) {
            console.log(error)
            alert("Issue getting services for account.")
        }
    },
    toggleActive(index) {
        if(document.getElementsByClassName("productDescription")[index].classList.contains("active")){
            document.getElementsByClassName("productDescription")[index].classList.remove("active");
        }else{
            document.getElementsByClassName("productDescription")[index].classList.add("active");
        }
    },
    SelectService(index){
        if(this.noRouteCodeAvailable == false){
            this.ShipmentData.isPickup = false;
            if(this.serviceOptions[index].productCode == 'SSLRPICK'){
                const serviceAddressRouteCode = this.serviceOptions.filter(serviceItem => serviceItem.productCode === 'SSLRLOC');
                this.selectedRouteCode = serviceAddressRouteCode[0].routeCode;
                this.ShipmentData.isPickup = true;
            }else{
                this.selectedRouteCode = this.serviceOptions[index].routeCode;
            }
            this.ShipmentData.Service = "";
            this.groupServiceRestServiceCode = "";
            this.ShipmentData.AdditionalServices = [];
            this.ShipmentData.deliveryWindowEnd = null;
            this.ShipmentData.deliveryWindowStart = null;
            this.ShipmentData.deliveryWindowLabel = null;
            const serviceContainers = document.querySelectorAll(".serviceContainer");
            this.ShipmentData.Service = this.serviceOptions[index].productCode;
            this.selectedProductCode = this.serviceOptions[index].productCode;
            this.ShipmentData.routeCode = this.selectedRouteCode;
            this.ShipmentData.pickupRouteCode = this.selectedPickupRouteCode;
            this.setAdditionalServiceGroup()

            this.$emit('GetSelectedService', this.ShipmentData);
            this.$emit('GetSelectedAdditionalService', this.ShipmentData);

            for(let i = 0; i < serviceContainers.length; i++){
                const serviceContainer = serviceContainers[i];
                serviceContainer.classList.remove('active')
            }

            document.getElementsByClassName("serviceContainer")[index].classList.add("active");
        }
    },
    SelectAdditionalService(){
        // this.ShipmentData.isPickup = false;
        for (let i = 0; i < this.ShipmentData.AdditionalServices.length; i++) {
            const additionalService = this.ShipmentData.AdditionalServices[i];
            if(additionalService == "PUN" || additionalService == "PUO"){
                this.ShipmentData.isPickup = true;
            }
            for (let j = 0; j < this.additionalServiceOptionsForProductCode.length; j++) {
                const serviceOption = this.additionalServiceOptionsForProductCode[j];
                if((additionalService == serviceOption.serviceCode) && (this.additionalServiceOptionsForProductCode[j].deliveryWindowLabel)){
                    this.ShipmentData.deliveryWindowEnd = this.additionalServiceOptionsForProductCode[j].deliveryWindowEnd;
                    this.ShipmentData.deliveryWindowStart = this.additionalServiceOptionsForProductCode[j].deliveryWindowStart;
                    this.ShipmentData.deliveryWindowLabel = this.additionalServiceOptionsForProductCode[j].deliveryWindowLabel;
                    this.$emit('GetSelectedAdditionalService', this.ShipmentData);
                }else{
                    this.$emit('GetSelectedAdditionalService', this.ShipmentData);
                }
            }
        }
    }
  }
}
</script>

<style scoped>
    .data-view{
        display: flex;
        flex-direction: column;
    }

    .serviceContainer{
        width: 25vw;
        border: 2px solid #33f18a;
        margin: 10px;
        box-shadow: rgba(0, 0, 0, 0.164) 0px 5px 10px;
        border-radius: 25px;
        transition-duration: .5s;
        animation: serviceContainerAnimate .5s ease;
    }

     @keyframes serviceContainerAnimate{
        from{
                margin-left: -10px;
                opacity: 0;
            }
        to{
            margin-left: 0;
            opacity: 1;
        }
     }

    .productDescription{
        margin-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        display: none;
        animation: showDetails .5s ease;
        flex-direction: column;
    }

    .productDescription.active{
        display: flex;
        transition-duration: .5s;
    }

    .serviceContainer:hover{
        cursor: pointer;
        background-color: #33f18a88;
        transition-duration: .5s;
    }

    .serviceContainer.active{
        background-color: #33f18a88;
        transition-duration: .5s;
    }

    .serviceRowOne{
        display: flex;
        justify-content: row;
        animation: serviceContainerAnimate .5s;
    }

    .serviceRowTwo{
        display: flex;
        justify-content: row;
        margin-top: 15px;
        animation: serviceContainerAnimate .5s;
    }

    .howContainerInner{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
    }

    .additionServicesInputs{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .additional-services-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .signatureInputLabel{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
    }

    .signatureInputLabel label{
        padding-bottom: 5px;
        padding-right: 5px;
        padding-top: 5px;
        text-align: left;
        /* width: 80%; */
    }

    .signatureInputLabel input{
        margin-left: auto !important;
        /* width: 20%; */
    }

    input, select{
        font-size: 15px;
        border: 1px solid rgba(0,0,0,.336);
        border-radius: 15px;
        outline: none;
        margin-top: 5px;
    }

    input[type="time"]{
        padding: 5px;
    }

    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0;
        /* margin-left: auto; */
        font: inherit;
        color: #33f18a;
        width: 1.05em;
        height: 1.05em;
        border: 0.15em solid #33f18a;
        border-radius: 0.15em;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
    }

    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        /* Windows High Contrast Mode */
        background-color: CanvasText;
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }

    .checkBox{
        width: 20px;
        cursor: pointer;
    }

    input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #c4c4c4;
    border-radius: 999px;
    padding: 0;

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="radio"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #33f18a;
    border-radius: 999px;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

    .serviceTypes{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .serviceTypesContainer{
        display: flex;
        flex-direction: column;
    }

    .arrow {
        border: solid #fff;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        margin-bottom: 2px;
        animation-duration: .5s;
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .up{
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-top: 10px;
        display: none;
    }

    .up.active{
        display: flex;
    }

    .down.active{
        display: flex;
    }

    @keyframes showDetails{
        from{
            margin-top: -50px;
            opacity: 0;
        }to{
            margin-top: 0px;
            opacity: 1;
        }
    }

    .arrowContainer{
        background-color: #33f18a;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .main-image-container{
        display: flex;
        justify-content: center;
    }

    .imageHeaderContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* width: 60%; */
    }

    .imageHeaderContainer svg{
        width: 45px;
        height: 45px;
    }

    .imageHeaderContainer img{
        width: 45px;
        height: 45px;
    }

    .st0{
        fill: none;
        stroke: #000000;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .text-container-svg{
        margin-right: 5px;
    }



@media screen and (max-width: 1000px) {
    /* Service Container */
    .serviceContainer h3{
        font-size: 17px;
    }

    .serviceContainer{
        width: 50%;
    }



    .signatureInputLabel{
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .serviceContainer{
        width: 85vw;
    }

    .signatureInputLabel{
        width: 100%;
        font-size: 14px;
    }
}
</style>
<template>
<div class="main-container">
    <div class="inner-container">
        <signed-in-user-info :user="user" :userData="userData" :loadingUser="loadingUser"/>
        <div class="track-shipment update-user">
            <div class="user-info">
                <div class="inner-overflow-container" id="inner-overflow-container">
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <h2>Create Vehicle</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="confirmContainerInner">
                            <div class="confirm-container-address">
                                <div class="confirmContainerInner1">
                                    <div class="confirm-svg">
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.36 164">
                                            <g id="_Ñëîé_1" data-name="Ñëîé 1">
                                                <g>
                                                <g>
                                                    <path class="cls-2" d="M24.36,106v20c0,5.52,4.48,10,10,10h30"/>
                                                    <path class="cls-2" d="M112.36,136h50c5.52,0,10-4.48,10-10V14c0-5.52-4.48-10-10-10H65.36"/>
                                                    <circle class="cls-2" cx="88.36" cy="136" r="24"/>
                                                    <circle class="cls-2" cx="216.36" cy="136" r="24"/>
                                                    <line class="cls-2" x1="166.36" y1="136" x2="192.36" y2="136"/>
                                                    <path class="cls-2" d="M240.36,136h10c5.52,0,10-4.48,10-10v-38.11c0-2.5-.94-4.91-2.63-6.76l-38.4-41.89c-1.89-2.07-4.57-3.24-7.37-3.24h-39.6"/>
                                                    <path class="cls-2" d="M260.36,84h-50c-5.52,0-10-4.48-10-10v-26"/>
                                                </g>
                                                <line class="cls-2" x1="94.78" y1="58" x2="12.72" y2="58"/>
                                                <line class="cls-2" x1="82.06" y1="86" y2="86"/>
                                                <line class="cls-2" x1="105.48" y1="30" x2="23.42" y2="30"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2>Vehicle Details</h2>
                                    </div>
                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="vehicleInput.vehicleCode" placeholder="Vehicle Code">
                                    </div>
                                    
                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="vehicleInput.plateNumber" placeholder="Plate No.">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <input type="text" v-model="vehicleInput.vehicleID" placeholder="Vehicle ID.">
                                    </div>

                                    <div class="confirmLabelContainer">
                                        <label for="isActive" style="font-weight: bold; margin-top: 5px; margin-right: 5px;">Active</label>
                                        <input id="isActive" type="checkbox" v-model="vehicleInput.isActive" value="true">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                <button class="update-user-button" v-if="validateCustomAddress == false" @click="CreateDropBoxItem()">Create Vehicle</button>
            </div>
        </div>
        <div v-if="(AdminUser || InternalAdmin)" class="track-shipment update-user">
            <vehicle-table :key="refreshKey" :jwtToken="jwtToken" :userData="userData"/>
        </div>
    </div>
</div>
<AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
// import {getUser} from '../../../graphql/queries'
import {createVehicle} from '../../../graphql/mutations'
import {Auth, API} from 'aws-amplify'
import AlertUser from '../../../components/Popups/AlertUser.vue';
import VehicleTable from '../VehicleDashboard/VehicleTable.vue'
import SignedInUserInfo from '../UserDashboard/SignedInUserInfo.vue'

export default {
    data(){
        return{
            user: {},
            jwtToken: null,
            loadingUser: false,
            userData: {},
            placeHolder: "Search for address",
            validateCustomAddress: false,
            userCustomAddress: "",
            toggleAlertBox: false,
            AdminUser: false,
            InternalAdmin: false,
            CreateNewAccount: false,
            Customer: false,
            CustomerAdmin: false,
            ManagePrices: false,
            EditorGroup: false,
            vehicleInput: {
                vehicleCode: "",
                vehicleID: "",
                plateNumber: "",
                isActive: true
            },
            refreshKey: 0,
            uploadFile: null,
            uploadFileNoSpaces: "",
            getFileStorageURL: null,
            logoUploadSuccess: false,
            logoLocationID: ""
        }
    },
    components:{
        AlertUser,
        VehicleTable,
        SignedInUserInfo
    },
    mounted(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.jwtToken = user.signInUserSession.accessToken.jwtToken;
            this.GetUserGraphQL();

            if(this.user.signInUserSession.idToken.payload['cognito:groups']){
                this.user.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                    if(group == 'Admin'){
                      this.AdminUser = true;
                    }

                    if(group == 'InternalAdmin'){
                        this.InternalAdmin = true;
                    }

                    if(group == 'Customer'){
                        this.Customer = true;
                    }

                    if(group == 'CustomerAdmin'){
                        this.CustomerAdmin = true;
                    }

                    if(group == 'ManagePrices'){
                        this.ManagePrices = true;
                    }

                    if(group == 'EditorGroup'){
                        this.EditorGroup = true;
                    }
                })
                if (!this.AdminUser && !this.InternalAdmin) {
                    alert("Access denied to vehicle dashboard")
                    // Redirect to the shipment page
                    this.$router.push('/ShipmentDashboard');
                }
            }
        }).catch(error => {
          if(error){
            this.$router.push('Login');
            Auth.signOut({global: false})
          }
        });
    },
    methods:{
        async CreateDropBoxItem(){
            this.loadingUser = true;
            await this.createDropBox();
            this.validateCustomAddress = false;
            this.loadingUser = false;
        },
        async GetUserGraphQL(){
            this.loadingUser = true;
            try {
                let userReturnData = await API.graphql({
                    query: `query GetUser($userName: String!){
                        getUser(userName: $userName){
                        userName
                        accountNo
                        fullname
                        locationID
                        userEmail
                        locationAddress{
                        labelImage
                        serviceAddress{
                            address{
                                companyName
                                attention
                                address1
                                address2
                                city
                                state
                                zipCode
                                phone
                                phoneExt
                                relevance
                                latitude
                                longitude
                                }
                        }
                        }
                        labelImage
                    }
                    }`,
                    variables: {
                            userName: this.user.username
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.userData = userReturnData.data.getUser;
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user information"
            }finally{
                this.loadingUser = false;
            }
        },
        async createDropBox(){
            this.loadingUser = true;
            try {
                let vehicleData = await API.graphql({
                    query: createVehicle,
                    variables: {
                        input:{
                            vehicleCode: this.vehicleInput.vehicleCode,
                            vehicleID: this.vehicleInput.vehicleID,
                            plateNumber: this.vehicleInput.plateNumber,
                            isActive: this.vehicleInput.isActive
                        }
                    },
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.refreshKey++
                let obj = this.vehicleInput;
                console.log(vehicleData)
                for (const key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        obj[key] = '';
                    }
                }
            } catch (error) {
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to create vehicle."
                this.loadingUser = false;
            }
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        GetSelectedAddressData(selectedAddress){
            this.dropBoxInput.serviceAddress.address.latitude = selectedAddress.latitude;
            this.dropBoxInput.serviceAddress.address.longitude = selectedAddress.longitude;
            this.dropBoxInput.serviceAddress.address.address1 = selectedAddress.address1;
            this.dropBoxInput.serviceAddress.address.address2 = selectedAddress.address2;
            this.dropBoxInput.serviceAddress.address.zipCode = selectedAddress.zipCode;
            this.dropBoxInput.serviceAddress.address.city = selectedAddress.city;
            this.dropBoxInput.serviceAddress.address.state = selectedAddress.state;
            this.dropBoxInput.serviceAddress.address.relevance = selectedAddress.relevance;
        },
        customAddressToggle(toggleCustom){
            this.toggleAlertBox = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";

            document.getElementById('address1').disabled = false;
            document.getElementById('city').disabled = false;
            document.getElementById('state').disabled = false;
            document.getElementById('zipcode').disabled = false;
            this.validateCustomAddress = true;
        },
        checkAddressCorrection(){
            let customAddressString = this.dropBoxInput.serviceAddress.address.address1 + " " + this.dropBoxInput.serviceAddress.address.city + " " + this.dropBoxInput.serviceAddress.address.state + " " + this.dropBoxInput.serviceAddress.address.zipCode;
            //AddressCorrection Child Component is triggered when customAddress changes values
            this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            this.dropBoxInput.serviceAddress.address.relevance = event.shipmentAddressData.relevance;
            this.dropBoxInput.serviceAddress.address.latitude = event.shipmentAddressData.latitude;
            this.dropBoxInput.serviceAddress.address.longitude = event.shipmentAddressData.longitude;
            this.dropBoxInput.serviceAddress.address.address1 = event.shipmentAddressData.Address1;
            this.dropBoxInput.serviceAddress.address.address2 = event.shipmentAddressData.Address2;
            this.dropBoxInput.serviceAddress.address.zipCode = event.shipmentAddressData.ZipCode;
            this.dropBoxInput.serviceAddress.address.city = event.shipmentAddressData.City;
            this.dropBoxInput.serviceAddress.address.state = event.shipmentAddressData.State;

            if(event.callInsertAddressBook == true){
                this.CreateDropBoxItem();
            }
        },
        useCorrectedAddress(event){
            this.dropBoxInput.serviceAddress.address.relevance = event.shipmentDataValue.relevance;
            this.dropBoxInput.serviceAddress.address.latitude = event.shipmentDataValue.latitude;
            this.dropBoxInput.serviceAddress.address.longitude = event.shipmentDataValue.longitude;
            this.dropBoxInput.serviceAddress.address.address1 = event.shipmentDataValue.Address1;
            this.dropBoxInput.serviceAddress.address.address2 = event.shipmentDataValue.Address2;
            this.dropBoxInput.serviceAddress.address.zipCode = event.shipmentDataValue.ZipCode;
            this.dropBoxInput.serviceAddress.address.city = event.shipmentDataValue.City;
            this.dropBoxInput.serviceAddress.address.state = event.shipmentDataValue.State;
            
            if(event.callInsertAddressBook == true){
                this.CreateDropBoxItem();
            }
        },
        useEnteredAddress(event){
            if(event.callInsertAddressBook == true){
                this.CreateDropBoxItem();
            }
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

.cls-1 {
    fill: none;
    stroke: #3f3f3f;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 10px;
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

.cls-2-alt{
    stroke: #000;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 11px;
}

input, select{
    padding: 10px;
    font-size: 15px;
    border: 1px solid rgba(0,0,0,.336);
    border-radius: 15px;
    outline: none;
    margin-top: 5px;
}


input[type=file]::file-selector-button {
    border: none;
    padding: 5px;
    border-radius: 15px;
    background-color: #32ccfe;
    transition: 1s;
    color: #fff;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: #2dbbeb;
    transition: 1s;
}

.confirmLabelContainer img{
    width: 50%;
    max-width: 150px;
    margin-top: 5px;
    border-radius: 5px;
}

.confirmLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.file-input{
    width: 80%;
}

html{
    background-color: #f3f3f3;
}

.main-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-container{
    width: 70%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-track-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 999px;
}

.header-track-inner svg{
    height: 22px;
    margin: 0;
}

.header-track-shipment p{
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
}

.header-track-shipment{
    width: 100%;
    margin-bottom: 5px;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    margin-top: 5px;
    cursor: pointer;
    padding: 0px !important;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.track-shipment{
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    flex-basis: 25%;
    width: fit-content;
    height: fit-content;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.update-user{
    flex-basis: 60%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

.user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.user-info p{
    margin: 0;
}

.confirmContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
}

.auto-complete-container{
    margin-top: 5px !important;
    width: 90%;
    margin-left: 10px;
}

.update-user-button{
    background-color: #e4e4e4;
    color: #000;
    border: none;
    border-radius: 999px;
    padding: 5px 15px;
    width: fit-content;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    transition-duration: .5s;
    cursor: pointer;
}

.update-user-button:hover{
    transition-duration: .5s;
    background-color: #d5d5d5;
}

@media only screen and (max-width: 1300px) {
    .inner-container{
        width: 85%;
    }
}

@media only screen and (max-width: 1000px) {
    .data-container-main, .inner-track-shipment-container{
        flex-wrap: wrap;
    }

    .track-shipment{
        flex-basis: 70%;
    }

    .data-container{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .inner-container{
        width: 95%;
    }

    .data-container{
        flex-basis: 80%;
    }

    .track-shipment{
        flex-basis: 80%;
    }

    .confirmContainerInner{
        flex-wrap: wrap;
    }

    .notifications{
        margin-top: 5px;
    }
}
</style>
<template>
<div class="alert-container">
    <AlertUser v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
    <AlertUser v-if="toggleCustomAddress" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</div>

  <div class="address-book-container">
      <div class="header-container">
          <div class="header-container-inner">
              <h1>Address Details</h1>
          </div>
      </div>
      
      <div class="main-button-container">
          <div class="button-container-inner">
              <button class="main-button-blue-white" @click="showInsertAddress = true">Add New</button>
              <div class="csv-import-export-container">
                <button class="main-button-blue-white" @click="showImportAddress = true">Import</button>
                <button @click="jsonToCSV()" class="transparent-button-orange">Export to CSV</button>
              </div>
          </div>
      </div>
      <div v-if="showInsertAddress" class="new-address-container">
          <div class="new-address-inner">
              <div class="address-input-container">
                    <div class="input-container">
                        <div class="aws-search-address-container">
                            <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                        </div>
                    </div>
                  
                    <div class="input-container" style="margin-top: 10px">
                        <label for="companyname">Company or Name</label>
                        <input class="input-container-input" type="text" name="companyname" v-model="addressBookInput.companyName">
                    </div>

                    <div class="input-container">
                        <label for="contactname">Contact Name</label>
                        <input class="input-container-input" type="text" name="contactname" v-model="addressBookInput.attention">
                    </div>

                    <div class="input-container">
                        <label for="address1">Address</label>
                        <input disabled class="input-container-input" type="text" name="address1" id="address1" v-model="addressBookInput.address1">
                    </div>

                    <div class="input-container">
                        <label for="address2">Suite/Floor/Building</label>
                        <input class="input-container-input" type="text" name="address2" v-model="addressBookInput.address2">
                    </div>

                    <div class="input-container">
                        <label for="city">City</label>
                        <input disabled class="input-container-input" type="text" name="city" id="city" v-model="addressBookInput.city">
                    </div>

                    <div class="input-container">
                        <label for="state">State</label>
                            <select required disabled name="state" id="state" class="stateInput" v-model="addressBookInput.state">
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                            </select>
                    </div>

                    <div class="input-container">
                        <label for="zipcode">Zip</label>
                        <input disabled class="input-container-input" type="text" name="zipcode" id="zipcode" v-model="addressBookInput.zipCode">
                    </div>

              </div>
              <div class="service-options-container">
                    <div class="input-container" style="height: 40px;">
                    </div>
                    <div class="input-container">
                        <label for="phone">Phone Number</label>
                        <input class="input-container-input" type="text" name="phone" v-on:blur="validatePhone()" v-model="addressBookInput.phone">
                    </div>

                    <div class="input-container">
                        <label for="phoneext">Phone Ext.</label>
                        <input class="input-container-input" type="text" name="phoneext" v-model="addressBookInput.phoneExt">
                    </div>

                    <div class="input-container">
                        <label for="fax">Fax Number</label>
                        <input class="input-container-input" type="text" name="fax" v-on:blur="validateFax()" v-model="addressBookInput.fax">
                    </div>

                    <div class="input-container">
                        <label for="email">Email</label>
                        <input class="input-container-input" type="text" name="email" v-on:blur="validateEmail()" v-model="addressBookInput.email">
                    </div>

                    <div class="input-container">
                        <label for="deliveryinstructions">Delivery Instructions</label>
                        <textarea class="input-container-input" name="deliveryinstructions" v-model="addressBookInput.deliveryInstructions" id="" cols="30" rows="5"></textarea>
                    </div>
              </div>
          </div>
      </div>
    <div v-if="showInsertAddress" class="address-button-container">
        <div class="address-button-inner">
            <button @click="saveAddressLogic()" class="main-button-blue-white">Save</button>
            <button @click="showInsertAddress = false" class="main-button-orange-white">Cancel</button>
        </div>
    </div>

    <div class="address-book-table-container"  v-if="showAddressBook && addressBook[0]">
        <div class="address-book-table-inner">
                 
            <div class="search-address-book">
                <input placeholder="Search Addresses" type="text" id="searchAddressBook" v-model="searchAddressBookValue">
            </div> 

            <div class="address-book-table-container-scroll">

            
            <table class="address-book-table" id="address-book-table" v-if="searchAddressToggle">
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th class="table-column-toggle">Contact Name</th>
                        <th>Address</th>
                        <th>Location</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="searchAddressBookResult.length <= 0"><td>No addresses match search.</td></tr>
                    <tr v-for="(items, index) in searchAddressBookResult" v-bind:key="items">
                        <td @click="SelectAddressForEdit(index)" class="select-address-edit">{{searchAddressBookResult[index].CompanyName}}</td>
                        <td class="table-column-toggle">{{searchAddressBookResult[index].Attention}}</td>
                        <td>{{searchAddressBookResult[index].Address}}</td>
                        <td>{{searchAddressBookResult[index].Location}}</td>
                        <!-- <td><i class="fa fa-times-circle" @click="SelectDelete(index)"></i></td> -->
                        <td><div @click="SelectDelete(index)" class="x-button-container"><div class="x-button"></div></div></td>
                    </tr>
                </tbody>
            </table>
            
            <table class="address-book-table" id="address-book-table">
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th class="table-column-toggle">Contact Name</th>
                        <th>Address</th>
                        <th>Location</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(items, index) in addressBook[0]" v-bind:key="items">
                        <td @click="SelectAddressForEdit(index)" class="select-address-edit">{{addressBook[0][index].CompanyName}}</td>
                        <td class="table-column-toggle">{{addressBook[0][index].Attention}}</td>
                        <td>{{addressBook[0][index].Address}}</td>
                        <td>{{addressBook[0][index].Location}}</td>
                        <!-- <td><i class="fa fa-times-circle" @click="SelectDelete(index)"></i></td> -->
                        <td><div @click="SelectDelete(index)" class="x-button-container"><div class="x-button"></div></div></td>
                    </tr>
                </tbody>
            </table>

            </div>


        </div>
    </div>

    <div class="address-book-table-container" v-if="!addressBook[0]">
        <div class="address-book-table-inner">
            <table class="address-book-table">
                <thead>
                    <tr>
                        <th>LRex</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>You have no current addresses in address book.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div v-if="gettingAddressData">
        <LoadingData :headerMessage="headerMessage"/>
    </div>

    <div class="edit-address-container" v-if="editAddressToggle">
        <div class="edit-address-popup">
            <div class="edit-address-background">
                <h2>Address Details</h2>
                <div class="edit-address-inner">
                    
                    <div class="address-input-container">
                            
                            <p class="invalid-address" v-if="addressBookEdit.relevance < .9">This address may not be valid.</p>

                            <div class="input-container">
                                <div class="aws-search-address-container">
                                    <AWSAutoComplete @GetSelectedAddressData="GetSelectedAddressData($event)" @customAddressToggle="customAddressToggle($event)" :placeHolder="placeHolder" />
                                </div>
                            </div>
                        
                            <div class="input-container" >
                            <!-- style="margin-top: 10px"> -->
                                <label for="companyname">Company or Name</label>
                                <input class="input-container-input" type="text" name="companyname" v-model="addressBookEdit.companyName">
                            </div>

                            <div class="input-container">
                                <label for="contactname">Contact Name</label>
                                <input class="input-container-input" type="text" name="contactname" v-model="addressBookEdit.attention">
                            </div>

                            <div class="input-container">
                                <label for="address1">Address</label>
                                <input disabled class="input-container-input" type="text" name="address1" id="edit-address1" v-model="addressBookEdit.address1">
                            </div>

                            <div class="input-container">
                                <label for="address2">Suite/Floor/Building</label>
                                <input disabled class="input-container-input" type="text" name="address2" id="edit-address2" v-model="addressBookEdit.address2">
                            </div>

                            <div class="input-container">
                                <label for="city">City</label>
                                <input disabled class="input-container-input" id="edit-city" type="text" name="city" v-model="addressBookEdit.city">
                            </div>

                            <div class="input-container">
                                <label for="state">State</label>
                                    <select required name="state" id="edit-state" class="stateInput" disabled v-model="addressBookEdit.state">
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District Of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                    </select>
                            </div>

                            <div class="input-container">
                                <label for="zipcode">Zip</label>
                                <input disabled class="input-container-input" type="text" name="zipcode" id="edit-zipcode" v-model="addressBookEdit.zipCode">
                            </div>
                    </div>
                    <div class="service-options-container">
                            <div class="input-container" style="height: 40px;">
                            </div>
                            <div class="input-container">
                                <label for="phone">Phone Number</label>
                                <input class="input-container-input" type="text" name="phone" v-on:blur="validateEditPhone()" v-model="addressBookEdit.phone">
                            </div>

                            <div class="input-container">
                                <label for="phoneext">Phone Ext.</label>
                                <input class="input-container-input" type="text" name="phoneext" v-model="addressBookEdit.phoneExt">
                            </div>

                            <div class="input-container">
                                <label for="fax">Fax Number</label>
                                <input class="input-container-input" type="text" name="fax" v-on:blur="validateEditFax()" v-model="addressBookEdit.fax">
                            </div>

                            <div class="input-container">
                                <label for="email">Email</label>
                                <input class="input-container-input" type="text" name="email" v-on:blur="validateEditEmail()" v-model="addressBookEdit.email">
                            </div>

                            <div class="input-container">
                                <label for="deliveryinstructions">Delivery Instructions</label>
                                <textarea class="input-container-input" name="deliveryinstructions" v-model="addressBookEdit.deliveryInstructions" id="" cols="30" rows="5"></textarea>
                            </div>
                    </div>
                </div>
                <div class="address-button-container">
                    <div class="address-button-inner">
                        <button @click="updateAddressBookLogic()" class="main-button-blue-white">Update</button>
                        <button @click="editAddressToggle = false" class="main-button-orange-white">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <div class="import-confirm" v-if="showImportAddress">
            <div class="import-confirm-inner">
                <h2>Import Address from CSV.</h2>
                <div class="import-text-container">
                    <p>To upload a .csv file, save an Excel document as .csv. Be sure your file contains the following fields:
                        <br>
                        <strong>CompanyName, ContactName, Address1, Address2, City, State, ZipCode, PlusFour, Phone, PhoneExt, Email, Fax, DeliveryInstructions</strong>
                        <br>
                        To retrieve a template of such a layout, export the template from the button below. Be sure not to delete the first row with the headers listed above.
                    </p>
                    <div>
                        <button style="margin: 0 !important;" class="main-button-green-white" @click="DownloadCSVTemplate()">Download Template</button>
                    </div>
                    <div>
                        <input type="file" id="csvFile" accept=".csv" @change="ImportCSV()" />
                    </div>
                    
                    <p v-if="ImportCSVData.length > 0">Number of addresses added: <strong>{{ImportCSVData[0].length}}</strong></p>

                <div class="import-address-table-container">
                    <table v-if="ImportCSVData.length > 0" class="address-book-table" id="address-book-table">
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Contact Name</th>
                                <th>Address1</th>
                                <th>Address2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>ZipCode</th>
                                <th>PlusFour</th>
                                <th>Phone</th>
                                <th>PhoneExt</th>
                                <th>Email</th>
                                <th>Fax</th>
                                <th>Delivery Instructions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(items, index) in this.ImportCSVData[0]" v-bind:key="items">
                                <td>{{ImportCSVData[0][index].CompanyName}}</td>
                                <td>{{ImportCSVData[0][index].ContactName}}</td>
                                <td>{{ImportCSVData[0][index].Address1}}</td>
                                <td>{{ImportCSVData[0][index].Address2}}</td>
                                <td>{{ImportCSVData[0][index].City}}</td>
                                <td>{{ImportCSVData[0][index].State}}</td>
                                <td>{{ImportCSVData[0][index].ZipCode}}</td>
                                <td>{{ImportCSVData[0][index].PlusFour}}</td>
                                <td>{{ImportCSVData[0][index].Phone}}</td>
                                <td>{{ImportCSVData[0][index].PhoneExt}}</td>
                                <td>{{ImportCSVData[0][index].Email}}</td>
                                <td>{{ImportCSVData[0][index].Fax}}</td>
                                <td>{{ImportCSVData[0][index].DeliveryInstructions}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    
                </div>

                <div class="validation-errors" v-if="ValidateImportErrors.length > 0">
                    <h3>Validation Errors</h3>
                    <p v-for="(items, index) in this.ValidateImportErrors" v-bind:key="items">{{ValidateImportErrors[index]}}</p>
                </div>

                <div class="validation-errors" v-if="InsertAddressErrorArray.length > 0">
                    <h3>Import Errors</h3>
                    <p v-for="(items, index) in this.InsertAddressErrorArray" v-bind:key="items">{{InsertAddressErrorArray[index]}}</p>
                </div>

                <div class="validation-success" v-if="showImportButton">
                    <h3>Input Validated</h3>
                    <div class="check-container">
                        <div class="check"></div>
                    </div>
                </div>
                
                <div class="import-button-container">
                    <button class="main-button-blue-white" v-if="showValidateButton && (ImportCSVData[0].length > 0)" @click="ValidateImport()">Validate</button>
                    <button class="main-button-green-white" v-if="showImportButton" @click="GeoCodeAddressImport()">Import</button>
                    <button class="main-button-blue-white" v-if="showOkayButton" @click="CancelImport()">Okay</button>
                    <button class="main-button-orange-white" v-if="showCancelButton" @click="CancelImport()">Cancel</button>
                </div>
            </div>
        </div>

        <div class="delete-confirm" v-if="showDeleteConfirm">
            <div class="delete-confirm-inner">
                <h2>Delete this address?</h2>
                <div class="delete-text-container">
                    <p v-if="addressBook[0][shipmentIndex].CompanyName"><span style="font-weight: bold;">Company Name: </span>{{addressBook[0][shipmentIndex].CompanyName}}</p>
                    <p v-if="addressBook[0][shipmentIndex].ContactName"><span style="font-weight: bold;">Contact Name: </span>{{addressBook[0][shipmentIndex].ContactName}}</p>
                    <p v-if="addressBook[0][shipmentIndex].Address"><span style="font-weight: bold;">Address: </span>{{addressBook[0][shipmentIndex].Address}}</p>
                    <p v-if="addressBook[0][shipmentIndex].Location"><span style="font-weight: bold;">Location: </span>{{addressBook[0][shipmentIndex].Location}}</p>
                    <p v-if="addressBook[0][shipmentIndex].Phone"><span style="font-weight: bold;">Phone: </span>{{addressBook[0][shipmentIndex].Phone}}</p>
                </div>
                
                <div class="delete-address-button-container">
                    <button class="main-button-blue-white" @click="DeleteAddressBook()">Delete</button>
                    <button class="main-button-orange-white" @click="showDeleteConfirm = false">Cancel</button>
                </div>
            </div>
        </div>
  </div>

  <AWSAddressCorrection v-if="validateCustomAddressEdit" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="addressBookEdit"/>
  <AWSAddressCorrection v-if="validateCustomAddress" @useEnteredAddress="useEnteredAddress($event)" @setCustomAddress="setCustomAddress($event)" @useCorrectedAddress="useCorrectedAddress($event)" :customAddress="userCustomAddress" :shipmentData="addressBookInput"/>
</template>

<script>
import axios from 'axios';
import {Auth} from 'aws-amplify';
import Location from "aws-sdk/clients/location";
import AlertUser from '../../components/Popups/AlertUser.vue';
import AWSAutoComplete from '../AWSLocation/AddressAutoComplete.vue';
import AWSAddressCorrection from '../AWSLocation/AddressCorrection.vue'
import LoadingData from '../Popups/LoadingData.vue';

export default {
    components:{
        AlertUser,
        AWSAutoComplete,
        AWSAddressCorrection,
        LoadingData
    },
    data(){
        return{
            placeHolder: "Search for Address",
            userCustomAddress: "",
            addressBookInput: {
                companyName: "",
                attention: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                parc: "",
                plusFour: "",
                phone: "",
                phoneExt: "",
                fax: "",
                email: "",
                warning: "",
                addressBookID: 0,
                deliveryInstructions: "",
                latitude: "",
                longitude: "",
                routeCode: "",
                addressId: 0,
                relevance: ""
            },
            addressBookEdit: {
                companyName: "",
                attention: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                parc: "",
                plusFour: "",
                phone: "",
                phoneExt: "",
                fax: "",
                email: "",
                warning: "",
                addressBookID: 0,
                deliveryInstructions: "",
                latitude: "",
                longitude: "",
                routeCode: "",
                addressId: 0,
                relevance: ""
            },
            user: {},
            showInsertAddress: false,
            addressBook: [],
            addressBookToggle: false,
            searchAddressBookValue: "",
            deleteAddress: {},
            editAddressToggle: false,
            toggleCustomAddress: false,
            showDeleteConfirm: false,
            showImportAddress: false,
            shipmentIndex: 0,
            toggleAlertBox: false,
            alertMessage: "",
            gettingAddressData: false,
            ImportCSVData: [],
            ValidateImportErrors: [],
            showImportButton: false,
            showValidateButton: false,
            showCancelButton: true,
            showOkayButton: false,
            showAddressBook: false,
            addressBookImportArray: [],
            InsertAddressErrorArray: [],
            headerMessage: "",
            validateCustomAddress: false,
            validateCustomAddressEdit: false
        }
    },
    /**
     * Search through JSON Data to find address book value
     */
    watch:{
        'searchAddressBookValue': function(){
            if(this.searchAddressBookValue.length >= 1){
                this.searchAddressBookArray();
            }else{
                this.clearSearchResults();
            }
        }
    },
    /**
     * Using Amplify Auth check if user is signed in before getting address data
     * If user is not signed in redirect back to login page
     */
    beforeMount(){
        Auth.currentAuthenticatedUser().then(user => {
            this.user = user;
            this.GetAddressBookData();
        }).catch(error => {
          if(error)
          {
            this.$router.push('Login');
            Auth.signOut({global: true});
          }
        });
    },
    methods:{
        /**
         * Validation of user input as they create a new address
         * Validates input values and edit values
         */
        validateEmail(){
            if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.addressBookInput.email) == true){
                return;
            }else{
                this.alertMessage = "Please enter a valid email.";
                this.toggleAlertBox = true;
                this.addressBookInput.email = "";
            }
        },
        validatePhone(){
            if(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.addressBookInput.phone) == true){
                let trimPhone = this.inputNotify.replace(/[^0-9]/g, '');
                let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                this.addressBookInput.phone = trimPhoneDashes;
            }else{
                this.alertMessage = "Please enter a valid phone number.";
                this.toggleAlertBox = true;
                this.addressBookInput.phone = "";
            }
        },
        validateFax(){  
            if(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.addressBookInput.fax) == true){
                let trimPhone = this.inputNotify.replace(/[^0-9]/g, '');
                let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                this.addressBookInput.fax = trimPhoneDashes;
            }else{
                this.alertMessage = "Please enter a valid fax number.";
                this.toggleAlertBox = true;
                this.addressBookInput.fax = "";
            }
        },
        validateEditEmail(){
            if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.addressBookEdit.email) == true){
                return;
            }else{
                this.alertMessage = "Please enter a valid email.";
                this.toggleAlertBox = true;
                this.addressBookEdit.email = "";
            }
        },
        validateEditPhone(){
            if(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.addressBookEdit.phone) == true){
                let trimPhone = this.inputNotify.replace(/[^0-9]/g, '');
                let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                this.addressBookEdit.phone = trimPhoneDashes;
            }else{
                this.alertMessage = "Please enter a valid phone number.";
                this.toggleAlertBox = true;
                this.addressBookEdit.phone = "";
            }
        },
        validateEditFax(){  
            if(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.addressBookEdit.fax) == true){
                let trimPhone = this.inputNotify.replace(/[^0-9]/g, '');
                let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                this.addressBookEdit.fax = trimPhoneDashes;
            }else{
                this.alertMessage = "Please enter a valid fax number.";
                this.toggleAlertBox = true;
                this.addressBookEdit.fax = "";
            }
        },
        /**
         * Creates download (CSV) for user to use as template for import
         */
        DownloadCSVTemplate(){
                let data = [];
                let addressData = {
                    CompanyName: null,
                    ContactName: null,
                    Address1: null,
                    Address2: null,
                    City: null,
                    State: null,
                    ZipCode: null,
                    PlusFour: null,
                    Phone: null,
                    PhoneExt: null,
                    Fax: null,
                    Email: null,
                    DeliveryInstructions: null
                }
                data.push(addressData);
                let Testcsv = data.map(row => Object.values(row));
                Testcsv.unshift(Object.keys(data[0]));
                Testcsv.join('\n');
                let returnCSV = `"${Testcsv.join('"\n"').replace(/,/g, '","')}"`;

                this.DownloadCSVData(returnCSV);
        },
        /**
         * jsonToCSV() takes JSON Data and maps to CSV Structure
         * DownloadCSVData() takes created CSV Structure from jsonToCSV() and downloads 
         */
        jsonToCSV(){
                let data = [];

                for(let i = 0; i < this.addressBook[0].length; i++){
                    let addressData = {
                        CompanyName: this.addressBook[0][i].CompanyName,
                        Attention: this.addressBook[0][i].Attention,
                        Address1: this.addressBook[0][i].Address1,
                        Address2: this.addressBook[0][i].Address2,
                        City: this.addressBook[0][i].City,
                        State: this.addressBook[0][i].State,
                        ZipCode: this.addressBook[0][i].ZipCode,
                        PlusFour: this.addressBook[0][i].PlusFour,
                        Phone: this.addressBook[0][i].Phone,
                        PhoneExt: this.addressBook[0][i].PhoneExt,
                        Fax: this.addressBook[0][i].Fax,
                        Email: this.addressBook[0][i].Email,
                        DeliveryInstructions: this.addressBook[0][i].AB[0].DeliveryInstructions
                    }
                    data.push(addressData);
                }
                let createCSV = data.map(row => Object.values(row));
                createCSV.unshift(Object.keys(data[0]));
                createCSV.join('\n');
                let returnCSV = `"${createCSV.join('"\n"').replace(/,/g, '","')}"`;

                this.DownloadCSVData(returnCSV);
        },
        DownloadCSVData(dataCSV){
            var fileCSV = new Blob([dataCSV], {
                type: "text/csv;charset=utf-8"
            });

            let downloadLink = document.createElement('a');

            downloadLink.download = "AddressBook.csv";
            let url = window.URL.createObjectURL(fileCSV);
            downloadLink.href = url;

            downloadLink.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        /**
         * csvToArray() takes csvString from user input and converts it to an array 
         * The array is checked for empty rows and then returned
         */
        csvToArray(csvString, delimiter = ','){
            var headers = csvString.slice(0, csvString.indexOf("\n")).split(delimiter);
            const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");

            headers = headers.map(function(el){
                el.trim();
                return el.replace(/\s/g, '');
            });

            const arrayCSV =  rows.map(function(row){
                // eslint-disable-next-line
                const values = row.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g);
                const element = headers.reduce(function(object, header, index){
                    object[header] = values[index];
                    return object;
                }, {});
                return element;
            });
            let jsonString = JSON.stringify(arrayCSV);
            let str = jsonString.replace(/\\"/g, '');
            const obj = JSON.parse(str);

            //Check for empty rows/rows with no values
            this.ImportCSVData.push(obj);            

            for(let i = this.ImportCSVData[0].length - 1; i >= 0; i--){
                //console.log(Object.keys(this.ImportCSVData[0][i]).length)
                //console.log(this.ImportCSVData[0])

                if(Object.keys(this.ImportCSVData[0][i]).length < 13){
                    this.ImportCSVData[0].splice(i, 1);
                }
                else if((this.ImportCSVData[0][i].CompanyName == null || this.ImportCSVData[0][i].CompanyName == "\r") 
                    && (this.ImportCSVData[0][i].ContactName == null || this.ImportCSVData[0][i].ContactName == "\r")
                    && (this.ImportCSVData[0][i].Address1 == null || this.ImportCSVData[0][i].Address1 == "\r")
                    && (this.ImportCSVData[0][i].Address2 == null || this.ImportCSVData[0][i].Address2 == "\r")
                    && (this.ImportCSVData[0][i].City == null || this.ImportCSVData[0][i].City == "\r")
                    && (this.ImportCSVData[0][i].State == null || this.ImportCSVData[0][i].State == "\r")
                    && (this.ImportCSVData[0][i].ZipCode == null || this.ImportCSVData[0][i].ZipCode == "\r")
                    && (this.ImportCSVData[0][i].PlusFour == null || this.ImportCSVData[0][i].PlusFour == "\r")
                    && (this.ImportCSVData[0][i].Phone == null || this.ImportCSVData[0][i].Phone == "\r")
                    && (this.ImportCSVData[0][i].PhoneExt == null || this.ImportCSVData[0][i].PhoneExt == "\r")
                    && (this.ImportCSVData[0][i].Fax == null || this.ImportCSVData[0][i].Fax == "\r")
                    && (this.ImportCSVData[0][i].Email == null || this.ImportCSVData[0][i].Email == "\r")
                    && (this.ImportCSVData[0][i].DeliveryInstructions == null || this.ImportCSVData[0][i].DeliveryInstructions == "\r")){
                        this.ImportCSVData[0].splice(i, 1);
                    }
                }

            return arrayCSV;
        },
        /**
         * ImportCSV() is called when file input changes
         * Using FileReader to consume the CSV data and send text to csvToArray()
         */
        ImportCSV(){
            this.ImportCSVData = [];
            this.ValidateImportErrors = [];
            this.InsertAddressErrorArray = [];
            this.ValidateImportErrors = [];
            this.addressBookImportArray = [];
            this.showValidateButton = true;
            this.showImportButton = false;
            this.showOkayButton = false;
            this.showCancelButton = true;

            const csvFile = document.getElementById("csvFile");
            const fileInput = csvFile.files[0];
            const reader = new FileReader();

            reader.onload =  (e)=> {
                const text = e.target.result;
                this.csvToArray(text)
                //console.log(text)
            }
            reader.readAsText(fileInput);
        },
        /**
         * Validate user import before allowing the import button to toggle
         */
        ValidateImport(){
            this.ValidateImportErrors = [];
            for(let i = 0; i < this.ImportCSVData[0].length; i++){
                if(this.ImportCSVData[0][i].CompanyName == ""){
                    this.ValidateImportErrors.push("Enter a Company Name for Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].Address1 == ""){
                    this.ValidateImportErrors.push("Enter an Address1 for Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].City == ""){
                    this.ValidateImportErrors.push("Enter a City for Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].ZipCode.length != 5){
                    this.ValidateImportErrors.push("Invalid Zip Code in Address " + (i+1));
                }
                if((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.ImportCSVData[0][i].Phone) == true) || (this.ImportCSVData[0][i].Phone == "")){
                    if(this.ImportCSVData[0][i].Phone != ""){
                        let trimPhone = this.ImportCSVData[0][i].Phone.replace(/[^0-9]/g, '');
                        let trimPhoneDashes = trimPhone.slice(0,3)+"-"+trimPhone.slice(3,6)+"-"+trimPhone.slice(6);
                        this.ImportCSVData[0][i].Phone = trimPhoneDashes;
                    }
                }else{
                    this.ValidateImportErrors.push("Invalid Phone Number in Address " + (i+1));
                }
                if((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(this.ImportCSVData[0][i].Fax) == true) || (this.ImportCSVData[0][i].Fax == "")){
                    if(this.ImportCSVData[0][i].Fax != ""){
                        let trimFax = this.ImportCSVData[0][i].Fax.replace(/[^0-9]/g, '');
                        let trimFaxDashes = trimFax.slice(0,3)+"-"+trimFax.slice(3,6)+"-"+trimFax.slice(6);
                        this.ImportCSVData[0][i].Fax = trimFaxDashes;
                    }
                }else{
                    this.ValidateImportErrors.push("Invalid Fax Number in Address " + (i+1));
                }
                if((/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.ImportCSVData[0][i].Email) == true) || (this.ImportCSVData[0][i].Email == "")){
                    //break;
                }else{
                    this.ValidateImportErrors.push("Invalid Email in Address " + (i+1));
                }
                if(this.ImportCSVData[0][i].State.length != 2){
                    this.ValidateImportErrors.push("Invalid State Value in Address " + (i+1));
                }else{
                    let stateUpper = this.ImportCSVData[0][i].State;
                    let resultState = stateUpper.toUpperCase();
                    this.ImportCSVData[0][i].State = resultState;
                }
                if((this.ImportCSVData[0][i].PlusFour.length == 4) || (this.ImportCSVData[0][i].PlusFour == "")){
                    //break;
                }else{
                    this.ValidateImportErrors.push("Invalid Plus Four in Address " + (i+1));
                }
            }
            //If no errors found toggle import button and create import array
            if(this.ValidateImportErrors.length == 0){
                this.showImportButton = true;
                this.showValidateButton = false;
                this.CreateAddressBookImportArray();
            }
            //If validation errors remove file from input
            if(this.ValidateImportErrors.length > 0){
                document.getElementById("csvFile").value = "";
            }
        },
        CancelImport(){
            this.showImportAddress = false;
            this.showImportButton = false;
            this.showValidateButton = false;
            this.ImportCSVData = [];
            this.ValidateImportErrors = [];
            this.InsertAddressErrorArray = [];
            document.getElementById("csvFile").value = "";
        },
        /**
         * Import Address and GeoCode with AWS Location
         * For each imported address call getClientImport() to set the relevance and geocode data
         */
        async getClientImport(addressInput){
            const credentials = await Auth.currentCredentials();

            let AWSLocationData = {
                geoCodeDataReturn: [],
                locationRelevance: "",
                placeData: {}
            }

            const locationClient = new Location({
                credentials,
                region: 'us-east-1'
            });

            const params = {
                IndexName: "lrex-place",
                Text: addressInput,
                FilterCountries: ["USA"],
                BiasPosition: [-74.1724, 40.7357],
                MaxResults: 5
            };

            locationClient.searchPlaceIndexForText(params,(err,data)=>{
                if(err){
                    alert(err)
                    }
                if(data){
                    let returnDataGeoCode = data.Results[0].Place.Geometry.Point;
                    let returnDataRelevance = data.Results[0].Relevance;
                    let returnPlaceData = data.Results[0].Place;
                    AWSLocationData.geoCodeDataReturn.push(returnDataGeoCode);
                    AWSLocationData.locationRelevance = returnDataRelevance;
                    AWSLocationData.placeData = returnPlaceData;
                }
            });

            return AWSLocationData;
        },
        /**
         * CreateAddressBookImportArray() is called when there are no validation errors
         * For each imported address set the addressBookImport value and push to addressBookImportArray
         * Set addressBookImport relevnace, latitude, and longitude to sort in GeoCodeAddressImport()
         */
        async CreateAddressBookImportArray(){
            for(let i = 0; i < this.ImportCSVData[0].length; i++){
                let addressInput = this.ImportCSVData[0][i].Address1 + ", " + this.ImportCSVData[0][i].City + ", " + this.ImportCSVData[0][i].State + ", " + this.ImportCSVData[0][i].ZipCode;
                let geoCodeData = await this.getClientImport(addressInput);

                let addressBookImport = {
                    companyName: "",
                    attention: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    parc: "",
                    plusFour: "",
                    phone: "",
                    phoneExt: "",
                    fax: "",
                    email: "",
                    warning: "",
                    addressBookID: 0,
                    deliveryInstructions: "",
                    latitude: "",
                    longitude: "",
                    routeCode: "",
                    addressId: 0,
                    relevance: ""
                };

                //Passing the GeoCode Data and Relevance Objects for geocode function
                addressBookImport.relevance = geoCodeData;
                addressBookImport.latitude = geoCodeData;
                addressBookImport.longitude = geoCodeData;
                addressBookImport.companyName = this.ImportCSVData[0][i].CompanyName;
                addressBookImport.attention = this.ImportCSVData[0][i].ContactName;
                addressBookImport.address1 = geoCodeData;
                addressBookImport.address2 = this.ImportCSVData[0][i].Address2;
                addressBookImport.city = geoCodeData;
                addressBookImport.state = geoCodeData;
                addressBookImport.zipCode = geoCodeData;
                addressBookImport.plusFour = geoCodeData;
                addressBookImport.phone = this.ImportCSVData[0][i].Phone;
                addressBookImport.phoneExt = this.ImportCSVData[0][i].PhoneExt;
                addressBookImport.fax = this.ImportCSVData[0][i].Fax;
                addressBookImport.email = this.ImportCSVData[0][i].Email;
                addressBookImport.deliveryInstructions = this.ImportCSVData[0][i].DeliveryInstructions;

                this.addressBookImportArray.push(addressBookImport);
            }
        },
        /**
         * GeoCodeAddressImport() called when user clicks Import button
         * For each item in addressBookImportArray set the lat, long, and relevance returned from getClientImport()
         */
        async GeoCodeAddressImport(){
            for(let i = 0; i < this.addressBookImportArray.length; i++){
                //If the relevance is less than .9 do not geocode, secondary geocode process will handle this
                if(this.addressBookImportArray[i].relevance.locationRelevance >= .9){
                    this.addressBookImportArray[i].latitude = JSON.stringify(this.addressBookImportArray[i].latitude.geoCodeDataReturn[0][1]);
                    this.addressBookImportArray[i].longitude = JSON.stringify(this.addressBookImportArray[i].longitude.geoCodeDataReturn[0][0]);
                }else{
                    this.addressBookImportArray[i].latitude = "";
                    this.addressBookImportArray[i].longitude = "";
                }
                
                this.addressBookImportArray[i].relevance = JSON.stringify(this.addressBookImportArray[i].relevance.locationRelevance);

                if((this.addressBookImportArray[i].address1.placeData.AddressNumber === undefined) && (this.addressBookImportArray[i].address1.placeData.Street === undefined)){
                    this.addressBookImportArray[i].address1 = this.ImportCSVData[0][i].Address1;
                }else{
                    this.addressBookImportArray[i].address1 = this.addressBookImportArray[i].address1.placeData.AddressNumber + " " + this.addressBookImportArray[i].address1.placeData.Street;
                }

                if((this.addressBookImportArray[i].city.placeData.Municipality === undefined)){
                    this.addressBookImportArray[i].city = this.ImportCSVData[0][i].City;
                }else{
                    this.addressBookImportArray[i].city = this.addressBookImportArray[i].city.placeData.Municipality;
                }

                if(this.addressBookImportArray[i].state.placeData.Region === undefined){
                    this.addressBookImportArray[i].state = this.ImportCSVData[0][i].State;
                }else{
                    switch(this.addressBookImportArray[i].state.placeData.Region){
                        case "Alabama":
                           this.addressBookImportArray[i].state = "AL";
                            break;
                        case "Alaska":
                           this.addressBookImportArray[i].state = "AK";
                            break;
                        case "Arizona":
                           this.addressBookImportArray[i].state = "AZ";
                            break;
                        case "Arkansas":
                           this.addressBookImportArray[i].state = "AR";
                            break;
                        case "California":
                           this.addressBookImportArray[i].state = "CA";
                            break;
                        case "Colorado":
                           this.addressBookImportArray[i].state = "CO";
                            break;
                        case "Florida":
                           this.addressBookImportArray[i].state = "FL";
                            break;
                        case "Georgia":
                           this.addressBookImportArray[i].state = "GA";
                            break;
                        case "Hawaii":
                           this.addressBookImportArray[i].state = "HI";
                            break;
                        case "Idaho":
                           this.addressBookImportArray[i].state = "ID";
                            break;
                        case "Illinois":
                           this.addressBookImportArray[i].state = "IL";
                            break;
                        case "Indiana":
                           this.addressBookImportArray[i].state = "IN";
                            break;
                        case "Iowa":
                           this.addressBookImportArray[i].state = "IA";
                            break;
                        case "Kansas":
                           this.addressBookImportArray[i].state = "KS";
                            break;
                        case "Kentucky":
                           this.addressBookImportArray[i].state = "KY";
                            break;
                        case "Louisiana":
                           this.addressBookImportArray[i].state = "LA";
                            break;
                        case "Michigan":
                           this.addressBookImportArray[i].state = "MI";
                            break;
                        case "Minnesota":
                           this.addressBookImportArray[i].state = "MN";
                            break;
                        case "Mississippi":
                           this.addressBookImportArray[i].state = "MS";
                            break;
                        case "Missouri":
                           this.addressBookImportArray[i].state = "MO";
                            break;
                        case "Montana":
                           this.addressBookImportArray[i].state = "MT";
                            break;
                        case "Nebraska":
                           this.addressBookImportArray[i].state = "NE";
                            break;
                        case "Nevada":
                           this.addressBookImportArray[i].state = "NV";
                            break;
                        case "New Mexico":
                           this.addressBookImportArray[i].state = "NM";
                            break;
                        case "North Carolina":
                           this.addressBookImportArray[i].state = "NC";
                            break;
                        case "North Dakota":
                           this.addressBookImportArray[i].state = "ND";
                            break;
                        case "Ohio":
                           this.addressBookImportArray[i].state = "OH";
                            break;
                        case "Oklahoma":
                           this.addressBookImportArray[i].state = "OK";
                            break;
                        case "Oregon":
                           this.addressBookImportArray[i].state = "OR";
                            break;
                        case "South Carolina":
                           this.addressBookImportArray[i].state = "SC";
                            break;
                        case "South Dakota":
                           this.addressBookImportArray[i].state = "SD";
                            break;
                        case "Tennessee":
                           this.addressBookImportArray[i].state = "TN";
                            break;
                        case "Texas":
                           this.addressBookImportArray[i].state = "TX";
                            break;
                        case "Utah":
                           this.addressBookImportArray[i].state = "UT";
                            break;
                        case "Vermont":
                           this.addressBookImportArray[i].state = "VT";
                            break;
                        case "Washington":
                           this.addressBookImportArray[i].state = "WA";
                            break;
                        case "West Virginia":
                           this.addressBookImportArray[i].state = "WV";
                            break;
                        case "Wisconsin":
                           this.addressBookImportArray[i].state = "WI";
                            break;
                        case "Wyoming":
                           this.addressBookImportArray[i].state = "WY";
                            break;
                        case "District of Columbia":
                           this.addressBookImportArray[i].state = "DC";
                            break;
                        case "Delaware":
                           this.addressBookImportArray[i].state = "DE";
                            break;
                        case "Massachusetts":
                           this.addressBookImportArray[i].state = "MA";
                            break;
                        case "Maryland":
                           this.addressBookImportArray[i].state = "MD";
                            break;
                        case "Maine":
                           this.addressBookImportArray[i].state = "ME";
                            break;
                        case "New Hampshire":
                           this.addressBookImportArray[i].state = "NH";
                            break;
                        case "New Jersey":
                           this.addressBookImportArray[i].state = "NJ";
                            break;
                        case "New York":
                           this.addressBookImportArray[i].state = "NY";
                            break;
                        case "Pennsylvania":
                           this.addressBookImportArray[i].state = "PA";
                            break;
                        case "Rhode Island":
                           this.addressBookImportArray[i].state = "RI";
                            break;
                        case "Virginia":
                           this.addressBookImportArray[i].state = "VA";
                            break;
                        default:
                            alert("Error with State Input")
                    }
                }

                if(this.addressBookImportArray[i].zipCode.placeData.PostalCode === undefined){
                    this.addressBookImportArray[i].zipCode = this.ImportCSVData[0][i].ZipCode;
                    this.addressBookImportArray[i].plusFour = this.ImportCSVData[0][i].PlusFour;
                }else{
                    this.addressBookImportArray[i].zipCode = this.addressBookImportArray[i].zipCode.placeData.PostalCode.substring(0, 5);
                    this.addressBookImportArray[i].plusFour = this.addressBookImportArray[i].plusFour.placeData.PostalCode.substring(6, 10);
                }
            }
            let returnArray = [];
            returnArray.push(this.addressBookImportArray);
            await this.InsertImportAdressBook(returnArray);
        },
        //Delete Confirm
        SelectDelete(index){
            this.scrollToTop();
            this.showDeleteConfirm = true;
            this.deleteAddress = this.addressBook[0][index].AddressId;
            this.shipmentIndex = index;
        },
        //Scroll Method
        scrollToTop(){
            window.scrollTo(0,0);
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
            this.toggleCustomAddress = toggleAlertBox;
        },
        /**
         * SelectAddressForEdit sets the addressBookEdit object when the shipment is selected
         * Toggle the drop down for user to edit the selected address
         */
        SelectAddressForEdit(index){
            this.scrollToTop();
            this.showInsertAddress = false;
            this.editAddressToggle = true;
            this.addressBookEdit.addressId = this.addressBook[0][index].AddressId;
            this.addressBookEdit.companyName = this.addressBook[0][index].CompanyName;
            this.addressBookEdit.attention = this.addressBook[0][index].Attention;
            this.addressBookEdit.phoneExt = this.addressBook[0][index].PhoneExt;
            this.addressBookEdit.phone = this.addressBook[0][index].Phone;
            this.addressBookEdit.fax = this.addressBook[0][index].Fax;
            this.addressBookEdit.email = this.addressBook[0][index].Email;
            this.addressBookEdit.deliveryInstructions = this.addressBook[0][index].AB[0].DeliveryInstructions;
            this.addressBookEdit.address1 = this.addressBook[0][index].Address1;
            this.addressBookEdit.address2 = this.addressBook[0][index].Address2;
            this.addressBookEdit.city = this.addressBook[0][index].City;
            this.addressBookEdit.state = this.addressBook[0][index].State;
            this.addressBookEdit.zipCode = this.addressBook[0][index].ZipCode;
            this.addressBookEdit.latitude = this.addressBook[0][index].Latitude;
            this.addressBookEdit.longitude = this.addressBook[0][index].Longitude;
            this.addressBookEdit.plusFour = this.addressBook[0][index].PlusFour;
            this.addressBookEdit.relevance = this.addressBook[0][index].Relevance;
        },
        GetAddressBookData(){
            this.gettingAddressData = true;
            this.headerMessage = "Getting Address Book Data";
            axios.get('https://api.stage.njls.com/api/Rest/GetAddressesByUserName', {
                headers: {
                    'User': this.user.username,
                    // get the user's JWT token given to it by AWS cognito 
                    'Authorization': `Bearer ${this.user.signInUserSession.accessToken.jwtToken}`
                },
            }).then((response)=>{
                    this.showAddressBook = true;
                    this.addressBook = [];
                    if(response.data.length != 0){
                        this.addressBook.push(response.data[0].A);
                    }else{
                        this.alertMessage = "Unable to get addresses.";
                        this.toggleAlertBox = true;
                    }
                }
            ).catch(error => alert(error)).finally(()=>{this.gettingAddressData = false})
        },
        checkAddressCorrection(){
                let customAddressString = this.addressBookInput.address1 + " " + this.addressBookInput.city + " " + this.addressBookInput.state + " " + this.addressBookInput.zipCode;
                //AddressCorrection Child Component is triggered when customAddress changes values
                //console.log(this.addressBookInput)
                this.userCustomAddress = customAddressString;
        },
        setCustomAddress(event){
            if(this.validateCustomAddress){
                this.addressBookInput.relevance = event.shipmentAddressData.relevance;
                this.addressBookInput.latitude = event.shipmentAddressData.latitude;
                this.addressBookInput.longitude = event.shipmentAddressData.longitude;
                this.addressBookInput.address1 = event.shipmentAddressData.Address1;
                this.addressBookInput.zipCode = event.shipmentAddressData.ZipCode;
                this.addressBookInput.city = event.shipmentAddressData.City;
                this.addressBookInput.state = event.shipmentAddressData.State;

                if(event.callInsertAddressBook == true){
                    this.InsertAddressBook();
                }
            }

            if(this.validateCustomAddressEdit){
                this.addressBookEdit.relevance = event.shipmentAddressData.relevance;
                this.addressBookEdit.latitude = event.shipmentAddressData.latitude;
                this.addressBookEdit.longitude = event.shipmentAddressData.longitude;
                this.addressBookEdit.address1 = event.shipmentAddressData.Address1;
                this.addressBookEdit.zipCode = event.shipmentAddressData.ZipCode;
                this.addressBookEdit.city = event.shipmentAddressData.City;
                this.addressBookEdit.state = event.shipmentAddressData.State;

                if(event.callInsertAddressBook == true){
                    this.UpdateAddressBook();
                }
            }
        },
        useCorrectedAddress(event){
            if(this.validateCustomAddress){
                this.addressBookInput.relevance = event.shipmentDataValue.relevance;
                this.addressBookInput.latitude = event.shipmentDataValue.latitude;
                this.addressBookInput.longitude = event.shipmentDataValue.longitude;
                this.addressBookInput.address1 = event.shipmentDataValue.Address1;
                this.addressBookInput.zipCode = event.shipmentDataValue.ZipCode;
                this.addressBookInput.city = event.shipmentDataValue.City;
                this.addressBookInput.state = event.shipmentDataValue.State;
                
                if(event.callInsertAddressBook == true){
                    this.InsertAddressBook();
                }
            }

            if(this.validateCustomAddressEdit){
                this.addressBookEdit.relevance = event.shipmentDataValue.relevance;
                this.addressBookEdit.latitude = event.shipmentDataValue.latitude;
                this.addressBookEdit.longitude = event.shipmentDataValue.longitude;
                this.addressBookEdit.address1 = event.shipmentDataValue.Address1;
                this.addressBookEdit.zipCode = event.shipmentDataValue.ZipCode;
                this.addressBookEdit.city = event.shipmentDataValue.City;
                this.addressBookEdit.state = event.shipmentDataValue.State;
                
                if(event.callInsertAddressBook == true){
                    this.UpdateAddressBook();
                }
            }
        },
        useEnteredAddress(event){
            if(this.validateCustomAddress){
                if(event.callInsertAddressBook == true){
                    this.InsertAddressBook();
                }
            }

            if(this.validateCustomAddressEdit){
                if(event.callInsertAddressBook == true){
                    this.UpdateAddressBook();
                }
            }
        },
        saveAddressLogic(){
            if(this.validateCustomAddress == true){
                this.checkAddressCorrection();
            } 

            if(this.validateCustomAddress == false){
                this.InsertAddressBook();
            }
        },
        async InsertAddressBook(){
            //await this.checkAddressCorrection();
            axios.post('https://api.stage.njls.com/api/Rest/InsertAddressBook', this.addressBookInput,{
                headers: {
                    'User': this.user.username,
                    // get the user's JWT token given by AWS cognito 
                    'Authorization': `Bearer ${this.user.signInUserSession.accessToken.jwtToken}`
                }
            }).then((response)=>{
                if(response){
                    this.alertMessage = "Successfully added address to address book.";
                    this.toggleAlertBox = true;
                    this.showInsertAddress = false;
                    this.addressBookInput = {
                        companyName: "",
                        attention: "",
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zipCode: "",
                        parc: "",
                        plusFour: "",
                        phone: "",
                        phoneExt: "",
                        fax: "",
                        email: "",
                        warning: "",
                        addressBookID: 0,
                        deliveryInstructions: "",
                        latitude: "",
                        longitude: "",
                        routeCode: "",
                        addressId: 0,
                        relevance: ""
                    };
                    this.GetAddressBookData();
                }
            })
            .catch(error => {
                if(error.response.data.error){
                    this.alertMessage = error.response.data.error;
                    this.toggleAlertBox = true;
                }else{
                    alert(error)
                }
            })
        },
        /**
         * Import address book array
         */
        async InsertImportAdressBook(returnArray){
            let counter = 0;
            for(let i = 0; i < returnArray[0].length; i++){
                counter += 1;
                axios.post('https://api.stage.njls.com/api/Rest/InsertAddressBook', returnArray[0][i],{
                    headers: {
                        'User': this.user.username,
                        // get the user's JWT token given by AWS cognito 
                        'Authorization': `Bearer ${this.user.signInUserSession.accessToken.jwtToken}`
                    }
                }).then((response)=>{
                    if(response){
                        if(counter == returnArray[0].length){
                            this.showImportButton = false;
                            this.showValidateButton = false;
                            this.showOkayButton = true;
                            this.ValidateImportErrors = [];
                            this.alertMessage = "Imported Successfully";
                            this.toggleAlertBox = true;
                            this.GetAddressBookData();
                        }
                    }
                })
                .catch(error => {
                    if(error.response.data.error){
                        this.alertMessage = error.response.data.error;
                        this.toggleAlertBox = true;
                        this.InsertAddressErrorArray.push(error.response.data.error + " " + "Address " + (i+1) + " is already in your address book.");
                        document.getElementById("csvFile").value = "";
                        this.showCancelButton = false;
                        this.showImportButton = false;
                        this.showOkayButton = true;
                    }else{
                        alert(error)
                        document.getElementById("csvFile").value = "";
                        this.showCancelButton = false;
                        this.showImportButton = false;
                        this.showOkayButton = true;
                    }
                })
            }
        },
        DeleteAddressBook(){
            this.addressBookInput.addressId = this.deleteAddress;

            axios.post('https://api.stage.njls.com/api/Rest/DeleteAddressBook', this.addressBookInput,{
                headers: {
                    'User': this.user.username,
                    // get the user's JWT token given by AWS cognito 
                    'Authorization': `Bearer ${this.user.signInUserSession.accessToken.jwtToken}`
                }
            }).then((response)=>{
                if(response){
                    this.alertMessage = "Successfully deleted from address book.";
                    this.toggleAlertBox = true;
                    this.showDeleteConfirm = false;
                    this.GetAddressBookData();
                }
            })
            .catch(error => alert(error))
        },
        checkAddressEditCorrection(){
                let customAddressString = this.addressBookEdit.address1 + " " + this.addressBookEdit.city + " " + this.addressBookEdit.state + " " + this.addressBookEdit.zipCode;
                //AddressCorrection Child Component is triggered when customAddress changes values
                //console.log(this.addressBookEdit)
                this.userCustomAddress = customAddressString;
        },
        updateAddressBookLogic(){
            if(this.validateCustomAddressEdit == true){
                this.checkAddressEditCorrection();
            } 

            if(this.validateCustomAddressEdit == false){
                this.UpdateAddressBook();
            }
        },
        UpdateAddressBook(){
            axios.post('https://api.stage.njls.com/api/Rest/UpdateAddressBook', this.addressBookEdit,{
                headers: {
                    'User': this.user.username,
                    // get the user's JWT token given by AWS cognito 
                    'Authorization': `Bearer ${this.user.signInUserSession.accessToken.jwtToken}`
                }
            }).then((response)=>{
                if(response){
                    this.alertMessage = "Successfully updated address book.";
                    this.toggleAlertBox = true;
                    this.editAddressToggle = false;
                    this.GetAddressBookData();
                }
            })
            .catch(error => alert(error))
        },
        //Address Book Search Functionality
        searchAddressBookArray(){
            this.searchAddressBook = [];
            this.searchAddressBookResult = [];
            this.searchAddressToggle = true;
            for(let i = 0; i < this.addressBook[0].length; i++){
                let address = this.addressBook[0][i].Address.toLowerCase();
                let companyName = this.addressBook[0][i].CompanyName.toLowerCase();
                let zipcode = this.addressBook[0][i].ZipCode.toLowerCase();
                let city = this.addressBook[0][i].City.toLowerCase();
                let state = this.addressBook[0][i].State.toLowerCase();
                let attention = this.addressBook[0][i].Attention.toLowerCase();
                let searchArray = [address, companyName, zipcode, city, state, attention]
               
                this.searchAddressBook.push(searchArray.join(''));
            }

            let searchValue = this.searchAddressBookValue;
            for(let j = 0; j < this.searchAddressBook.length; j++){
                if(this.searchAddressBook[j].includes(searchValue)){
                    this.searchAddressBookResult.push(this.addressBook[0][j]);
                }
            }
        },
        clearSearchResults(){
            this.searchAddressBook = [];
            this.searchAddressBookResult = [];
            this.searchAddressToggle = false;
            document.getElementById('searchAddressBook').value = '';
        },
        /**
         * GetSelectedAddressData() checks if user is editing or inserting address
         * Based on showInsertAddress or editAddressToggle value set the correct addressBook object values
         */
        GetSelectedAddressData(selectedAddress){
            if(this.showInsertAddress == true){
                this.addressBookInput.latitude = selectedAddress.latitude;
                this.addressBookInput.longitude = selectedAddress.longitude;
                this.addressBookInput.address1 = selectedAddress.address1;
                this.addressBookInput.zipCode = selectedAddress.zipCode;
                this.addressBookInput.plusFour = selectedAddress.plusFour;
                this.addressBookInput.city = selectedAddress.city;
                this.addressBookInput.state = selectedAddress.state;
                this.addressBookInput.relevance = selectedAddress.relevance;
            }

            if(this.editAddressToggle == true){
                this.addressBookEdit.latitude = selectedAddress.latitude;
                this.addressBookEdit.longitude = selectedAddress.longitude;
                this.addressBookEdit.address1 = selectedAddress.address1;
                this.addressBookEdit.zipCode = selectedAddress.zipCode;
                this.addressBookEdit.plusFour = selectedAddress.plusFour;
                this.addressBookEdit.city = selectedAddress.city;
                this.addressBookEdit.state = selectedAddress.state;
                this.addressBookEdit.relevance = selectedAddress.relevance;
            } 
        },
        customAddressToggle(toggleCustom){
            this.toggleCustomAddress = toggleCustom;
            this.alertMessage = "Using custom addresses may incur additional costs.";
            this.scrollToTop();

            if(this.showInsertAddress){
                document.getElementById('address1').disabled = false;
                document.getElementById('city').disabled = false;
                document.getElementById('state').disabled = false;
                document.getElementById('zipcode').disabled = false;
                this.validateCustomAddress = true;
                this.validateCustomAddressEdit = false;
            }

            if(this.editAddressToggle){
                document.getElementById('edit-address1').disabled = false;
                document.getElementById('edit-address2').disabled = false;
                document.getElementById('edit-city').disabled = false;
                document.getElementById('edit-state').disabled = false;
                document.getElementById('edit-zipcode').disabled = false;
                this.validateCustomAddressEdit = true;
                this.validateCustomAddress = false;
            }
        },
    }
}
</script>

<style scoped>
/* Alert Container */
    .alert-container{
        height: 100%;
        width: 100%;
    }
/* Header Container */
    .header-container{
        width: 100%;
        display: flex;
        justify-content: center;
    
    }

    .header-container h1{
        margin: 0;
    }

    .header-container-inner{
        width: 60%;
        text-align: left;
    }
/* Button Container */

    .main-button-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .button-container-inner{
        width: 60%;
        display: flex; 
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-bottom: 25px;

        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
        padding: 10px;
        border-radius: 25px;
        margin-top: 20px;
    }
/* Input Styles */

    .stateInput{
        width: 50%;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.336);
        border-radius: 5px;
        outline: none;
        margin-left: auto;
    }

/* Container Structure */

    .address-book-container{
        width: 100%;
        margin-bottom: 5%;
    }

    .new-address-container{
        width: 100%;
        display: flex;
        justify-content: center;
        animation: animate-address-add .5s ease;
    }

    @keyframes animate-address-add{
        from{margin-top: -5%;}
        to{margin-top: 0%;}
    }

    .new-address-inner{
        width: 60%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
        border-radius: 25px;
        box-shadow: 0 0 20px rgb(0 0 0 / 12%);
    }

    /* Edit Address */

    .edit-address-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @keyframes animate-address-edit{
        from{margin-top: -5%;}
        to{margin-top: 0%;}
    }

    .edit-address-inner{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
    }

    .edit-address-background{
        background-color: #2cb6e4;
        width: 60%;
        border-radius: 15px;
        background-color: #ffffff;
        box-shadow: 0 0 100px rgba(0, 0, 0, 0.9);
        position: relative;
        z-index: 10;
        animation: animate-address-edit .5s ease;
    }

    .edit-address-background h2{
        background-color: #32ccfe;
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #ffffff;
        border-radius: 15px 15px 0 0;
    }

    .edit-address-popup{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 7.5%;
    }

    .address-input-container, .service-options-container{
        width: 50%;
        position: relative;
        z-index: 5;
    }

    .input-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;
        width: 95%;
    }

    .input-container-input{
        padding: 10px;
        font-size: 15px;
        border: 1px solid rgba(0, 0, 0, 0.336);
        border-radius: 5px;
        outline: none;
        margin-left: auto;
        width: 50%;
    }

    .aws-search-address-container{
        width: 100%;
    }

    .serivce-options-container{
        width: 50%;
    }

    .service-input-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    /* Address Button Container */
    .address-button-container{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    /* Address Book */
    .search-address-book{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .search-address-book input{
        padding: 10px;
        font-size: 15px;
        border: 1px solid rgba(0, 0, 0, 0.336);
        border-radius: 5px;
        outline: none;
        width: 50%;
    }

    .search-address-book button{
        background-color: #33f18a;
        color: #ffffff;
        border-radius: 5px;
        border: 0;
        cursor: pointer;
        padding: 5px;
        margin-left: 5px;
        transition-duration: .5s;
    }

    .search-address-book button:hover{
        background-color: #30df9c;
        transition-duration: .5s;
    }

    /* Table Styles */

    .import-address-table-container{
        width: 100%;
        overflow-x: scroll;
        margin-bottom: 15px;
    }

    .address-book-table-container{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 10%;
        animation: address-book-animate .5s ease;
    }

    .address-book-table-container h2{
        text-align: left;
        margin: 0;
    }

    .address-book-table-inner{
        padding: 10px;
        background-color: #eeeeee;
        overflow: auto;
        border-radius: 25px;
        height: 80vh;
        width: 60%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.116);
    }

    @keyframes address-book-animate {
        from{margin-top: -5%;}
        top{margin-top: 0%;}
    }

    @keyframes address-book-table-animate {
        from{margin-left: -2.5%;}
        top{margin-left: 0%;}
    }

    .address-book-table button{
        background-color: #33f18a;
        box-shadow: rgba(0, 0, 0, 0.164) 0px 1px 5px;
        border-radius: 5px;
        cursor: pointer;
        transition-duration: .5s;
        border: none;
        color: #ffffff;
    }

    .address-book-table-container-scroll{
        margin-top: 15px;
        height: 70vh;
        overflow-y: scroll;
        /* padding-right: 5px;
        padding-left: 5px; */
        background-color: transparent;
        border-radius: 25px;
    }

    .address-book-table{
        border-collapse: collapse;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        /* margin: 2em 0; */
        font-size: 0.9em;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        text-align: left;
        animation: address-book-table-animate 1s ease;
        margin-bottom: 20px;
    }

    .address-book-table th:first-child{
        border-top-left-radius: 5px;
    }

    .address-book-table th:last-child{
        border-top-right-radius: 5px;
    }

    .address-book-table tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    .address-book-table tbody tr:last-of-type {
        border-bottom: 2px solid #32ccfe;
    }

    .address-book-table th{
        background-color: #33f18a;
        color: #ffffff;

        position: sticky;
        top: 0;
        z-index: 2;
    }

    .address-book-table th,
    .address-book-table td{
        padding: 12px 15px;
    }

    .address-book-table tbody tr{
        border-bottom: 1px solid #dddddd;
    }

    .fa-times-circle{
        color: #32ccfe;
        font-size: 1.25em;
        text-align: center;
        cursor: pointer;
        transition-duration: .5s;
        vertical-align: baseline;
        margin-left: 50%;
    }

    .fa-times-circle:hover{
        color: #2cb6e4;
        cursor: pointer;
        transition-duration: .5s;
    }

    .select-address-edit{
        color: #32ccfe;
        text-decoration: underline;
        cursor: pointer;
    }

    .select-address-edit:hover{
        color:#2cb6e4;
        transition-duration: .5s;
    }

    /* Confirm Delete */

    .delete-confirm{
        width: 100%;
        height: 150%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* animation: delete-confirm-animate .5s ease; */
        background: linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 90%, rgba(236, 236, 236, 0.05) 100%);
    }

    @keyframes delete-confirm-animate {
        from{
            margin-top: -10%;
        }
        to{
            margin-top: 5%;
        }
    }

    .delete-text-container{
        text-align: left;
        width: fit-content;

    }

    .delete-confirm-inner h2{
        margin: 0;
        width: 100%;
        background-color: #32ccfe;
        border-radius: 10px 10px 0px 0px;
        padding: 10px;
        color: #fff;
    }

    .delete-confirm-inner{
        animation: delete-confirm-animate .5s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* width: 20%; */
        width: fit-content;
        background-color: #ffffff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 0 10px 10px 10px;
        margin-top: 5%;
    }

    /* Confirm import */

    .import-confirm{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 5%;
        margin-top: 5%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: import-confirm-animate .5s ease;
    }

    @keyframes import-confirm-animate {
        from{
            margin-top: -10%;
        }
        to{
            margin-top: 5%;
        }
    }

    .import-text-container{
        text-align: left;
        width: 100%;

    }

    .import-confirm-inner h2{
        margin: 0;
        width: 100%;
        background-color: #32ccfe;
        border-radius: 10px 10px 0px 0px;
        padding: 10px;
        color: #fff;
    }

    .import-confirm-inner{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 70%;
        background-color: #ffffff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 0 10px 10px 10px;
    }

    input[type=file]::file-selector-button {
        border: none;
        padding: 5px;
        border-radius: 15px;
        margin: 10px 1px 1px 1px;
        background-color: #32ccfe;
        transition: 1s;
        color: #fff;
        cursor: pointer;
    }

    input[type=file]::file-selector-button:hover {
        background-color: #2dbbeb;
        transition: 1s;
    }

    /* Download Template */

    .validation-errors{
        width: 100%;
        text-align: left;
    }

    .validation-errors h3{
        padding: 5px 10px;
        border-radius: 50px;
        width: fit-content;
        background-color: #fe804d;
        color: #fff;
    }

    /* Valdidation Success */
    .validation-success{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .check-container{
        margin-bottom: 15px;
        width: 50px;
        height: 50px;
        border: 5px solid #33f18a;
        background-color: #33f18a;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: animate-check-container 1s ease;
    }

    .check {
        height: 25px;
        width: 12.5px;
        border-width: 7px;
        transform: rotate(45deg);
        border-bottom: 7px solid #fff;
        border-right: 7px solid #fff;
        margin-bottom: 7px;
    }

    @keyframes animate-check-container {
        0%{
            transform: rotate(0deg) scale(10%);
            background-color: #fff;
        }
        100%{
            transform: rotate(360deg) scale(100%);
        }
    }

    .button-container-inner button, .address-button-inner button, .delete-address-button-container button, .import-button-container button{
        margin: 5px;
    }

    @media screen and (max-width: 1000px) {
        .address-book-table{
            font-size: 12px;
        }

        .address-book-container{
            width: 100%;
        }

        .button-container-inner, .new-address-inner{
            width: 90%;
        }

        .address-book-table-inner{
            width: 90%;
        }

        /* .delete-confirm-inner{
            width: 80%;
        } */

        .header-container-inner{
            width: 90%;
        }

        .edit-address-background{
            width: 90%;
        }

        .import-confirm-inner{
            width: 85%;
        }
    }

    @media screen and (max-width: 830px){
        .input-container{
            flex-direction: column;
            align-items: flex-start;
        }

        /* .header-container-inner{
            width: 80%;
        } */

        .input-container-input{
            width: 80%;
            margin-left: 0;
        }

        .stateInput{
            width: 80%;
            margin-left: 0;
        }
    }

    @media screen and (max-width: 650px){
        .address-book-table td, .address-book-table th{
            padding: 10px 2px 10px 2px;
        }

        .table-column-toggle{
            display: none;
        }

        .service-options-container{
            margin-left: 5%;
        }

        .button-container-inner{
            flex-direction: column;
            align-items: flex-start;
        }

        .csv-import-export-container{
            display: flex;
            justify-content: space-between;
        }

        .header-container-inner{
            width: 90%;
        }

        .address-book-table-inner{
            overflow-x: auto;
        }
    }
</style>
<template>
  <div class="table-container-main">
    <div class="confirm-svg">
        <svg version="1.1" id="Layer_2_00000011015521083262897090000013693107950429558190_"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 320.6 219.7"
            style="enable-background:new 0 0 320.6 219.7;" xml:space="preserve">
            <g id="_Ñëîé_1">
                <g>
                    <g>
                        <polyline class="st0" points="59.6,21 93.4,54.7 21,127.6 5,111.7 			"/>
                        <path class="st0" d="M101.7,187l1.4,1.2l4.7,4.7c4.6,4.6,5.4,12,1.3,17c-4.7,5.8-13.2,6.1-18.4,1l-22.1-21.7
                            c-5-5-9.4-10.6-13-16.6c-5-8.4-8.4-17.7-10.2-27.3L43.1,132l-13.3-13.2"/>
                        <path class="st0" d="M106.1,191.2l20,19.9c4.9,4.8,12.7,4.8,17.5-0.1c4.8-4.9,4.8-12.7-0.1-17.5l-1-1l-4.4-4.3l-2.5-2.5"/>
                        <path class="st0" d="M144.7,194.8l14,13.9l-0.7-0.7l1.9,1.9c4.9,4.8,12.7,4.8,17.6-0.1c2.7-2.7,3.8-6.3,3.5-9.8
                            c-0.2-2.8-1.4-5.6-3.6-7.8l-9.1-8.7"/>
                        <path class="st0" d="M85.1,63.1l15.1,14.9l11.4-3.4c12.2-3.7,25.5-1.7,36.1,5.3l51,33.9c2.1,1.4,3,3.7,2.8,6c-0.1,1-0.4,2-1,2.9
                            c-5.9,9-18.2,11.3-26.9,5L159,117.4c-1.2-0.8-2.5-1.3-3.8-1.4c-2.4-0.2-4.8,0.7-6.5,2.5c-2.8,3.1-2.7,7.8,0.3,10.8l62,61.6
                            c5.2,5.2,4.9,13.8-1,18.6c-5,3.9-12.2,3.2-16.8-1.3l-14.5-14.3"/>
                    </g>
                    <g>
                        <polyline class="st0" points="237.1,206.3 315.6,206.3 315.6,5 115.9,5 115.9,49.6 			"/>
                        <g>
                            <line class="st0" x1="258.2" y1="177.4" x2="253.3" y2="177.4"/>
                            <line class="st0" x1="285.9" y1="177.4" x2="281" y2="177.4"/>
                        </g>
                    </g>
                    <polyline class="st0" points="242.2,5 242.2,55.2 213.6,38.6 185,55.2 185,5 		"/>
                </g>
            </g>
        </svg>
        <h2>Account Users</h2>
    </div>
    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">User Name</div>
            <div class="col-2">Location</div>
            <div class="col-3">Email</div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div class="table-row-container">
            <div class="table-row" v-for="(user, index) in userAccountData" :key="index">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text" @click="getUserDataClick(user.userName)">{{user.userName}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{user.accountNo}}</span> {{user.locationAddress.serviceAddress.address.address1}}, {{user.locationAddress.serviceAddress.address.city}}, {{user.locationAddress.serviceAddress.address.state}}, {{user.locationAddress.serviceAddress.address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{user.userEmail}}</p></div>
            </div>
            <div v-if="userAccountData.length == 0" class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Users</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
            </div>
        </div>
    </div>
  </div>
  <div class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
<user-management-info v-if="toggleAddressBookInfo" @closeAddressInfo="closeAddressInfo($event)" :username="username"/>
<alert-user  v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import AlertUser from '../../../components/Popups/AlertUser.vue';
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import UserManagementInfo from '../UserManagementDashboard/UserManagementInfo.vue'
import jsonexport from 'jsonexport'

export default {
    components:{
        AlertUser,
        LoadingCharts,
        UserManagementInfo
    },
    props: ['userData', 'jwtToken'],
    watch:{
        'userData':function(){
            if(this.userData)
            {
                this.getAccountUsers();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get user data to generate table."
            }
        }
    },
    data(){
        return{
            toggleAddressBookInfo: false,
            user: null,
            userAccountData: [],
            addressIDArray: [],
            addressID: null,
            loadingTable: false,
            toggleAlertBox: '',
            alertMessage: '',
            items: `items{
                userName
                accountNo
                userEmail
                locationAddress{
                    labelImage
                    serviceAddress{
                        address{
                            companyName
                            attention
                            address1
                            address2
                            city
                            state
                            zipCode
                            phone
                            phoneExt
                            relevance
                            latitude
                            longitude
                        }
                    }
                }
            }`,
            filterField: null,
            filterValue: null,
            username: ""
        }
    },
    methods:{
        async getUserDataClick(username){
            this.username = username;
            this.toggleAddressBookInfo = true;
        },
        closeAddressInfo(event){
            this.toggleAddressBookInfo = event;
            this.getAccountUsers();
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async getAccountUsers(){
            this.loadingTable = true;
            let graphqlQuery = `query listUsers{
                listUsers(filter: {accountNo: {eq: "${this.userData.accountNo}"}}){
                    ${this.items}
                }
            }`
            
            try{
                let usersByAccount = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                this.userAccountData = usersByAccount.data.listUsers.items;
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting users"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;
            csvArray = this.userAccountData.map(obj =>{
                return {
                    UserName: obj.userName,
                    AccountNo: obj.accountNo,
                    UserEmail: obj.userEmail,
                    Address1: obj.locationAddress.serviceAddress.address.address1,
                    City: obj.locationAddress.serviceAddress.address.city,
                    State: obj.locationAddress.serviceAddress.address.state,
                    ZipCode: obj.locationAddress.serviceAddress.address.zipCode
                }
            })
            jsonexport(csvArray, (error, csv) => {
                if (error) {
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error downloading user data"
                }
                const blob = new Blob([csv], { type: 'text/csv' });
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = 'UserData.csv';
                anchor.click();
                window.URL.revokeObjectURL(anchor.href);
                anchor.remove();
                this.loadingTable = false;
            })
        }
    }
}
</script>

<style scoped>
.st0{fill:none;stroke:#000000;stroke-width:10;stroke-linecap:round;stroke-linejoin:round;}

h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

.responsive-table{
    width: 100%;
}

.confirm-svg{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 999px;
    padding: 5px;
    width: fit-content;
    margin-bottom: 5px;
}

.confirm-svg svg{
    width: 35px;
    height: 35px;
    padding-right: 5px;
}

.confirm-svg h2{
    margin: 0;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    /* white-space: nowrap; */
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text, .col-data{
        font-size: 8px;
    }

    .shipmentid-text{
        font-size: 10px;
    }
}

@media only screen and (max-width: 1135px) {
   .col-1{
        min-width: 30%;
    }
}
</style>
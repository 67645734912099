/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentShipmentsPickupService = /* GraphQL */ `
  query GetCurrentShipmentsPickupService {
    getCurrentShipmentsPickupService {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const getShipmentsPickupInitiated = /* GraphQL */ `
  query GetShipmentsPickupInitiated {
    getShipmentsPickupInitiated {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      header
      description
      imageBase64
      urlLink
      businessRelated
      shippingRelated
      marketingRelated
      dailyUpdateRelated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        header
        description
        imageBase64
        urlLink
        businessRelated
        shippingRelated
        marketingRelated
        dailyUpdateRelated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShipment = /* GraphQL */ `
  query GetShipment($shipmentID: String!) {
    getShipment(shipmentID: $shipmentID) {
      username
      owner
      accountNo
      secretKey
      service
      shipmentID
      ref1
      ref2
      ref3
      ref4
      ref5
      description
      deliveryInstructions
      weight
      packageCount
      notify {
        __typename
      }
      additionalServices
      isContractedDelivery
      deliveryPickup {
        type
        location
        __typename
      }
      serviceAddress {
        type
        location
        __typename
      }
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      labelImage
      deliveryDay
      voidAt
      currentKey
      historyKey
      inTransitKey
      voidKey
      pickupInitiated
      isPickup
      createdAt
      updatedAt
      activity {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        __typename
      }
      processError
      apiKey
      __typename
    }
  }
`;
export const listShipments = /* GraphQL */ `
  query ListShipments(
    $shipmentID: String
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShipments(
      shipmentID: $shipmentID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentByCurrentKey = /* GraphQL */ `
  query ShipmentByCurrentKey(
    $currentKey: String!
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentByCurrentKey(
      currentKey: $currentKey
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentByHistoryKey = /* GraphQL */ `
  query ShipmentByHistoryKey(
    $historyKey: String!
    $usernameDeliveryDay: ModelShipmentHistoryKeyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentByHistoryKey(
      historyKey: $historyKey
      usernameDeliveryDay: $usernameDeliveryDay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentByInTransitKey = /* GraphQL */ `
  query ShipmentByInTransitKey(
    $inTransitKey: String!
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentByInTransitKey(
      inTransitKey: $inTransitKey
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentByVoidKey = /* GraphQL */ `
  query ShipmentByVoidKey(
    $voidKey: String!
    $voidAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentByVoidKey(
      voidKey: $voidKey
      voidAt: $voidAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentByPickupInitiated = /* GraphQL */ `
  query ShipmentByPickupInitiated(
    $pickupInitiated: String!
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentByPickupInitiated(
      pickupInitiated: $pickupInitiated
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentByProcessError = /* GraphQL */ `
  query ShipmentByProcessError(
    $processError: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentByProcessError(
      processError: $processError
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        owner
        accountNo
        secretKey
        service
        shipmentID
        ref1
        ref2
        ref3
        ref4
        ref5
        description
        deliveryInstructions
        weight
        packageCount
        additionalServices
        isContractedDelivery
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        labelImage
        deliveryDay
        voidAt
        currentKey
        historyKey
        inTransitKey
        voidKey
        pickupInitiated
        isPickup
        createdAt
        updatedAt
        processError
        apiKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($accountNo: String!) {
    getAccount(accountNo: $accountNo) {
      accountNo
      accountType
      contact1
      contact2
      contact3
      defaultWeight
      balance
      creditLimit
      billable
      contractedLocations
      specialPricing
      billRunDate
      billFrequency
      industry
      product {
        productCode
        serviceCode
        description
        additionalService
        serviceNetwork
        vendor
        price
        productName
        restServiceCode
        descriptionUI
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        productFamily
        serviceGroup
        serviceGroupSequence
        productSequence
        __typename
      }
      billingAddress {
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        phone
        phoneExt
        email
        relevance
        latitude
        longitude
        routeCode
        __typename
      }
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $accountNo: String
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      accountNo: $accountNo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountNo
        accountType
        contact1
        contact2
        contact3
        defaultWeight
        balance
        creditLimit
        billable
        contractedLocations
        specialPricing
        billRunDate
        billFrequency
        industry
        locationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userName: String!) {
    getUser(userName: $userName) {
      userName
      owner
      accountNo
      fullname
      userEmail
      createdBy
      createdDate
      updatedBy
      updatedDate
      location
      labelImage
      webKey
      locationID
      locationAddress {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userName: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userName
        owner
        accountNo
        fullname
        userEmail
        createdBy
        createdDate
        updatedBy
        updatedDate
        location
        labelImage
        webKey
        locationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddressBook = /* GraphQL */ `
  query GetAddressBook($id: ID!) {
    getAddressBook(id: $id) {
      id
      userName
      accountNo
      companyName
      attention
      address1
      address2
      city
      state
      zipCode
      routeCode
      plusFour
      phone
      phoneExt
      email
      latitude
      longitude
      deliveryInstructions
      relevance
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAddressBooks = /* GraphQL */ `
  query ListAddressBooks(
    $filter: ModelAddressBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddressBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        accountNo
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        routeCode
        plusFour
        phone
        phoneExt
        email
        latitude
        longitude
        deliveryInstructions
        relevance
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const addressBookByUserName = /* GraphQL */ `
  query AddressBookByUserName(
    $userName: String!
    $accountNo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddressBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressBookByUserName(
      userName: $userName
      accountNo: $accountNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        accountNo
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        routeCode
        plusFour
        phone
        phoneExt
        email
        latitude
        longitude
        deliveryInstructions
        relevance
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const addressBookByAccountNo = /* GraphQL */ `
  query AddressBookByAccountNo(
    $accountNo: String!
    $userName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddressBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressBookByAccountNo(
      accountNo: $accountNo
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        accountNo
        companyName
        attention
        address1
        address2
        city
        state
        zipCode
        routeCode
        plusFour
        phone
        phoneExt
        email
        latitude
        longitude
        deliveryInstructions
        relevance
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      accountNo
      serviceAddress {
        type
        location
        __typename
      }
      labelImage
      isContractedPickup
      isCustomerPickup
      isContractedDelivery
      isUser
      contractedLocation {
        pickupWindowEnd
        pickupWindowStart
        username
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        __typename
      }
      placeID
      place {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      placePickupTime
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const locationByAccountNo = /* GraphQL */ `
  query LocationByAccountNo(
    $accountNo: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByAccountNo(
      accountNo: $accountNo
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountNo
        labelImage
        isContractedPickup
        isCustomerPickup
        isContractedDelivery
        isUser
        placeID
        placePickupTime
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlace = /* GraphQL */ `
  query GetPlace($placeCode: String!) {
    getPlace(placeCode: $placeCode) {
      placeCode
      address1
      address2
      city
      state
      zipCode
      relevance
      latitude
      longitude
      pickupRoute
      radius
      pickupWindowEnd
      pickupWindowStart
      serviceTime
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPlaces = /* GraphQL */ `
  query ListPlaces(
    $placeCode: String
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlaces(
      placeCode: $placeCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const placeByAddress1 = /* GraphQL */ `
  query PlaceByAddress1(
    $address1: String!
    $placeCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    placeByAddress1(
      address1: $address1
      placeCode: $placeCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const placeByCity = /* GraphQL */ `
  query PlaceByCity(
    $city: String!
    $placeCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    placeByCity(
      city: $city
      placeCode: $placeCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const placeByZipCode = /* GraphQL */ `
  query PlaceByZipCode(
    $zipCode: String!
    $placeCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    placeByZipCode(
      zipCode: $zipCode
      placeCode: $placeCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        placeCode
        address1
        address2
        city
        state
        zipCode
        relevance
        latitude
        longitude
        pickupRoute
        radius
        pickupWindowEnd
        pickupWindowStart
        serviceTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($productCode: String!, $serviceCode: String!) {
    getProduct(productCode: $productCode, serviceCode: $serviceCode) {
      productCode
      serviceCode
      description
      additionalService
      serviceNetwork
      vendor
      price
      productName
      restServiceCode
      descriptionUI
      deliveryWindowEnd
      deliveryWindowStart
      deliveryWindowLabel
      productFamily
      serviceGroup
      serviceGroupSequence
      productSequence
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $productCode: String
    $serviceCode: ModelStringKeyConditionInput
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      productCode: $productCode
      serviceCode: $serviceCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productCode
        serviceCode
        description
        additionalService
        serviceNetwork
        vendor
        price
        productName
        restServiceCode
        descriptionUI
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        productFamily
        serviceGroup
        serviceGroupSequence
        productSequence
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productByServiceNetwork = /* GraphQL */ `
  query ProductByServiceNetwork(
    $serviceNetwork: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByServiceNetwork(
      serviceNetwork: $serviceNetwork
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productCode
        serviceCode
        description
        additionalService
        serviceNetwork
        vendor
        price
        productName
        restServiceCode
        descriptionUI
        deliveryWindowEnd
        deliveryWindowStart
        deliveryWindowLabel
        productFamily
        serviceGroup
        serviceGroupSequence
        productSequence
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCode = /* GraphQL */ `
  query GetProductCode($productCode: String!) {
    getProductCode(productCode: $productCode) {
      productCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductCodes = /* GraphQL */ `
  query ListProductCodes(
    $productCode: String
    $filter: ModelProductCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductCodes(
      productCode: $productCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceCode = /* GraphQL */ `
  query GetServiceCode($serviceCode: String!) {
    getServiceCode(serviceCode: $serviceCode) {
      serviceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listServiceCodes = /* GraphQL */ `
  query ListServiceCodes(
    $serviceCode: String
    $filter: ModelServiceCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceCodes(
      serviceCode: $serviceCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serviceCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceNetwork = /* GraphQL */ `
  query GetServiceNetwork($serviceNetwork: String!) {
    getServiceNetwork(serviceNetwork: $serviceNetwork) {
      serviceNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listServiceNetworks = /* GraphQL */ `
  query ListServiceNetworks(
    $serviceNetwork: String
    $filter: ModelServiceNetworkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceNetworks(
      serviceNetwork: $serviceNetwork
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serviceNetwork
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($vendor: String!) {
    getVendor(vendor: $vendor) {
      vendor
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $vendor: String
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVendors(
      vendor: $vendor
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        vendor
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRestServiceCode = /* GraphQL */ `
  query GetRestServiceCode($restServiceCode: String!) {
    getRestServiceCode(restServiceCode: $restServiceCode) {
      restServiceCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRestServiceCodes = /* GraphQL */ `
  query ListRestServiceCodes(
    $restServiceCode: String
    $filter: ModelRestServiceCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRestServiceCodes(
      restServiceCode: $restServiceCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        restServiceCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductFamily = /* GraphQL */ `
  query GetProductFamily($productFamily: String!) {
    getProductFamily(productFamily: $productFamily) {
      productFamily
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductFamilies = /* GraphQL */ `
  query ListProductFamilies(
    $productFamily: String
    $filter: ModelProductFamilyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductFamilies(
      productFamily: $productFamily
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productFamily
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceGroup = /* GraphQL */ `
  query GetServiceGroup($serviceGroup: String!) {
    getServiceGroup(serviceGroup: $serviceGroup) {
      serviceGroup
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listServiceGroups = /* GraphQL */ `
  query ListServiceGroups(
    $serviceGroup: String
    $filter: ModelServiceGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceGroups(
      serviceGroup: $serviceGroup
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serviceGroup
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoute = /* GraphQL */ `
  query GetRoute($routeCode: String!) {
    getRoute(routeCode: $routeCode) {
      routeCode
      serviceNetwork
      breakDuration
      breakWindowEnd
      breakWindowStart
      costPerUnitDistance
      costPerUnitTime
      earliestStartTime
      endDepot
      latestStartTime
      maxTotalTime
      startDepot
      products {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRoutes = /* GraphQL */ `
  query ListRoutes(
    $routeCode: String
    $filter: ModelRouteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoutes(
      routeCode: $routeCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeoCodes = /* GraphQL */ `
  query GetGeoCodes($geoCode: String!, $routeCode: String!) {
    getGeoCodes(geoCode: $geoCode, routeCode: $routeCode) {
      geoCode
      routeCode
      route {
        routeCode
        serviceNetwork
        breakDuration
        breakWindowEnd
        breakWindowStart
        costPerUnitDistance
        costPerUnitTime
        earliestStartTime
        endDepot
        latestStartTime
        maxTotalTime
        startDepot
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGeoCodes = /* GraphQL */ `
  query ListGeoCodes(
    $geoCode: String
    $routeCode: ModelStringKeyConditionInput
    $filter: ModelGeoCodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGeoCodes(
      geoCode: $geoCode
      routeCode: $routeCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        geoCode
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const geoCodeByRouteCode = /* GraphQL */ `
  query GeoCodeByRouteCode(
    $routeCode: String!
    $geoCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGeoCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    geoCodeByRouteCode(
      routeCode: $routeCode
      geoCode: $geoCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        geoCode
        routeCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDropBox = /* GraphQL */ `
  query GetDropBox($dropBoxCode: String!) {
    getDropBox(dropBoxCode: $dropBoxCode) {
      dropBoxCode
      isActive
      serviceAddress {
        type
        location
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDropBoxes = /* GraphQL */ `
  query ListDropBoxes(
    $dropBoxCode: String
    $filter: ModelDropBoxFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDropBoxes(
      dropBoxCode: $dropBoxCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dropBoxCode
        isActive
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($vehicleCode: String!) {
    getVehicle(vehicleCode: $vehicleCode) {
      vehicleCode
      isActive
      plateNumber
      vehicleID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $vehicleCode: String
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVehicles(
      vehicleCode: $vehicleCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        vehicleCode
        isActive
        plateNumber
        vehicleID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTripSheet = /* GraphQL */ `
  query GetTripSheet($TripSheetId: Int!) {
    getTripSheet(TripSheetId: $TripSheetId) {
      TripSheetId
      DriverId
      RouteId
      VehicleId
      TripDate
      SignOffDate
      StartingMileage
      SignOffId
      EndingMileage
      ManifestSource
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTripSheets = /* GraphQL */ `
  query ListTripSheets(
    $TripSheetId: Int
    $filter: ModelTripSheetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripSheets(
      TripSheetId: $TripSheetId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        TripSheetId
        DriverId
        RouteId
        VehicleId
        TripDate
        SignOffDate
        StartingMileage
        SignOffId
        EndingMileage
        ManifestSource
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQueuedActivities = /* GraphQL */ `
  query GetQueuedActivities($id: ID!) {
    getQueuedActivities(id: $id) {
      id
      shipmentID
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      ignoreActivity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQueuedActivities = /* GraphQL */ `
  query ListQueuedActivities(
    $filter: ModelQueuedActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueuedActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentID
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queuedActivityByShipmentID = /* GraphQL */ `
  query QueuedActivityByShipmentID(
    $shipmentID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQueuedActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queuedActivityByShipmentID(
      shipmentID: $shipmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shipmentID
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        ignoreActivity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepot = /* GraphQL */ `
  query GetDepot($name: String!) {
    getDepot(name: $name) {
      name
      depotCode
      latitude
      longitude
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDepots = /* GraphQL */ `
  query ListDepots(
    $name: String
    $filter: ModelDepotFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDepots(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        depotCode
        latitude
        longitude
        timeWindowEnd
        timeWindowStart
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManifestShipment = /* GraphQL */ `
  query GetManifestShipment($shipmentID: String!) {
    getManifestShipment(shipmentID: $shipmentID) {
      shipmentID
      address {
        address1
        address2
        attention
        city
        companyName
        phone
        latitude
        longitude
        relevance
        routeCode
        state
        zipCode
        __typename
      }
      isDelivery
      isPriority
      latitude
      longitude
      route
      status
      timeWindowEnd
      timeWindowStart
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManifestShipments = /* GraphQL */ `
  query ListManifestShipments(
    $shipmentID: String
    $filter: ModelManifestShipmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManifestShipments(
      shipmentID: $shipmentID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shipmentID
        isDelivery
        isPriority
        latitude
        longitude
        route
        status
        timeWindowEnd
        timeWindowStart
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manifestShipmentByRoute = /* GraphQL */ `
  query ManifestShipmentByRoute(
    $route: String!
    $sortDirection: ModelSortDirection
    $filter: ModelManifestShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manifestShipmentByRoute(
      route: $route
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shipmentID
        isDelivery
        isPriority
        latitude
        longitude
        route
        status
        timeWindowEnd
        timeWindowStart
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTripShipment = /* GraphQL */ `
  query GetTripShipment($tripSheetID: String!, $shipmentID: String!) {
    getTripShipment(tripSheetID: $tripSheetID, shipmentID: $shipmentID) {
      tripSheetID
      shipmentID
      activityTime
      recordedTime
      status
      statusID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTripShipments = /* GraphQL */ `
  query ListTripShipments(
    $tripSheetID: String
    $shipmentID: ModelStringKeyConditionInput
    $filter: ModelTripShipmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripShipments(
      tripSheetID: $tripSheetID
      shipmentID: $shipmentID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tripSheetID
        shipmentID
        activityTime
        recordedTime
        status
        statusID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTripWaypoint = /* GraphQL */ `
  query GetTripWaypoint($tripSheetID: String!) {
    getTripWaypoint(tripSheetID: $tripSheetID) {
      tripSheetID
      activityTime
      recordedTime
      status
      statusID
      routeCode
      waypoint
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTripWaypoints = /* GraphQL */ `
  query ListTripWaypoints(
    $tripSheetID: String
    $filter: ModelTripWaypointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripWaypoints(
      tripSheetID: $tripSheetID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tripSheetID
        activityTime
        recordedTime
        status
        statusID
        routeCode
        waypoint
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManifestWaypoint = /* GraphQL */ `
  query GetManifestWaypoint($routeCode: String!, $waypoint: String!) {
    getManifestWaypoint(routeCode: $routeCode, waypoint: $waypoint) {
      routeCode
      waypoint
      arrival
      assignmentRule
      deliveries
      departure
      fromPreviousDistance
      fromPreviousTravelTime
      id
      isDelivery
      isDropBox
      latitude
      locations
      longitude
      pickups
      radius
      route
      sequence
      serviceTime
      shipments {
        shipmentID
        __typename
      }
      stop
      stopType
      violationTime
      waitTime
      windowEnd
      windowStart
      created
      distanceCost
      endTime
      error
      geometry
      jobID
      orderCount
      overTimeCost
      processed
      regularTimeCost
      routeEnd
      routeStart
      startTime
      status
      totalBreakServiceTime
      totalCost
      totalDistance
      totalOrderServiceTime
      totalTime
      totalTravelTime
      totalViolationTime
      totalWaitTime
      violatedConstraints
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManifestWaypoints = /* GraphQL */ `
  query ListManifestWaypoints(
    $routeCode: String
    $waypoint: ModelStringKeyConditionInput
    $filter: ModelManifestWaypointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManifestWaypoints(
      routeCode: $routeCode
      waypoint: $waypoint
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        routeCode
        waypoint
        arrival
        assignmentRule
        deliveries
        departure
        fromPreviousDistance
        fromPreviousTravelTime
        id
        isDelivery
        isDropBox
        latitude
        locations
        longitude
        pickups
        radius
        route
        sequence
        serviceTime
        stop
        stopType
        violationTime
        waitTime
        windowEnd
        windowStart
        created
        distanceCost
        endTime
        error
        geometry
        jobID
        orderCount
        overTimeCost
        processed
        regularTimeCost
        routeEnd
        routeStart
        startTime
        status
        totalBreakServiceTime
        totalCost
        totalDistance
        totalOrderServiceTime
        totalTime
        totalTravelTime
        totalViolationTime
        totalWaitTime
        violatedConstraints
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivities = /* GraphQL */ `
  query GetActivities($code: String!) {
    getActivities(code: $code) {
      code
      description
      statusID
      shipmentStage
      shipmentPoint
      shippingEvent
      activityTime
      recordedTime
      comments
      internalComments
      notes
      tripSheetId
      latitude
      longitude
      driverCode
      manifestSource
      imageKeyID
      imageKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $code: String
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActivities(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        description
        statusID
        shipmentStage
        shipmentPoint
        shippingEvent
        activityTime
        recordedTime
        comments
        internalComments
        notes
        tripSheetId
        latitude
        longitude
        driverCode
        manifestSource
        imageKeyID
        imageKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

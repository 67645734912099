<template>
  <div v-if="((selectedShipmentArray.length > 0) && (selectedShipment == 'Current'))" class="button-container">
    <div class="print-selected">
        <p class="print-selected-text" @click="PrintLabel()">Print Label</p>
    </div>
    <div class="void-selected">
        <p class="void-selected-text" @click="VoidShipment()">Void Shipment</p>
    </div>
    <div v-if="labelData != null" class="print-selected close-label">
        <p class="print-selected-text" @click="closeLabel(); labelData = null; selectedShipmentArray = []">Close Label</p>
    </div>
  </div>
  <div class="label-container" v-if="labelData != null">
      <shipment-label :baseLabelData="labelData" />
  </div>
  <loading-charts v-if="loadingData" class="loading-charts"/>
  <table-filter 
  :filterByService="true" 
  :filterByReference="true" 
  :filterByCompanyName="true" 
  :filterByAddress="true" 
  :filterByCity="true"
  :filterByState="true"
  :filterByZipCode="true"
  @filterValues="filterValues($event)"/>
  <div class="table-container-main">
    <div class="responsive-table">
        <div class="table-header" id="header">
            <div class="col-1">Shipment ID</div>
            <div class="col-2">Location</div>
            <div class="col-3">Service</div>
            <div class="col-4"><input v-if="selectedShipment == 'Current'" style="border: 0.15em solid #000; margin-left: 5px;" type="checkbox" name="" id="select-all-checkbox" @change="selectAllShipmentID()"></div>
        </div>
        <loading-charts v-if="loadingTable" class="loading-charts"/>
        <div v-if="selectedShipment == 'Current'" class="table-row-container">
            <div class="table-row" v-for="(shipment, index) in currentShipments" :key="index">
                <div @click="trackShipment(shipment.shipmentID)" class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{shipment.shipmentID}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{shipment.serviceAddress.address.companyName}}</span> {{shipment.serviceAddress.address.address1}}, {{shipment.serviceAddress.address.city}}, {{shipment.serviceAddress.address.state}}, {{shipment.serviceAddress.address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{shipmentServiceType(shipment)}}</p></div>
                <div class="col-4"><input type="checkbox" name="" id="" :value="shipment.shipmentID" v-model="selectedShipmentArray"></div>
            </div>
        </div>
        <div v-if="selectedShipment == 'In Transit'" class="table-row-container">
            <div class="table-row" v-for="(shipment, index) in inTransitShipments" :key="index">
                <div @click="trackShipment(shipment.shipmentID)" class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{shipment.shipmentID}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{shipment.serviceAddress.address.companyName}}</span> {{shipment.serviceAddress.address.address1}}, {{shipment.serviceAddress.address.city}}, {{shipment.serviceAddress.address.state}}, {{shipment.serviceAddress.address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{shipmentServiceType(shipment)}}</p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="selectedShipment == 'Delivered'" class="table-row-container">
            <div class="table-row" v-for="(shipment, index) in deliveredShipments" :key="index">
                <div @click="trackShipment(shipment.shipmentID)" class="col-1"><p class="shipmentid-text" id="shipmentid-text">{{shipment.shipmentID}}</p></div>
                <div class="col-2 col-data"><span style="font-weight: bold;">{{shipment.serviceAddress.address.companyName}}</span> {{shipment.serviceAddress.address.address1}}, {{shipment.serviceAddress.address.city}}, {{shipment.serviceAddress.address.state}}, {{shipment.serviceAddress.address.zipCode}}</div>
                <div class="col-3 col-data"><p class="service-text">{{shipmentServiceType(shipment)}}</p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="(inTransitShipments.length == 0) && (selectedShipment == 'In Transit')" class="table-row-container">
            <div class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Shipments</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="(deliveredShipments.length == 0) && (selectedShipment == 'Delivered')" class="table-row-container">
            <div class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Shipments</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
        <div v-if="(currentShipments.length == 0) && (selectedShipment == 'Current')" class="table-row-container">
            <div class="table-row">
                <div class="col-1"><p class="shipmentid-text" id="shipmentid-text"></p></div>
                <div class="col-2 col-data">No Shipments</div>
                <div class="col-3 col-data"><p class="service-text"></p></div>
                <div class="col-4"></div>
            </div>
        </div>
    </div>
  </div>
  <div class="button-container">
    <div class="print-selected close-label">
        <p class="print-selected-text download-csv" @click="downloadCSVTest()">Download CSV</p>
    </div>
  </div>
  

<shipment-info v-if="toggleTrackShipment" @closeShipmentInfo="closeShipmentInfo($event)" :trackShipmentID="trackShipmentID" :cognitoUser="cognitoUser" :jwtToken="jwtToken" :userData="userData"/>
<alert-user  v-if="toggleAlertBox" @closeAlertBox="closeAlertBox($event)" :message="alertMessage"/>
</template>

<script>
import {API} from 'aws-amplify';
import ShipmentInfo from './../ShipmentInfo.vue'
import {createShipmentLabelLambda, voidShipmentLambda} from '../../../graphql/mutations' //filterCurrentShipments
import ShipmentLabel from '../../ShipmentDashboard/ShipmentLabel.vue'
import LoadingCharts from '../../ShipmentDashboard/LoadingCharts.vue'
import TableFilter from './../TableFilter.vue'
import AlertUser from '../../Popups/AlertUser.vue';
import jsonexport from 'jsonexport'


export default {
    components:{
        ShipmentInfo,
        ShipmentLabel,
        LoadingCharts,
        TableFilter,
        AlertUser
    },
    props: ['selectedShipment', 'selectedDates', 'userData', 'jwtToken', 'cognitoUser', 'selectedAccountNo'],
    mounted(){
        this.toDate = new Date(new Date().setDate(new Date().getDate())).toJSON().slice(0, 10);
        this.fromDate =  new Date(new Date().setDate(new Date().getDate() - 7)).toJSON().slice(0, 10);
        this.sliderValue = 1;

        let previousMonthDate = new Date();
        // Set the current date to the first day of the current month
        previousMonthDate.setDate(1);
        // Subtract one day to get to the last day of the previous month
        previousMonthDate.setDate(0);
        this.previousMonth = previousMonthDate.toJSON().slice(0,7);
        this.currentMonth = this.toDate.slice(0,7);
    },
    watch:{
        'selectedAccountNo':function(){
            if(this.selectedAccountNo != "")
            {
                this.CurrentKey = this.selectedAccountNo;
                this.getInTransitShipmentsGraphQL();
            }else{
                this.toggleAlertBox = true;
                this.alertMessage = "Unable to get account data to generate table."
            }
        },
        'selectedShipment'(newValue, oldValue){
            if(newValue != oldValue){
            if(this.selectedShipment == 'Current'){
                document.getElementById('header').style.backgroundColor = "#33f18a"
                this.selectedShipmentArray = [];
                this.currentShipments = []
                this.fromDate = this.selectedDates.fromDate; 
                this.toDate = this.selectedDates.toDate;
                this.sliderValue = this.selectedDates.sliderValue;
                this.labelData = null;
                this.getCurrentShipmentsGraphQL();
            }else if(this.selectedShipment == 'In Transit'){
                document.getElementById('header').style.backgroundColor = "#32ccfe"
                this.selectedShipmentArray = [];
                this.inTransitShipments = [];
                this.fromDate = this.selectedDates.fromDate; 
                this.toDate = this.selectedDates.toDate;
                this.sliderValue = this.selectedDates.sliderValue;
                this.labelData = null;
                this.getInTransitShipmentsGraphQL();
            }else if(this.selectedShipment == 'Delivered'){
                document.getElementById('header').style.backgroundColor = "#fe804d"
                this.selectedShipmentArray = [];
                this.deliveredShipments = [];
                this.fromDate = this.selectedDates.fromDate; 
                this.toDate = this.selectedDates.toDate;
                this.sliderValue = this.selectedDates.sliderValue;
                this.labelData = null;
                this.getDeliveredShipmentsGraphQL();
            }
            }
        },
        'selectedDates'(newValue, oldValue){
            let newDate = JSON.stringify(newValue);
            let oldDate = JSON.stringify(oldValue);
            if(newDate != oldDate){
                if(this.selectedShipment == 'Current'){
                    document.getElementById('header').style.backgroundColor = "#33f18a"
                    this.selectedShipmentArray = [];
                    this.currentShipments = []
                    this.fromDate = this.selectedDates.fromDate; 
                    this.toDate = this.selectedDates.toDate;
                    this.sliderValue = this.selectedDates.sliderValue;
                    this.labelData = null;
                    this.getCurrentShipmentsGraphQL();
                    this.filterValue = '';
                    this.filterField = '';
                }else if(this.selectedShipment == 'In Transit'){
                    document.getElementById('header').style.backgroundColor = "#32ccfe"
                    this.selectedShipmentArray = [];
                    this.inTransitShipments = [];
                    this.fromDate = this.selectedDates.fromDate; 
                    this.toDate = this.selectedDates.toDate;
                    this.sliderValue = this.selectedDates.sliderValue;
                    this.labelData = null;
                    this.getInTransitShipmentsGraphQL();
                    this.filterValue = '';
                    this.filterField = '';
                }else if(this.selectedShipment == 'Delivered'){
                    document.getElementById('header').style.backgroundColor = "#fe804d"
                    this.selectedShipmentArray = [];
                    this.deliveredShipments = [];
                    this.fromDate = this.selectedDates.fromDate; 
                    this.toDate = this.selectedDates.toDate;
                    this.sliderValue = this.selectedDates.sliderValue;
                    this.labelData = null;
                    this.getDeliveredShipmentsGraphQL();
                    this.filterValue = '';
                    this.filterField = '';
                }
            }
        }
    },
    data(){
        return{
            currentShipments: [],
            inTransitShipments: [],
            deliveredShipments: [],
            CurrentKey: null,
            trackShipmentID: null,
            toggleTrackShipment: false,
            user: null,
            fromDate: null,
            toDate: null,
            sliderValue: null,
            selectedShipmentArray: [],
            labelData: null,
            loadingData: false,
            loadingTable: false,
            isFiltered: false,
            filterField: '',
            filterValue: '',
            toggleAlertBox: '',
            alertMessage: '',
            previousMonth: '',
            currentMonth: '',
            items: `items{
                    shipmentID
                    service
                    username
                    accountNo
                    ref1
                    description
                    deliveryInstructions
                    serviceAddress{
                        address{
                        companyName
                        address1
                        city
                        state
                        zipCode
                        }
                    }
                    deliveryPickup{
                        address{
                        companyName
                        address1
                        city
                        state
                        zipCode
                        }
                    }
                    notify{
                        delivery{
                        phone
                        email
                        }
                        nonDelivery{
                        phone
                        email
                        }
                    }
                    additionalServices
                }`
        }
    },
    methods:{
        shipmentServiceType(shipment){
            if(shipment.service == "SSLRLOC"){
                return "Standard"
            }else if(shipment.service == "PSLRLOC"){
                return "Priority"
            }else if(shipment.service == "SSLRPICK"){
                return "Pickup"
            }else{
                return shipment.service
            }
        },
        selectAllShipmentID(){
            if(this.selectedShipmentArray.length == 0){
                this.selectedShipmentArray = [];
                this.selectedShipmentArray = this.currentShipments.map(item => item.shipmentID)
            }else{
                this.selectedShipmentArray = [];
            }
        },
        filterValues(event){
            this.isFiltered = true;
            this.filterField = event.filterField;
            this.filterValue = event.filterValue;

            if(this.selectedShipment == 'Current'){
                switch (event.filterField) {
                    case "companyName":
                    case "address1":
                    case "city":
                    case "state":
                    case "zipCode":
                        this.filterCurrent();
                        this.isFiltered = false;
                        break;
                    default:
                        this.getCurrentShipmentsGraphQL();
                        this.isFiltered = false;
                        break;
                }
            }else if(this.selectedShipment == 'In Transit'){
                switch (event.filterField) {
                    case "companyName":
                    case "address1":
                    case "city":
                    case "state":
                    case "zipCode":
                        this.filterInTransit();
                        this.isFiltered = false;
                        break;
                    default:
                        this.getInTransitShipmentsGraphQL();
                        this.isFiltered = false;
                        break;
                }
            }else if(this.selectedShipment == 'Delivered'){
                switch (event.filterField) {
                    case "companyName":
                    case "address1":
                    case "city":
                    case "state":
                    case "zipCode":
                        this.filterDelivered();
                        this.isFiltered = false;
                        break;
                    default:
                        this.getDeliveredShipmentsGraphQL();
                        this.isFiltered = false;
                        break;
                }
            }
        },
        async filterCurrent(){
            this.loadingData = true;
            let input;

            input = {
                input:{
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    userName: this.userData.userName,
                    currentKey: this.CurrentKey,
                    filterField: this.filterField,
                    filterValue: this.filterValue,
                    filterByWeek: true,
                    isAdmin: true
                }
            }

            try{
                let filterCurrent = API.graphql({
                query:  `mutation filterCurrentShipments($input: filterShipmentInputLambda!){ 
                filterCurrentShipments(input: $input){
                            shipmentID
                            service
                            serviceAddress{
                            address{
                                companyName
                                address1
                                city
                                state
                                zipCode
                            }
                        }
                    }
                }`,
                variables: input,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                filterCurrent.then((response)=>{
                    this.currentShipments = response.data.filterCurrentShipments;
                    for (let index = 0; index < this.currentShipments.length; index++) {
                        const element = this.currentShipments[index];
                        console.log(element)
                    }
                    this.loadingData = false;
                }).catch((err)=>{
                    if(err){
                        this.toggleAlertBox = true;
                        this.alertMessage = "No current shipments match the filter request"
                        this.loadingData = false;
                    }
                })
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting filtered current shipments"
                }
                this.loadingData = false;
            }
        },
        async filterInTransit(){
            this.loadingData = true;

            let input;

            input = {
                input:{
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    userName: this.userData.userName,
                    inTransitKey: this.CurrentKey,
                    filterField: this.filterField,
                    filterValue: this.filterValue,
                    filterByWeek: true,
                    isAdmin: true
                }
            }

            try{
                let filterInTransitData = API.graphql({
                query:  `mutation filterInTransitShipments($input: filterInTransitShipmentInputLambda!){ 
                filterInTransitShipments(input: $input){
                            shipmentID
                            service
                            serviceAddress{
                            address{
                                companyName
                                address1
                                city
                                state
                                zipCode
                            }
                        }
                    }
                }`,
                variables: input,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                filterInTransitData.then((response)=>{
                    this.inTransitShipments = response.data.filterInTransitShipments;
                    this.loadingData = false;
                }).catch((err)=>{
                    if(err){
                        this.toggleAlertBox = true;
                        this.alertMessage = "No in transit shipments match the filter request"
                        this.loadingData = false;
                    }
                })
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting filtered in transit shipments"
                }
                this.loadingData = false;
            }
        },
        async filterDelivered(){
            this.loadingData = true;
            let input;

            switch (this.sliderValue) {
                case 1:
                    input = {
                        input:{
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            userName: this.userData.userName,
                            accountNo: this.CurrentKey,
                            filterField: this.filterField,
                            filterValue: this.filterValue,
                            filterByWeek: true,
                            isAdmin: true
                        }
                    }
                    break;
                case 2:
                    input = {
                        input:{
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            userName: this.userData.userName,
                            accountNo: this.CurrentKey,
                            filterField: this.filterField,
                            filterValue: this.filterValue,
                            filterByWeek: false,
                            filterByMonth: [this.toDate.slice(0,7)],
                            isAdmin: true
                        }
                    }
                    break;
                case 3:
                    input = {
                        input:{
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            userName: this.userData.userName,
                            accountNo: this.CurrentKey,
                            filterField: this.filterField,
                            filterValue: this.filterValue,
                            filterByWeek: false,
                            filterByMonth: [this.toDate.slice(0,7), this.previousMonth],
                            isAdmin: true
                        }
                    }
                    break;
                default:
                    break;
            }

            try{
                let filterDeliveredData = API.graphql({
                query:  `mutation filterDeliveredShipments($input: filterDeliveredShipmentInputLambda!){ 
                filterDeliveredShipments(input: $input){
                            shipmentID
                            service
                            serviceAddress{
                            address{
                                companyName
                                address1
                                city
                                state
                                zipCode
                            }
                        }
                    }
                }`,
                variables: input,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                filterDeliveredData.then((response)=>{
                    this.deliveredShipments = response.data.filterDeliveredShipments;
                    this.loadingData = false;
                }).catch((err)=>{
                    if(err){
                        this.toggleAlertBox = true;
                        this.alertMessage = "No delivered shipments match the filter request"
                        this.loadingData = false;
                    }
                })
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting filtered delivered shipments"
                }
                this.loadingData = false;
            }
        },
        async getCurrentShipmentsGraphQL(){
            this.loadingTable = true;
            let graphqlQuery;
            let graphqlQueryPreviousMonth;
            this.currentShipments = []
            let currentArray = []
            let currentArrayPrev = []

            if(this.isFiltered == true){
                graphqlQuery = `query shipmentByCurrentKey{
                    shipmentByCurrentKey(currentKey: "${this.CurrentKey}", filter:{${this.filterField}:{eq: "${this.filterValue}"}}, limit: 1000){
                        ${this.items}
                    }
                }`
            }else{
                graphqlQuery = `query shipmentByCurrentKey{
                    shipmentByCurrentKey(currentKey: "${this.CurrentKey}", limit: 1000){
                        ${this.items}
                    }
                }`
            }
            
            try{
                let shipmentByCurrentKey = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                currentArray = shipmentByCurrentKey.data.shipmentByCurrentKey.items;

                if(graphqlQueryPreviousMonth && graphqlQueryPreviousMonth.length > 0){
                    let shipmentByCurrentKeyPrevious = await API.graphql({
                    query:  graphqlQueryPreviousMonth,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    currentArrayPrev = shipmentByCurrentKeyPrevious.data.shipmentByCurrentKey.items
                }

                if(currentArrayPrev.length > 0){
                    this.currentShipments = currentArray.concat(currentArrayPrev);
                }else{
                    this.currentShipments = currentArray;
                }
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting current shipments"
                }
            }finally{
                this.loadingTable = false;
                this.isFiltered = false;
            }
        },
        async getInTransitShipmentsGraphQL(){
            this.loadingTable = true;
            let graphqlQuery;
            let graphqlQueryPreviousMonth;
            let inTransitArray = []
            let inTransitArrayPrev = []

            if(this.isFiltered == true){
                graphqlQuery = `query shipmentByInTransitKey{
                    shipmentByInTransitKey(inTransitKey: "${this.CurrentKey}", filter:{${this.filterField}:{eq: "${this.filterValue}"}}, limit: 1000){
                        ${this.items}
                    }
                }`
            }else{
                graphqlQuery = `query shipmentByInTransitKey{
                    shipmentByInTransitKey(inTransitKey: "${this.CurrentKey}", limit: 1000){
                        ${this.items}
                    }
                }`
            }

            try{
                this.inTransitShipments = []
                let shipmentByInTransitKey = await API.graphql({
                query:  graphqlQuery,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                
                inTransitArray = shipmentByInTransitKey.data.shipmentByInTransitKey.items;

                if(graphqlQueryPreviousMonth && graphqlQueryPreviousMonth.length > 0){
                    let shipmentByInTransitKeyPrevious = await API.graphql({
                    query:  graphqlQueryPreviousMonth,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    inTransitArrayPrev = shipmentByInTransitKeyPrevious.data.shipmentByInTransitKey.items;
                }

                if(inTransitArrayPrev.length > 0){
                    this.inTransitShipments = inTransitArray.concat(inTransitArrayPrev)
                }else{
                    this.inTransitShipments = inTransitArray;
                }

            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting in transit shipments"
                }
            }finally{
                this.loadingTable = false;
            }
        },
        async getDeliveredShipmentsGraphQL(){
            this.deliveredShipments = []
            this.loadingTable = true;
            let graphqlQuery;
            let graphqlQuerySecond;
            let HistoryKey = this.CurrentKey + '-' + this.toDate.slice(0,7);
            let DeliveryDayTo = this.toDate.slice(8,10);
            let DeliveryDayFrom = this.fromDate.slice(8,10);
            let previousMonthKey = this.CurrentKey + '-' + this.previousMonth
            let deliveredArray = []
            let deliveredArrayPrev = []

            switch (this.sliderValue) {
                case 1:
                    //Check for week overlap with previous month
                    if(DeliveryDayFrom > DeliveryDayTo){
                        const previousMonthDate = new Date();
                        // Set the current date to the first day of the current month
                        previousMonthDate.setDate(1);
                        // Subtract one day to get to the last day of the previous month
                        previousMonthDate.setDate(0);
                        //const numberOfDays = previousMonthDate.getDate(); // Get the number of days in the previous month
                       
                        let previousMonthHistoryKey = this.CurrentKey + "-" + this.fromDate.slice(0, 7)
                        if(this.isFiltered){
                            try{
                                let shipmentByHistoryKeyPrevMonth = await API.graphql({
                                query:  `query shipmentByHistoryKey{
                                    shipmentByHistoryKey(historyKey: "${previousMonthHistoryKey}", limit: 1000) {
                                    ${this.items}
                                }
                                }`,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                })

                                deliveredArrayPrev = shipmentByHistoryKeyPrevMonth.data.shipmentByHistoryKey.items;

                                let shipmentByHistoryKeyCurrentMonth = await API.graphql({
                                query:  `query shipmentByHistoryKey{
                                    shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                                    ${this.items}
                                }
                                }`,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                })

                                deliveredArray = shipmentByHistoryKeyCurrentMonth.data.shipmentByHistoryKey.items;
                              
                                this.deliveredShipments = deliveredArray.concat(deliveredArrayPrev)
                            }catch(e){
                                console.log(e)
                                if(e){
                                    this.toggleAlertBox = true;
                                    this.alertMessage = "Error getting delivered shipments"
                                }
                            }finally{
                                this.loadingTable = false;
                            }
                        }else{
                            try{
                                let shipmentByHistoryKeyCurrentMonth = await API.graphql({
                                query:  `query shipmentByHistoryKey{
                                    shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                                    ${this.items}
                                }
                                }`,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                })
                                deliveredArray = shipmentByHistoryKeyCurrentMonth.data.shipmentByHistoryKey.items;

                                let shipmentByHistoryKeyPrevMonth = await API.graphql({
                                query:  `query shipmentByHistoryKey{
                                    shipmentByHistoryKey(historyKey: "${previousMonthHistoryKey}", limit: 1000) {
                                    ${this.items}
                                }
                                }`,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                })
                                deliveredArrayPrev = shipmentByHistoryKeyPrevMonth.data.shipmentByHistoryKey.items;
                        
                                this.deliveredShipments = deliveredArray.concat(deliveredArrayPrev);
                            }catch(e){
                                console.log(e)
                                if(e){
                                    this.toggleAlertBox = true;
                                    this.alertMessage = "Error getting delivered shipments"
                                }
                            }finally{
                                this.loadingTable = false;
                            }
                        }
                    }else{
                        if(this.isFiltered){
                            try{
                                let shipmentByHistoryKeyCurrentMonth = await API.graphql({
                                query:  `query shipmentByHistoryKey{
                                    shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                                    ${this.items}
                                }
                                }`,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                })

                                deliveredArray = shipmentByHistoryKeyCurrentMonth.data.shipmentByHistoryKey.items;
                              
                                this.deliveredShipments = deliveredArray.concat(deliveredArrayPrev)
                            }catch(e){
                                console.log(e)
                                if(e){
                                    this.toggleAlertBox = true;
                                    this.alertMessage = "Error getting delivered shipments"
                                }
                            }finally{
                                this.loadingTable = false;
                            }
                        }else{
                            try{
                            let shipmentByHistoryKey = await API.graphql({
                                query:  `query shipmentByHistoryKey{
                                    shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                                    ${this.items}
                                }
                                }`,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                })
                                deliveredArray = shipmentByHistoryKey.data.shipmentByHistoryKey.items;
                            
                                this.deliveredShipments = deliveredArray;
                            }catch(e){
                                console.log(e)
                                if(e){
                                    this.toggleAlertBox = true;
                                    this.alertMessage = "Error getting delivered shipments"
                                }
                            }finally{
                                this.loadingTable = false;
                            }
                        }
                    }
                    break;
                case 2:
                    if(this.isFiltered == true){
                    graphqlQuery =  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`
                }else{
                    graphqlQuery = `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`
                }
                try{
                    let shipmentByHistoryKey = await API.graphql({
                    query: graphqlQuery,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    this.deliveredShipments = shipmentByHistoryKey.data.shipmentByHistoryKey.items;
                }catch(e){
                    console.log(e)
                    if(e){
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error getting delivered shipments"
                    }
                }finally{
                    this.loadingTable = false;
                }
                    break;
                case 3:
                    if(this.isFiltered == true){
                    graphqlQuery =  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`

                    graphqlQuerySecond =  `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${previousMonthKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`
                }else{
                    graphqlQuery = `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${HistoryKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`

                        graphqlQuerySecond = `query shipmentByHistoryKey{
                            shipmentByHistoryKey(historyKey: "${previousMonthKey}", limit: 1000) {
                            ${this.items}
                        }
                        }`
                }
                try{
                    let shipmentByHistoryKey = await API.graphql({
                    query: graphqlQuery,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })

                    let shipmentByPreviousMonthKey = await API.graphql({
                    query: graphqlQuerySecond,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })

                    deliveredArray = shipmentByHistoryKey.data.shipmentByHistoryKey.items;
                    deliveredArrayPrev = shipmentByPreviousMonthKey.data.shipmentByHistoryKey.items;
                    this.deliveredShipments = deliveredArray.concat(deliveredArrayPrev);
                }catch(e){
                    console.log(e)
                    if(e){
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error getting delivered shipments"
                    }
                }finally{
                    this.loadingTable = false;
                }
                    break;
            
                default:
                    break;
            }
        },
        async PrintLabel(){
            this.loadingData = true;
            const renderRequest = {
                    data: {
                        user: this.userData.userName,
                        jwtToken: this.jwtToken,
                        idArray: this.selectedShipmentArray
                    },
                    template: {
                        name: "LREXTemplateGraphQLv2",
                        engine: "handlebars",
                        recipe: "chrome-pdf"
                    }
                }
            try{
                let shipmentLabels = await API.graphql({
                query:  createShipmentLabelLambda,
                variables:{ input:{renderRequest} },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                })
                this.labelData = "data:application/pdf;base64, " + shipmentLabels.data.createShipmentLabelLambda.labelImage.slice(1, -1);
            }catch(e){
                console.log(e)
                if(e){
                    this.toggleAlertBox = true;
                    this.alertMessage = "Error getting current shipments"
                }
            }finally{
                this.loadingData = false;
            }
        },
        async VoidShipment(){
            this.loadingData = true;
            let counter = 0;
            this.selectedShipmentArray.forEach(element => {
                const input = {
                    input: {
                            shipmentID: element,
                            username: this.userData.userName
                        }
                    }
                try{
                    counter++;
                    let voidShipment = API.graphql({
                    query:  voidShipmentLambda,
                    variables: input,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    })
                    
                    voidShipment.then((response)=>{
                        console.log(response)
                        if(counter === (this.selectedShipmentArray.length))
                        {
                            this.loadingData = false;
                            this.selectedShipmentArray = [];
                            this.fromDate = this.selectedDates.fromDate; 
                            this.toDate = this.selectedDates.toDate;
                            this.getCurrentShipmentsGraphQL();
                            this.$emit('refreshAfterVoid', true);
                        }
                    }).catch((error)=>{
                        if(error){
                            this.toggleAlertBox = true;
                            this.alertMessage = "Error voiding shipments"
                            this.loadingData = false;
                            this.selectedShipmentArray = [];
                            this.fromDate = this.selectedDates.fromDate; 
                            this.toDate = this.selectedDates.toDate;
                            this.getCurrentShipmentsGraphQL();
                            this.$emit('refreshAfterVoid', true);
                        }
                    })
                }catch(e){
                    console.log(e)
                    if(e){
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error voiding shipments"
                        this.loadingData = false;
                        this.selectedShipmentArray = [];
                        this.fromDate = this.selectedDates.fromDate; 
                        this.toDate = this.selectedDates.toDate;
                        this.getCurrentShipmentsGraphQL();
                        this.$emit('refreshAfterVoid', true);
                    }
                }
            });
        },
        closeLabel(){
            const checkbox = document.getElementById('select-all-checkbox');
            if (checkbox) {
                checkbox.checked = false;
            }
        },
        trackShipment(shipmentID){
            this.toggleTrackShipment = true;
            this.trackShipmentID = shipmentID;
        },
        closeShipmentInfo(event){
            if(this.selectedShipment == 'In Transit'){
                this.getInTransitShipmentsGraphQL();
                this.$emit('refreshAfterVoid', true);
            }
            this.toggleTrackShipment = event;
        },
        closeAlertBox(toggleAlertBox){
            this.toggleAlertBox = toggleAlertBox;
        },
        async downloadCSVTest(){
            this.loadingTable = true;
            let csvArray;

            if (this.selectedShipment == 'Current') {
                csvArray = this.currentShipments.map(obj =>{
                    return {
                        shipmentID: obj.shipmentID,
                        Service: obj.service,
                        AccountNo: obj.accountNo,
                        DeliveryCompanyName: obj.serviceAddress.address.companyName,
                        DeliveryAddress1: obj.serviceAddress.address.address1,
                        DeliveryAddress2: obj.serviceAddress.address.address2,
                        DeliveryCity: obj.serviceAddress.address.city,
                        DeliveryState: obj.serviceAddress.address.state,
                        DeliveryZipCode: obj.serviceAddress.address.zipCode,
                        PickupCompanyName: obj.deliveryPickup.address.companyName,
                        PickupAddress1: obj.deliveryPickup.address.address1,
                        PickupAddress2: obj.deliveryPickup.address.address2,
                        PickupCity: obj.deliveryPickup.address.city,
                        PickupState: obj.deliveryPickup.address.state,
                        PickupZipCode: obj.deliveryPickup.address.zipCode,
                    }
                })
                jsonexport(csvArray, (error, csv) => {
                    if (error) {
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error downloading current shipments"
                    }
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.download = 'CurrentShipments.csv';
                    anchor.click();
                    window.URL.revokeObjectURL(anchor.href);
                    anchor.remove();
                    this.loadingTable = false;
                })
            }else if(this.selectedShipment == 'In Transit'){
                csvArray = this.inTransitShipments.map(obj =>{
                    return {
                        shipmentID: obj.shipmentID,
                        Service: obj.service,
                        AccountNo: obj.accountNo,
                        DeliveryCompanyName: obj.serviceAddress.address.companyName,
                        DeliveryAddress1: obj.serviceAddress.address.address1,
                        DeliveryAddress2: obj.serviceAddress.address.address2,
                        DeliveryCity: obj.serviceAddress.address.city,
                        DeliveryState: obj.serviceAddress.address.state,
                        DeliveryZipCode: obj.serviceAddress.address.zipCode,
                        PickupCompanyName: obj.deliveryPickup.address.companyName,
                        PickupAddress1: obj.deliveryPickup.address.address1,
                        PickupAddress2: obj.deliveryPickup.address.address2,
                        PickupCity: obj.deliveryPickup.address.city,
                        PickupState: obj.deliveryPickup.address.state,
                        PickupZipCode: obj.deliveryPickup.address.zipCode,
                    }
                })
                jsonexport(csvArray, (error, csv) => {
                    if (error) {
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error downloading in transit shipments"
                    }
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.download = 'InTransitShipments.csv';
                    anchor.click();
                    window.URL.revokeObjectURL(anchor.href);
                    anchor.remove();
                    this.loadingTable = false;
                })
            }else if(this.selectedShipment == 'Delivered'){
                csvArray = this.deliveredShipments.map(obj =>{
                    return {
                        shipmentID: obj.shipmentID,
                        Service: obj.service,
                        AccountNo: obj.accountNo,
                        DeliveryCompanyName: obj.serviceAddress.address.companyName,
                        DeliveryAddress1: obj.serviceAddress.address.address1,
                        DeliveryAddress2: obj.serviceAddress.address.address2,
                        DeliveryCity: obj.serviceAddress.address.city,
                        DeliveryState: obj.serviceAddress.address.state,
                        DeliveryZipCode: obj.serviceAddress.address.zipCode,
                        PickupCompanyName: obj.deliveryPickup.address.companyName,
                        PickupAddress1: obj.deliveryPickup.address.address1,
                        PickupAddress2: obj.deliveryPickup.address.address2,
                        PickupCity: obj.deliveryPickup.address.city,
                        PickupState: obj.deliveryPickup.address.state,
                        PickupZipCode: obj.deliveryPickup.address.zipCode,
                    }
                })
                jsonexport(csvArray, (error, csv) => {
                    if (error) {
                        this.toggleAlertBox = true;
                        this.alertMessage = "Error downloading delivered shipments"
                    }
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.download = 'Delivered.csv';
                    anchor.click();
                    window.URL.revokeObjectURL(anchor.href);
                    anchor.remove();
                    this.loadingTable = false;
            })
            }
        }
    }
}
</script>

<style scoped>
h3{
    margin: 0;
}
.table-container-main{
    width: 100%;
    margin-top: 0;
    overflow-x: hidden;
}

.responsive-table{
    width: 100%;
}

* {
    scrollbar-width: auto;
    scrollbar-color: #f3f3f3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.table-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #32ccfe;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
    color: #fff;
}

.table-row-container{
    max-height: 350px;
    overflow-y: auto;
    border-radius: 5px;
    width: 100%;
    animation: animate-table-row 1s ease;
}

@keyframes animate-table-row{
    from{
        margin-left: -50px;
    }
    to{
        margin-left: 0px;
    }
}

.table-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    /* width: calc(100% - 20px); */
}

.table-header .col-1{
    margin-right: 5px;
}

.col-1{
    flex-basis: 20%;
    text-align: left;
    white-space: nowrap;
}

.col-2{
    flex-basis: 40%;
    text-align: left;
    margin-left: 5px;
}

.col-3{
    flex-basis: 30%;
    text-align: left;
    margin-left: 5px;
}

.col-data{
    text-align: left;
}

.col-4{
    flex-basis: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-text{
    background-color: #d4d4d4;
    padding: 2.5px 10px;
    border-radius: 999px;
    width: fit-content;
    margin: 0;
    font-size: 12px;
}

.shipmentid-text{
    margin: 0;
    color: #6a6a6a;
    width: fit-content;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.shipmentid-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    font: inherit;
    color: #33f18a;
    width: 1.05em;
    height: 1.05em;
    border: 0.15em solid #33f18a;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */

    display: grid;
    place-content: center;
    transition-duration: .5s;
}

input[type="checkbox"]:hover{
    background-color: #f3f3f3;
    transition-duration: .5s;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    animation: animate-table-row 1s ease;
}

.print-selected-text{
    margin: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    border-radius: 999px;
    background-color: #f3f3f3;
    border: 1px solid #a0a0a0;
    transition-duration: .5s;
}

.download-csv{
    margin-top: 5px;
}

.void-selected-text{
    margin: 0;
    margin-bottom: 5px;
    padding: 2.5px 10px;
    margin-bottom: 5px;
    border-radius: 999px;
    background-color: #fe804d;
    border: 1px solid #ff5512;
    color: #fff;
    transition-duration: .5s;
}

.print-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.void-selected-text:hover{
    filter: brightness(0.9);
    cursor: pointer;
    transition-duration: .5s;
}

.close-label{
    margin-left: auto;
}

.label-container{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .service-text{
        font-size: 8px;
    }
}

@media only screen and (max-width: 1135px) {
   .col-1{
        min-width: 30%;
    }
}
</style>
<template>
<div class="mobileNavigation">
    <header>
        <div v-if="shipmentPages" class="logo_nav">
            <a href="https://www.lrex.com/" @click="scrollTo('#')">
              <div class="header-logo-container">
                  <div class="lrex-dino-logo">
                    <img src="./assets/LREXDinoFooter.jpg" alt="">
                  </div>
                  <div v-if="shipmentPages && loadingCompanyLogo" class="skeleton-container">
                    <loading-skeleton :loadingHeight="'80px'" />
                  </div>
                  <div v-if="shipmentPages && userDataLogo">
                    <img class="logo" :src="userDataLogo" alt="LREX">
                  </div>
                  <div v-if="(shipmentPages && (userDataLogo == null) && (loadingCompanyLogo == false))">
                    <img class="logo" src="./assets/LREXHeaderLogo.jpg" alt="LREX">
                  </div>
                </div>
            </a>
        </div>
        <div v-else class="logo_nav">
            <a href="https://www.lrex.com/" @click="scrollTo('#')">
              <div class="header-logo-container">
                <div class="lrex-dino-logo">
                  <img src="./assets/LREXDinoFooter.jpg" alt="">
                </div>
                <img class="logo" src="./assets/LREXHeaderLogo.jpg" alt="LREX">
              </div>
            </a>
        </div>
        <div v-if="(url == 'https://www.lrex.com') || (url == 'https://www.stage.lrex.com') || (url == 'https://www.njls.com')" class="create_account">
          <a v-if="!createShipmentToggleSignIn"><button class="button_signin" @click="SignInTogglePopup('SignInButtonTrigger'), scrollToTop()">Sign in</button></a>
          <img src="./assets/Hamburger_icon.png" alt="" @click.prevent="moveNav()" class="menu_icon">
        </div>
        <div v-else class="create_account">
          <a v-if="!createShipmentToggleSignIn"><button class="button_signin" @click="scrollToTop()"><router-link to="/Login" style="text-decoration: none; color: inherit;">Sign in</router-link></button></a>
          <img src="./assets/Hamburger_icon.png" alt="" @click.prevent="moveNav()" class="menu_icon">
        </div>
    </header>
        <nav id="mobileLinks" class="mobileLinks">
            <ul class="nav_links">
                <li v-if="!shipmentPages && !extraPages"><a href="#oursolutions" @click="scrollTo('oursolutions')">Our solutions</a></li>
                <li v-if="!shipmentPages && !extraPages"><a href="#aboutus" @click="scrollTo('aboutus')">About us</a></li>
                <li v-if="!shipmentPages"><a  @click="()=> DropBoxTogglePopup('DropBoxButtonTrigger')">Box locations</a></li>
                <li v-if="!shipmentPages"><a  @click="()=> GetInTouchTogglePopup('GetInTouchButtonTrigger')">Get in touch</a></li>
                <li v-if="!shipmentPages"><a target="_blank" href="https://www.lrex.com/clients/payinvoice/0/0">Pay bill</a></li>
                <li v-if="!shipmentPages"><router-link to="/AnnouncementBoard" @click.prevent="moveNav()">Announcement Board</router-link></li>
                <li v-if="shipmentPages"><router-link to="/Ship" @click.prevent="moveNav()">New Shipment</router-link></li>
                <li v-if="shipmentPages"><router-link to="/ShipmentDashboard" @click.prevent="moveNav()">My Shipments</router-link></li>
                <li v-if="shipmentPages">
                  <a v-on:click="showDropDown = !showDropDown">My LREX</a>
                  <div v-show="showDropDown" class="dropdown-container">
                    <router-link class="dropdown-link" to="/UserDashboard" @click.prevent="moveNav()">Profile</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/UserManagementDashboard" @click.prevent="moveNav()">User Management</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/AccountDashboard" @click.prevent="moveNav()">Account Management</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/PickupDashboard" @click.prevent="moveNav()">Pick Up</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/LocationDashboard" @click.prevent="moveNav()">Location</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/PlaceDashboard" @click.prevent="moveNav()">Places</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/DropboxDashboard" @click.prevent="moveNav()">Dropbox</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/VehicleDashboard" @click.prevent="moveNav()">Vehicles</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/GeoCodeDashboard" @click.prevent="moveNav()">GeoCodes</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/RouteDashboard" @click.prevent="moveNav()">Routes</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/ProductDashboard" @click.prevent="moveNav()">Products</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/PartnerShippingDashboard" @click.prevent="moveNav()">Partner Shipping</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/AdminShipmentDashboard" @click.prevent="moveNav()">Admin ShipmentDashboard</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/AdminUserManagementDashboard" @click.prevent="moveNav()">Admin User Management</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/ManifestDashboard" @click.prevent="moveNav()">Manifest Dashboard</router-link>
                    <router-link v-show="AdminUser || InternalAdmin" class="dropdown-link" to="/QueuedActivitiesDashboard" @click.prevent="moveNav()">Queued Activities Dashboard</router-link>
                    <!-- <router-link class="dropdown-link" to="/ShipmentDashboard">Billing</router-link> -->
                    <router-link v-show="EditorGroup" class="dropdown-link" to="/CreateAnnouncement" @click.prevent="moveNav()">Create Announcement</router-link>
                    <router-link class="dropdown-link-address-book" to="/AddressBookDashboard" @click.prevent="moveNav()">Address Book</router-link>
                  </div>
                </li>
                <li class="create-account-mobile"><a href="https://www.lrex.com/clients/RegisterNewCustomer.aspx" target="_blank"><strong style="color: #fff">Create an account</strong></a></li>
                <li style="border-bottom: none;">
                <amplify-authenticator v-if="authState === 'signedin' && shipmentPages">
                  <div class="sign-out-container">
                      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                      <amplify-sign-out slot="sign-out"
                          id="signout-button"
                          >
                      </amplify-sign-out>
                  </div>
                </amplify-authenticator>
                </li>
            </ul>
        </nav>
  </div>

  <div class="homepage" ref="homepage" :class="{ 'is-hidden': !showHeader }">
    <header>
        <div class="logo_nav">
            <a href="https://www.lrex.com/" @click="scrollTo('#')">
            
            <div v-if="shipmentPages" class="logo_nav">
              <a href="https://www.lrex.com/" @click="scrollTo('#')">
                <div class="header-logo-container">
                  <div class="lrex-dino-logo">
                    <img src="./assets/LREXDinoFooter.jpg" alt="">
                  </div>
                  <div v-if="shipmentPages && loadingCompanyLogo" class="skeleton-container">
                    <loading-skeleton :loadingHeight="'80px'" />
                  </div>
                  <div v-if="shipmentPages && userDataLogo">
                    <img class="logo" :src="userDataLogo" alt="LREX">
                  </div>
                  <div v-if="(shipmentPages && (userDataLogo == null) && (loadingCompanyLogo == false))">
                    <img class="logo" src="./assets/LREXHeaderLogo.jpg" alt="LREX">
                  </div>
                </div>
              </a>
            </div>
            <div v-else class="header-logo-container">
               <div class="lrex-dino-logo">
                <img src="./assets/LREXDinoFooter.jpg" alt="">
              </div>
              <img class="logo" src="./assets/LREXHeaderLogo.jpg" alt="LREX">
            </div>
            
            </a>
        <nav>
            <ul class="nav_links">
                <li class="nav-item" v-if="!shipmentPages && !extraPages"><a href="#oursolutions" @click="scrollTo('oursolutions')">Our solutions</a></li>
                <li class="nav-item" v-if="!shipmentPages && !extraPages"><a href="#aboutus" @click="scrollTo('aboutus')">About us</a></li>
                <li class="nav-item" v-if="!shipmentPages"><a  @click="()=> DropBoxTogglePopup('DropBoxButtonTrigger')">Box locations</a></li>
                <li class="nav-item" v-if="!shipmentPages">
                  <a v-on:mouseover="animateDropdown()">Useful links</a>
                    <div  v-on:mouseleave="animateDropdown()" class="dropdown-menu">
                      <div class="list-container">
                        <li class="nav-router" @click="()=> GetInTouchTogglePopup('GetInTouchButtonTrigger')">Get in touch</li>
                        <li class="nav-router"><router-link to="/IndependentContractor">Drive with us</router-link></li>
                        <li class="nav-router"><a target="_blank" href="https://www.lrex.com/clients/payinvoice/0/0">Pay Bill</a></li>
                        <li class="nav-router"><router-link to="/AnnouncementBoard">Announcement Board</router-link></li>
                      </div>
                    </div>
                </li>
                <li class="nav-item" v-if="shipmentPages"><router-link to="/Ship">New Shipment</router-link></li>
                <li class="nav-item" v-if="shipmentPages"><router-link to="/ShipmentDashboard">My Shipments</router-link></li>
                <li class="my-lrex-nav" v-if="shipmentPages">
                  <a v-on:mouseover="showDropDown = true" v-on:mouseleave="showDropDown = false">My LREX</a>
                  <div v-on:mouseover="showDropDown = true" v-on:mouseleave="showDropDown = false" v-show="showDropDown" class="dropdown-container">
                    <router-link class="dropdown-link" to="/UserDashboard">Profile</router-link>
                    <!-- <router-link class="dropdown-link" to="/ShipmentDashboard">Billing</router-link> -->
                    <router-link v-show="EditorGroup" class="dropdown-link" to="/CreateAnnouncement">Create Announcement</router-link>
                    <router-link class="dropdown-link-address-book" to="/AddressBookDashboard">Address Book</router-link>
                  </div>
                </li>
            </ul>
        </nav>
        </div>

        <div v-if="(url == 'https://www.lrex.com') || (url == 'https://www.stage.lrex.com') || (url == 'https://www.njls.com')" class="create_account">
          <li><a href="https://www.lrex.com/clients/RegisterNewCustomer.aspx" target="_blank"><strong style="color: #32ccfe">Create an account</strong></a></li>
          <a v-if="!createShipmentToggleSignIn"><button class="button_signin" @click="SignInTogglePopup('SignInButtonTrigger'), scrollToTop()">Sign in</button></a>
        </div>
        <div v-else class="create_account">
          <li><a href="https://www.lrex.com/clients/RegisterNewCustomer.aspx" target="_blank"><strong style="color: #32ccfe">Create an account</strong></a></li>
          <a v-if="!createShipmentToggleSignIn"><button class="button_signin" @click="scrollToTop()"><router-link to="/Login" style="text-decoration: none; color: inherit;">Sign in</router-link></button></a>
        </div>

        <amplify-authenticator v-if="authState === 'signedin' && shipmentPages">
          <div class="sign-out-container">
              <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
              <amplify-sign-out slot="sign-out"
                  id="signout-button"
                  >
              </amplify-sign-out>
          </div>
        </amplify-authenticator>

    </header>
    
  </div>

  <div class="popup-container">
    <SignInPopup 
      v-if="SignInPopupTriggers.SignInButtonTrigger" 
      :SignInTogglePopup="()=> SignInTogglePopup('SignInButtonTrigger')"
      class="signin-popup">
    </SignInPopup>

    <DropBoxLocation 
      v-if="DropBoxPopupTriggers.DropBoxButtonTrigger" 
      :DropBoxTogglePopup="()=> DropBoxTogglePopup('DropBoxButtonTrigger')"
    ></DropBoxLocation>

    <GetInTouchPopup 
      v-if="GetInTouchPopupTriggers.GetInTouchButtonTrigger" 
      :GetInTouchTogglePopup="()=> GetInTouchTogglePopup('GetInTouchButtonTrigger')"
      class="signin-popup">
      
    </GetInTouchPopup>
  </div>

  <div v-if="!shipmentPages && !extraPages" ref="homepage">
    <HomePage/>
  </div>
  
  <div v-if="!shipmentPages && !extraPages" ref="oursolutions">
    <OurSolutions/>
  </div>
  
  <div v-if="!shipmentPages && !extraPages" ref="aboutus">
    <AboutUs/>
  </div>

  <dashboard-menu :AdminUser="AdminUser" :InternalAdmin="InternalAdmin" :CustomerAdmin="CustomerAdmin" v-show="showDashboardMenu"/>
  
  <router-view></router-view>

  <div class="cookie-container-main" v-if="authState === 'signedin' || !shipmentPages">
    <div class="cookie-container">
    <p>
        We use cookies in this website to give you the best experience. To find out more, read our
        <router-link to="/PrivacyPolicy">privacy policy</router-link> and <router-link to="/CookiesPolicy">cookie policy</router-link>.
      </p>
      
      <div class="cookie-button-image">
          <button class="cookie-button" @click="cookieBanner()">
            Okay
          </button>
          <img src="./assets/lrexDino-transparent.png" alt="">
      </div>
      
    </div>
  </div>

    <div class="footer">
      <img src="./assets/LREXFooterLogo.jpg" alt="">
      <div class="site_map">
        <div class="inner-site_map">
          <p @click="()=> GetInTouchTogglePopup('GetInTouchButtonTrigger')">Get in Touch</p>
        </div>

        <div class="inner-site_map">
          <p @click="showHelpfulLinks = !showHelpfulLinks" style="cursor: pointer">Helpful Links</p>
          <div v-if="showHelpfulLinks" class="helpful-link-container">
            <router-link class="helpful-link" style="border-bottom: 1px solid #dfdfdf;" to="/CriticalMotionDates"><p>Critical Motion Dates</p><i class="fa fa-angle-right"></i></router-link>
            <router-link class="helpful-link" style="border-bottom: 1px solid #dfdfdf;" to="/FuelSurcharge"><p>Fuel Surcharge</p><i class="fa fa-angle-right"></i></router-link>
            <router-link class="helpful-link" style="border-bottom: 1px solid #dfdfdf;" to="/AdditionalCharges"><p>Additional Charges</p><i class="fa fa-angle-right"></i></router-link>
            <router-link class="helpful-link" style="border-bottom: 1px solid #dfdfdf;" to="/HolidaySchedule"><p>Holiday Schedule</p><i class="fa fa-angle-right"></i></router-link>
            <router-link class="helpful-link" to="/ShippingTC"><p>Shipping T&C</p><i class="fa fa-angle-right"></i></router-link>
          </div>
          
        </div>

        <div class="inner-site_map">
          <router-link class="router-link-footer" to="/TermsConditions"><p>Terms and Conditions</p></router-link>
        </div>
        
        <div class="inner-site_map">
          <router-link class="router-link-footer" to="/CookiesPolicy"><p>Cookie Policy</p></router-link>
        </div>

        <div class="inner-site_map">
          <router-link class="router-link-footer" to="/PrivacyPolicy"><p>Privacy Policy</p></router-link>
        </div>
        
      </div>
    </div>

    <div class="footer_two">
      <div class="footer_track">
            <p>Track a package.</p>
                <form @submit.prevent="ShipmentTrackingTogglePopup('ShipmentTrackingButtonTrigger');">
                    <input type="text" v-model="posts.shipmentId"> <br>
                </form>
      </div>
      <div class="footer_dino">
        <img src="./assets/LREXDinoFooter.jpg" alt="">
      </div>
    </div>
    <p class="footer-text">© Copyright 2022 NJLS (New Jersey Lawyers Service, LLC dba LREX) | All rights reserved.</p>

    <ShipmentTrackingPopup 
        v-if="ShipmentTrackingPopupTriggers.ShipmentTrackingButtonTrigger" 
        :ShipmentTrackingTogglePopup="()=> ShipmentTrackingTogglePopup('ShipmentTrackingButtonTrigger')"
        :shipmentIdProp = "posts.shipmentId"
        >
    </ShipmentTrackingPopup>

    <NewUIPopup></NewUIPopup>

    <ContactUsButton class="contact-us" :class="{ 'is-hidden': !showHeader }"></ContactUsButton>
  
</template>

<script>
import HomePage from './components/HomePage.vue'
import OurSolutions from './components/OurSolutions.vue'
import AboutUs from './components/AboutUs.vue'
import SignInPopup from './components/Popups/SignInPopup.vue'
import GetInTouchPopup from './components/Popups/GetInTouchPopup.vue'
import DropBoxLocation from './components/Popups/DropBoxLocation.vue'
import ShipmentTrackingPopup from './components/Popups/ShipmentTrackingPopup.vue'
import ContactUsButton from './components/Popups/ContactUsButton.vue'
import NewUIPopup from './components/Popups/NJLSLREX.vue'
import LoadingSkeleton from './components/ShipmentDashboard/LoadingSkeleton.vue'
import {ref} from 'vue';
import {onAuthUIStateChange} from "@aws-amplify/ui-components";
import {Auth, API} from 'aws-amplify';
import DashboardMenu from './components/ShipmentDashboard/DashboardMenu.vue'

export default {
  name: 'App',
  data(){
    return{
      showDropDown: false,
      showDropDownExtra: false,
      showAdminDropDown: false,
      showCustomerAdminDropDown: false,
      showHeader: true,
      lastScrollPosition: 0,
      scrollOffset: 40,
      url: window.location.origin,
      authState: undefined,
      posts:{
            shipmentId: null,
            IncludeImageURL: true
      },
      showHelpfulLinks: false,
      userDataLogo: null,
      username: "",
      userDataCognito: null,
      AdminUser: false,
      InternalAdmin: false,
      CreateNewAccount: false,
      Customer: false,
      CustomerAdmin: false,
      ManagePrices: false,
      EditorGroup: false,
      loadingCompanyLogo: false
    }
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)

    Auth.currentAuthenticatedUser().then(user => {
      if(user){
        localStorage.setItem('userAuthenticated', true);
        this.authState = 'signedin';
        this.username = user.username;
      }
    }).catch(error => {
      if(error){
        localStorage.setItem('userAuthenticated', false);
        Auth.signOut({global: false})
      }
    });

    onAuthUIStateChange((nextAuthState, authData) => {
        this.authState = nextAuthState;
        if(nextAuthState === 'verifyContact'){
          this.$router.push('Login')
        }

        if(authData){
          this.username = authData.username;
          this.getAccountUserImage();
          this.userDataCognito = authData;
          if(this.userDataCognito.signInUserSession.idToken.payload['cognito:groups']){
              this.userDataCognito.signInUserSession.idToken.payload['cognito:groups'].forEach((group)=>{
                  if(group == 'Admin'){
                      this.AdminUser = true;
                  }

                  if(group == 'InternalAdmin'){
                      this.InternalAdmin = true;
                  }

                  if(group == 'Customer'){
                      this.Customer = true;
                  }

                  if(group == 'CustomerAdmin'){
                      this.CustomerAdmin = true;
                  }

                  if(group == 'ManagePrices'){
                      this.ManagePrices = true;
                  }

                  if(group == 'EditorGroup'){
                      this.EditorGroup = true;
                  }
              })
          }else{
            return
          }
        }
        if (!authData) {
            this.signedIn = false;
            this.$router.push('Login')
        }
    });

    //Sign user out when JWT expires
    setTimeout(() => {Auth.signOut({global: false})}, 3600000);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  components: {
    HomePage,
    OurSolutions,
    AboutUs,
    SignInPopup,
    GetInTouchPopup,
    DropBoxLocation,
    ShipmentTrackingPopup,
    NewUIPopup,
    ContactUsButton,
    LoadingSkeleton,
    DashboardMenu
  },
  methods: {
    async getAccountUserImage(){
        this.loadingCompanyLogo = true;
        let graphqlQuery = `query getUser{
            getUser(userName: "${this.username}"){
                locationAddress{
                    labelImage
                }
            }
        }`
        
        try{
            let getUser = await API.graphql({
            query:  graphqlQuery,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            })
            
            this.userDataLogo = getUser.data.getUser.locationAddress.labelImage;
        }catch(e){
            console.log(e)
        }finally{
          this.loadingCompanyLogo = false;
        }
    },
    animateDropdown(){
      if(!document.querySelector('.dropdown-menu').classList.contains('dropdown-menu-animate')){
        document.querySelector('.dropdown-menu').classList.add('dropdown-menu-animate')
        document.querySelector('.dropdown-menu').classList.remove('dropdown-menu-close')

        document.querySelector('.list-container').classList.add('list-container-animate')
        document.querySelector('.list-container').classList.remove('list-container-animate-close')
      }else{
        setTimeout(() => {
          document.querySelector('.dropdown-menu').classList.remove('dropdown-menu-animate');
          document.querySelector('.list-container').classList.remove('list-container-animate');
        }, 400);
        document.querySelector('.dropdown-menu').classList.add('dropdown-menu-close');
        document.querySelector('.list-container').classList.add('list-container-animate-close');
      }
    },
    cookieBanner(){
      const cookieContainer = document.querySelector(".cookie-container-main");
      cookieContainer.classList.remove("active");
      localStorage.setItem("cookieBannerDisplayed", "true");
    },
    scrollToTop(){
      window.scrollTo(0,0);
    },
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      this.showHeader = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
      this.showDropDownExtra = false;
    },
    scrollTo(refName){
      const el = this.$refs[refName];
      el.scrollIntoView({behavior: 'smooth'})
      let x = document.getElementById("mobileLinks");
      if(document.body.contains(x)){
        x.style.marginTop = "-100%";
      }
    },
    moveNav(){
      this.showDropDown = false;
      let x = document.getElementById("mobileLinks");
      if(document.body.contains(x)){
          if (x.style.marginTop === "0%") {
            x.style.marginTop = "-100%";
            x.style.transitionDuration = "1s"
          } else {
            x.style.marginTop = "0%";
            x.style.transitionDuration = ".5s"
          }
        }
      },
    moveContactButton(){
      let x = document.getElementById("contact-button");
      if(document.body.contains(x)){
          if (x.style.marginTop === "0%") {
            x.style.marginTop = "-100%";
            x.style.transitionDuration = "1s"
          } else {
            x.style.marginTop = "0%";
            x.style.transitionDuration = ".5s"
          }
        }
      }
  },
  setup(){
    //Sign In Popup
    const SignInPopupTriggers = ref({
      SignInButtonTrigger: false
    });

    const SignInTogglePopup = (trigger) =>{
      SignInPopupTriggers.value[trigger] = !SignInPopupTriggers.value[trigger]
    }

    //DropBox Popup
    const DropBoxPopupTriggers = ref({
      DropBoxButtonTrigger: false
    });

    const DropBoxTogglePopup = (trigger) =>{
      DropBoxPopupTriggers.value[trigger] = !DropBoxPopupTriggers.value[trigger]
    }

    //Get in touch Popup
    const GetInTouchPopupTriggers = ref({
      GetInTouchButtonTrigger: false
    });

    const GetInTouchTogglePopup = (trigger) =>{
      GetInTouchPopupTriggers.value[trigger] = !GetInTouchPopupTriggers.value[trigger]
    }

    //ShipmentTracking Popup
    const ShipmentTrackingPopupTriggers = ref({
      ShipmentTrackingButtonTrigger: false
    });

    const ShipmentTrackingTogglePopup = (trigger) =>{
      ShipmentTrackingPopupTriggers.value[trigger] = !ShipmentTrackingPopupTriggers.value[trigger]
    }

    return{
      SignInTogglePopup,
      SignInPopupTriggers,
      DropBoxTogglePopup,
      DropBoxPopupTriggers,
      GetInTouchTogglePopup,
      GetInTouchPopupTriggers,
      ShipmentTrackingTogglePopup,
      ShipmentTrackingPopupTriggers
    }
  },
  computed:{
    shipmentPages(){
      return this.$route.name == 'Ship' || this.$route.name == 'QueuedActivitiesDashboard' || this.$route.name == 'ManifestDashboard' || this.$route.name == 'AdminUserManagementDashboard' || this.$route.name == 'CustomerAdminShipmentDashboard' || this.$route.name == 'PartnerShippingDashboard' ||this.$route.name == 'AdminShipmentDashboard' || this.$route.name == 'ShipmentCenter' || this.$route.name == 'PickupDashboard' || this.$route.name == 'AccountDashboard' || this.$route.name == 'ProductDashboard' || this.$route.name == 'DropboxDashboard' || this.$route.name == 'RouteDashboard' || this.$route.name == 'GeoCodeDashboard' || this.$route.name == 'VehicleDashboard' || this.$route.name == 'AddressBookDashboard' || this.$route.name == 'LocationDashboard' || this.$route.name == 'PlaceDashboard' || this.$route.name == 'UserDashboard' || this.$route.name == 'CreateAnnouncement' || this.$route.name == 'ShipmentDashboard' || this.$route.name == 'UserManagementDashboard';
    },
    createShipmentToggleSignIn(){
      return this.$route.name == 'Ship' || this.$route.name == 'QueuedActivitiesDashboard' || this.$route.name == 'ManifestDashboard' || this.$route.name == 'AdminUserManagementDashboard' || this.$route.name == 'CustomerAdminShipmentDashboard' || this.$route.name == 'PartnerShippingDashboard' || this.$route.name == 'AdminShipmentDashboard' || this.$route.name == 'ShipmentCenter' || this.$route.name == 'PickupDashboard' || this.$route.name == 'AccountDashboard' || this.$route.name == 'ProductDashboard' || this.$route.name == 'DropboxDashboard' || this.$route.name == 'RouteDashboard' || this.$route.name == 'GeoCodeDashboard' ||  this.$route.name == 'VehicleDashboard' || this.$route.name == 'AddressBookDashboard' || this.$route.name == 'LocationDashboard' || this.$route.name == 'PlaceDashboard' || this.$route.name == 'UserDashboard' || this.$route.name == 'CreateAnnouncement' || this.$route.name == 'ShipmentDashboard' || this.$route.name == 'UserManagementDashboard';
    },
    showDashboardMenu(){
      return this.$route.name == 'AdminUserManagementDashboard' || this.$route.name == 'QueuedActivitiesDashboard' || this.$route.name == 'ManifestDashboard' || this.$route.name == 'CustomerAdminShipmentDashboard' || this.$route.name == 'PartnerShippingDashboard' || this.$route.name == 'AdminShipmentDashboard' || this.$route.name == 'ShipmentCenter' || this.$route.name == 'PickupDashboard' || this.$route.name == 'AccountDashboard' || this.$route.name == 'ProductDashboard' || this.$route.name == 'DropboxDashboard' || this.$route.name == 'RouteDashboard' || this.$route.name == 'GeoCodeDashboard' ||  this.$route.name == 'VehicleDashboard' || this.$route.name == 'LocationDashboard' || this.$route.name == 'PlaceDashboard' || this.$route.name == 'UserDashboard' || this.$route.name == 'CreateAnnouncement' || this.$route.name == 'ShipmentDashboard' || this.$route.name == 'UserManagementDashboard';
    },
    extraPages(){
      // if(this.$route.name == 'Tracking'){
      //   let appBackground = document.getElementById('app');
      //   appBackground.style.backgroundColor = '#282828';
      // }
      return this.$route.name == 'AnnouncementBoard' || this.$route.name == 'ShippingTC' || this.$route.name == 'Tracking' || this.$route.name == 'Login' || this.$route.name == 'ContractorServices' || this.$route.name == 'ZipShip' || this.$route.name == 'HolidaySchedule' || this.$route.name == 'FuelSurcharge' || this.$route.name == 'AdditionalCharges' || this.$route.name == 'IndependentContractor' || this.$route.name == 'CookiesPolicy' || this.$route.name == 'PrivacyPolicy' || this.$route.name == 'TermsConditions' || this.$route.name == 'CriticalMotionDates';
    }
  },
  created(){
        //Toggle Cookie Banner
        setTimeout(() => {
          const cookieContainer = document.querySelector(".cookie-container-main");
          if (!localStorage.getItem("cookieBannerDisplayed")) {
            cookieContainer.classList.add("active");
          }
        }, 2000);
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

.x-button::after{
    content: ' \002B';
    font-weight: bold;
    color: #fff;
    font-size: 17.5px;
}

.x-button-container{
    width: 20px;
    height: 20px;
    background-color: #32ccfe;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    border-radius: 50px;
    cursor: pointer;
    transition-duration: .2s;
}

.x-button-container:hover{
    background-color: #2db9e7;
    transition-duration: .2s;
}

.njls-dba-lrex-container{
  margin-top: 14px;
}

.njls-dba-lrex{
  position: relative;
}

.njls-dba-lrex p{
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  font-weight: bold !important;
}
/* Amplify Authenticator */

    amplify-sign-out{
        --amplify-primary-color: #32ccfe;
        --amplify-secondary-tint: #2cb6e4;
        --amplify-primary-shade: #32ccfe;
        --amplify-primary-tint: #32ccfe;
    }

    .sign-out-container{
      background-color: #32ccfe;
      padding: 10px;
      border-radius: 50px;
      margin-left: 10px;
    }


#app {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 180px;
}

@media only screen and (max-width: 600px){
  #app{
    margin-top: 145px;
  }
}

input{
  font-family: 'Work Sans', sans-serif;
}

textarea{
  font-family: 'Work Sans', sans-serif;
}

a{
  cursor: pointer;
}

button{
  font-family: 'Work Sans', sans-serif;
}

::-webkit-scrollbar {
    background-color: #fff;
    border-radius: 10px;
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-thumb{
    background-color: #33f18a;
    border-radius: 10px;
}

/* Cookie Container */
.cookie-container-main{
  width: 100%;
  display: flex;
  position: fixed;
  text-align: left;
  bottom: -100%;
  justify-content: center;
  z-index: 15;
}

.cookie-container-main.active {
  bottom: .5%;
}

.cookie-container {
  justify-content: center;
  border-radius: 10px;
  width: 60%;
  background: #c0c0c0;
  color: #ffffff;
  padding: 0 32px;
  box-shadow: 0 -2px 16px rgba(47, 54, 64, 0.39);
  transition: 400ms;
}

.cookie-button {
  background: #33f18a;
  border: 0;
  color: #ffffff;
  padding: 12px 38px;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition-duration: .5s;
}

.cookie-button:hover{
  background: #2fdf7e;
  transition-duration: .5s;
}

.cookie-container a {
  color: #2c82e4;
}

.cookie-button-image{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.cookie-button-image img{
  width: 50px;
  margin-left: auto;
}

/* || Header Syles */
    .mobileNavigation{
      display: none;
    }

    .homepage{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #fff;
      z-index: 10;
      box-shadow: 0 6px 6px -6px rgb(218, 218, 218);
      transform: translateY(0);
      transition: transform 300ms linear;
    }

    .homepage.is-hidden{
      transform: translateY(-100%);
    }

    .contact-us{
      transition: transform 300ms linear;
    }

    .contact-us.is-hidden{
      transform: translateX(100%) translateX(5px);
    }

    header{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 30px 5%;
        font-family: 'Work Sans', sans-serif;
        font-size: 1.2vw;
    }

    @media only screen and (max-width: 1150px) and (min-width:1001px){
      header{
        padding: 30px 2%;
      }
    }

    .logo_nav{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: auto;
    }

    .logo_nav img{
      height: 80px;
      max-width: 250px;
    }

    .lrex-dino-logo{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    .lrex-dino-logo img{
      height: 80px;
    }

    .header-logo-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .skeleton-container{
      min-width: 150px;
      min-height: 80px;
    }

    .dropdown-container{
      position: absolute;
      background-color: #fff;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      border-radius: 0 0 5px 5px;
      text-align: left;
      display: flex;
      flex-direction: column;
      animation: drop-down-animate .5s ease;
      z-index: 5;
    }

    @keyframes drop-down-animate {
      from{margin-top: -20px;}
      to{margin-top: 0;}
    }

    .admin-sub-dropdown {
      position: absolute;
      /* top: 0; */
      left: 100%;
      background-color: #fff;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      border-radius: 5px 5px 5px 5px;
      text-align: left;
      display: flex;
      flex-direction: column;
      width: fit-content;
    }

    .admin-menu{
      display: flex;
      flex-direction: row;
    }

    .admin-menu i{
      transition: all 0.4s ease;
    }

    .admin-menu:hover i{
      transform: rotateZ(90deg);
    }

    .mobileLinks{
      overflow-y: auto;
      max-height: 80vh;
    }

    .nav_links{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .nav_links li{
        padding: 0px 20px;
        margin-right: auto;
    }

    .nav-item a:hover{
      border-bottom: 1px solid #ececec;
    }

    .my-lrex-nav:hover{
      border-bottom: transparent;
    }

    .dropdown-link{
      padding: 0;
      margin: 0;
      padding: 2.5px 5px  2.5px 5px;
      white-space: nowrap;
    }

    .dropdown-link:hover{
      background-color: #ececec;
      transition-duration: .5s ease;
    }

    .dropdown-link-address-book:hover{
      background-color: #ececec;
      transition-duration: .5s ease;
    }

    .dropdown-link-address-book{
      padding: 0;
      margin: 0;
      padding: 2.5px 5px  2.5px 5px;
      border-radius: 0 0 5px 5px;
    }

    /* Dropdown More Information */

    /* .dropdown-container{
      background-color: blue;
      height: 100%;
      width: fit-content;
    } */

    .dropdown-menu{
      width: fit-content;
      animation: animate-dropdown .5s ease;
      /* height: auto; */
      height: 280px;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 55px;
      border-radius: 25px;
      padding: 5px;
      margin: 0;
      position: relative;
    }

    .dropdown-menu-animate{
      display: flex;
      animation: animate-dropdown .5s ease;
    }

    .dropdown-menu-close{
      animation: animate-dropdown-close .5s ease;
    }

    .dropdown-menu {
      position: absolute;
      background: #fff;
      border: 4px solid #fff;
      margin-top: 30px;
    }
    .dropdown-menu:after, .dropdown-menu:before {
      bottom: 100%;
      left: 25%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .dropdown-menu:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
      border-width: 30px;
      margin-left: -30px;
    }
    .dropdown-menu:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: rgba(0, 0, 0, 0.008);
      border-width: 36px;
      margin-left: -36px;
    }

    @keyframes animate-dropdown{
      from{height: 0px}
      to{height: 280px}
    }

    @keyframes animate-dropdown-close{
      from{height: 280px}
      to{height: 0px}
    }

    .nav-router{
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      /* padding: 5px; */
      padding: 0px;
      margin: 0;
      height: 60px;
      width: fit-content;
      text-align: center;
      border-radius: 999px;
      transition-duration: .5s;
    }

    .nav-router:hover{
      background-color: #f3f3f3cc;
      transition-duration: .5s;
      cursor: pointer;
    }

    /* .dropdown-menu li{
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 5px;
      margin: 0;
      height: 60px;
      width: 100%;
      text-align: center;
      border-radius: 999px;
      transition-duration: .5s;
    }

    .dropdown-menu li:hover{
      background-color: #f3f3f3cc;
      transition-duration: .5s;
      cursor: pointer;
    } */

    .list-container-animate{
      animation: animate-dropdown-li .5s ease;
    } 

    .list-container-animate-close{
      animation: animate-dropdown-li-close .5s ease;
    }

    @keyframes animate-dropdown-li{
      0%{ height: 0px; opacity: 0; }
      30%{ opacity: 0; }
      100%{ height: 280px; opacity: 1; }
    }

    @keyframes animate-dropdown-li-close{
      0%{ height: 280px; opacity: 1; }
      30%{ opacity: 0; }
      100%{ height: 0px; opacity: 0; }
    }

    .list-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* height: 0px; */
      /* overflow: hidden; */
      /* animation: animate-dropdown-li .5s ease; */
    }

    /* ************************* */

    .nav_links li a{
      text-decoration: none;
      color: black;
      transition: all .35s ease;
      position: relative;
      z-index: 99;
    }

    .create_account{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .create_account li{
        list-style: none;
        padding-right: 20px;
    }

    .create_account li a:hover{
      border-bottom: 1px solid #ffcccc;
    }

    .create_account li a{
      text-decoration: none;
      color: black;
      transition: all .35s ease;
    }

    .button_signin:hover{
      background-color: #2ee480;
    }

    .button_signin{
        padding: 15px 25px;
        background-color: #33f18a;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        font-family: 'Work Sans', sans-serif;
        font-size: 1.2vw;
        font-weight: bold;
        transition: all .5s ease;
    }
/**************************************/
/****Popup */
.popup-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: drop .5s ease forwards;
  margin-bottom: -30px;
}

.popup-container h2{
  margin-top: -5px;
}

/**************************/
/* || Footer Syles */
.footer{
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: solid black 2px;
  padding-top: 75px;
}

.site_map{
  column-count: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
  color: black;
  width: 70%;
}

.site_map div p{
  font-size: 1.4vw;
  text-align: left;
  margin: 5px;
  cursor: pointer;
}

.fa-angle-right{
  margin: 5px;
  margin-left: 10px;
  font-size: 1.4vw;
}

.helpful-link{
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition-duration: .5s;
}

.helpful-link:hover{
  background-color: #dfdfdf69;
  transition-duration: .5s;
  border-radius: 5px;
}

.helpful-link-container{
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  animation: animateHelpfulLink .5s ease;
}

@keyframes animateHelpfulLink{
  from{margin-top: -10%;}
  to{margin-top: 0;}
}

.router-link-footer{
  text-decoration: none;
  color: black;
  text-align: left;
}

.footer img{
  position: relative;
  bottom: 2em;
  width: 15vw;
}

.footer_two{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  padding-top: 1%;
}

.footer_track{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_track input{
  padding: 30px;
  padding-right: 30vw;
  border: 1px solid black;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.footer_track p{
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  color: black;
}

.footer_dino{
  margin-left: 35vw;
}

.footer_dino img{
  width: 5vw;
  position: relative;
  top: 4em;
}

.footer-text{
  font-size: 10px;
  margin-top: 0;
}
@media only screen and (max-width: 1250px) and (min-width: 1001px){
  .nav_links li{
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 365px){
  .logo_nav img{
    height: 30px !important;
  }

  .lrex-dino-logo img{
    height: 30px !important;
  }
}

@media only screen and (max-width: 600px){
  .logo_nav img{
    height: 50px;
  }

  .lrex-dino-logo img{
    height: 50px;
  }
}

@media only screen and (max-width: 1000px){
/**************************/
    .footer-text{
      font-size: 8px;
    }

    .sign-out-container{
      background-color: #32ccfe;
      padding: 10px;
      border-radius: 50px;
      margin-left: -10px;
    }
/* || Header Syles */
    .nav_links{
        display: flex;
        flex-direction: column;
    }

    .nav_links li a{
      z-index: 9;
    }

    .mobileNavigation header{
      position: relative;
      background-color: #fff;
      z-index: 11;
    }

    .homepage{
      display: none;
    }

    .mobileNavigation{
      display: block;
      margin-bottom: 0;
      position: fixed;
      width: 100vw;
      z-index: 10;
      background-color: #fff;
      top: 0;
      left: 0;
      box-shadow: 0 6px 6px -6px rgb(218, 218, 218);
      transform: translateY(0);
      transition: transform 300ms linear;
      border-bottom: 1px solid #32ccfe;
    }

    .mobileNavigation.is-hidden {
      transform: translateY(-100%);
    }

    .mobileNavigation nav{
      margin-top: -100%;
    }

    .create_account button{
      font-size: 3vw;
    }

    .create-account-mobile a{
      background-color: #33f18a;
      padding: 10px;
      border-radius: 50px;
      margin-left: -10px;
    }

    .dropdown-container{
      position: relative;
      background-color: #fff;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
      text-align: left;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      animation: drop-down-animate .5s ease;
    }

    .dropdown-link{
      padding: 5px;
      border-bottom: 1px solid #dfdfdf;
    }

    .dropdown-link-address-book{
      padding: 5px;
    }

    .nav_links li{
      margin-bottom: 5vw;
      font-size: 4vw;
      font-weight: 900;
      position: relative;
      right: 15px;
      width: 70%;

      text-align: left;
    }

    .menu_icon{
      width: 8vw;
      margin-left: 10px;
      cursor: pointer;
    }

    /* || Footer Syles */
  .footer_track input{
    padding-right: 5vw;
    padding: 15px;
  }

  .footer_dino{
    margin-left: 30vw;
  }

  .footer_dino img{
  top: 1.5em;
  }

  .footer{
    padding-top: 50px;
  }

  .footer_track p{
    font-size: 3vw;
  }

  .site_map div p{
    font-size: 2.5vw;
  }
}
</style>
